import { createApp } from "vue";
import App from "./App.vue";
import "@/config/external-scripts";
import vSelect from "vue-select";
//importing all plugins folder
import "@/plugins";
import { plugins } from "@/plugins/allPlugins.js";
// main.js
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
//importing all style
import "@/assets/scss/styleabstract.scss";
const app = createApp(App);
app.component("v-select", vSelect);

plugins.map(({ plugin, params }) => app.use(plugin, params));
app.mount("#app");

