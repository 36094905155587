import { axios } from "@/config/axios";
import { customerQuery, newsletter } from "@/endpoints";
import { notify } from "@kyvg/vue3-notification";
import { STATE_STATUSES } from "@/helpers/constants";

export const actions = {
  async SubmitQuery({ commit }, payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      await axios.post(customerQuery, payload);
      notify({
        type: "success",
        title: "Request Submited",
        text: "Our customer will touch as soon as possible to resolve your issue",
      });
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
  async SubmitNewsLetter({ commit }, payload) {
    commit("NEWSLETTER_STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.post(newsletter, payload);
      commit("NEWSLETTER_STATE_STATUSES", STATE_STATUSES.READY);
      return response;
    } catch (e) {
      console.log("error", e);
      commit("NEWSLETTER_STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
};
