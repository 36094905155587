import { STATE_STATUSES } from "@/helpers/constants";
export default {
  STATE_STATUSES(state, payload) {
    state.status = payload;
  },
  saveAffiliateId(state, payload) {
    state.affiliateId = payload;
  },
  setCountriesLoading(state, payload) {
    state.setCountriesLoading = payload;
  },

  STATE_STATUSES_SENDER(state, payload) {
    state.loadingSender = payload;
  },
  setTravelerReasons(state, payload) {
    state.travelerReasons = payload;
  },
  setSportsAdventure(state, payload) {
    state.sportsAdventure = payload;
  },



  setItiraTravelerReasons(state, payload) {
    state.itira = {
      ...state.itira,
      travel_reasons: payload,
    };
  },
  setItiraTravelerSportAdventure(state, payload) {
    state.itira = {
      ...state.itira,
      travel_sport_adventure: payload,
    };
  },


  skipTravelerReasons(state) {
    state.itira = {
      ...state.itira,
      travel_reasons: null,
    };
  },

  setTravelerConcerns(state, payload) {
    state.travelerConcerns = payload;
  },
  setAllCardProduct(state, payload) {
    state.cart = payload;
  },
  setDestionationType(state, payload) {
    state.destinationType = payload;
  },
  getItiraDetails(state, payload) {
    // state.travelerConcerns = payload;
  },

  setItiraTravelerConcerns(state, payload) {
    state.itira = {
      ...state.itira,
      travel_concerns: payload,
    };
  },
  setTripCost(state, payload) {
    state.itira = {
      ...state.itira,
      trip_cost: payload,
    };
  },
  skipTravelerConcerns(state) {
    state.itira = {
      ...state.itira,
      travel_concerns: null,
    };
  },

  setAlreadyDates(state, payload) {
    state.date = null;
    state.itira = {
      ...state.itira,
      date: payload,
    };
  },

  setTravelers(state, payload) {
    state.itira = {
      ...state.itira,
      travelers: payload,
    };
  },
  setPrice(state, payload) {
    state.itira = {
      ...state.itira,
      trip_cost: { ...state.itira.trip_cost, trip_cost: payload },
    };
  },
  DataByRequest(state, payload) {
    let {
      uuid,
      quote_id,
      destination,
      origin,
      departure_date,
      arrival_date,
      date_type,
      traveler,
      trip_cost,
      first_payment_date,
    } = payload;
    state.airportsDestinationCountry = destination;
    state.airportsOriginCountry = origin;

    state.itira = {
      ...state.itira,
      itira_uuid: uuid,
      travelers: traveler,
      quote_id: quote_id,
      trip_cost: {
        flag: "next",
        first_payment_date,
        trip_cost,
      },
      country: { destination, origin },
      date: { departure_date, return_date: arrival_date, flag: date_type },
    };
  },
  setMoreBooking(state, payload) {
    state.itira = {
      ...state.itira,
      more_booking: payload,
    };
  },

  getItiraAirportsOriginCountry(state, payload) {
    state.airportsOriginCountry = payload;
  },
  setMultipleDestionations(state, payload) {
    state.setMultipleDestinations = payload;
  },
  setMultipleDest(state, payload) {
    state.multipleDestinations = payload;
  },
  saveBothCountryOrigingAndDestionation(state, payload) {
    state.itira = {
      ...state.itira,
      country: payload,
    };
  },
  getItiraAirportsOriginCountryLoader(state, payload) {
    state.airportsOriginCountryLoader = payload;
  },

  getItiraAirportsDestinationCountry(state, payload) {
    state.airportsDestinationCountry = payload;
  },

  updateTravelerNumber(state, payload) {
    state.travelerNumbers.persons = payload;
  },
  updateTravelerPets(state, payload) {
    state.travelerNumbers.pets = payload;
  },
  createMultiTraveler(state, payload) {
    state.addMultiTraveler = [...payload];
  },
  addNewMultiTraveler(state, payload) {
    state.addMultiTraveler = [...state.addMultiTraveler, payload];
  },
  riskScors(state, payload) {
    state.riskScors = payload;
  },
  itiraFinal(state, payload) {
    state.itira = {
      ...state.itira,
      itira_uuid: payload.itira_uuid,
      quote_id: payload.quote_id,
    };
  },

  updateEditTravaler(state, payload) {
    state.itira = {
      ...state.itira,
      travelers: [...state.itira.travelers, payload],
    };
  },
  updateDestinationItira(state, payload) {
    state.itira = {
      ...state.itira,
      country: { ...state.itira.country, destination: payload },
    };
  },

  setPlanApiCpmingFrom(state, payload) {
    state.redirectingFromForProducts = payload;
  },

  setWidgetParamsData(state, payload) {
   // console.log('mutation', payload)
    state.widgetParams = payload;
  },

  resetItira(state, payload) {
    state.itira = {};
    state.destinationType = "TravelSingleDestination";
    state.setMultipleDestinations = [];
    state.multipleDestinations = [];
    state.travelerReasons = null;
    state.travelerConcerns = null;
    state.airportsOriginCountry = null;
    state.airportsOriginCountryLoader = STATE_STATUSES.INIT;
    state.airportsDestinationCountry = null;
    state.airportsDestinationCountryLoader = STATE_STATUSES.INIT;
    state.addMultiTraveler = [];
    state.riskScors = null;
    state.widgetParams = null;
    state.status = STATE_STATUSES.INIT;
    state.loadingSender = STATE_STATUSES.INIT;
    state.travelerNumbers = {
      persons: 1,
      pets: 0,
    };
  },
  resetItiraAffiliateId(state, payload) {
    state.affiliateId = null;
  },
  setTravelersUuid(state, payload) {
    let travelers = state.itira.travelers;
    payload.forEach((element, index) => {
      travelers[index].uuid = element.uuid
    });
    state.itira = {
      ...state.itira,
      travelers,
    };
  },

  removeEditItiraTraveler(state, payload) {
    let travelers = state.itira.travelers;
    travelers = travelers.filter((element) => element.uuid !== payload);
    state.itira = {
      ...state.itira,
      travelers,
    };
  },

  setTravelerInfo(state, payload) {
    state.travelerInfo = {
      ...state.travelerInfo,
      ...payload,
    };
  },


  setTravelerEmailFromCheckout(state, payload) {
    let travelers = state.travelerInfo;
    travelers.email = payload[0].email;
    // travelers.email
    // is_primary
    // payload.forEach((element, index) => {
    //   travelers[index].email = element.email
    // });
     state.travelerInfo = travelers;
  },
};
