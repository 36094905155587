export default {
  name: "PrimaryButton",
  props: {
    onClick: {
      type: Function,
      default: () => {
        console.log("default function is calling kindly pass parent function using onClick props ");
      }
    }
  },
  props: ["label", "id", "data", "disabled", "loading"]
};