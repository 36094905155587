import { axios } from "@/config/axios";
import { blogs, faqs, singleFaq, singleBlog, pressReleases } from "@/endpoints";
import { STATE_STATUSES } from "@/helpers/constants";

export const actions = {
  async fetchAllBlogs({ commit }) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.get(blogs);
      commit("setBlogs", {items:response.data.data , meta:response.data.meta});

      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },

  async loadMoreBlogs({ commit } ,payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.get(`${blogs+'?page='+payload}`);
      commit("setBlogs", {items:response.data.data , meta:response.data.meta});

      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },





  async fetchSingleBlog({ commit }, payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.get(singleBlog(payload));

     // console.log('single blog',response.data.records)
      commit("setSingleBlogs", response.data.records);
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
  async fetchAllFaqs({ commit }) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.get(faqs);

    //  console.log('faq',response)
      commit("setFaqs", response.data.records);

      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
  async fetchSingleFaq({ commit }, payload) {

    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.get(singleFaq(payload));
     // console.log('faq response',response.data.records.detail_faq)
      commit("setSingleFaqs", response.data.records.detail_faq);
      commit("setSingleRelatedFaqs", response.data.records.category_related_faqs);
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },

  // press release

 async fetchPressRelease({ commit }) {
   commit("STATE_STATUSES", STATE_STATUSES.PENDING);
   try {
      const response = await axios.get(pressReleases);
      commit("setPressReleases", response.data.records);
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
   }
    },
};
