const Login = () => import("@/pages/login.vue");
const Forgot = () => import("@/pages/forgot.vue");
const Reset = () => import("@/pages/resetPassword.vue");
const ResetPasswordConformation = () => import("@/pages/resetPasswordConformation.vue");
const Register = () => import("@/pages/register.vue");
const ActivateAccount = () => import("@/pages/activateAccount.vue");
const RequestActivate = () => import("@/pages/requestActivate.vue");
const routes =[
    {
        path: "/login",
        name: "Login",
        component: Login,
        props: { default: true, layout: "Index", footer: true },
    },
    {
        path: "/forgot",
        name: "Forgot",
        component: Forgot,
        props: { default: true, layout: "Index", footer: true },
    },
    {
        path: "/reset-password-email-conformation",
        name: "ResetPasswordConformation",
        component: ResetPasswordConformation,
        props: { default: true, layout: "Index", footer: true },
    },
    {
        path: "/reset-password/:token",
        name: "Reset",
        component: Reset,
        props: { default: true, layout: "Index", footer: true },
    },
    {
        path: "/register",
        name: "Register",
        component: Register,
        props: { default: true, layout: "Index" },
    },
    {
        path: "/activate-account/:token",
        name: "ActivateAccount",
        component: ActivateAccount,
        props: { default: true, layout: "Index", footer: true },
    },
    {
        path: "/request-activate",
        name: "RequestActivate",
        component: RequestActivate,
        props: { default: true, layout: "Index", footer: true },
    },
];
export default routes;
