import { createStore } from "vuex";

//Import Modules
import plans from "./modules/plans";
import itira from "./modules/itira";
import countries from "./modules/countries";
import dashboard from "./modules/dashboard";
import blogandFaq from "./modules/blogandFaq";
import query from "./modules/customerQuery";
import user from "./modules/auth/";
import policies from "./modules/policies";

import claims from "./modules/claims";

import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });
// Create a new store instance in vue3 vux4
export const store = createStore({
  modules: {
    plans,
    countries,
    dashboard,
    blogandFaq,
    query,
    user,
    claims,
    itira,
    policies,
  },

  plugins: [
    createPersistedState({
      key: "g1g",
      paths: ["user", "itira", "plans", "dashboard","policies","claims"],
      // storage: {
      //   getItem: (key) => ls.get(key),
      //   setItem: (key, value) => ls.set(key, value),
      //   removeItem:(key)=>ls.remove(key)
      // },
    }),
  ],
});
