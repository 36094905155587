require("core-js/modules/es.array.push.js");
// src/external-scripts.js

function loadScript(src, async = true) {
  const script = document.createElement('script');
  script.src = src;
  script.async = async;
  document.body.appendChild(script);
}

// Google Maps API
loadScript(`https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}&libraries=places`);

// Zendesk Widget script
const zendeskScript = document.createElement('script');
zendeskScript.id = "ze-snippet";
zendeskScript.src = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.VUE_APP_ZENDESK_KEY}`;
document.body.appendChild(zendeskScript);

// Google Tag Manager
loadScript(`https://www.googletagmanager.com/gtag/js?id=${process.env.VUE_APP_GTAG_KEY}`);
window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
gtag('js', new Date());
gtag('config', process.env.VUE_APP_GTAG_KEY);

// Meta Pixel Code
!function (f, b, e, v, n, t, s) {
  if (f.fbq) return;
  n = f.fbq = function () {
    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
  };
  if (!f._fbq) f._fbq = n;
  n.push = n;
  n.loaded = !0;
  n.version = '2.0';
  n.queue = [];
  t = b.createElement(e);
  t.async = !0;
  t.src = v;
  s = b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t, s);
}(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', process.env.VUE_APP_META_PIXEL_KEY);
fbq('track', 'PageView');

// Hotjar Tracking Code
(function (h, o, t, j, a, r) {
  h.hj = h.hj || function () {
    (h.hj.q = h.hj.q || []).push(arguments);
  };
  h._hjSettings = {
    hjid: process.env.VUE_APP_HOTJAR_KEY,
    hjsv: 6
  };
  a = o.getElementsByTagName('body')[0];
  r = o.createElement('script');
  r.async = 1;
  r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
  a.appendChild(r);
})(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

// Reddit Pixel
!function (w, d) {
  if (!w.rdt) {
    var p = w.rdt = function () {
      p.sendEvent ? p.sendEvent.apply(p, arguments) : p.callQueue.push(arguments);
    };
    p.callQueue = [];
    var t = d.createElement("script");
    t.src = "https://www.redditstatic.com/ads/pixel.js", t.async = !0;
    var s = d.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(t, s);
  }
}(window, document);
rdt('init', process.env.VUE_APP_REDDIT_PIXEL_KEY);
rdt('track', 'PageVisit');

// Segment
!function () {
  var i = "analytics",
    analytics = window[i] = window[i] || [];
  if (!analytics.initialize) if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice.");else {
    analytics.invoked = !0;
    analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "screen", "once", "off", "on", "addSourceMiddleware", "addIntegrationMiddleware", "setAnonymousId", "addDestinationMiddleware", "register"];
    analytics.factory = function (e) {
      return function () {
        if (window[i].initialized) return window[i][e].apply(window[i], arguments);
        var n = Array.prototype.slice.call(arguments);
        if (["track", "screen", "alias", "group", "page", "identify"].indexOf(e) > -1) {
          var c = document.querySelector("link[rel='canonical']");
          n.push({
            __t: "bpc",
            c: c && c.getAttribute("href") || void 0,
            p: location.pathname,
            u: location.href,
            s: location.search,
            t: document.title,
            r: document.referrer
          });
        }
        n.unshift(e);
        analytics.push(n);
        return analytics;
      };
    };
    for (var n = 0; n < analytics.methods.length; n++) {
      var key = analytics.methods[n];
      analytics[key] = analytics.factory(key);
    }
    analytics.load = function (key, n) {
      var t = document.createElement("script");
      t.type = "text/javascript";
      t.async = !0;
      t.setAttribute("data-global-segment-analytics-key", i);
      t.src = "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";
      var r = document.getElementsByTagName("script")[0];
      r.parentNode.insertBefore(t, r);
      analytics._loadOptions = n;
    };
    analytics._writeKey = process.env.VUE_APP_SEGMENT_KEY;
    ;
    analytics.SNIPPET_VERSION = "5.2.0";
    analytics.load(process.env.VUE_APP_SEGMENT_KEY);
    analytics.page();
  }
}();