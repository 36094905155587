export default {
  STATE_STATUSES(state, payload) {
    state.status = payload;
  },
  setBlogs(state ,payload) {

     state.allBlogs.items = [...state.allBlogs.items,...payload.items];
     state.allBlogs.meta = {last_page: payload.meta['last_page']};
  },
  setFaqs(state, payload) {
    state.allFaqs = payload;
  },
  setSingleBlogs(state, payload) {
    state.blog = payload;
  },
  setSingleFaqs(state, payload) {
   // console.log('faq mutation payload',payload)
    state.faq = payload;
  },
  setSingleRelatedFaqs(state, payload) {
    //console.log('faq mutation payload',payload)
    state.relatedFaqs = payload;
  },

  setPressReleases(state, payload) {
   // console.log('pressRelease mutation payload',payload)
    state.pressRelease = payload;
  },

};
