import {uuid} from "vue-uuid";


// Function to get URL parameter by name
function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

var url = window.location.href;
var utm_source_name = getParameterByName('utm_source', url);
var utm_campaign_name = getParameterByName('utm_campaign', url);

localStorage.setItem('utm_source', utm_source_name);
localStorage.setItem('utm_campaign',utm_campaign_name);

const customerInfo = () =>{

    const localStorageData = JSON.parse(localStorage.getItem('g1g'));
    const storedUtmSource = localStorage.getItem('utm_source');
    const storedUtmCampaign = localStorage.getItem('utm_campaign');
    //const utm_source = storedUtmSource === 'null' && storedUtmCampaign === 'null' ? null : `utm_source=${storedUtmSource}&utm_campaign=${storedUtmCampaign}`;
    if (localStorageData.user.user !== null) {
        return {
            ...localStorageData.user.user,
            utm_parameters: {
                utm_source: storedUtmSource,
                utm_campaign: storedUtmCampaign,
            }
        };
    }

    else {
       // console.log(localStorageData.itira.travelerInfo);
        if (localStorageData.itira.travelerInfo === null) {
            return null;
        }
        return {
            ...localStorageData.itira.travelerInfo,
            utm_parameters: {
                utm_source: storedUtmSource,
                utm_campaign: storedUtmCampaign,
            }
        };
    }
}


export {  customerInfo };
