import { customerQuery } from '@/endpoints';

export default {
  STATE_STATUSES(state, payload) {
    state.status = payload;
  },
  NEWSLETTER_STATE_STATUSES(state, payload) {
    state.newLetterStatus = payload;
  },
  sendQuery(state, payload) {
    state.message = payload;
  },
};
