//api for all posts

import {baseURLv2} from "@/config";

export const posts = `posts`;
export const postsById = (id) => `posts/${id}`;
//get all countries
export const countries = `countries`;

//Create User order
export const order = `create-order`;
export const products = `products`;

export const planDetails = `plan-details`;

//carting endpoints
export const cartList = `cart-list`;
export const addToCart = `add-to-cart`;
export const deleteCartItem = `delete-cart-item`;
export const deleteCartItemAll = `clear-all-cart-items`;

//order conform with payment gateway
export const success = `success`;

//check user order
// export const purchasedPolicy = `purchased-policy`;

//get Blog and single blog
export const blogs = `blogs`;
export const singleBlog = (slug) => `blog-detail-view/${slug}`;

//get faq and single faq
export const faqs = `faqs`;
export const singleFaq = (id) => `faq-detail-view/${id}`;

//send customer query
export const customerQuery = `customer-query`;

//send news letter
export const newsletter = `newsletter`;

export const cities_against_state = (state_id) =>
  `cities-against-state/${state_id}`;

//authtentication
export const login = `login`;
export const socialLogin = `social-login`;
export const register = `register`;
export const userDetail = `user-detail`;
export const forgotPassword = `forgot-password`;
export const resetPassword = `reset-password`;
export const getUserDetail = `get-user-details`;

//check user order
export const userDashboard = `user-dashboard`;
export const userDashboardDetailPolicy = (id) =>
  `user-dashboard-detail-policy/${id}`;
// edit beneficiary
export const editPolicy = `user-dashboard-edit-policy`;
export const updateBeneficiary = `update-beneficiary`;
export const extentPolicy = `extend-policy`;
export const cancelPolicy = `cancel-policy`;

//claims
export const allClaims = `claims`;
export const claimDetailView = `claim-detail-view`;
export const createNewClaim = `create-new-claim`;
export const documentUpload = `document-upload`;

export const claimReason = `claim-reason`;
export const claimType = `claim-type`;

export const activateAcctount = (token) => `activate-account/${token}`;

export const activateAcctountByEmail = `resend-email`;

export const addClaim = `add-claim`;

// itira
export const travelReason = `travel-reasons`;
export const getAllSports = `all-sports`;
export const travelConcernEndPoint = `travel-concerns`;
export const itira = `itira`;

// export const airports = `airports`;
export const searchLocation = `/search-location`;

//export const riskEvaluation = `country-risk-score`;
export const riskEvaluation = `country-risk-score-v2`;

//edit itira
export const editqoute = `edit-qoute`;

export const updatetItiraTraveler = `update-traveler`;
export const updateItiratripcost = `update-itira-trip-cost`;

export const updateItiraDestination = `update-itira-destination`;
export const updateItiraTripDates = `update-itira-trip-date`;
export const addNewTraveler = `add-new-traveler`;
export const removeTraveler = `remove-traveler`;

export const editTravelerTicket = `edit-traveler-for-customer-portal`;
export const updateTravelerNameAndAddressPoint = `update-traveler-without-ticket`;
export const updateTravelerWithTicketPoint = `update-traveler-with-ticket`;

//policies

export const getEditTravelerDetails = `edit-traveler-details`;
export const getEditTravelerDates = `edit-traveler-dates`;
export const getEditTravelerDestination = `edit-traveler-destination`;
export const getTripCost = `edit-trip-cost`;
export const getBeneficiaryInfo = `edit-beneficiary`;
export const updateBeneficiaryInfo = `update-beneficiary`;
export const getEmergency = `edit-emergency-contact`;
export const updateEmergency = `update-emergency-contact`;

export const extendPolicyEndDate = `extend-policy`;
export const pressReleases = `press-releases`;

//downloadable files links

export const productDocument = `product-document`;
export const planBrochure = `plan-brochure`;
export const specificUpgradesCostForCallTypeAPI = `specific-upgrades-cost`;
export const getReceipt = `get-receipt`;
export const getVisaLetter = `get-visa-letter`;

// export const productDocument = (uuid) => `product-document/${uuid}`;
// export const planBrochure = (uuid) => `plan-brochure/${uuid}`;

// claim new flow api end points
export const claimTypes = `claim-type`;
