import { toRaw } from "@vue/reactivity";

export default {
  STATE_STATUSES(state, payload) {
    state.status = payload;
  },
  getAllClaims(state, payload) {
    state.claims = payload;
  },
  getClaim(state, payload) {
    state.claim = payload;
  },
  getClaimsTypes(state, payload) {
    state.types = payload;
  },
  getClaimsReason(state, payload) {
    state.reasons = payload;
  },
  setClaimTravelers(state, payload) {
    state.claimsInfo = { ...state.claimsInfo, travelers: payload };
  },
  setClaimTraveler(state, payload) {
    state.createClaim = { ...state.createClaim, traveler: payload };
  },
  setAllClaimTypes(state, payload) {
    state.claimsInfo = { ...state.claimsInfo, types: payload };
  },
  setClaimSelectedType(state, payload) {
    state.claimsInfo = { ...state.claimsInfo, selectedType: payload };
  },

  setClaimType(state, payload) {
    console.log('setClaimType mutation',payload);
    if (state.createClaim?.types?.length > 0) {
      console.log('claim type true');
      let { types } = state.createClaim;

      types.find(({ id }) => payload.id == id)
        ? (state.createClaim.types = types.filter(({ id }) => payload.id != id))
        : (state.createClaim = {
            ...state.createClaim,
            types: [...types, payload],
          });

      return;
    }
    state.createClaim = {
       ...state.createClaim,
      types: [payload],
    };
  },

  setClaimDate(state, payload) {
    state.createClaim = { ...state.createClaim, claim_date: payload };
  },

  //trip cancelation form
  tripCancellationSchema(state, payload) {
    state.claimsInfo = {
      ...state.claimsInfo,
      tripCancellationSchema: payload,
    };
  },
  setTripCancellationPrimaryReason(state, payload) {
    state.claimsInfo = {
      ...state.claimsInfo,
      selectedTripCancellationPrimaryReason: payload,
    };
  },
  getAllTripCancellationEffecteds(state, payload) {
    state.claimsInfo = {
      ...state.claimsInfo,
      tripCancellationEffecteds: payload,
    };
  },
  setTripCancellationEffecteds(state, payload) {
    state.claimsInfo = {
      ...state.claimsInfo,
      selectedTripCancellationEffecteds: payload,
    };
  },
  setSalesIdForClaimsListing(state, payload) {
    console.log('setSalesIdForClaimsListing mutation',payload);
    state.createClaim = { ...state.createClaim, sale_id:payload };
  },
  // for Trip Cancellation claim mutation
  setTripCanellationForm(state, payload) {
    state.createClaim = { ...state.createClaim, ...payload};
  },


  // for travel Delay claim mutation
  setTripDelayReasonsForm(state, payload) {
    state.createClaim = { ...state.createClaim,...payload};
  },
  setTravelDelayForm(state, payload) {
    state.createClaim = { ...state.createClaim,...payload};
  },

  // for Missed Connection claim mutation

  setMissedConnectionReasonsForm(state, payload) {
    state.createClaim = { ...state.createClaim,...payload};
  },
  setMissedConnectionForm(state, payload) {
    state.createClaim = { ...state.createClaim,...payload};
  },

  // for medical care mutation

  setMedicalCareReasonsForm(state,payload){
    state.createClaim = {...state.createClaim , ...payload};
  },




  setTripCanellationDates(state, payload) {
    console.log('setTripCanellationDates mutation',payload);
    state.createClaim = { ...state.createClaim, trip_cancellation_dates:payload };
  },

  setTripCanellationInterruption(state, payload) {
    console.log('setTripCanellationInterruption mutation',payload);
    state.createClaim = { ...state.createClaim, interruption_information:payload };
  },
  setLossInformation(state, payload) {
    console.log('setLossInformation mutation',payload);
    state.createClaim = { ...state.createClaim,
      loss_information:payload.loss_information,
      claim_type:payload.claim_type };
  },

  savedClaimID(state, payload) {
    state.claimsInfo = {saved_claim_id:payload};
  },



  /*setTripCanellationForm(state, payload) {
    console.log(payload);
    state.createClaim = { ...state.createClaim, types: payload.types, selectedType: payload.selectedType };
  },*/
  resetTripClaim(state) {
    state.createClaim = {
      types: [],
    };
  }
};
