import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "navbar-container"
};
import Navbar from "./components/navbar.vue";
import TopHeader from "./components/topHeader.vue";
export default {
  __name: 'mainHeader',
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("header", _hoisted_1, [_createVNode(Navbar)]);
    };
  }
};