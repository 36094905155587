
//Itira Steps routes
const ItiraWelcome = () => import("@/pages/itiraWelcome.vue");
const ItiraSteps = () => import("@/pages/itiraSteps.vue");
const Reasons = () => import("@/components/itiraSteps/travelReasons.vue");
const TravelSportAdventure = () => import("@/components/itiraSteps/travelSportAdventure.vue");
const Concerns = () => import("@/components/itiraSteps/travelConcerns.vue");
const Region = () => import("@/components/itiraSteps/travelRegion.vue");
const DepartureDates = () =>
    import("@/components/itiraSteps/travelDepartureDates.vue");
const AddTravelers = () =>
    import("@/components/itiraSteps/addMultiTravelers.vue");
const TripCost = () => import("@/components/itiraSteps/tripCost.vue");
const MoreBooking = () => import("@/components/itiraSteps/moreBookings.vue");
const RiskEvaluationWait = () =>
    import("@/components/itiraSteps/riskEvaluationWait.vue");
const TravlerNumbers = () =>
    import("@/components/itiraSteps/travelerNumbers.vue");
const MultipleTraveler = () =>
    import("@/components/itiraSteps/multipleTraveler.vue");

//edit itira quote

const EditItiraStepsWrapper = () =>
    import("@/components/editItiraSteps/editItiraStepsWrapper.vue");
const EditItiraQuote = () =>
    import("@/components/editItiraSteps/editItiraQuote.vue");
const EditTraveler = () =>
    import("@/components/editItiraSteps/editTraveler.vue");

const EditDepartureDates = () =>
    import("@/components/itiraSteps/travelDepartureDates.vue");
const EditRegion = () => import("@/components/itiraSteps/travelRegion.vue");
const ItiraEditTripCost = () => import("@/components/itiraSteps/tripCost.vue");
const ItiraEditAdditionalCoverage = () =>
    import("@/components/editItiraSteps/editAdditionalCoverage.vue");
const ItiraEditAddTraveler = () =>
    import("@/components/editItiraSteps/editAddTraveler.vue");
const ItiraEditAddUpgrades = () =>
    import("@/components/editItiraSteps/editAddUpgrades.vue");
const routes = [
    {
        path: "/itira-welcome",
        name: "ItiraWelcome",
        component: ItiraWelcome,
        props: { default: false, footer: false },
    },
    {
        path: "/itira",
        name: "ItiraSteps",
        component: ItiraSteps,
        props: { default: false, footer: false },

        children: [
            {
                path: "travel-reasons",
                name: "Reasons",
                component: Reasons,
            },
            {
                path: "travel-sport-adventure",
                name: "TravelSportAdventure",
                component: TravelSportAdventure,
            },
            {
                path: "travel-concerns",
                name: "Concerns",
                component: Concerns,
            },
            {
                path: "travel-region",
                name: "Region",
                component: Region,
            },
            {
                path: "travel-departure-dates",
                name: "DepartureDates",
                component: DepartureDates,
            },
            {
                path: "add-travelers",
                name: "AddTravelers",
                component: AddTravelers,
            },
            {
                path: "trip-cost",
                name: "TripCost",
                component: TripCost,
            },
            {
                path: "more-booking",
                name: "MoreBooking",
                component: MoreBooking,
            },
            {
                path: "risk-evaluation",
                name: "RiskEvaluationWait",
                component: RiskEvaluationWait,
            },
            {
                path: "travel-numbers",
                name: "TravlerNumbers",
                component: TravlerNumbers,
            },
            {
                path: "add-multiple-travelers",
                name: "MultipleTraveler",
                component: MultipleTraveler,
            },
        ],
    },
    {
        path: "/itira",
        name: "EditItiraStepsWrapper",
        component: EditItiraStepsWrapper,
        props: { default: false, footer: false },

        children: [
            {
                path: "qoute",
                name: "EditItiraQuote",
                component: EditItiraQuote,
            },
            {
                path: "edit-traveler/:id",
                name: "EditTraveler",
                component: EditTraveler,
            },
            {
                path: "edit-depature-date",
                name: "EditDepartureDates",
                component: EditDepartureDates,
            },
            {
                path: "edit-travel-region",
                name: "EditRegion",
                component: EditRegion,
            },
            {
                path: "edit-trip-cost",
                name: "ItiraEditTripCost",
                component: ItiraEditTripCost,
            },
            {
                path: "edit-additional-coverage",
                name: "ItiraEditAdditionalCoverage",
                component: ItiraEditAdditionalCoverage,
            },
            {
                path: "edit-add-new-traveler",
                name: "ItiraEditAddTraveler",
                component: ItiraEditAddTraveler,
            },
            {
                path: "edit-add-upgrades",
                name: "ItiraEditAddUpgrades",
                component: ItiraEditAddUpgrades,
            },
        ],
    },


    ];
export default routes;
