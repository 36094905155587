const components = {
    'understanding-visitors-insurance-coverage-what-doctors-or-hospitals-can-i-visit': () => import('@/pages/blogs/understanding-visitors-insurance-coverage-what-doctors-or-hospitals-can-i-visit.vue'),
    'the-mission-that-drives-us': () => import('@/pages/blogs/the-mission-that-drives-us.vue'),
    'how-to-survive-a-long-flight': () => import('@/pages/blogs/how-to-survive-a-long-flight.vue'),
    'navigating-medical-treatment-while-visiting-the-usa-essential-guidelines': () => import('@/pages/blogs/navigating-medical-treatment-while-visiting-the-usa-essential-guidelines.vue'),
    'health-insurance-guidelines-for-visitors-to-the-usa': () => import('@/pages/blogs/health-insurance-guidelines-for-visitors-to-the-usa.vue'),
    'wildfire-risks-and-travel-insurance-what-every-traveler-should-know': () => import('@/pages/blogs/wildfire-risks-and-travel-insurance-what-every-traveler-should-know.vue'),
    'emergency-support-meets-financial-backing-a-new-era-for-adventure-travelers': () => import('@/pages/blogs/emergency-support-meets-financial-backing-a-new-era-for-adventure-travelers.vue'),
    'travel-insurance-explained-the-ultimate-guide-on-how-to-buy-travel-insurance': () => import('@/pages/blogs/travel-insurance-explained-the-ultimate-guide-on-how-to-buy-travel-insurance.vue'),
    'is-cfar-worth-the-cost': () => import('@/pages/blogs/is-cfar-worth-the-cost.vue'),
    '5-reasons-to-buy-travel-insurance': () => import('@/pages/blogs/5-reasons-to-buy-travel-insurance.vue'),
    '10-things-to-do-before-traveling-abroad': () => import('@/pages/blogs/10-things-to-do-before-traveling-abroad.vue'),
    'travel-insurance-benefit-spotlight-cancel-for-any-reason': () => import('@/pages/blogs/travel-insurance-benefit-spotlight-cancel-for-any-reason.vue'),
    'how-to-take-better-photos-with-smartphone': () => import('@/pages/blogs/how-to-take-better-photos-with-smartphone.vue'),
    'solo-female-travel-safety': () => import('@/pages/blogs/solo-female-travel-safety.vue'),
    'guide-to-preparing-for-your-visiting-parents': () => import('@/pages/blogs/guide-to-preparing-for-your-visiting-parents.vue'),
    'does-my-travel-insurance-cover-coronavirus': () => import('@/pages/blogs/does-my-travel-insurance-cover-coronavirus.vue'),
    'with-you-when-it-matters-g1gs-commitment-to-you': () => import('@/pages/blogs/with-you-when-it-matters-g1gs-commitment-to-you.vue'),
    'is-travel-insurance-worth-it': () => import('@/pages/blogs/is-travel-insurance-worth-it.vue'),
    'questions-you-will-be-asked-when-filing-a-cfar-claim': () => import('@/pages/blogs/questions-you-will-be-asked-when-filing-a-cfar-claim.vue'),
    'trip-cancellation-vs-cancel-for-any-reason': () => import('@/pages/blogs/trip-cancellation-vs-cancel-for-any-reason.vue'),
    'the-3-best-cancel-for-any-reason-travel-insurance-plans': () => import('@/pages/blogs/the-3-best-cancel-for-any-reason-travel-insurance-plans.vue'),
    'planning-a-usa-visit-for-senior-citizens-on-a-b1b2-visa-top-tips-g1g': () => import('@/pages/blogs/planning-a-usa-visit-for-senior-citizens-on-a-b1b2-visa-top-tips-g1g.vue'),
    'faqs-coronavirus-covid-19-effect-on-traveling': () => import('@/pages/blogs/faqs-coronavirus-covid-19-effect-on-traveling.vue'),
    'how-to-choose-best-travel-insurance-company': () => import('@/pages/blogs/how-to-choose-best-travel-insurance-company.vue'),
    'how-do-travel-insurance-claims-get-paid': () => import('@/pages/blogs/how-do-travel-insurance-claims-get-paid.vue'),
    'rock-stacks-demystified': () => import('@/pages/blogs/rock-stacks-demystified.vue'),
    'faqs-coronavirus-outbreak-travel-insurance': () => import('@/pages/blogs/faqs-coronavirus-outbreak-travel-insurance.vue'),
    'cancel-for-any-reason-travel-insurance-explained-guide-for-travelers': () => import('@/pages/blogs/cancel-for-any-reason-travel-insurance-explained-guide-for-travelers.vue'),
    'g1g-partners-with-cap-tripside-assistance-to-enhance-safety-for-international-travelers': () => import('@/pages/blogs/g1g-partners-with-cap-tripside-assistance-to-enhance-safety-for-international-travelers.vue'),
    'how-to-buy-gifts-while-traveling': () => import('@/pages/blogs/how-to-buy-gifts-while-traveling.vue'),
    'how-to-play-host-to-visiting-relatives': () => import('@/pages/blogs/how-to-play-host-to-visiting-relatives.vue'),
    'insider-tips-to-lower-visitor-insurance-costs': () => import('@/pages/blogs/insider-tips-to-lower-visitor-insurance-costs.vue'),
    'how-to-file-a-travel-insurance-claim-a-step-by-step-guide': () => import('@/pages/blogs/how-to-file-a-travel-insurance-claim-a-step-by-step-guide.vue'),
    'which-doctors-can-i-visit-with-g1g-insurance': () => import('@/pages/blogs/which-doctors-can-i-visit-with-g1g-insurance.vue'),
    'when-is-the-best-time-to-buy-travel-insurance': () => import('@/pages/blogs/when-is-the-best-time-to-buy-travel-insurance.vue'),
    'faqs-coronavirus-covid-19': () => import('@/pages/blogs/faqs-coronavirus-covid-19.vue'),
    'is-cancel-for-any-reason-travel-insurance-worth-it-g1g-guide': () => import('@/pages/blogs/is-cancel-for-any-reason-travel-insurance-worth-it-g1g-guide.vue'),
    'g1g-partners-with-cap-tripside-assistance-to-enhance-travel-safety-with-advanced-emergency-evacuation-services': () => import('@/pages/blogs/g1g-partners-with-cap-tripside-assistance-to-enhance-travel-safety-with-advanced-emergency-evacuation-services.vue'),
    'g1g-revolutionizes-travel-safety-with-launch-of-itira-the-ai-powered-platform-ensuring-informed-and-insured-trips': () => import('@/pages/blogs/g1g-revolutionizes-travel-safety-with-launch-of-itira-the-ai-powered-platform-ensuring-informed-and-insured-trips.vue'),

};

const routes = Object.keys(components).map(key => {
    return {
        path: `/blogs/${key}`,
        name: key.replace(/-/g, '_'),
        component: components[key],
        meta: { parentPath: "blog" },
        props: { footer: true },
    };
});

export default routes;
