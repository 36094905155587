import { openBlock as _openBlock, createBlock as _createBlock } from "vue";
import CartListing from "@/components/cart/cartListing.vue";
import { createNamespacedHelpers } from "vuex-composition-helpers";
export default {
  __name: 'cartWrapper',
  setup(__props) {
    const {
      useState
    } = createNamespacedHelpers("plans");
    const {
      cart
    } = useState(["cart"]);
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(CartListing);
    };
  }
};