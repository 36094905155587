export const getters = {
  getSinleBlog(state) {
    return state.blog;
  },
  getBlogs(state) {
    return state.allBlogs;
  },
  getFaqs(state) {
    return state.allFaqs;
  },
  getSinleFaq(state) {
    return state.faq;
  },
  status(state) {
    return state.status;
  },
};
