import { actions } from "./action";
import mutations from "./mutations";

import { STATE_STATUSES } from "@/helpers/constants";
import { reactive } from "@vue/reactivity";

const state = reactive({
  travelers: null,
  travelerDates: null,
  extendPolicy: null,
  editDestination: null,
  cancelPolicy: null,
  status: STATE_STATUSES.INIT,
  selectedTraveler: null,
  travelerDatesLoading: STATE_STATUSES.INIT,

  tripCost: null,
  tripCostLoading: STATE_STATUSES.INIT,

  updateTravelerLoading: STATE_STATUSES.INIT,

  componentId: "TravelDetailsInner",

  beneficiaryInfoLoading: STATE_STATUSES.INIT,
  beneficiaryInfo: null,

  emergencyContactLoading: STATE_STATUSES.INIT,
  emergencyContact: null,

  getReceiptDataStatus: STATE_STATUSES.INIT,
  getVisaLetterDataStatus: STATE_STATUSES.INIT,
  receipt:null,
  visaLetter:null

});

const policies = {
  namespaced: true,
  state,
  actions,
  mutations,
};

export default policies;
