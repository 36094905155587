import { axios } from "@/config/axios";
import moment from "moment";
import Bugsnag from "@bugsnag/js";

import {
  travelReason,
  travelConcernEndPoint,
  itira,
  searchLocation,
  riskEvaluation,
  editqoute,
  updateItiratripcost,
  updatetItiraTraveler,
  updateItiraDestination,
  updateItiraTripDates,
  addNewTraveler, getSports, getAllSports, removeTraveler,
} from "@/endpoints";

import { STATE_STATUSES } from "@/helpers/constants";
import { notify } from "@kyvg/vue3-notification";

import { router } from "@/router/index";
import { toRaw } from "vue";

export const actions = {

  //    for affiliate
  async saveAffiliateId({ commit }, payload) {
    commit("saveAffiliateId", payload);
  },
  //    get reasons
  async getTravelerReasons({ state, commit }) {
    let { travelerReasons } = toRaw(state);
    if (travelerReasons == null) {
      commit("STATE_STATUSES", STATE_STATUSES.PENDING);
      try {
        const response = await axios.get(travelReason);
        console.log(response.data.data,'travelerReasons');
        if (response.data.message && response.data.data != null) {
          commit("setTravelerReasons", response.data.data);
        }

        commit("STATE_STATUSES", STATE_STATUSES.READY);
      } catch (e) {
        console.log("error", e);

        Bugsnag.notify(new Error(e));
        commit("STATE_STATUSES", STATE_STATUSES.ERROR);
      }
    }
  },
  //    get sports
  async getItiraSportsAdventure({ state, commit }) {
    let { stopsAdventure } = toRaw(state);
    if (stopsAdventure == null) {
      commit("STATE_STATUSES", STATE_STATUSES.PENDING);
      try {
        const response = await axios.get(getAllSports);
        console.log('response',response.data.records)
        commit("setSportsAdventure", response.data.records);

        commit("STATE_STATUSES", STATE_STATUSES.READY);
      } catch (e) {
        console.log("error", e);

        Bugsnag.notify(new Error(e));
        commit("STATE_STATUSES", STATE_STATUSES.ERROR);
      }
    }
  },



  async setItiraTravelerReasons({ commit }, payload) {
    commit("STATE_STATUSES_SENDER", STATE_STATUSES.PENDING);

    const targetUUID = '97711730-79d0-48d3-8be5-124d71623fd3';
    const isAdventureStore = payload.includes(targetUUID);

    try {
      const response = await axios.post(itira, {
        itira_step: "travel_reasons",
        travel_reasons: payload,
      });

      if (response.data?.data?.is_validated) {
        //const redirectPath = isAdventureStore ? '/itira/travel-sport-adventure' : '/itira/travel-region';
        const redirectPath = '/itira/travel-region';
        router.push({ path: redirectPath });
        commit("setItiraTravelerReasons", payload);
      }

      commit("STATE_STATUSES_SENDER", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES_SENDER", STATE_STATUSES.ERROR);
    }
  },

  async setItiraSportsAdventure({ state,commit }, payload) {
    //console.log('setItiraSportsAdventure',payload)
     commit("STATE_STATUSES_SENDER", STATE_STATUSES.PENDING);
    try {
      const response = await axios.post(itira, {
        sports: payload,
      });

      if (response.data?.data?.is_validated) {
        commit("setItiraTravelerSportAdventure", payload);
        router.push({
          path: "/itira/travel-region",
        });
      }

      commit("STATE_STATUSES_SENDER", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES_SENDER", STATE_STATUSES.ERROR);
    }
  },


  async skipTravelerReasons({ commit }) {
    commit("skipTravelerReasons");
  },
  async setItiraTravelerConcerns({ commit }, payload) {
    commit("STATE_STATUSES_SENDER", STATE_STATUSES.PENDING);

    try {
      const response = await axios.post(itira, {
        itira_step: "travel_concerns",
        travel_concerns: payload,
      });

      if (response.data?.data?.is_validated) {
        router.push({
          path: "/itira/travel-region",
        });
        commit("setItiraTravelerConcerns", payload);
      }

      commit("STATE_STATUSES_SENDER", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES_SENDER", STATE_STATUSES.ERROR);
    }
  },
  async validateItiraTraveling({ state, commit }, payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);

    let { origin, destination, flag, destinationType } = payload;

    try {
      if (flag == "next") {
        const response = await axios.post(itira, {
          itira_step: "country",
          origin: origin,
          destination,
        });

        if (response.data?.data?.is_validated) {
          router.push({
            path: "/itira/travel-departure-dates",
          });
        }
      }
      if (flag == "update") {
        await axios.post(updateItiraDestination, {
          uuid: state.itira.itira_uuid,
          destination: destination,
        });

        // commit("updateDestinationItira", destination);

        router.push({
          path: "/itira/qoute",
        });
      }
      commit("getItiraAirportsOriginCountry", origin);
      commit("getItiraAirportsDestinationCountry", destination);
      commit("setDestionationType", destinationType);
      commit("saveBothCountryOrigingAndDestionation", payload);
      commit("setMultipleDest", []);
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
  async validateItiraMultiDestinationTraveling({ state, commit }, payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);

    let { origin, destination, countriesID, flag, destinationType } = payload;

    try {
      if (flag == "next") {
        const response = await axios.post(itira, {
          itira_step: "country",
          origin: origin,
          destination: countriesID,
        });

        if (response.data?.data?.is_validated) {
          router.push({
            path: "/itira/travel-departure-dates",
          });
        }
      }
      if (flag == "update") {
        await axios.post(updateItiraDestination, {
          uuid: state.itira.itira_uuid,
          destination: countriesID,
        });

        commit("updateDestinationItira", destination);

        router.push({
          path: "/itira/qoute",
        });
      }
      commit("getItiraAirportsOriginCountry", origin);
      commit("setMultipleDest", destination);
      commit("setDestionationType", destinationType);
      commit("getItiraAirportsDestinationCountry", null);

      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
  async skipTravelerConcerns({ commit }) {
    commit("skipTravelerConcerns");
  },
  async updateTravelerNumber({ commit }, payload) {
    if (payload >= 11) {
      notify({
        type: "error",
        title: "Adding Person Error",
        text: "you cant be add more than 10 person",
      });
      return;
    }
    if (payload <= 0) {
      return;
    }
    commit("updateTravelerNumber", payload);
  },
  async updateTravelerPets({ commit }, payload) {
    if (payload >= 10) {
      notify({
        type: "error",
        title: "Adding Pets Error",
        text: "you cant be add more than 10 person",
      });
      return;
    }
    if (payload <= -1) {
      return;
    }

    commit("updateTravelerPets", payload);
  },
  async createMultiTraveler({ commit }, payload) {
    commit("createMultiTraveler", payload);
  },
  async addNewMultiTraveler({ commit }, payload) {
    if (payload >= 10) {
      notify({
        type: "error",
        title: "Adding Person Error",
        text: "you cant be add more than 10 person",
      });
      return;
    }
    if (payload <= -1) {
      return;
    }
    commit("addNewMultiTraveler", payload);
  },



  // get concerns
  async getTravelerConcerns({ state, commit }) {
    let { travelerConcerns } = toRaw(state);

    if (travelerConcerns == null) {
      commit("STATE_STATUSES", STATE_STATUSES.PENDING);
      try {
        const response = await axios.get(travelConcernEndPoint);
        if (response.data.message && response.data.data != null) {
          commit("setTravelerConcerns", response.data.data);
        }

        commit("STATE_STATUSES", STATE_STATUSES.READY);
      } catch (e) {
        console.log("error", e);
        commit("STATE_STATUSES", STATE_STATUSES.ERROR);
      }
    }
  },
  async getItiraAirportsOriginCountry({ commit }, payload) {
    commit("setCountriesLoading", STATE_STATUSES.PENDING);
    try {
      const response = await axios.get(searchLocation, {
        params: {
          query: payload,
        },
      });
      commit("setCountriesLoading", STATE_STATUSES.READY);
      return response.data.data;
    } catch (e) {
      console.log("error", e);
      commit("setCountriesLoading", STATE_STATUSES.ERROR);
    }
  },
  async getItiraAirportsDestinationCountry({ commit }) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.get(airports);
      commit("getItiraAirportsDestinationCountryLoader", STATE_STATUSES.READY);
      commit("getItiraAirportsDestinationCountry", response.data);
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
  async validateItiraChooseDate({ state, commit }, payload) {

    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    let { flag, departure_date, return_date } = payload;
   // console.log('validateItiraChooseDate flag',flag)
    try {
      if (flag == "next") {
        const response = await axios.post(itira, {
          itira_step: "date",
          date_type: "choose_dates",
          ...payload,
        });

        if (response.data?.data?.is_validated) {
          commit("setAlreadyDates", { ...payload, flag: "chooseDates" });
          commit("STATE_STATUSES", STATE_STATUSES.READY);
          router.push({
            path: "/itira/travel-numbers",
          });
        }
        return;
      }

      if (flag == "update") {
        const response = await axios.post(updateItiraTripDates, {
          date_type: "choose_dates",
          uuid: state.itira.itira_uuid,
          departure_date,
          arrival_date: return_date,
        });

        commit("setAlreadyDates", { ...payload, flag: "chooseDates" });
        commit("STATE_STATUSES", STATE_STATUSES.READY);
        router.push({
          path: "/itira/qoute",
        });

        return;
      }
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },

  async validateItiraAlreadyTraveling({ state, commit }, payload) {

    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    let { flag, departure_date, return_date } = payload;
   // console.log('validateItiraAlreadyTraveling flag',flag)
    try {
      if (flag == "next") {
        const response = await axios.post(itira, {
          itira_step: "date",
          date_type: "already_dates",
          ...payload,
        });

        if (response.data?.data?.is_validated) {
          commit("setAlreadyDates", { ...payload, flag: "alreadyDates" });
          commit("STATE_STATUSES", STATE_STATUSES.READY);
          router.push({
            path: "/itira/travel-numbers",
          });
        }
        return;
      }
      if (flag == "update") {
        const response = await axios.post(updateItiraTripDates, {
          date_type: "already_dates",
          uuid: state.itira.itira_uuid,
          departure_date,
          arrival_date: return_date,
        });

        commit("setAlreadyDates", { ...payload, flag: "alreadyDates" });
        commit("STATE_STATUSES", STATE_STATUSES.READY);
        router.push({
          path: "/itira/qoute",
        });

        return;
      }
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },

  async validateItiraFlexibleTraveling({ state, commit }, payload) {

    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    let { flag, departure_date, return_date, flexible_date_type } = payload;
   // console.log('validateItiraFlexibleTraveling flag',flag)
    try {
      if (flag == "next") {
        console.log("payload", payload);

        let sendPayload = {};
        let monthIntoString = null;
        if (flexible_date_type == "month") {
          let dateFormat = "YYYY-MM-DD";
          let combineDateAll = payload.months.map(({ year, month_number }) =>
            moment(`${year}-${month_number}-01`)
          );

          let maxDate = moment.max(combineDateAll);
          let minDate = moment.min(combineDateAll);
          monthIntoString = combineDateAll.map((date) =>
            moment(date).format(dateFormat)
          );

          departure_date = minDate.format(dateFormat);
          return_date = maxDate.format(dateFormat);
          //console.log("departure_date", departure_date);
          //console.log("return_date", return_date);
          sendPayload = {
            itira_step: "date",
            date_type: "flexible_dates",
            ...payload,
            departure_date,
            return_date,
            months: combineDateAll,
          };
        } else {
          sendPayload = {
            itira_step: "date",
            date_type: "flexible_dates",
            ...payload,
            departure_date,
            return_date,
          };
        }

        const response = await axios.post(itira, sendPayload);

        if (response.data?.data?.is_validated) {
          commit("setAlreadyDates", {
            ...payload,
            flag: "felxible",
            departure_date,
            return_date,
            combineMonths: monthIntoString,
          });
          commit("STATE_STATUSES", STATE_STATUSES.READY);
          router.push({
            path: "/itira/travel-numbers",
          });
        }
        return;
      }
      if (flag == "update") {
        let { departure_date, return_date, flexible_date_type } = payload;
        //console.log('flexble payload',payload)
        if (flexible_date_type == "week" || flexible_date_type == "year") {
          const response = await axios.post(updateItiraTripDates, {
            date_type: "flexible_dates",
            uuid: state.itira.itira_uuid,
            flexible_date_type,
            departure_date,
            arrival_date: return_date,
          });
        }else if (flexible_date_type == "month") {
          const response = await axios.post(updateItiraTripDates, {
            date_type: "flexible_dates",
            uuid: state.itira.itira_uuid,
            ...payload,
            flexible_date_type,
          });
        }

        commit("setAlreadyDates", { ...payload, flag: "felxible" });
        commit("STATE_STATUSES", STATE_STATUSES.READY);
        router.push({
          path: "/itira/qoute",
        });
        return;
      }
      commit("STATE_STATUSES", STATE_STATUSES.READY);

    } catch (e) {
      if (flag == "update"){
        router.push({
          path: "/itira/qoute",
        });
      }else {
        router.push({
          path: "/itira/travel-numbers",
        });
      }

      commit("setAlreadyDates", { ...payload, flag: "felxible" });
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },

  async validateMultiTraveler({ state,commit }, payload) {
   
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    let { country } = toRaw(state.itira);
    try {
      const response = await axios.post(itira, {
        itira_step: "travelers",

        travelers: payload,
      });

      if (response.data?.data?.is_validated) {

        commit("setTravelers", payload);
        commit("STATE_STATUSES", STATE_STATUSES.READY);
        //console.log(country.origin.country_iso);

        if (country.origin.country_iso == 'US')
        {
          router.push({
            path: "/itira/trip-cost",
          });
        }else if (country.origin.country_iso == 'US'){
          //if more booking option need to add again then /more-booking route should place here
          const response = await axios.post(riskEvaluation, {
            destination: country.destination,
          });
          if (response.data?.data) {
            commit("STATE_STATUSES", STATE_STATUSES.READY);
            commit("riskScors", response.data?.data);
            router.push({
              path: "/itira/risk-evaluation",
            });
          }
          // router.push({
          //   path: "/itira/more-booking",
          // });
        }else {
          const more_booking = false
          commit("setMoreBooking", more_booking);
          const response = await axios.post(riskEvaluation, {
            destination: country.destination,
          });
          if (response.data?.data) {
            commit("STATE_STATUSES", STATE_STATUSES.READY);
            commit("riskScors", response.data?.data);
            router.push({
              path: "/itira/risk-evaluation",
            });
          }
          // router.push({
          //   path: "/itira/more-booking",
          // });
        }
      }
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },

  async validateTripCost({ state, commit }, payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    let { flag } = payload;
    let { country } = toRaw(state.itira);
    try {
      if (flag == "next") {
        const response = await axios.post(itira, {
          itira_step: "trip_cost",
          ...payload,
        });

        if (response.data?.data?.is_validated) {
          commit("setTripCost", payload);
          commit("STATE_STATUSES", STATE_STATUSES.READY);
          // due to skip the more booking page need to redirect the page to riskscore from trip cost page
          const response = await axios.post(riskEvaluation, {
            destination: country.destination,
          });
          if (response.data?.data) {
            commit("STATE_STATUSES", STATE_STATUSES.READY);
            commit("riskScors", response.data?.data);
            router.push({
              path: "/itira/risk-evaluation",
            });
          }
          // router.push({
          //   path: "/itira/more-booking",
          // });
        }
        return;
      }
      if (flag == "update") {
       // console.log("state", state.itira.itira_uuid);
        const response = await axios.post(updateItiratripcost, {
          uuid: state.itira.itira_uuid,
          trip_cost: payload.trip_cost,
          first_payment_date: payload.first_payment_date,
        });
        commit("setTripCost", payload);

        router.push({
          path: "/itira/qoute",
        });

        commit("STATE_STATUSES", STATE_STATUSES.READY);
        return;
      }
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },

  async skipTripCost({ state, commit }) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    let { country } = toRaw(state.itira);
    try {
      const response = await axios.post(riskEvaluation, {
        destination: country.destination,
      });
      if (response.data?.data) {
        commit("STATE_STATUSES", STATE_STATUSES.READY);
        commit("riskScors", response.data?.data);
        router.push({
          path: "/itira/risk-evaluation",
        });
      }
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
//more booking is not used for now
  async validateMoreBooking({ commit }, payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);

    try {
      const response = await axios.post(itira, {
        itira_step: "more_booking",

        more_booking: payload.more_booking,
      });

      if (response.data?.data?.is_validated) {
        commit("setMoreBooking", payload);

        const response = await axios.post(riskEvaluation, {
          destination: payload.destination,
        });

        if (response.data?.data) {
          commit("STATE_STATUSES", STATE_STATUSES.READY);
          commit("riskScors", response.data?.data);
          router.push({
            path: "/itira/risk-evaluation",
          });
        }
      }
    } catch (e) {
      console.log("error", e);
      router.push({
        path: "/itira/risk-evaluation",
      });
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    }
  },

  async getRiskScore({ commit }, payload) {
    try {
      const response = await axios.post(riskEvaluation, {
        destination: payload,
      });

      //console.log('response getRiskScore',response.data?.data);

      if (response.data?.data) {
        commit("STATE_STATUSES", STATE_STATUSES.READY);
        commit("riskScors", response.data?.data);
      }
    } catch (e) {
      console.log("error", e);

      commit("STATE_STATUSES", STATE_STATUSES.READY);
    }
  },

  async itiraFinal({ commit }, payload) {
    try {
      commit("STATE_STATUSES", STATE_STATUSES.PENDING);
      const response = await axios.post("itira", payload);
      if (response.data?.data?.is_validated) {
        commit("itiraFinal", response.data.data);
      }

      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },

  async resetItiraStore({ commit }) {
    commit("resetItira");
  },
  async resetItiraAffiliateId({ commit }) {
    commit("resetItiraAffiliateId");
  },

  async getItiraDetails({ state, commit }) {
    try {
      commit("STATE_STATUSES", STATE_STATUSES.PENDING);
      const response = await axios.post(editqoute, {
        uuid: state.itira.itira_uuid,
      });

      commit("setTravelers", response.data.data.itira.traveler);
      commit("setPrice", response.data.data.itira.trip_cost);

      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },

  async getItiraDetailsByGetRequest({ state, commit }, payload) {
    try {
      commit("STATE_STATUSES", STATE_STATUSES.PENDING);
      const response = await axios.get("itira", {
        params: {
          quote_id: payload,
        },
      });
      //console.log(response.data.data,'getItiraDetailsByGetRequest')
      /*if (response.data.status === 205){
  Swal.fire({
    icon: "warning",
    title: "Plan already purchased",
    text: response.data.message,
    timer: 5000,
  });
  router.push({
    path: "/login",
  });

}*/
      commit("DataByRequest", response.data.data);
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },

  async updateEditItiraTraveler({ state, commit }, payload) {
    try {
      commit("STATE_STATUSES", STATE_STATUSES.PENDING);
      const response = await axios.post(updatetItiraTraveler, {
        uuid: state.itira.itira_uuid,
        ...payload.update,
      });

      commit("updateEditTravaler", ...payload.orginal);

      router.push({
        path: "/itira/qoute",
      });
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);

      router.push({
        path: "/itira/qoute",
      });
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },

  async removeEditItiraTraveler({ state, commit }, payload) {
    console.log(payload,"payload");
    try {
      commit("STATE_STATUSES", STATE_STATUSES.PENDING);
      const response = await axios.post(removeTraveler, {
        itira_uuid: state.itira.itira_uuid,
        traveler_uuid:payload,
      });
      console.log(response,"response");
      commit("removeEditItiraTraveler", payload);

      router.push({
        path: "/itira/qoute",
      });
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);

      router.push({
        path: "/itira/qoute",
      });
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },

  async addNewTravelerSigle({ state, commit }, payload) {
    try {
      commit("STATE_STATUSES", STATE_STATUSES.PENDING);
      const response = await axios.post(addNewTraveler, {
        uuid: state.itira.itira_uuid,
        ...payload,
      });

      router.push({
        path: "/itira/qoute",
      });
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);

      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },

  async setMultipleDestionations({ commit }, payload) {
    commit("setMultipleDestionations", payload);
  },
  async setPlanApiCpmingFrom({ commit }, payload) {
    commit("setPlanApiCpmingFrom", payload);
  },
  async addTravelersUuid({ commit }, payload) {
    commit("setTravelersUuid", payload);
  },

  async setWidgetParams({ commit },payload) {
   // console.log('action',payload);
    commit('setWidgetParamsData',payload)
  },

  async setTravelerInfo({ commit },payload) {
    // console.log('action',payload);
    commit('setTravelerInfo',payload)
  },

  async setTravelerEmailFromCheckout({ commit },payload) {
    // console.log('action',payload);
    commit('setTravelerEmailFromCheckout',payload)
  },

};
