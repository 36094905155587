// checkout

const CheckoutWrapper = () => import("@/components/checkout/checkoutWrapper");
const CheckoutTraveler = () => import("@/components/checkout/checkoutTraveler");
const CheckoutBeneficiary = () =>
    import("@/components/checkout/checkoutBeneficiary");
const CheckoutPayment = () => import("@/components/checkout/checkoutPayment");
const Thankyou = () => import("@/pages/thankyou.vue");
const CheckOrder = () => import("@/pages/checkOrder.vue");
const Checkout = () => import("@/pages/checkout.vue");

const Cart = () => import("@/pages/cart.vue");
const PaymentCancel = () => import("@/pages/paymentCancellation.vue");

const routes = [

    {
        path: "/checkout",
        name: "CheckoutWrapper",
        component: CheckoutWrapper,
        meta: {
            isAuth: true,
            allowGuest: true,
        },
        props: { default: false, footer: false },
        children: [
            {
                path: "traveler",
                name: "CheckoutTraveler",
                component: CheckoutTraveler,
            },
            {
                path: "beneficiary",
                name: "CheckoutBeneficiary",
                component: CheckoutBeneficiary,
            },
            {
                path: "payment",
                name: "CheckoutPayment",
                component: CheckoutPayment,
            },
        ],
    },
    {
        path: "/checkout/:id",
        name: "Checkout",
        component: Checkout,
        meta: {
            isAuth: true,
        },
        props: { default: true, layout: "", footer: true },
    },
    {
        path: "/thank-you",
        name: "Thankyou",
        component: Thankyou,
        props: { default: true, layout: "Index", footer: true },
    },
    {
        path: "/payment-cancel",
        component: PaymentCancel,
        props: { default: true, layout: "Index", footer: true },
    },
    {
        path: "/check-order",
        name: "CheckOrder",
        component: CheckOrder,

        props: { default: true, layout: "Index", footer: true },
    },
    {
        path: "/cart-list",
        name: "addToCart",
        component: Cart,
        props: { default: true, layout: "Index", footer: true },
    },
];
export default routes;
