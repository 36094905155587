import { axios } from '@/config/axios';
import { cities_against_state } from '@/endpoints';
import { countriesList } from './countries';
import { STATE_STATUSES } from '@/helpers/constants';
import {countriesPhoneCodes} from "./countriesPhoneCode";

export const actions = {
  async fetchAllcountries({ commit }, payload) {
    commit('STATE_STATUSES', STATE_STATUSES.PENDING);
    try {
      commit('allCountries', countriesList);
      commit('STATE_STATUSES', STATE_STATUSES.READY);
    } catch (e) {
      console.log('error', e);
      commit('STATE_STATUSES', STATE_STATUSES.ERROR);
    }
  },
  async fetchAllCountriesPhoneCodes({ commit }, payload) {
    commit('STATE_STATUSES', STATE_STATUSES.PENDING);
    try {
      commit('allCountriesPhoneCodes', countriesPhoneCodes);
      commit('STATE_STATUSES', STATE_STATUSES.READY);
    } catch (e) {
      console.log('error', e);
      commit('STATE_STATUSES', STATE_STATUSES.ERROR);
    }
  },
  async fetcCitiesAgainstState({ commit }, payload) {
    commit('STATE_STATUSES', STATE_STATUSES.PENDING);
    try {
      const response = await axios.get(cities_against_state(payload));
      commit('fetcCitiesAgainstState', response.data.records);
      commit('STATE_STATUSES', STATE_STATUSES.READY);
    } catch (e) {
      console.log('error', e);
      commit('STATE_STATUSES', STATE_STATUSES.ERROR);
    }
  },
};
