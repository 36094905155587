
//   Media kit
const MediaMain = () => import("@/pages/mediaMain.vue");
const MediaKitHome = () => import("@/components/mediaKit/MediaKitHome.vue");
const RecentRelease = () => import("@/components/mediaKit/recentReleases.vue");
const ContentGuidelines = () =>
    import("@/components/mediaKit/contentGuidelines.vue");
const MediaAssetsPage = () =>
    import("@/components/mediaKit/mediaAssetsPage.vue");
const Logos = () => import("@/components/mediaKit/logos.vue");
const Videos = () => import("@/components/mediaKit/videos.vue");
const Images = () => import("@/components/mediaKit/images.vue");



const routes =[
    {
        path: "/media",
        name: "MediaMain",
        component: MediaMain,
        props: { default: true, footer: true },
        children: [
            {
                path: "media-kit",
                name: "MediaKitHome",
                component: MediaKitHome,
            },
            {
                path: "recent-release",
                name: "RecentRelease",
                component: RecentRelease,
            },
            {
                path: "content-guidelines",
                name: "ContentGuidelines",
                component: ContentGuidelines,
            },
            {
                path: "media-assets",
                name: "MediaAssetsPage",
                component: MediaAssetsPage,
            },
            {
                path: "logos",
                name: "Logos",
                component: Logos,
            },
            {
                path: "videos",
                name: "Videos",
                component: Videos,
            },
            {
                path: "images",
                name: "Images",
                component: Images,
            },
        ],
    },
];
export default routes;
