import { toRaw } from "vue";
import { axios } from "@/config/axios";
import {
  products,
  order,
  planDetails,
  cartList,
  addToCart,
  deleteCartItem,
  deleteCartItemAll,
  success, specificUpgradesCost, specificUpgradesCostForCallTypeAPI,
} from "@/endpoints";
import { toggleCartModal } from "@/helpers/modal";
import { STATE_STATUSES } from "@/helpers/constants";
import { router } from "@/router/index";
import {notify} from "@kyvg/vue3-notification";
import Swal from "sweetalert2";
import {track} from "@/analytics/events";
import {beneficiaryState, billingState, checkoutTravelersState, emergencyContactState} from "@/composable/vuelidate";
export const actions = {
  async fectAllProducts({ commit }, payload) {
    try {
      commit("STATE_STATUSES", STATE_STATUSES.PENDING);
      const response = await axios.post(products, payload);

      commit("getAllPlans", response.data.records);
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },

  async fetchAllPlanAgianstFilters({ commit, state }, payload) {
    let { allPlans } = toRaw(state);
    let { trip_details } = allPlans;
    trip_details = {
      ...trip_details,
      add_on_upgrades: [""],
      state_billing: payload.state_billing,
      origin_country: [payload.origin_country],
      destination: [payload.destination],
      travelers_ages: [payload.age.toString()],
      total_trip_cost: payload.trip_cost,
      trip_start_date: payload.TripStartDate,
      trip_end_date: payload.TripEndDate,
    };
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.get(products, {
        params: trip_details,
      });
     // console.log('getAllPlans',response.data.records)
      commit("getAllPlans", response.data.records);

      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },

  async fetchPlanPrices({ commit }, payload) {
    // console.log("fetchPlanPrices", payload);
    commit("statusPlanPrice", STATE_STATUSES.PENDING);
    try {
      const response = await axios.post(planDetails, payload);

      // console.log("planDetails response", response.data.records);
      if (response.data.records != null) {
        let { coverage, deductible, plan_id } = payload;
        commit("emergencyMedEvac", response.data.records.emergency_med_evac);
        commit("fullDetailPlan", response.data.records);
        commit("updatePlan", {
          records: response.data.records,
          plan_id,
          coverage,
          deductible,
        });
        // commit('resetAddOns');
        commit("calculateTotalAmountUpdate", payload);
      }

      if (response.data.records.price != null) {
        return response.data.records.price;
      }
      commit("statusPlanPrice", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
    }
  },

  async submitUserOrder({ commit, state }, payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);

    try {
      let { selected_add_on_cancel_for_any_reason, selectedAddOns, allPlans } =
        toRaw(state);
      let { trip_details, plans } = allPlans;
      let plan = plans.filter((plan) => plan.PlanId == payload.plan_id);
      let sendOrder = {
        plan: { ...plan[0] },
        ...payload,
        selected_add_on_cancel_for_any_reason,
        selectedAddOns,
        trip_details,
      };

      const response = await axios.post(order, sendOrder);
      commit("STATE_STATUSES", STATE_STATUSES.READY);

      if (
        response.data?.records != null &&
        response.data?.records.length != 0
      ) {
        commit("updateUserOrder", response.data.records);

        return;
      }
      if (response.data.errors != null && response.data?.records.length == 0) {
        commit("updateUserOrder", response.data.errors);

        return;
      }
    } catch (e) {
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
  async submitCheckout({ commit, state }, payload) {
     // console.log("submitCheckout payload", payload);
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      //segment payload here for complete order
      const trackSegment = {
        billing: payload.billing,
        travelers: payload.travelers,
        beneficiary: payload.beneficiary,
        emergency_contact: payload.emergency_contact,
        products:  Object.values(payload.cart).map(value => {
          return {
            product_id: value.id,
            name: value.name,
            price: value.price,
            price_with_addons: value.priceWithAddons,
            quantity: value.quantity,
          };
        }),
        quantity:Object.keys(payload.cart).length,
        checkout_id: payload.itira_uuid,
        order_id: payload.itira_uuid,
        total: payload.total_price,
        revenue: 0,
        shipping: 0,
        tax: 0,
        discount: 0,
        currency: 'USD',
        total_price:  payload.total_price,
        itira_uuid: payload.itira_uuid,
        affiliate_id: payload.affiliate_id,
        step: 4,
      }
      let { cart } = toRaw(state);
      const response = await axios.post(order, payload);

      // console.log(response.data.records.user,"response.data.records order")
      if (cart.length === 1)
      {
        if (response.data.records.orderData[0].error === true)
        {
          Swal.fire({
            icon: "error",
            title: response.data.records.orderData[0].payment_status === 'failed' ? "Payment Failed" : " ",
            text: response.data.records.orderData[0].payment_fail_message,
          });
          commit("STATE_STATUSES", STATE_STATUSES.READY);
          return;
        }else {
          track("Checkout Step Completed", {
            ...trackSegment
          });
          commit("order_summary", response.data.records.orderData);

          if (response.data.records.user !== undefined){
            commit("loginUserData", response.data.records.user);
          }

          commit("STATE_STATUSES", STATE_STATUSES.READY);
          router.push({
            path: "/thank-you",
          });
        }
      }else {
        commit("order_summary", response.data.records.orderData);
        commit("STATE_STATUSES", STATE_STATUSES.READY);
        track("Checkout Step Completed", {
          ...trackSegment
        });
        router.push({
          path: "/thank-you",
        });
      }
    } catch (e) {
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },

  // cart actions
  async getAllCartsProducts({ commit }, payload) {
    commit("STATE_STATUSES_CART", STATE_STATUSES.PENDING);
    try {
      const response = await axios.post(cartList, payload);

      commit("setAllCardProduct", response.data.data);

      commit("STATE_STATUSES_CART", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);

      commit("STATE_STATUSES_CART", STATE_STATUSES.ERROR);
    }
  },
  async addToCartsProduct({ commit, state }, payload) {
    commit("STATE_STATUSES_ADD_CART", STATE_STATUSES.PENDING);

    try {
      const response = await axios.post(addToCart, payload);
      toggleCartModal();
      commit("STATE_STATUSES_ADD_CART", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES_ADD_CART", STATE_STATUSES.ERROR);
    }
  },
  async deleteToCartItem({ commit }, payload) {
    commit("STATE_STATUSES_CART", STATE_STATUSES.PENDING);
    try {
      const response = await axios.post(deleteCartItem, payload);
      const cartListresponse = await axios.post(cartList, {
        itira_id: payload.itira_id,
      });
      commit("setAllCardProduct", cartListresponse.data.data);
      commit("STATE_STATUSES_CART", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES_CART", STATE_STATUSES.ERROR);
    }
  },
  async deleteAllCartItems({ commit }, payload) {
    commit("STATE_STATUSES_CART", STATE_STATUSES.PENDING);
    try {
      if (payload != undefined) {
        const cartListresponse = await axios.post(deleteCartItemAll, {
          itira_id: payload,
        });
      }

      commit("empty_cart", STATE_STATUSES.READY);
      commit("STATE_STATUSES_CART", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES_CART", STATE_STATUSES.ERROR);
    }
  },

  //order conformation
  async orderConformation({ commit }, payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.post(success, { ...payload });
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },

  //all set createOrder to send in final
  async addToUserCreateOrder({ commit }, payload) {
    commit("addToCreateOrder", payload);
  },
  async resetTotalAmount({ commit }, payload) {
    commit("resetTotalAmount", payload);
  },
  async resetAddOns({ commit }) {
    commit("resetAddOns");
  },

  // calculateTotalAmountUpdate
  async calculateTotalAmountUpdate({ commit }, payload) {
    commit("calculateTotalAmountUpdate", payload);
  },
  // addOnExtraAdd
  async addOnExtraAdd({ commit }, payload) {
    commit("addOnExtraAdd", payload);

    commit("calculateTotalAmountUpdate", payload);
  },

  //get specific Upgrades Cost for API call type plans
  async specificUpgradesCost({ commit }, payload) {
    let {plan_id} = payload;
   // console.log("plan_id", plan_id);
    //console.log("specificUpgradesCost", );
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.post(specificUpgradesCostForCallTypeAPI, { ...payload });
     // console.log("response", response.data.records.total_price);
        commit("specificUpgradesCost", response.data.records.total_price);
        commit("updatePlanForSpecificUpgradesCost", {
            total_price: response.data.records.total_price,
            plan_id: plan_id
          });
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },


  // remoeOnExtraAdd
  async removeOnExtraAdd({ commit }, payload) {
    commit("removeOnExtraAdd", payload);
    commit("calculateTotalAmountUpdate", payload);
  },
  async setAddOns({ commit }, payload) {
    commit("setAddOns", payload);
    // commit('calculateTotalAmountUpdate', payload);
  },
  // updateOnExtraAdd
  async UpdateOnExtraAdd({ commit }, payload) {
    commit("UpdateOnExtraAdd", payload);
    commit("calculateTotalAmountUpdate", payload);
  },


  //updatePolicyOption
  async updatePolicyOption({ commit }, payload) {
    if (payload.check) {
      commit("updatePolicyOption", payload);
      commit("calculateTotalAmountUpdate", payload);
    } else {
      commit("updatePolicyOption", null);
      commit("calculateTotalAmountUpdate", payload);
    }
  },






  //reset alll session
  async resetCreateOrderAndHolderUpdatePlanPrice({ commit }) {
    commit("resetCreateOrderAndHolderUpdatePlanPrice");
  },
  //reset alll session
  async resetOrderSummary({ commit }) {
    commit("resetOrderSummary");
  },
  async resetAllSession({ commit }) {
    commit("resetAllSession");
  },
  //reset alll session
  async resetComparePlans({ commit }) {
    commit("resetComparePlans");
  },

  async addingComparePlan({ commit }, payload) {
    commit("addingComparePlan", payload);
  },

  //new checkout plan
  async addBeneficiaryAndBiiling({ commit }, payload) {
    commit("addBeneficiaryAndBiiling", payload);
  },
  async updateTravelerForAllEmails({ commit }, payload) {
    // console.log("updateTravelerForAllEmails", payload);
    commit("updateTravelerForAllEmails", payload);
  },
  async setTripSideAssistance({ commit }, payload) {
    commit("setTripSideAssistance", payload);
  },
  async setBlueRibbonBag({ commit }, payload) {
    commit("setBlueRibbonBag", payload);
  },
  async resetBlueRibbonBag({ commit }, payload) {
    commit("RESET_BLUE_RIBBON_BAG", payload);
  },

};
