import { customerInfo ,eventId } from "./customerInfo";
import {uuid} from "vue-uuid";
const alias = customerId => {
    if (typeof window !== "undefined") {
       window?.analytics?.alias(customerId);
    }
}


const identify = customerId => {
    if (typeof window !== "undefined") {
        window?.analytics?.alias(customerId);
    }
}

const track = (event, properties) => {
    // get some data from session
    // get some data from local storage if available
    let updatedProperties = properties;
    if (typeof window !== "undefined") {
        try {
            const propertyOverrides = {
                customer_type: window?.analytics?.user().traits().customerType,
            }
            updatedProperties = { ...properties, ...propertyOverrides, event_id:uuid.v4(),customer_info:customerInfo()};
        } catch (err) {
            console.error(err);
        }
        window?.analytics?.track(event, updatedProperties);
    }
}

export {
    alias,
    identify,
    track
}
