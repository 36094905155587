import { actions } from './action';
import mutations from './mutations';
import { getters } from './getters';

import { STATE_STATUSES } from '@/helpers/constants';
import { reactive } from '@vue/reactivity';

const state = reactive({
  allBlogs: {
    items:[],
    meta:null
  },
  allFaqs: null,
  blog:null,
  faq:null,
  relatedFaqs:null,
  pressRelease:null,
  status: STATE_STATUSES.INIT,
});

const plans = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default plans;
