import { actions } from "./action";
import mutations from "./mutations";
import { getters } from "./getters";

import { STATE_STATUSES } from "@/helpers/constants";
import { reactive } from "@vue/reactivity";

const state = reactive({
  query_type: null,
  message: null,
  status: STATE_STATUSES.INIT,
  newLetterStatus: STATE_STATUSES.INIT,
});

const query = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
export default query;
