import { toRaw } from 'vue';

export const getters = {
  allPlans(state) {
    return state.allPlans;
  },
  traveleDetails(state) {
    if (state.allPlans != null) {
      return state.allPlans.trip_details;
    }
    return {};
  },

  status(state) {
    return state.status;
  },
  singlePlan(state) {
    return state.fullDetailPlan;
  },

  cart(state) {
    return state.cart;
  },
  guest_user(state) {
    return state.guest_user;
  },
  emergencyMedEvac(state) {
    return state.emergencyMedEvac;
  },

  userOrder(state) {
    return state.userOrder;
  },
};
