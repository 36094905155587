import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref } from 'vue';
export default {
  __name: 'loader',
  props: {
    parentClass: {
      type: String,
      default: 'd-flex justify-content-center'
    },
    childClass: {
      type: String,
      default: 'spinner-border'
    }
  },
  setup(__props) {
    const props = __props;
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(__props.parentClass)
      }, [_createElementVNode("div", {
        class: _normalizeClass(__props.childClass),
        role: "status"
      }, null, 2)], 2);
    };
  }
};