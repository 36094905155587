const PlanListing = () => import("@/pages/planListing.vue");
const ItiraScores = () => import("@/pages/itiraScores.vue");
const PlanFullDetails = () => import("@/pages/planFullDetails.vue");
const Upgrades = () => import("@/pages/dynamicBrochure.vue");
const Compare = () => import("@/pages/planCompare.vue");
const routes =[
    {
        path: "/plan-listing/:quote_id",
        name: "PlanListing",
        component: PlanListing,
        props: { default: false, layout: "PlanListing", footer: true },
    },
    {
        path: "/plan-listing/plan-details/:id/:quote_id?",
        name: "PlanFullDetails",
        component: PlanFullDetails,
        props: { default: false, layout: "PlanListing", footer: true },
    },
    {
        path: "/upgrade/:id/:quote_id?",
        name: "Upgrades",
        component: Upgrades,
        props: { default: false, layout: "planListing", footer: true },
    },
    {
        path: "/compare",
        name: "Compare",
        component: Compare,
        props: { default: false, layout: "PlanListing", footer: true },
    },
    {
        path: "/itira-scores",
        name: "ItiraScores",
        component: ItiraScores,
        props: { default: false, layout: "PlanListing", footer: true },
    },

];

export default routes;
