import moment from "moment";
let showDateFormet = "MMM DD, YYYY";
let showDateFormetWith = "ddd MMM DD, YYYY";
let showDateFormetWithoutYear = "MMM DD";
let showHourFormat = "hh:mm A";
export const formatVueCelenderPicker = (date) =>
  moment(date).format(showDateFormet);

export const formatVueCelenderPickerWithoutYear = (startDate, endDate) =>
  `${moment(startDate).format(showDateFormetWithoutYear)} - ${moment(
    endDate
  ).format(showDateFormetWithoutYear)} ${moment(startDate).format("YYYY")}`;

export const formatVueCelenderPickerWithDay = (date) =>
  moment(date).format(showDateFormetWith);

export const getDaysOfDepatureAndReturen = (getdepatureDate, getreturnDate) => {
  let depatureDate = moment(getdepatureDate);
  let returnDate = moment(getreturnDate);

  return returnDate.diff(depatureDate, "days") + 1;
};

export const checkDateIsPast = (date) => {
  let currentDate = moment().format(showDateFormet);
  let comingDate = moment(date).format(showDateFormet);

  return !moment(comingDate).isAfter(currentDate);
};

export const formatVueCalendarHour = (date) =>
  moment(date).format(showHourFormat);

export const formatVueCelenderRange = (dates) => {
  return `${moment(dates[0]).format(showDateFormet)} - ${moment(dates[1]).format(showDateFormet)}`
}

export const checkLookBackPeriod = (startDate, cancelationDays) => {
  if (cancelationDays === null) {
    // Handle the case when cancellation days are null
    return false; // or any other appropriate action
  }

  const currentDate = moment().startOf('day'); // Current date
  const tripStartDate = moment(startDate, showDateFormet).startOf('day'); // Start date of the trip
  const daysDifference = tripStartDate.diff(currentDate, 'days'); // Difference in days

  return daysDifference <= cancelationDays; // Return true if the difference is less than or equal to the cancellation days
};



function pad(number) {
  return number < 10 ? '0' + number : number;
}

export const  formatToISODate = (dateString) => {
  const date = new Date(dateString);
  return date.toISOString();
}

