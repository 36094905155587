export default {
  STATE_STATUSES(state, payload) {
    state.status = payload;
  },
  allCountries(state, payload) {
    state.allCountries = payload;
  },
  allCountriesPhoneCodes(state, payload) {
    state.allCountriesPhoneCodes = payload;
  },
  fetcCitiesAgainstState(state, payload) {
    state.cities = payload;
  },
};
