import {computed, reactive, ref, toRaw, watch} from "vue";
import { store } from "@/store/";
import moment from "moment";

import { v4 as uuidv4 } from "uuid";
import { useStorage } from "@vueuse/core";
let { itira, destinationType, setMultipleDestinations } = store.state.itira;

import {
  required,
  integer,
  email,
  numeric,
  helpers,
  alpha,
  sameAs,
  maxValue,
  minValue,
  minLength,
  maxLength,
  between,
  alphaNum,
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

let chracterReg = /^[A-Za-z ]+$/;
var checkSpecialCracterReg = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g;
let  forNumeric = !/^\d+$/;
const checkAlpha = (value) => chracterReg.test(value);
const onlyNumeric = (value) => forNumeric.test(value)
let dateFormat = "YYYY-MM-DD";
const checkDOB = (dob) => {
  return !moment(dob).isSameOrAfter(moment().format(dateFormat));
};

const yearIsPastFun = (expiryYear) => {
  let re = /\b(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})\b/;

  if (re.test(expiryYear) == false) {
    return false;
  }

  if (re.test(expiryYear)) {
    let month = expiryYear.split("/")[0];

    let year = expiryYear.split("/")[1];
    let dateFormatYear = "YYYY";

    let convertYearIntoDate = moment(year, "YY");

    let getCurrentYear = moment().format(dateFormatYear);

    if (moment(convertYearIntoDate).isBefore(getCurrentYear, dateFormatYear)) {
      console.log("isBefore", isBefore);
      return false;
    }

    if (moment(convertYearIntoDate).isSame(getCurrentYear, dateFormatYear)) {
      let convertMonthIntoDate = moment(month).format("MM");

      let getCurrentMonth = moment().format("MM");

      if (moment(convertMonthIntoDate).isBefore(getCurrentMonth, "MM")) {
        return false;
      }
      if (moment(convertMonthIntoDate).isSame(getCurrentMonth, "MM")) {
        return true;
      }
      if (moment(convertMonthIntoDate).isAfter(getCurrentMonth, "MM")) {
        return true;
      }
    }

    if (moment(convertYearIntoDate).isAfter(getCurrentYear, dateFormatYear)) {
      return true;
    }

    return true;
  }
};

const dateIsValid = (dob) => {
  return moment(dob, "YYYY-MM-DD").isValid();
};

//genral defication of rules
const generalRules = {
  fname: { required, $autoDirty: true, maxLength: maxLength(50) },
  lname: { required, $autoDirty: true, maxLength: maxLength(50) },
  email: { required, email, $autoDirty: true },
  age: { required, numeric, $autoDirty: true },
  password: { required, $autoDirty: true, minLength: minLength(4) },
  passwordConfirm: {
    required,
    sameAsPassword: sameAs("password"),
    $autoDirty: true,
  },
};

export let itiraStates = ref([]);
// export let itiraSportsAdventure = ref([]);
export let itiraMultiDestStates = ref([]);

if (toRaw(itira)?.travelers != undefined) {
  toRaw(itira?.travelers).length
    ? (itiraStates.value = toRaw(itira?.travelers))
    : [];
}

export const generateMultipeTravelers = (index) => {
  if (index == 0) {
    itiraStates.value.push({
      id: index,
      first_name: null,
      last_name: null,
      citizenship: null,
      residence: null,
      zipcode: null,
      day: null,
      month: null,
      year: null,
      dob: null,
      email: null,
      is_primary: true,
    });
  } else {
    itiraStates.value.push({
      id: index,
      first_name: null,
      last_name: null,
      citizenship: null,
      residence: null,
      zipcode: null,
      day: null,
      month: null,
      year: null,
      dob: null,
      email: null,
      is_primary: false,
    });
  }
};

export const initMultiDestion = () => {
  itiraMultiDestStates.value.push({
    id: uuidv4(),
    destination: null,
  });
};
export const addNewMultiDestination = (dest) => {
  itiraMultiDestStates.value.push({
    ...dest,
  });
};

export const setMultiDestination = (multiDestinations) => {
  itiraMultiDestStates.value = multiDestinations;
};
export const resetMultiDestination = (iso) => {
  // alert(iso)
  itiraMultiDestStates.value = itiraMultiDestStates.value.filter(
    ({ country_iso }) => country_iso != iso
  );
  // itiraMultiDestStates.value = [];
};
export const deleteMultiDestination = (uuid) => {
  itiraMultiDestStates.value = itiraMultiDestStates.value.filter(
    ({ id }) => uuid != id
  );
};

export const deleteMultiTraveler = (TravelerIndex) => {
  itiraStates.value = itiraStates.value.filter(({ id }) => id != TravelerIndex);
};
export const primaryMultiTraveler = (TravelerIndex) => {
  itiraStates.value = itiraStates.value.map((value) =>
    value.id == TravelerIndex
      ? { ...value, is_primary: true }
      : { ...value, is_primary: false }
  );
};


const zipcodeValidation = () => {
  watch(
      () => itiraStates.value,
      (newItiraStates) => {
        const hasUSResidence = newItiraStates.some((state) => state.residence === 'United States');
        if (hasUSResidence) {
          return  {
            required: helpers.withMessage("Zip code required", required),
            maxLength: helpers.withMessage("Max 8 characters", maxLength(8)),
            minLength: helpers.withMessage("Min 2 characters", minLength(2)),
          };
        } else {
          return {};
        }
      },
      { deep: true }
  );
}

export const itiraReulse = reactive({
  $each: helpers.forEach({
    id: {
      required: helpers.withMessage("First name required", required),
    },
    first_name: {
      required: helpers.withMessage("First name required", required),
      isAlphaWithSpace: helpers.withMessage(
        "First name should be character",
        checkAlpha
      ),
      maxLength: helpers.withMessage(
        "First name should be 99 characters",
        maxLength(99)
      ),
    },
    last_name: {
      required: helpers.withMessage("Last name required", required),
      isAlphaWithSpace: helpers.withMessage(
        "First name should be character",
        checkAlpha
      ),
      maxLength: helpers.withMessage(
        "Last name should be 99 characters",
        maxLength(99)
      ),
    },
    citizenship: {
      required: helpers.withMessage("Citizenship required", required),
    },
    residence: {
      required: helpers.withMessage("Residence required", required),
    },

    zipcode: zipcodeValidation(),

   /* zipcode:  ()=>{
      toRaw(itiraStates.value).forEach((element) => {
        console.log(element, "element.residence");
        if (element.residence === 'United States' ) {
            return{
              required: helpers.withMessage("Zip code required", required),
              maxLength: helpers.withMessage("Max 8 characters", maxLength(8)),
              minLength: helpers.withMessage("Min 2 characters", minLength(2)),
            }
        }
      });
    },*/
    day: {
      required: helpers.withMessage("Day  required", required),

    },
    month: {
      required: helpers.withMessage("month required", required),

    },
    year: {
      required: helpers.withMessage("year required", required),

    },
    dob: {
      required: helpers.withMessage("Date of birth is required", required),
      dobIsValidInMonth: helpers.withMessage(
        "Invalid Date of birth ",
        dateIsValid
      ),
      dobIsValid: helpers.withMessage(
        "Date of birth should be the past time",
        checkDOB
      ),
    },
  }),
});

export const multiDestinationReulse = reactive({
  $each: helpers.forEach({
    destination: {
      required: helpers.withMessage("destination is required", required),
    },
  }),
});

export let checkoutTravelersState = ref([]);

export const generateMultipeTravelersCheckout = (
  uuid,
  first_name,
  last_name,
  dob
) => {
  checkoutTravelersState.value.push({
    uuid,
    first_name,
    last_name,
    email: null,
    dob,
  });
};

export const checkoutTravelers = reactive({
  $each: helpers.forEach({
    uuid: {
      required: helpers.withMessage("Uuid is required", required),
    },
    first_name: {
      required: helpers.withMessage("First name required", required),
      isAlphaWithSpace: helpers.withMessage(
        "First name should be character",
        checkAlpha
      ),
      maxLength: helpers.withMessage(
        "First name should be 250 characters",
        maxLength(250)
      ),
    },
    last_name: {
      required: helpers.withMessage("Last name required", required),
      isAlphaWithSpace: helpers.withMessage(
        "First name should be 250 character",
        checkAlpha
      ),
      maxLength: helpers.withMessage(
        "Last name should be 250 characters",
        maxLength(250)
      ),
    },

    // email: {
    //   required: helpers.withMessage("Email required", required),
    //   email: helpers.withMessage("Email address is not valid", email),
    // },
  }),
});

// new checkout state

export let beneficiaryState = ref({
  first_name: null,
  last_name: null,
  relation:null,
  email: null,
});
export let emergencyContactState = ref({
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  phone_code: "",
});

export let cardPayment = ref({
  street: "",
  city: "",
  state: "",
  zipcode: "",
  passport: "",
  email: "",
});

export let googleAddress = ref(null);
export let googleAdderesSingleOrigin = ref(null);
export let googleAdderesSingleDestination = ref(null);

export let sportsAdventureState = ref({
  travel_sport_adventure: null,
});
export let googleAddressTravelRegion = ref({
  origin:"",
  destination:""
});

export let billingState = ref({
  street: null,
  city: null,
  state: null,
  zipcode: null,
  passport: null,
  phone: null,
  phone_code: null,
});
export let billingContactState = ref({
  email: null,
  phone: null,
  phone_code: null,
});
export let paymentCardState = ref({
  name: null,
  account_no: null,
  cvv: null,
  expiry: null,
});

export const paymentCardRule = {
  name: {
    $autoDirty: true,
    required: helpers.withMessage("Card Holder name is required", required),
  },
  account_no: {
    $autoDirty: true,
    required: helpers.withMessage("Card Number is required", required),
  },
  cvv: {
    $autoDirty: true,
    required: helpers.withMessage("CVV is required", required),
  },
  expiry: {
    $autoDirty: true,
    required: helpers.withMessage("Expiry date is required", required),

    yearIsPast: helpers.withMessage(
      ({ $pending, $invalid, $params, $model }) => {
        return `Expiry date is not valid `;
      },
      yearIsPastFun
    ),
  },
};
export const billingRules = {
  street: {
    $autoDirty: true,
    // required: helpers.withMessage("Street required", required),
  },

  city: {
    $autoDirty: true,
    required: helpers.withMessage(
      "complete address is required with street,state and country",
      required
    ),
  },

  state: {
    $autoDirty: true,
    required: helpers.withMessage("state required", required),
  },
  zipcode: {
    $autoDirty: true,
    // required: helpers.withMessage("Zip code  required", required),
    maxLength: helpers.withMessage("Max 8 characters", maxLength(8)),
    minLength: helpers.withMessage("Min 2 characters", minLength(2)),
  },
  passport: {
    $autoDirty: true,
    required: helpers.withMessage("Country required", required),
  },
  /*email: {
    $autoDirty: true,
    required: helpers.withMessage("Email required", required),
    email: helpers.withMessage("Email address is not valid", email),
  },
  phone: {
    $autoDirty: true,
    required: helpers.withMessage("Phone Number required", required),
  },
  phone_code: {
    $autoDirty: true,
    // required: helpers.withMessage("Phone Code required", required),
  },

   */
};
export const billingContactRules = {
  email: {
    $autoDirty: true,
    required: helpers.withMessage("Email required", required),
    email: helpers.withMessage("Email address is not valid", email),
  },
  phone: {
    $autoDirty: true,
    required: helpers.withMessage("Phone Number required", required),
  },
  phone_code: {
    $autoDirty: true,
     required: helpers.withMessage("Phone Code required", required),
  },
};
export const googleRules = {
  $autoDirty: true,
  required: helpers.withMessage("Street required", required),
};
export const googleAdderesSingleOriginRules = {
  $autoDirty: true,
  required: helpers.withMessage("Origin is required", required),
};
export const googleAdderesSingleDestinationRules = {
  $autoDirty: true,
  required: helpers.withMessage("Destination is required", required),
};
export const googleAddressTravelRegionRules = {
  origin:{
    $autoDirty: true,
    required: helpers.withMessage("origin is required", required),
  },
  destination: {
    $autoDirty: true,
    required: helpers.withMessage("Destination is required", required),
  },
};

export const beneficiaryRules = {
  first_name: {
    $autoDirty: true,
    required: helpers.withMessage("First name required", required),
    maxLength: helpers.withMessage(
      "The maximum length is allowed 50 characters",
      maxLength(99)
    ),
    checkAlpha: helpers.withMessage(
      "First name should be character",
      checkAlpha
    ),
  },

  last_name: {
    $autoDirty: true,
    required: helpers.withMessage("Last name required", required),
    maxLength: helpers.withMessage(
      "The maximum length is allowed 50 characters",
      maxLength(99)
    ),
    checkAlpha: helpers.withMessage(
      "Last name should be character",
      checkAlpha
    ),
  },

  relation: {
    $autoDirty: true,
    required: helpers.withMessage("Relation name required", required),
    maxLength: helpers.withMessage(
      "The maximum length is allowed 50 characters",
      maxLength(50)
    ),
    /*checkAlpha: helpers.withMessage(
      "Relation name should be character",
      checkAlpha
    ),*/
  },

  email: {
    $autoDirty: true,
    // required: helpers.withMessage("Email required", required),
    email: helpers.withMessage("Email address is not valid", email),
  },
};
export const emergencyContactRules = {
  first_name: {
    $autoDirty: true,
    maxLength: helpers.withMessage(
      "The maximum length is allowed 99 characters",
      maxLength(99)
    ),
    checkAlpha: helpers.withMessage(
      "First name should be character",
      checkAlpha
    ),
  },

  last_name: {
    $autoDirty: true,
    maxLength: helpers.withMessage(
      "The maximum length is allowed 99 characters",
      maxLength(99)
    ),
    checkAlpha: helpers.withMessage(
      "Last name should be character",
      checkAlpha
    ),
  },

  email: {
    $autoDirty: true,
    email: helpers.withMessage("Email address is not valid", email),
  },

  phone: {
    $autoDirty: true,
    integer: helpers.withMessage("Number only allowed", integer),
    maxLength: helpers.withMessage(
      "The maximum length is allowed 16 characters",
      maxLength(16)
    ),
  },
};

//User Info state and validation
export const userState = reactive({
  fname: "",
  lname: "",
  age: "",
  email: "",
  password: "",
  passwordConfirm: "",
});

export const orderStates = reactive({
  email: null,
  order_number: null,
});
export const traveler = reactive({
  trav_first_name: "",
  trav_last_name: "",
  trav_email: "",
  trav_address: "",
  trav_gender: "",
});

export const benef = reactive({
  benef_name: "",
  benef_address: "",
  benef_relation: "",
});
export const BillAddress = reactive({
  bill_address_city: "",
  bill_address_postal_code: "",
  bill_address_state: "",
  bill_address_country: "",
  bill_street_address: "",
});
export const userRules = {
  fname: {
    ...generalRules.fname,
  }, // Matches state.firstName
  lname: {
    ...generalRules.lname,
  }, // Matches state.lastName
  email: { ...generalRules.email },
  age: { ...generalRules.age },
  password: { ...generalRules.password },
  order_number: {
    required: helpers.withMessage("Order number required", required),
    $autoDirty: true,
    maxLength: maxLength(50),
  },
  passwordConfirm: { ...generalRules.passwordConfirm },
};
export const orderRules = {
  email: {
    ...generalRules.email,
    $touch: true,
  },
  order_number: { ...generalRules.fname, $touch: true },
};
export const travelerRules = {
  trav_first_name: {
    required: helpers.withMessage("First name required", required),
    $autoDirty: true,
    maxLength: helpers.withMessage(
      "The maximum length is allowed 50 characters",
      maxLength(50)
    ),
  },
  trav_last_name: {
    $autoDirty: true,
    required: helpers.withMessage("Last name required", required),
    maxLength: helpers.withMessage(
      "The maximum length is allowed 50 characters",
      maxLength(50)
    ),
  },
  trav_email: {
    $autoDirty: true,
    required: helpers.withMessage("Email required", required),
    email: helpers.withMessage("Email address is not valid", email),
  },
  trav_phone: {
    $autoDirty: true,
    integer: helpers.withMessage("Number only allowed", integer),
    required: helpers.withMessage("phone required", required),
    maxLength: helpers.withMessage(
      "The maximum length is allowed 16 characters",
      maxLength(16)
    ),
  },
  trav_address: {
    $autoDirty: true,
    required: helpers.withMessage("Address required", required),
  },
  trav_gender: {
    required: helpers.withMessage("Please select gender type", required),
  },
};
export const benefRules = {
  benef_name: {
    $autoDirty: true,
    required: helpers.withMessage("Name required", required),
    maxLength: helpers.withMessage(
      "The maximum length is allowed 50 characters",
      maxLength(50)
    ),
  },

  benef_address: {
    $autoDirty: true,
    required: helpers.withMessage("Address required", required),
  },
  benef_relation: {
    $autoDirty: true,
    required: helpers.withMessage("Relation required", required),
  },
};
export const billingAddressRules = {
  bill_address_city: {
    $autoDirty: true,
    required: helpers.withMessage("City required", required),
  },

  bill_address_postal_code: {
    $autoDirty: true,
    required: helpers.withMessage("Postal code required", required),
  },

  bill_street_address: {
    $autoDirty: true,
    required: helpers.withMessage("Address required", required),
  },
  bill_address_state: {
    $autoDirty: true,
    required: helpers.withMessage("State required", required),
  },
  bill_address_country: {
    $autoDirty: true,
    required: helpers.withMessage("Country required", required),
  },
};

export const planFilter = reactive({
  destination: null,
  origin_country: null,
  dates: null,
  state_billing: null,
  dateconfig: {
    mode: "range",
    minDate: "today",
    dateFormat: "Y/m/d",
  },
  age: {
    value: "",
    show: false,
  },
  trip_cost: {
    value: "",
    show: false,
  },
});
export const PlanListingRules = {
  destination: {
    $touch: true,
    required: helpers.withMessage("Destination required", required),
  },
  origin_country: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Origin required", required),
  },
  state_billing: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Resident state  required", required),
  },
  dates: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Date required", required),
  },
  age: {
    $touch: true,
    value: {
      $autoDirty: true,
      $touch: true,
      integer,
      required: helpers.withMessage("Age required", required),
      between: helpers.withMessage(
        "Age between 0 to 130 Years",
        between(0, 130)
      ),
    },
    origin_country: {
      $autoDirty: true,
      $touch: true,
      required: helpers.withMessage("Origin required", required),
    },
    state_billing: {
      $autoDirty: true,
      $touch: true,
      required: helpers.withMessage("Resident state required", required),
    },
    dates: {
      $autoDirty: true,
      $touch: true,
      required: helpers.withMessage("Date required", required),
    },
    age: {
      $touch: true,
      value: {
        $autoDirty: true,
        $touch: true,
        integer,
        required: helpers.withMessage("Age required", required),
        between: helpers.withMessage(
          "Age between 0 to 130 Years",
          between(0, 130)
        ),
      },
    },
    trip_cost: {
      $touch: true,
      value: {
        $autoDirty: true,
        $touch: true,
        integer,
        required: helpers.withMessage("Cost required", required),
        minValue: helpers.withMessage("Min Cost is $1", minValue(1)),
      },
    },
  },
};
export const Quote = reactive({
  start_date: null,
  end_date: null,
  destination: null,
  traveler_age: null,
  traveler_age_dob: null,
  traveler_citizen: null,
  traveler_residence: null,
  traveler_state_residence: null,
  billing_state: null,
  billing_country: null,
  trip_cost: null,
  allStateToCountryForTraveler: null,
  allStateToCountryForBlling: null,
});
export const alreadyTravelingState = ref({
  depatureDate: null,
  returnDate: null,
});
export const chooseInitialDatesState = ref({
  depatureDate: null,
  returnDate: null,
});

export const flexibleTravelingState = ref({
  flexible_date_type: "week",
  months: [],
});
export const TripCostPaymentDatesState = reactive({
  date_of_first_payment: null,
  trip_cost: null,
});

export const moreBooking = ref(false);

export const alreadyTravelingStatRules = {
  depatureDate: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Arrival date required", required),
  },
  returnDate: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Return date  required", required),
  },
};

export const chooseInitialDatesStateRules = {
  depatureDate: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Depature date required", required),
  },
  returnDate: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Return date  required", required),
  },
};
export const TripCostPaymentDatesRules = {
  trip_cost: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Cost required", required),
     /*minValue: helpers.withMessage("Min Cost is 0", minValue(0)),
     integer: helpers.withMessage("Only numbers allowed", integer),*/

  },
  // date_of_first_payment: {
  //   $autoDirty: true,
  //   $touch: true,
  //   required: helpers.withMessage("Payment date required", required),
  // },
};
export let editItiraTravelerState = ref({
  first_name: null,
  last_name: null,
  citizenship: null,
  residence: null,
  zipcode: null,
  day: null,
  month: null,
  year: null,
  dob: null,
});
export let editAddItiraTravelerState = ref({
  first_name: null,
  last_name: null,
  citizenship: null,
  residence: null,
  zipcode: null,
  day: null,
  month: null,
  year: null,
  dob: null,
});
export const editItiraTravelerRules = reactive({
  first_name: {
    $autoDirty: true,
    required: helpers.withMessage("First name required", required),
    maxLength: helpers.withMessage(
      "The maximum length is allowed 50 characters",
      maxLength(99)
    ),
    isAlphaWithSpace: helpers.withMessage(
      "First name should be character",
      checkAlpha
    ),
  },
  last_name: {
    required: helpers.withMessage("Last name required", required),
    isAlphaWithSpace: helpers.withMessage(
      "Last name should be character",
      checkAlpha
    ),
    maxLength: helpers.withMessage(
      "Last name should be 16 characters",
      maxLength(99)
    ),
  },
  citizenship: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Citizenship required", required),
  },
  residence: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Residence required", required),
  },
  zipcode: {
    $autoDirty: true,
    $touch: true,
    maxLength: helpers.withMessage("Max 8 characters", maxLength(8)),
    minLength: helpers.withMessage("Min 2 characters", minLength(2)),
  },
  day: {
    required: helpers.withMessage("Day  required", required),
    maxLength: helpers.withMessage(
      "The maximum length is allowed 2 characters",
      maxLength(2)
    ),
  },
  month: {
    required: helpers.withMessage("month required", required),
  },
  year: {
    required: helpers.withMessage("year required", required),
  },
  dob: {
    required: helpers.withMessage("Date of birth is required", required),
    dobIsValidInMonth: helpers.withMessage(
      "Invalid Date of birth ",
      dateIsValid
    ),

    dobIsValid: helpers.withMessage(
      "Date of birth should be the past time",
      checkDOB
    ),
  },
});

export const quoteRules = {
  destination: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Destination required", required),
  },
  traveler_state_residence: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("State of residence required", required),
  },
  traveler_residence: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Country of residence required", required),
  },
  traveler_citizen: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Citizenship required", required),
  },
  start_date: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Start Date required", required),
  },
  end_date: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("End Date required", required),
  },
  traveler_age: {
    $touch: true,
    $autoDirty: true,

    required: helpers.withMessage("Date of birth required", required),
  },
  trip_cost: {
    $autoDirty: true,
    $touch: true,
    integer,
    required: helpers.withMessage("Cost required", required),
    minValue: helpers.withMessage("Min Cost is $1", minValue(1)),
  },
};

// export const newLetterState = reactive({
//   email: null,
// });
//
// export const newLetterRules = {
//   email: {
//     $autoDirty: true,
//     required: helpers.withMessage("Email required", required),
//     email: helpers.withMessage("Email address is not valid", email),
//   },
// };

export const loginState = reactive({
  email: null,
  password: null,
});

export const loginRules = {
  email: {
    $autoDirty: true,
    required: helpers.withMessage("Email required", required),
    email: helpers.withMessage("Email address is not valid", email),
  },
  password: {
    required: helpers.withMessage("Password required", required),
    $autoDirty: true,
    minLength: minLength(8),
  },
};
export const forgotState = reactive({
  email: null,
});
export const resendState = reactive({
  email: null,
});

export const forgotRules = {
  email: {
    $autoDirty: true,
    required: helpers.withMessage("Email required", required),
    email: helpers.withMessage("Email address is not valid", email),
  },
};

export const resendRules = {
  email: {
    $autoDirty: true,
    required: helpers.withMessage("Email required", required),
    email: helpers.withMessage("Email address is not valid", email),
  },
};
export const resetState = reactive({
  password: null,
  confirm_password: null,
});

export const resetRules = {
  password: {
    required: helpers.withMessage("Password required", required),
    $autoDirty: true,
    minLength: minLength(8),
  },
  confirm_password: {
    required: helpers.withMessage("Confirm password required", required),
    $autoDirty: true,
  },
};
export const signUpState = reactive({
  first_name: null,
  last_name: null,
  email: null,
  password: null,
  confirm_password: null,
});

export const signUpRules = {
  email: {
    $autoDirty: true,
    required: helpers.withMessage("Email required", required),
    email: helpers.withMessage("Email address is not valid", email),
  },
  first_name: {
    $autoDirty: true,
    required: helpers.withMessage("First name required", required),
    maxLength: helpers.withMessage(
      "The maximum length is allowed 50 characters",
      maxLength(50)
    ),
    required: helpers.withMessage("First name should be character", checkAlpha),
  },
  last_name: {
    $autoDirty: true,
    required: helpers.withMessage("Last name required", required),
    maxLength: helpers.withMessage(
      "The maximum length is allowed 50 characters",
      maxLength(50)
    ),
    required: helpers.withMessage("Last name  should be character", checkAlpha),
  },
  password: {
    required: helpers.withMessage("Password required", required),
    $autoDirty: true,
    minLength: minLength(8),
    maxLength: maxLength(16),
    //alphaNum,
  },
  confirm_password: {
    required: helpers.withMessage("Confirm password required", required),
    $autoDirty: true,
  },
};

export const queryState = reactive({
  message: null,
  query_type: null,
});

export let regionalTravel = reactive({
  region: {
    country: null,
    country_iso: null,
    state_iso: null,
    state: null,
    city: null,
    street: null,
    formatted_address: null,
  },
  destination: {
    country: null,
    country_iso: null,
    state_iso: null,
    state: null,
    city: null,
    street: null,
    formatted_address: null,
  },
});

export const regionalTravelRules = {
  region: {
    required: helpers.withMessage("Region required", required),
    $autoDirty: true,
  },
  destination: {
    required: helpers.withMessage("Destination required", required),
    $autoDirty: true,
  },
};

export const queryRules = {
  message: {
    required: helpers.withMessage("Question required", required),
    $autoDirty: true,
    $touch: true,
  },
  query_type: {
    required: helpers.withMessage("Query type required", required),
    $autoDirty: true,
    $touch: true,
  },
};

//edit travel detail scheme
export const editTravelerName = reactive({
  traveler_first_name: "",
  traveler_last_name: "",
});
export const editTravelerAge = reactive({
  traveler_age: "",
});
export const editTravelerCitizen = reactive({
  traveler_citizen: "",
});
export const editTravelerResidence = reactive({
  traveler_residence: "",
});
export const editTravelerEmail = reactive({
  traveler_email: "",
});
export const editTravelerPhone = reactive({
  traveler_phone: "",
});
export const editTravelerAddress = reactive({
  traveler_address: "",
});

export const editTravelerNameRules = reactive({
  traveler_first_name: {
    required: helpers.withMessage("First name required", required),
    $autoDirty: true,
    maxLength: helpers.withMessage(
      "The maximum length is allowed 50 characters",
      maxLength(50)
    ),
  },
  traveler_last_name: {
    $autoDirty: true,
    required: helpers.withMessage("Last name required", required),
    maxLength: helpers.withMessage(
      "The maximum length is allowed 50 characters",
      maxLength(50)
    ),
  },
});
export const editTravelerAgeRules = reactive({
  traveler_age: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("DOB date required", required),
  },
});
export const editTravelerCitizenRules = reactive({
  traveler_citizen: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Citizenship required", required),
  },
});
export const editTravelerResidenceRules = reactive({
  traveler_residence: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Residence required", required),
  },
});
export const editTravelerEmailRules = reactive({
  traveler_email: {
    $autoDirty: true,
    required: helpers.withMessage("Email required", required),
    email: helpers.withMessage("Email address is not valid", email),
  },
});
export const editTravelerPhoneRules = reactive({
  traveler_phone: {
    $autoDirty: true,
    integer: helpers.withMessage("Number only allowed", integer),
    required: helpers.withMessage("phone required", required),
    maxLength: helpers.withMessage(
      "The maximum length is allowed 16 characters",
      maxLength(16)
    ),
  },
});
export const editTravelerAddressRules = reactive({
  traveler_address: {
    $autoDirty: true,
    required: helpers.withMessage("Address required", required),
  },
});

//edit extend date scheme
export const editExtendDate = reactive({
  extend_date: "",
});

export const editExtendDateRules = reactive({
  extend_date: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("End Date required", required),
  },
});

//edit travel start dates
export const editStartDate = reactive({
  start_date: "",
});

export const editStartDateRules = reactive({
  start_date: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Start Date required", required),
  },
});

//edit travel end dates
export const editEndDate = reactive({
  end_date: "",
});

export const editEndDateRules = reactive({
  end_date: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("End Date required", required),
  },
});

//edit travel destination
export const editTravelerDestination = reactive({
  traveler_destination: "",
});
export const editTravelerDestinationRules = reactive({
  traveler_destination: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Destination required", required),
  },
});

//edit travel trip cost
export const editTripCost = reactive({
  total_trip_cost: null,
  date_of_first_payment: null,
});
export const editTripCostRules = reactive({
  total_trip_cost: {
    $autoDirty: true,
    $touch: true,
    // integer,
    required: helpers.withMessage("Trip cost required", required),
    // minValue: helpers.withMessage(
    //   "The minimum value is allowed 1 ",
    //   minValue(0)
    // ),
  },
  date_of_first_payment: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Payment date required", required),
  },
});

//update beneficiary
export const editBeneficiary = reactive({
  first_name: '',
  last_name: '',
  email: '',
  relation: ''
});

export const editBeneficiaryRules = reactive({
  first_name: {
    $autoDirty: true,
    required: helpers.withMessage("First name required", required),
    maxLength: helpers.withMessage(
      "The maximum length is allowed 99 characters",
      maxLength(99)
    ),
  },
  last_name: {
    $autoDirty: true,
    required: helpers.withMessage("Last name required", required),
    maxLength: helpers.withMessage(
      "The maximum length is allowed 99 characters",
      maxLength(99)
    ),
  },
  email: {
    $autoDirty: true,
    required: helpers.withMessage("Email required", required),
    email: helpers.withMessage("Email address is not valid", email),
  },
  relation: {
    $autoDirty: true,
    required: helpers.withMessage("Relation required", required),
  }

});

//update emergency contact
export const editEmergencyContact = reactive({
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
});
export const editEmergencyContactRules = reactive({
  first_name: {
    $autoDirty: true,
    required: helpers.withMessage("First Name required", required),
    maxLength: helpers.withMessage(
      "The maximum length is allowed 50 characters",
      maxLength(50)
    ),
  },
  last_name: {
    $autoDirty: true,
    required: helpers.withMessage("Last Name required", required),
    maxLength: helpers.withMessage(
      "The maximum length is allowed 50 characters",
      maxLength(50)
    ),
  },
  email: {
    $autoDirty: true,
    required: helpers.withMessage("Email required", required),
    email: helpers.withMessage("Email address is not valid", email),
  },
  phone: {
    $autoDirty: true,
    integer: helpers.withMessage("Number only allowed", integer),
    maxLength: helpers.withMessage(
      "The maximum length is allowed 16 characters",
      maxLength(16)
    ),
  },
});

// add new claims
export const addNewClaims = reactive({
  claimer_name: "",
  types_claim: "",
  reason_claim: "",
  who_effected_claim: "",
  message: "",
  acknowledgement: false,
});

export const addNewClaimsRules = reactive({
  claimer_name: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Please Select traveler", required),
  },
  types_claim: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Please Select Claim type", required),
  },
  reason_claim: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Please select Claim reason", required),
  },
  who_effected_claim: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Please select Effected Person", required),
  },
  message: {
    $autoDirty: true,
    required: helpers.withMessage("Question required", required),
  },
});

export const claimsEffects = reactive([
  { name: "Insured", value: "insured" },
  { name: "Family Member", value: "family_member" },
  { name: "Traveling Companion", value: "traveling_companion" },
  { name: "Business Partner", value: "business_partner" },
  { name: "Host At Destination", value: "host_at_destination" },
  { name: "Other", value: "other" },
]);
//  claims info
export const claimsEffectedTravelers = reactive({
  relation_member: "",
  name: "",
  date_of_birth: "",
});
export const claimsEffectedTravelersRules = reactive({
  relation_member: {
    $autoDirty: true,
    required: helpers.withMessage("Name required", required),
    maxLength: helpers.withMessage(
      "The maximum length is allowed 50 characters",
      maxLength(50)
    ),
  },
  name: {
    $autoDirty: true,
    required: helpers.withMessage("Name required", required),
    maxLength: helpers.withMessage(
      "The maximum length is allowed 50 characters",
      maxLength(50)
    ),
  },
  date_of_birth: {
    required: helpers.withMessage("Date of birth is required", required),
    dobIsValidInMonth: helpers.withMessage(
      "Invalid Date of birth ",
      dateIsValid
    ),

    dobIsValid: helpers.withMessage(
      "Date of birth should be the past time",
      checkDOB
    ),
  },
});

export const cardDetails = reactive({
  cardName: "",
  cardNumber: "",
  cardMonth: "",
  cardYear: "",
  cardCvv: "",
  cardType: "",
});

export const cardDetailsRules = reactive({
  cardName: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Please Enter Card Holder Name", required),
    required: helpers.withMessage("Card name should be character", checkAlpha),
  },
  cardType: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Please Card Type ", required),
    alpha: helpers.withMessage("Card name  should be character", alpha),
  },
  cardNumber: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Please Enter Card Holder Number", required),
    minLength: helpers.withMessage("Min Length should be 16", minLength(19)),
    maxLength: helpers.withMessage("Max Length should be 16", maxLength(19)),
  },
  cardMonth: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Please select card month", required),
    numeric: helpers.withMessage("CVV should be number", numeric),
  },
  cardYear: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Please select year", required),
    numeric,
  },
  cardCvv: {
    $autoDirty: true,
    required: helpers.withMessage("CVC required", required),
    numeric: helpers.withMessage("CVC should be number", numeric),
  },
});

export const userRulesVidation = useVuelidate(userRules, userState);
export const orderRulesVidation = useVuelidate(orderRules, orderStates);
export const travelerValidation = useVuelidate(travelerRules, traveler);
export const beneficiaryValidation = useVuelidate(benefRules, benef);
export const billingAddressValidation = useVuelidate(
  billingAddressRules,
  BillAddress
);
export const PlanListingValidation = useVuelidate(PlanListingRules, planFilter);
export const QuoteValidation = useVuelidate(quoteRules, Quote);

// export const newsLetterValiadtion = useVuelidate(
//   newLetterRules,
//   newLetterState
// );

//Authtincation
export const loginValiadtion = useVuelidate(loginRules, loginState);
export const SignUpValiadtion = useVuelidate(signUpRules, signUpState);
export const forgotUpValiadtion = useVuelidate(forgotRules, forgotState);
export const resendValiadtion = useVuelidate(resendRules, resendState);
export const resetValiadtion = useVuelidate(resetRules, resetState);
export const queryValidation = useVuelidate(queryRules, queryState);
export const cardDetailsValidation = useVuelidate(
  cardDetailsRules,
  cardDetails
);

// export eidt pikcy validations
export const editTravelerNameValidation = useVuelidate(
  editTravelerNameRules,
  editTravelerName
);
export const editTravelerAgeValidation = useVuelidate(
  editTravelerAgeRules,
  editTravelerAge
);
export const editTravelerCitizenValidation = useVuelidate(
  editTravelerCitizenRules,
  editTravelerCitizen
);
export const editTravelerResidenceValidation = useVuelidate(
  editTravelerResidenceRules,
  editTravelerResidence
);
export const editTravelerEmailValidation = useVuelidate(
  editTravelerEmailRules,
  editTravelerEmail
);
export const editTravelerPhoneValidation = useVuelidate(
  editTravelerPhoneRules,
  editTravelerPhone
);
export const editTravelerAddressValidation = useVuelidate(
  editTravelerAddressRules,
  editTravelerAddress
);
export const editExtendDateValidation = useVuelidate(
  editExtendDateRules,
  editExtendDate
);

export const editStartDateValidation = useVuelidate(
  editStartDateRules,
  editStartDate
);

export const editEndDateValidation = useVuelidate(
  editEndDateRules,
  editEndDate
);
export const editTravelerDestinationValidation = useVuelidate(
  editTravelerDestinationRules,
  editTravelerDestination
);
export const editTripCostValidation = useVuelidate(
  editTripCostRules,
  editTripCost
);
export const editBeneficiaryValidation = useVuelidate(
  editBeneficiaryRules,
  editBeneficiary
);
export const editEmergencyContactValidation = useVuelidate(
  editEmergencyContactRules,
  editEmergencyContact
);
export const addNewClaimValidation = useVuelidate(
  addNewClaimsRules,
  addNewClaims
);
export const regionalTravelValidation = useVuelidate(
  regionalTravelRules,
  regionalTravel
);

export const multiTravelerDestinationValidation = useVuelidate(
  multiDestinationReulse,
  itiraMultiDestStates
);
export const multiTravelValidation = useVuelidate(itiraReulse, itiraStates);
export const alreadyTravelValidation = useVuelidate(
  alreadyTravelingStatRules,
  alreadyTravelingState
);
export const chooseDatesTravelValidation = useVuelidate(
  chooseInitialDatesStateRules,
  chooseInitialDatesState
);
export const TripCostPaymentDatesValidation = useVuelidate(
  TripCostPaymentDatesRules,
  TripCostPaymentDatesState
);
export const editItiraTravelerValidation = useVuelidate(
  editItiraTravelerRules,
  editItiraTravelerState
);
export const editAddItiraTravelerValidation = useVuelidate(
  editItiraTravelerRules,
  editAddItiraTravelerState
);
export const beneficiaryNewValidation = useVuelidate(
  beneficiaryRules,
  beneficiaryState
);
export const emergencyContactNewValidation = useVuelidate(
  emergencyContactRules,
  emergencyContactState
);
export const travelerCheckoutValidation = useVuelidate(
  checkoutTravelers,
  checkoutTravelersState
);
export const  travelerCheckoutBillingContactValidation = useVuelidate(
    billingContactRules,
    billingContactState
);
export const travelerCheckoutBillingValidation = useVuelidate(
  billingRules,
  billingState
);
export const googleAddressValidation = useVuelidate(googleRules, googleAddress);
export const googleAdderesSingleOriginValidation = useVuelidate(
  googleAdderesSingleOriginRules,
  googleAdderesSingleOrigin
);
export const googleAdderesSingleDestinationValidation = useVuelidate(
  googleAdderesSingleDestinationRules,
  googleAdderesSingleDestination
);

export const googleAddressTravelRegionValidation = useVuelidate(
    googleAddressTravelRegionRules,
    googleAddressTravelRegion
);
export const travelerCheckoutPaymentCardValidation = useVuelidate(
  paymentCardRule,
  paymentCardState
);
export const claimsEffectedTravelersValidation = useVuelidate(
  claimsEffectedTravelers,
  claimsEffectedTravelersRules
);

// new claims info

export let primaryReason = ref(null);
export let effected = ref(null);
export let certifiedByDocter = ref(false);
export let certifiedByDocterDestination = ref(false);
export let legalActionParty = ref(false);
export let schoolRelated = ref(false);
export let militaryService = ref(false);
export let workReasons = ref(false);
export let primaryResidenceDestination = ref(false);
export let accidentRouteDestination = ref(false);
export let adoptChild = ref(false);
export let petCriticalCondition = ref(false);

export const familyMember = reactive({
  relation: null,
  name: null,
  dob: null,
  travelingWith: false,
  certifiedByDocter: false,
});
export const travelCompanion = reactive({
  name: null,
  dob: null,
  certifiedByDocter: false,
});

export const businessPartner = reactive({
  name: null,
  dob: null,
  travelingWith: false,
  certifiedByDocter: false,
});
export const tripCancellationDates = reactive({
  date_injury_start: null,
  date_injury_end: null,
  death_date: null,
  hospital_period: null,
  details: null,
});

export const travelDelayInformation = reactive({
  beginning_delay_date: null,
  ended_delay_date: null,
  delay_duration: null,
  summary_reason_for_delay: null,
});

export const missedConnectionInformation = reactive({
  missed_connection_date: null,
  how_long_missed_connection: null,
  missed_connection_end: null,
  summary_for_missed_connection: null,
});


export const interruptionInformation = reactive({
  noticeDate: null,
  location: null,
  details: null,
});

export const supplierInformation = reactive({
  company: null,
  address: null,
  postal_code: null,
  country: null,
  contact_name: null,
  email: null,
  phone: null,
  date_of_arrangement: null,
  date_of_initial_trip_payment: null,
  scheduled_departure_date: null,
  scheduled_return_date: null,
});

export const travelCompanionHijackQuarantine = reactive({
  name: null,
  dob: null,
  legalActionParty: false,
});

export const travelCompanionExtendExam = reactive({
  name: null,
  dob: null,
  schoolRelated: false,
  schoolExtensionDate: null,
});

export const travelCompanionMilitaryService = reactive({
  name: null,
  dob: null,
  militaryService: false,
});

export const travelCompanionWorkReasons = reactive({
  name: null,
  dob: null,
  workReasons: false,
});

export const travelCompanionPrimaryResidenceDestination = reactive({
  name: null,
  dob: null,
  primaryResidenceDestination: false,
});

export const travelCompanionAccidentRouteDestination = reactive({
  name: null,
  dob: null,
  accidentRouteDestination: false,
});

export const travelCompanionAdoptChild = reactive({
  name: null,
  dob: null,
  adoptChild: false,
});

export const travelCompanionPetCriticalCondition = reactive({
  name: null,
  dob: null,
  petCriticalCondition: false,
});

//claims rules
export  const claimDate= reactive({
  date: null
});

export  const unexpectedMedicalCare= reactive({
  physician_name: null,
  illness_start_date: null,
  illness_first_treatment_date: null,
  country: null,
  address: null,
  postal_code: null,
  email: null,
  phone: null,
  injury_causes:false,
  claim_result:null,
  illness_description:null,
  injury_place:null,
  injury_time:null,
  injury_date:null,
  injury_description:null,
  accident_description:null,
  physician_facility_phone:null,
  prior_treatment_doctor:false,
  hospital_phone_number:null,
  hospital_name:null,
  hospital_address:null,

});




export const primaryReasonRules = {
  $autoDirty: true,
  required: helpers.withMessage("Primary Reason is required", required),
};
export const effectedRules = {
  $autoDirty: true,
  required: helpers.withMessage("Effected Reason is required", required),
};
export const certifiedByDocterRules = {
  $autoDirty: true,
  required: helpers.withMessage("Certified  doctor  is required", required),
};
export const certifiedByDocterDestinationRules = {
  $autoDirty: true,
  required: helpers.withMessage("Certified  doctor  is required", required),
};
export const claimDateRules = reactive({
  date:{
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Claim Date is required", required),
  }
});
// unexpectedMedicalCareRules,
//   unexpectedMedicalCare
// physician_name: null,
//   illness_start_date: null,
//   illness_first_treatment_date: null,
//   country: null,
//   address: null,
//   postal_code: null,
//   email: null,
//   phone: null,
//   claim_result:null,
export const unexpectedMedicalCareRules = reactive({
  physician_name:{
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Physician Name is required", required),
  },
  illness_start_date:{
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Accident/illness start date is required", required),
  },
  illness_first_treatment_date:{
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Accident/illness first treatment date is required", required),
  },
  country:{
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Country is required", required),
  },
  address:{
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Address is required", required),
  },
  postal_code:{
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Postal Code is required", required),
  },
  email:{
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Email is required", required),
  },
 phone:{
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Phone is required", required),
  },
  claim_result:{
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Select claim is a result", required),
  },
  illness_description:{
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Please describe symptoms, including the start date", required),
  },
  injury_description:{
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Please describe", required),
  },
  accident_description:{
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Please describe", required),
  },
  injury_place:{
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Injury place is required", required),
  },
  injury_time:{
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Injury time is required", required),
  },
  injury_date:{
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Injury date is required", required),
  },

  physician_facility_phone:{
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Physician phone number is required", required),
  },
  physician_address:{
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Physician Address is required", required),
  },
  hospital_name:{
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Hospital Name is required", required),
  },
  hospital_phone_number:{
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Hospital phone number is required", required),
  },
  hospital_address:{
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Hospital Address is required", required),
  },



});




export const familyMemberRules = reactive({
  relation: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Relation is required", required),
  },
  name: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Name is required", required),
  },
  dob: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Date of birth is required", required),
  },
  travelingWith: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Were they traveling with you?", required),
  },
  certifiedByDocter: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage(
      "Were they unable to travel as certified by a doctor at the time of loss?",
      required
    ),
  },
});

export const travelCompanionRules = reactive({
  relation: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Relation is required", required),
  },
  name: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Name is required", required),
  },
  dob: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Date of birth is required", required),
  },

  certifiedByDocter: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage(
      "Were they unable to travel as certified by a doctor at the time of loss?",
      required
    ),
  },
});

export const businessPartnerRules = reactive({
  name: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Name is required", required),
  },
  dob: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Date of birth is required", required),
  },
  travelingWith: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Were they traveling with you?", required),
  },
  certifiedByDocter: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage(
      "Were they unable to travel as certified by a doctor at the time of loss?",
      required
    ),
  },
});


export const tripCancellationDatesRules = reactive({
  date_injury_start: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Injury start date is required", required),
  },
  date_injury_end: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Injury end date is required", required),
  },
  /*death_date: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Date of birth is Required", required),
  },
  hospital_period: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Were they traveling with you?", required),
  },*/
  details: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Please briefly describe the nature of sickness/injury", required),
  },
});

export const interruptionInformationRules = reactive({
  noticeDate: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Date of birth is required", required),
  },
  location: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("location is required", required),
  },
  details: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Were they traveling with you?", required),
  },
});

export const supplierInformationRules = reactive({
  company: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Company is required", required),
  },
  address: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Address is required", required),
  },
  postal_code: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Postal code is required", required),
  },
  country: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Country is required", required),
  },
  contact_name: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Contact name is required", required),
  },
  email: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Email is required", required),
  },
  phone: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Phone is required", required),
  },
  date_of_arrangement: {
    required: helpers.withMessage(
      "Date Arrangement were made is required",
      required
    ),
    dobIsValidInMonth: helpers.withMessage(
      "Invalid Date Arrangement were made ",
      dateIsValid
    ),
  },
  date_of_initial_trip_payment: {
    required: helpers.withMessage(
      "Date OF initial trip payment is required",
      required
    ),
    dobIsValidInMonth: helpers.withMessage(
      "Invalid Date OF initial trip payment ",
      dateIsValid
    ),
  },
  scheduled_departure_date: {
    required: helpers.withMessage(
      "Schedule departure date is required",
      required
    ),
    dobIsValidInMonth: helpers.withMessage(
      "Invalid Schedule departure date",
      dateIsValid
    ),
  },
  scheduled_return_date: {
    required: helpers.withMessage("Schedule return date is required", required),
    dobIsValidInMonth: helpers.withMessage(
      "Invalid Schedule return date",
      dateIsValid
    ),
  },
});

export const travelCompanionHijackQuarantineRules = reactive({
  relation: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Relation is required", required),
  },
  name: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Name is required", required),
  },
  dob: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Date of birth is required", required),
  },

  legalActionParty: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage(
      "Were they a party in the legal action or appearing as a law enforcement officer?",
      required
    ),
  },
});

export const travelCompanionExtendExamRules = reactive({
  relation: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Relation is required", required),
  },
  name: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Name is required", required),
  },
  dob: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Date of birth is required", required),
  },

  schoolRelated: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage(
      "Were they a party in the legal action or appearing as a law enforcement officer?",
      required
    ),
  },

  schoolExtensionDate: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage(
      "Extension date is required",
      required
    ),
  },
});

export const travelCompanionMilitaryServiceRules = reactive({
  relation: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Relation is required", required),
  },
  name: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Name is required", required),
  },
  dob: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Date of birth is required", required),
  },

  militaryService: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage(
      "Were they a party in the legal action or appearing as a law enforcement officer?",
      required
    ),
  },
});

export const travelCompanionWorkReasonsRules = reactive({
  relation: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Relation is required", required),
  },
  name: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Name is required", required),
  },
  dob: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Date of birth is required", required),
  },

  workReasons: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage(
      "Were they unable to travel due to work reasons?",
      required
    ),
  },
});

export const travelCompanionPrimaryResidenceDestinationRules = reactive({
  relation: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Relation is required", required),
  },
  name: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Name is required", required),
  },
  dob: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Date of birth is required", required),
  },

  primaryResidenceDestination: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage(
      "Were the primary residence or destination made uninhabitable/inaccessible?",
      required
    ),
  },
});

export const travelCompanionAccidentRouteDestinationRules = reactive({
  relation: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Relation is required", required),
  },
  name: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Name is required", required),
  },
  dob: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Date of birth is required", required),
  },

  accidentRouteDestination: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage(
      "Have they had an accident in route to the destination?",
      required
    ),
  },
});

export const travelCompanionAdoptChildRules = reactive({
  relation: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Relation is required", required),
  },
  name: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Name is required", required),
  },
  dob: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Date of birth is required", required),
  },

  adoptChild: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage(
      "Were they traveling to adopt a child but the adoption was canceled for reasons out of their control?",
      required
    ),
  },
});

export const travelCompanionPetCriticalConditionRules = reactive({
  relation: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Relation is required", required),
  },
  name: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Name is required", required),
  },
  dob: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Date of birth is required", required),
  },

  petCriticalCondition: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage(
      "Were their pet in critical condition or passed away?",
      required
    ),
  },
});

export const travelDelayInformationRules = reactive({
  beginning_delay_date: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Beginning Date of Delay is required", required),
  },
  ended_delay_date: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("End Date of Delay is required", required),
  },
  delay_duration: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Delay Duration is required", required),
  },
/*  summary_reason_for_Delay: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Summary reason for delay is required", required),
  }*/
});

export const missedConnectionInformationRules = reactive({
  missed_connection_date: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Date of missed connection is required", required),
  },
  how_long_missed_connection: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("How long missed connection is required", required),
  },
  missed_connection_end: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Missed Connection End is required", required),
  },
  summary_for_missed_connection: {
    $autoDirty: true,
    $touch: true,
    required: helpers.withMessage("Summary reason for missed connection is required", required),
  }
});

export const newsLetterState = ref({
  email: ""
});

export const newsLetterRules = reactive({
  email:{
    $autoDirty: true,
    required: helpers.withMessage("Email required", required),
    email: helpers.withMessage("Email address is not valid", email)
  }
});
export const newsLetterValidation = useVuelidate(
  newsLetterState,
  newsLetterRules
);

//validators



// medical care
// export let injuryCauses = ref(false);
export const unexpectedMedicalCareValidation = useVuelidate(
  unexpectedMedicalCareRules,
  unexpectedMedicalCare
);



export const claimsDateValidation = useVuelidate(
  claimDateRules,
  claimDate
);

export const supplierInformationValidation = useVuelidate(
  supplierInformationRules,
  supplierInformation
);

export const claimsPrimaryReasonValidation = useVuelidate(
  primaryReasonRules,
  primaryReason
);
export const claimsEffectedValidation = useVuelidate(effectedRules, effected);
export const claimsCertifiedByDocterValidation = useVuelidate(
  certifiedByDocterRules,
  certifiedByDocter
);
export const claimsFamilyMemberValidation = useVuelidate(
  familyMemberRules,
  familyMember
);
export const claimsTravelCompanionValidation = useVuelidate(
  travelCompanionRules,
  travelCompanion
);
export const claimsBusinessPartnerValidation = useVuelidate(
  businessPartnerRules,
  businessPartner
);
export const claimsCertifiedByDocterDestinationRulesValidation = useVuelidate(
  certifiedByDocterDestinationRules,
  certifiedByDocterDestination
);
export const claimstripCancellationDatesValidation = useVuelidate(
  tripCancellationDatesRules,
  tripCancellationDates
);
export const claimsTravelDelayInformationValidation = useVuelidate(
  travelDelayInformationRules,
  travelDelayInformation
);
export const claimsMissedConnectionInformationValidation = useVuelidate(
  missedConnectionInformationRules,
  missedConnectionInformation
);
export const claimsInterruptionInformationValidation = useVuelidate(
  interruptionInformationRules,
  interruptionInformation
);

export const claimsTravelCompanionHijackQuarantineValidation = useVuelidate(
  travelCompanionHijackQuarantineRules,
  travelCompanionHijackQuarantine
);

export const claimsTravelCompanionExtendExamValidation = useVuelidate(
  travelCompanionExtendExamRules,
  travelCompanionExtendExam
);

export const claimsTravelCompanionMilitaryServiceValidation = useVuelidate(
  travelCompanionMilitaryServiceRules,
  travelCompanionMilitaryService
);

export const claimsTravelCompanionWorkReasonsValidation = useVuelidate(
  travelCompanionWorkReasonsRules,
  travelCompanionWorkReasons
);

export const claimsTravelCompanionPrimaryResidenceDestinationValidation = useVuelidate(
  travelCompanionPrimaryResidenceDestinationRules,
  travelCompanionPrimaryResidenceDestination
);

export const claimsTravelCompanionAccidentRouteDestinationValidation = useVuelidate(
  travelCompanionAccidentRouteDestinationRules,
  travelCompanionAccidentRouteDestination
);

export const claimsTravelCompanionAdoptChildValidation = useVuelidate(
  travelCompanionAdoptChildRules,
  travelCompanionAdoptChild
);

export const claimsTravelCompanionPetCriticalConditionValidation = useVuelidate(
  travelCompanionPetCriticalConditionRules,
  travelCompanionPetCriticalCondition
);
