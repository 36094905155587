import { axios } from "@/config/axios";
import {
  addClaim,
  allClaims,
  claimDetailView,
  claimReason,
  claimType,
  claimTypes,
  createNewClaim,
  documentUpload
} from "@/endpoints";
import { notify } from "@kyvg/vue3-notification";
import { router } from "@/router";

import { STATE_STATUSES } from "@/helpers/constants";
import { useRoute } from "vue-router";
import { toRaw } from "vue";

const claimsTravelers = [
  {
    uuid: "992c0d7c-298b-4827-beee-438b6e843222",
    first_name: "Usman",
    last_name: "Islam",
    citizenship: 233,
    residence: 233,
    dob: "2017-04-06",
    zipcode: "90001",
    is_primary: 1,
  },
  {
    uuid: "992c0d7c-2d99-42db-967b-67f26c5ad012",
    first_name: "Asif",
    last_name: "khan",
    citizenship: 233,
    residence: 233,
    dob: "2019-05-05",
    zipcode: "90001",
    is_primary: 0,
  },
  {
    uuid: "992c0d7c-30ff-484f-b060-6b23251bfcfd",
    first_name: "Waheeed",
    last_name: "khan",
    citizenship: 233,
    residence: 233,
    dob: "2018-04-04",
    zipcode: "90001",
    is_primary: 0,
  },
  {
    uuid: "992c0d7c-3454-44d9-a477-65ca7125eebc",
    first_name: "rizwan",
    last_name: "khan",
    citizenship: 233,
    residence: 233,
    dob: "2017-05-05",
    zipcode: "90001",
    is_primary: 0,
  },
  {
    uuid: "992c0d7c-391f-42ef-8f98-bbc6681f7e23",
    first_name: "osh",
    last_name: "gosh",
    citizenship: 233,
    residence: 233,
    dob: "2018-05-04",
    zipcode: "90001",
    is_primary: 0,
  },
];

const whoAffectedSicknessInjury = [
  {
    id: 1,
    name: "The insured",
    slug: "the-insured",
  },
  {
    id: 2,
    name: "Family member",
    slug: "family-member",
  },
  {
    id: 3,
    name: "Traveling companion",
    slug: "traveling-companion",
  },
  {
    id: 4,
    name: "Business partner",
    slug: "business-partner",
  },
  {
    id: 5,
    name: "Host at destination ",
    slug: "host-destination",
  },
];


export const actions = {
  async getAllClaims({ commit }, payload) {
     //payload have order id or sales id
    // store sales id in createClaim State for claim listing
    commit("setSalesIdForClaimsListing", payload);
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.get(`${allClaims}/${payload}`);
      // const response = await axios.get(allClaims);
      commit("STATE_STATUSES", STATE_STATUSES.READY);
      commit("getAllClaims", response.data.records.reverse());
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
  async getClaimDetail({ commit }, payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.get(
        `${claimDetailView}/${payload}`,
        payload
      );
      commit("STATE_STATUSES", STATE_STATUSES.READY);
      commit("getClaim", response.data.records);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
  async addNewClaim({ commit }, payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      await axios.post(createNewClaim, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      commit("STATE_STATUSES", STATE_STATUSES.READY);
      notify({
        type: "Success",
        title: "Added Successfull",
        text: "Your claim has been added",
      });

      router.push({
        path: `/dashboard/claims/all-claims/${payload.get("order_no")}`,
      });
    } catch (e) {
      console.log("error", e);
      notify({
        type: "error",
        title: "Added Faild",
        text: "Your claim has not been added",
      });

      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
  async getClaimsTypes({ commit }, payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.get(claimType, {
        params:{
          administrator_id: payload
        }
      });
      commit("STATE_STATUSES", STATE_STATUSES.READY);
      commit("getClaimsTypes", response.data.records);
      commit("setAllClaimTypes", response.data.records);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
  async getClaimsReason({ commit }) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.get(claimReason);
      commit("STATE_STATUSES", STATE_STATUSES.READY);
      commit("getClaimsReason", response.data.records);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
  async uploadFileClaim({ commit }, payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      await axios.post(documentUpload, payload);
      commit("STATE_STATUSES", STATE_STATUSES.READY);
      router.push({
        path: `/dashboard/claims/claim-details/${payload.get("claim_id")}`,
        query: { claim_status: payload.get("claim_status") },
      });
    } catch (e) {
      console.log("error", e);

      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },

  //new claims flow

  async getAllClaimTravelers({ commit }, payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      commit("setClaimTravelers", claimsTravelers);
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);

      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
  async setClaimTraveler({ commit }, payload) {
    commit("setClaimTraveler", payload);
    router.push({
      path: "claim-type",
    });
  },
  async getAllClaimTypes({ commit },payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      let response = await axios.get(claimTypes,{
        params:{
          administrator_id:payload
        }
      });

      commit("setAllClaimTypes", response.data.records);
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);

      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
  async setClaimType({ commit }, payload) {
    commit("setClaimType", payload);
  },
  async setClaimDate({ commit }, payload) {
    let { claim_date, slug } = payload;
    commit("setClaimDate", claim_date);
    router.push({
      path: slug,
    });
  },

  //trip cancelation form

  async getTripCancellationSchema({ commit }) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      // let response = await axios.get(claimTypes);

      commit("tripCancellationSchema", tripCancellationSchema);
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);

      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },

  async setTripCancellationPrimaryReason({ commit }, payload) {
    let { slug, ...reason } = payload;

    commit("setTripCancellationPrimaryReason", reason);
    router.push({
      path: `trip-cancellation/${slug}`,
    });
  },
  async getAllTripCancellationEffecteds({ commit }) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      // let response = await axios.get(claimTypes);

      commit("getAllTripCancellationEffecteds", whoAffectedSicknessInjury);
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);

      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },

  async setTripCancellationEffecteds({ commit }, payload) {
    let { previousSlug, ...reason } = payload;

    commit("setTripCancellationEffecteds", reason.list);
  },

  // for Trip Canellation claim
  async setTripCanellationForm({ state,commit }, payload) {
    commit("setTripCanellationForm", payload);
    let { createClaim } = toRaw(state);

    //TODO will do change this condition
    if (createClaim.primary_reason == "Sickness, injury, or the death")
    {
      router.push({
        path: `trip-cancellation-dates`,
      });
    }else
    {
      router.push({
        path: `trip-cancellation-information`,
      });
    }


  },

  async setTripCanellationDatesForm({ commit }, payload) {
    let { trip_cancellation_dates } = payload;
    commit("setTripCanellationDates", trip_cancellation_dates);
    router.push({
      path: `trip-cancellation-information`,
    });
  },

  async setTripCanellationInterruptionForm({ commit }, payload) {
    let { interruption_information } = payload;
    commit("setTripCanellationInterruption", interruption_information);
    router.push({
      path: `loss-information`,
    });
  },





// for travel Delay claim

async setTripDelayReasonsForm({ commit }, payload) {
  commit("setTripDelayReasonsForm", payload);
  router.push({
    path: `travel-delay-information`,
  });
},
async setTravelDelayForm({ commit }, payload) {
  commit("setTravelDelayForm", payload);
  router.push({
    path: `loss-information`,
  });
},


  // for Missed Connection claim

  async setMissedConnectionReasonsForm({ commit }, payload) {
    commit("setMissedConnectionReasonsForm", payload);
    router.push({
      path: `missed-connection-information`,
    });
  },
  async setMissedConnectionForm({ commit }, payload) {
    commit("setMissedConnectionForm", payload);
    router.push({
      path: `loss-information`,
    });
  },

//for Medical Care
  async setMedicalCareReasonsForm({ commit }, payload) {
    commit("setMedicalCareReasonsForm", payload);
    router.push({
      path: `loss-information`,
    });
  },




// async setMedicalCareFormAction({ commit }, payload) {
//   console.log('medical form',payload);
//   commit("setMedicalCareForm", payload);
//   router.push({
//     path: `trip-cancellation-dates`,
//   });
// },






async setLossInformationForm({ commit }, payload) {
  // let { loss_information , claim_type} = payload;
  console.log('trip cancellation interruption payload',payload)
  commit("setLossInformation", payload);
  // router.push({
  //   path: `loss-information`,
  // });
},




async setClaimSelectedType({ commit }, payload) {
  commit("setClaimSelectedType", payload);
  router.push({
    path: `claim-date`,
  });
},



async setAddClaim({ commit, state }) {
  let response= await axios.post(addClaim, { claim: state.createClaim });
  commit("savedClaimID", response.data.records.id);
   commit("resetTripClaim");
 //  console.log('ID',response.data.data.id);
  //console.log('stored claim',response.data.records.id);
  router.push({
    path: "claim-submit",
  });
},


};
