import { actions } from "./action";
import mutations from "./mutations";
import { getters } from "./getters";

import { STATE_STATUSES } from "@/helpers/constants";
import { reactive } from "@vue/reactivity";
import { useLocalStorage } from "@vueuse/core";
const state = reactive({
  userOrder: null,
  userDashboard: null,
  extendPolicyDetail: null,
  status: STATE_STATUSES.INIT,
  fileLoader: STATE_STATUSES.INIT,
});

const order = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default order;
