import { axios } from "@/config/axios";
import {
  userDashboard,
  editTravelerTicket,
  updateTravelerNameAndAddressPoint,
  updateTravelerWithTicketPoint,

  //get api endpoint
  getEditTravelerDetails,
  getEditTravelerDates,
  getEditTravelerDestination,
  getTripCost,
  getBeneficiaryInfo,
  updateBeneficiaryInfo,
  getEmergency,
  updateEmergency, extendPolicyEndDate, getReceipt, getVisaLetter,
} from "@/endpoints";

import { notify } from "@kyvg/vue3-notification";

import { STATE_STATUSES } from "@/helpers/constants";
import {router} from "@/router";

export const actions = {
  //get edit policies data by backend
  async getTravelers({ commit }, payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.get(getEditTravelerDetails, {
        params: { order_id: payload },
      });
      commit("setTravelers", response.data.data);
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
  async getTravelersDates({ commit }, payload) {
    commit("setTravelerDatesLoading", STATE_STATUSES.PENDING);
    try {
      const response = await axios.get(getEditTravelerDates, {
        params: { order_id: payload },
      });

      commit("setTravelerDates", response.data.data);
      commit("setTravelerDatesLoading", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("setTravelerDatesLoading", STATE_STATUSES.ERROR);
    }
  },
  // async getTravelers({ commit }, payload) {
  //   commit("STATE_STATUSES", STATE_STATUSES.PENDING);
  //   try {
  //     const response = await axios.get(editTravelerTicket, {
  //       params: { order_id: payload },
  //     });
  //     commit("setTravelers", response.data.data);
  //     commit("STATE_STATUSES", STATE_STATUSES.READY);
  //   } catch (e) {
  //     console.log("error", e);
  //     commit("STATE_STATUSES", STATE_STATUSES.ERROR);
  //   }
  // },
  async updateTravelerName({ commit }, payload) {
    commit("updateTravelerLoading", STATE_STATUSES.PENDING);
    try {
      const response = await axios.post(
        updateTravelerNameAndAddressPoint,
        payload
      );
      // commit("updateTravelers", response.data);
      commit("updateTravelerLoading", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("updateTravelerLoading", STATE_STATUSES.ERROR);
    }
  },
  async updateTravelerAgeResidenceCitizenship({ commit }, payload) {
    commit("updateTravelerLoading", STATE_STATUSES.PENDING);
    try {
      const response = await axios.post(updateTravelerWithTicketPoint, payload);
      // commit("updateTravelers", response.data);
      commit("updateTravelerLoading", STATE_STATUSES.READY);
      notify({
        type: "Success",
        title: "We will contact you",
        text: "Our CSx Team will contact you soon.",
      });
      router.push({
        path: `/dashboard/extend-policy-plan-detail/${payload.order_id}`,
      });
    } catch (e) {
      console.log("error", e);
      commit("updateTravelerLoading", STATE_STATUSES.ERROR);
    }
  },

  async getTravelersDate({ commit }) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.get(userDashboard);
      commit("setTravelersDate", response.data);
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
  async updateTravelersDate({ commit }, payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.post(userDashboard, payload);
      commit("updateTravelersDate", response.data);
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
  async getExtendPolicy({ commit }) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.get(userDashboard);
      commit("setExtendPolicy", response.data);
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
  async updateExtendPolicy({ commit }, payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.post(userDashboard, payload);
      commit("updateExtendPolicy", response.data);
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
  async getEditDestination({ commit }, payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.get(getEditTravelerDestination, {
        params: { order_id: payload },
      });
      commit("setEditDestination", response.data.data);
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
  async updateEditDestination({ commit }, payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.post(userDashboard, payload);
      commit("updateEditDestination", response.data);
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
  async getCancelPolicy({ commit }) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.get(userDashboard);
      commit("setCancelPolicy", response.data);
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },

  async updateCancelPolicy({ commit }, payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.post(userDashboard, payload);
      commit("updateCancelPolicy", response.data);
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
  setSelectedTraveler({ commit }, payload) {
    commit("setSelectedTraveler", payload);
  },
  setComponentId({ commit }, payload) {
    commit("setComponentId", payload);
  },

  async getTripCostData({ commit }, payload) {
    commit("setTripCostLoading", STATE_STATUSES.PENDING);
    try {
      const response = await axios.get(getTripCost, {
        params: { order_id: payload },
      });

      commit("setTripCost", response.data.data);
      commit("setTripCostLoading", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("setTripCostLoading", STATE_STATUSES.ERROR);
    }
  },

  async getBeneficiaryInfoData({ commit }, payload) {
    commit("setBeneficiaryInfoLoading", STATE_STATUSES.PENDING);
    try {
      const response = await axios.get(getBeneficiaryInfo, {
        params: { order_id: payload },
      });
//console.log(response.data.records, "response.data.records")
      commit("setBeneficiaryInfo", response.data.records);
      commit("setBeneficiaryInfoLoading", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("setBeneficiaryInfoLoading", STATE_STATUSES.ERROR);
    }
  },

  async updateBeneficiaryInfoData({ commit }, payload) {
    commit("setBeneficiaryInfoLoading", STATE_STATUSES.PENDING);
    try {
      const response = await axios.post(updateBeneficiaryInfo, payload);
      commit("updateBeneficiaryInfo", response.data.records);
      commit("setBeneficiaryInfoLoading", STATE_STATUSES.READY);
      /*notify({
        type: "Success",
        title: "We will contact you",
        text: "Our CSx Team will contact you soon.",
      });*/
    } catch (e) {
      console.log("error", e);
      commit("setBeneficiaryInfoLoading", STATE_STATUSES.ERROR);
    }
  },

  async getEmergencyContact({ commit }, payload) {
    //console.log("payload", payload);
    commit("setEmergencyContactLoading", STATE_STATUSES.PENDING);
    try {
      const response = await axios.get(getEmergency, {
        params: { order_id: payload },
      });
     // console.log("setEmergencyContact response",  response.data.records);
      commit("setEmergencyContact", response.data.records);
      commit("setEmergencyContactLoading", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("setEmergencyContactLoading", STATE_STATUSES.ERROR);
    }
  },

  async updateEmergencyContact({ commit }, payload) {
    commit("setEmergencyContactLoading", STATE_STATUSES.PENDING);
    try {
      const response = await axios.post(updateEmergency, payload);
      commit("updateEmergencyContact", response.data);
      commit("setEmergencyContactLoading", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("setEmergencyContactLoading", STATE_STATUSES.ERROR);
    }
  },

  async updateExtendPolicyEndDate({ commit }, payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.post(extendPolicyEndDate, payload);
      notify({
        type: "Success",
        title: "We will contact you",
        text: "Our CSx Team will contact you soon.",
      });
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      notify({
        type: "error",
        title: "Data no updated",
        text: "Some thing bad happen data did not update",
      });
      console.log("error", e);

      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
  async getReceiptData ({commit}, payload) {
    //console.log("payload", payload);
    commit("getReceiptDataStatus", STATE_STATUSES.PENDING);
    try {
      const response = await axios.get(getReceipt, {
       params: {order_id: payload}
      });
     // console.log(response.data.records);
       commit("getReceiptData", response.data.records);
      commit("getReceiptDataStatus", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("getReceiptDataStatus", STATE_STATUSES.ERROR);
    }
  },

  async getVisaLetterData ({commit}, payload) {
    //console.log("payload", payload);
    commit("getVisaLetterDataStatus", STATE_STATUSES.PENDING);
    try {
      const response = await axios.post(getVisaLetter, payload);
     // console.log(response.data.records);
       commit("getVisaLetterData", response.data.records);
      commit("getVisaLetterDataStatus", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("getVisaLetterDataStatus", STATE_STATUSES.ERROR);
    }
  },

};
