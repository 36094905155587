import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, Transition as _Transition, Fragment as _Fragment } from "vue";
import PrimaryLayout from "@/layouts/default.vue";
export default {
  __name: 'App',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_metainfo = _resolveComponent("metainfo");
      const _component_notifications = _resolveComponent("notifications");
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_metainfo, null, {
        title: _withCtx(({
          content
        }) => [_createTextVNode(_toDisplayString(content ? `${content} | G1G Travel & Visitors Insurance` : `G1G Travel & Visitors Insurance`), 1)]),
        _: 1
      }), _createVNode(_component_notifications, {
        position: "bottom right",
        width: "400",
        duration: 3000
      }), _createVNode(PrimaryLayout, null, {
        default: _withCtx(() => [_createVNode(_component_router_view, null, {
          default: _withCtx(({
            Component,
            route
          }) => [_createVNode(_Transition, {
            name: "route",
            mode: "out-in"
          }, {
            default: _withCtx(() => [(_openBlock(), _createElementBlock("div", {
              key: route.name
            }, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
              key: _ctx.$route.fullPath
            }))]))]),
            _: 2
          }, 1024)]),
          _: 1
        })]),
        _: 1
      })], 64);
    };
  }
};