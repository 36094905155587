export const getters = {
  status(state) {
    return state.status;
  },
  message(state) {
    return state.message;
  },
  newLetterStatus(state) {
    return state.newLetterStatus;
  },
};
