import { ref } from 'vue';
export let isOpenCart = ref(false);
export const toggleCartModal = () => {
  isOpenCart.value = !isOpenCart.value;
};
// toggle megemenu
export let isOpenMegaMenu = ref(false);
export const toggleMegaMenuModal = () => {
  isOpenMegaMenu.value = !isOpenMegaMenu.value;
};
