import { Quote } from "@/composable/vuelidate";
import { computed } from "vue";
import TopHeader from "./topHeader.vue";
import Navbar from "@/layouts/components/navbar";
export default {
  name: "compareHeader",
  components: {
    Navbar,
    TopHeader
  },
  setup() {
    const loadingStatus = computed(() => {
      return store.getters["plans/status"] === "pending" ? true : false;
    });
    return {
      loadingStatus,
      Quote
    };
  }
};