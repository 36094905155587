import one from '@/pages/definitions/1.vue';
import TwentyFourHourAdd from '@/pages/definitions/24-hour-add.vue';
import TwentyFourHourAssistance from '@/pages/definitions/24-hour-assistance.vue';
import TwentyFourHourWorldwideAssist from '@/pages/definitions/24-hour-worldwide-assist.vue';
import TwentyFourSevenWorldwideTravelAndMedicalAssistance from '@/pages/definitions/247-worldwide-travel-and-medical-assistance.vue';
import A from '@/pages/definitions/a.vue';
import Aadeftest from '@/pages/definitions/aadeftest.vue';
import Accident from '@/pages/definitions/accident.vue';
import AccidentalDeathAndDismembermentAdd from '@/pages/definitions/accidental-death-and-dismemberment-add.vue';
import ActiveFamilyPak from '@/pages/definitions/active-family-pak.vue';
import AcuteOnsetEmergencyOfPreExistingConditions from '@/pages/definitions/acute-onset-emergency-of-pre-existing-conditions.vue';
import AdaptiveHomeAndVehicleBenefit from '@/pages/definitions/adaptive-home-and-vehicle-benefit.vue';
import AddCommcarrAirUpgrade from '@/pages/definitions/add-commcarr-air-upgrade.vue';
import Administrator from '@/pages/definitions/administrator.vue';
import Admitted from '@/pages/definitions/admitted.vue';
import AdventureSportsRider from '@/pages/definitions/adventure-sports-rider.vue';
import AdventurerPlusPak from '@/pages/definitions/adventurer-plus-pak.vue';
import Age from '@/pages/definitions/age.vue';
import AgeLimit from '@/pages/definitions/age-limit.vue';
import Agent from '@/pages/definitions/agent.vue';
import AlcoholOrDrugAbuse from '@/pages/definitions/alcohol-or-drug-abuse.vue';
import AMBestRating from '@/pages/definitions/am-best-rating.vue';
import AmateurSports from '@/pages/definitions/amateur-sports.vue';
import Ambulance from '@/pages/definitions/ambulance.vue';
import Amendment from '@/pages/definitions/amendment.vue';
import ApplicationFee from '@/pages/definitions/application-fee.vue';
import AthleticSportCoverage from '@/pages/definitions/athletic-sport-coverage.vue';
import ATMSafe from '@/pages/definitions/atm-safe.vue';
import AutomobileCoverage from '@/pages/definitions/automobile-coverage.vue';
import AvailableForNonUSCitizen from '@/pages/definitions/available-for-non-us-citizen.vue';
import BaggageDelayMinimumTime from '@/pages/definitions/baggage-delay-minimum-time.vue';
import BaggageDelayOrLoss from '@/pages/definitions/baggage-delay-or-loss.vue';
import BaggageDelayLoss from '@/pages/definitions/baggage-delayloss.vue';
import BBBRating from '@/pages/definitions/bbb-rating.vue';
import Beneficiary from '@/pages/definitions/beneficiary.vue';
import BenefitSummary from '@/pages/definitions/benefit-summary.vue';
import Brochure from '@/pages/definitions/brochure.vue';
import CancelForAnyReason from '@/pages/definitions/cancel-for-any-reason.vue';
import CancelForAnyReasonDepartureDate from '@/pages/definitions/cancel-for-any-reason-departure-date.vue';
import CancelForAnyReasonEligibility from '@/pages/definitions/cancel-for-any-reason-eligibility.vue';
import CancelForAnyReasonInitialDepositLimits from '@/pages/definitions/cancel-for-any-reason-initial-deposit-limits.vue';
import CancelForAnyReasonPriceLimit from '@/pages/definitions/cancel-for-any-reason-price-limit.vue';
import CancelForWorkReason from '@/pages/definitions/cancel-for-work-reason.vue';
import CancellationDetails from '@/pages/definitions/cancellation-details.vue';
import Carrier from '@/pages/definitions/carrier.vue';
import CarrierFilter from '@/pages/definitions/carrier-filter.vue';
import ChartNumber from '@/pages/definitions/chart-number.vue';
import CitizenReturnRider from '@/pages/definitions/citizen-return-rider.vue';
import ClaimSubmission from '@/pages/definitions/claim-submission.vue';
import ClaimsReview from '@/pages/definitions/claims-review.vue';
import CoInsurance from '@/pages/definitions/co-insurance.vue';
import CoPayCoInsurance from '@/pages/definitions/co-pay-co-insurance.vue';
import ComaBenefit from '@/pages/definitions/coma-benefit.vue';
import CommonCarrierAdd from '@/pages/definitions/common-carrier-add.vue';
import ComplicationsOfPregnancy from '@/pages/definitions/complications-of-pregnancy.vue';
import ComprehensiveCoveragePlans from '@/pages/definitions/comprehensive-coverage-plans.vue';
import ContinuingCoverage from '@/pages/definitions/continuing-coverage.vue';
import Coverage from '@/pages/definitions/coverage.vue';
import CoveredExpenses from '@/pages/definitions/covered-expenses.vue';
import Covid19 from '@/pages/definitions/covid-19.vue';
import CruiseCoverage from '@/pages/definitions/cruise-coverage.vue';
import Deductible from '@/pages/definitions/deductible.vue';
import DeductibleChoices from '@/pages/definitions/deductible-choices.vue';
import Definition from '@/pages/definitions/definition.vue';
import DentalCoveragePainAccident from '@/pages/definitions/dental-coverage-painaccident.vue';
import Dependent from '@/pages/definitions/dependent.vue';
import DirectBilling from '@/pages/definitions/direct-billing.vue';
import Doctor from '@/pages/definitions/doctor.vue';
import EaseOfClaims from '@/pages/definitions/ease-of-claims.vue';
import EcoFriendlyPlans from '@/pages/definitions/eco-friendly-plans.vue';
import EconomyPlans from '@/pages/definitions/economy-plans.vue';
import EffectiveDate from '@/pages/definitions/effective-date.vue';
import Eligibility from '@/pages/definitions/eligibility.vue';
import EmergencyDentalBenefit from '@/pages/definitions/emergency-dental-benefit.vue';
import EmergencyEvacRepatUpgrade from '@/pages/definitions/emergency-evac-repat-upgrade.vue';
import EmergencyMedicalCoverageRange from '@/pages/definitions/emergency-medical-coverage-range.vue';
import EmergencyMedicalEvacuation from '@/pages/definitions/emergency-medical-evacuation.vue';
import EmergencyMedicalReunion from '@/pages/definitions/emergency-medical-reunion.vue';
import EmergencyMedicalTreatment from '@/pages/definitions/emergency-medical-treatment.vue';
import EmergencyRoom from '@/pages/definitions/emergency-room.vue';
import EnchancedAdd from '@/pages/definitions/enchanced-add.vue';
import EnhancedBaggage from '@/pages/definitions/enhanced-baggage.vue';
import EnhancedCrisisResponse from '@/pages/definitions/enhanced-crisis-response.vue';
import EnhancedEmergencyMedicalEvacuation from '@/pages/definitions/enhanced-emergency-medical-evacuation.vue';
import Enrollment from '@/pages/definitions/enrollment.vue';
import EvacuationPlusRider from '@/pages/definitions/evacuation-plus-rider.vue';
import EventTicketRegistrationFeeProtection from '@/pages/definitions/event-ticket-registration-fee-protection.vue';
import EvidenceOfInsurabilityEOI from '@/pages/definitions/evidence-of-insurability-eoi.vue';
import ExcludedActivities from '@/pages/definitions/excluded-activities.vue';
import ExcludedDestination from '@/pages/definitions/excluded-destination.vue';
import ExcludedReasonsForTripCancellation from '@/pages/definitions/excluded-reasons-for-trip-cancellation.vue';
import ExcludedStates from '@/pages/definitions/excluded-states.vue';
import Exclusions from '@/pages/definitions/exclusions.vue';
import ExpirationDate from '@/pages/definitions/expiration-date.vue';
import ExposureAndDisappearance from '@/pages/definitions/exposure-and-disappearance.vue';
import FVisaCompliant from '@/pages/definitions/f-visa-compliant.vue';
import FeatureFilters from '@/pages/definitions/feature-filters.vue';
import FeloniousAssaultBenefit from '@/pages/definitions/felonious-assault-benefit.vue';
import FinancialDefault from '@/pages/definitions/financial-default.vue';
import FlightAccidentCoverage from '@/pages/definitions/flight-accident-coverage.vue';
import FollowMeHomeCoverage from '@/pages/definitions/follow-me-home-coverage.vue';
import FrequentFliersCoverage from '@/pages/definitions/frequent-fliers-coverage.vue';
import GroupTravelPolicy from '@/pages/definitions/group-travel-policy.vue';
import GuaranteeIssue from '@/pages/definitions/guarantee-issue.vue';
import HVisaCompliant from '@/pages/definitions/h-visa-compliant.vue';
import HazardousAndExtremeSportsCoverage from '@/pages/definitions/hazardous-and-extreme-sports-coverage.vue';
import HeartCarePlusRider from '@/pages/definitions/heart-care-plus-rider.vue';
import HijackingAndAirOrWaterPiracyCoverage from '@/pages/definitions/hijacking-and-air-or-water-piracy-coverage.vue';
import HomeCountry from '@/pages/definitions/home-country.vue';
import HomeCountryCoverage from '@/pages/definitions/home-country-coverage.vue';
import Hospital from '@/pages/definitions/hospital.vue';
import HospitalIndemnity from '@/pages/definitions/hospital-indemnity.vue';
import HospitalRoomBoard from '@/pages/definitions/hospital-room-board.vue';
import IdentificationCard from '@/pages/definitions/identification-card.vue';
import IdentityTheftAssistance from '@/pages/definitions/identity-theft-assistance.vue';
import ImmediateFamilyMember from '@/pages/definitions/immediate-family-member.vue';
import InclementWeather from '@/pages/definitions/inclement-weather.vue';
import InitialDepositDate from '@/pages/definitions/initial-deposit-date.vue';
import Injury from '@/pages/definitions/injury.vue';
import Inpatient from '@/pages/definitions/inpatient.vue';
import Insured from '@/pages/definitions/insured.vue';
import IntensiveCareUnitICU from '@/pages/definitions/intensive-care-unit-icu.vue';
import InternationalContact from '@/pages/definitions/international-contact.vue';
import InternationalNetwork from '@/pages/definitions/international-network.vue';
import J1VisaCompliant from '@/pages/definitions/j1-visa-compliant.vue';
import Limb from '@/pages/definitions/limb.vue';
import LostGolfRoundsEquipmentRental from '@/pages/definitions/lost-golf-rounds-equipment-rental.vue';
import LostSkiDaysAndEquipmentRental from '@/pages/definitions/lost-ski-days-and-equipment-rental.vue';
import LumpSum from '@/pages/definitions/lump-sum.vue';
import Maternity from '@/pages/definitions/maternity.vue';
import MaxDurationWithoutRenewal from '@/pages/definitions/max-duration-without-renewal.vue';
import MaxLimit from '@/pages/definitions/max-limit.vue';
import MaxTripLength from '@/pages/definitions/max-trip-length.vue';
import MaximumTripCost from '@/pages/definitions/maximum-trip-cost.vue';
import MedicalEmergency from '@/pages/definitions/medical-emergency.vue';
import MedicalEvacuationForDef from '@/pages/definitions/medical-evacuation-for-def.vue';
import MedicalExpenseUpgrade from '@/pages/definitions/medical-expense-upgrade.vue';
import MedicalMonitoring from '@/pages/definitions/medical-monitoring.vue';
import MentalIllness from '@/pages/definitions/mental-illness.vue';
import MinimumPurchaseForRenewalEligibility from '@/pages/definitions/minimum-purchase-for-renewal-eligibility.vue';
import MinimumTripCost from '@/pages/definitions/minimum-trip-cost.vue';
import MinimumTripDuration from '@/pages/definitions/minimum-trip-duration.vue';
import MissedConnectionBenefit from '@/pages/definitions/missed-connection-benefit.vue';
import Monoplegia from '@/pages/definitions/monoplegia.vue';
import N9Visa from '@/pages/definitions/n9-visa.vue';
import NaturalDisasterBenefit from '@/pages/definitions/natural-disaster-benefit.vue';
import NetworkPPO from '@/pages/definitions/network-ppo.vue';
import NonAdmittedCarrier from '@/pages/definitions/non-admitted-carrier.vue';
import NonSurgicalPhysicianVisits from '@/pages/definitions/non-surgical-physician-visits.vue';
import NonUSCitizen from '@/pages/definitions/non-us-citizen.vue';
import NurseServices from '@/pages/definitions/nurse-services.vue';
import OneTimeDeductible from '@/pages/definitions/one-time-deductible.vue';
import OptionalBedRest from '@/pages/definitions/optional-bed-rest.vue';
import OptionalElectronicEquipment from '@/pages/definitions/optional-electronic-equipment.vue';
import OptionalExpressDeliveryCharge from '@/pages/definitions/optional-express-delivery-charge.vue';
import OptionalMedical from '@/pages/definitions/optional-medical.vue';
import OptionalPolicyEnhancementRider from '@/pages/definitions/optional-policy-enhancement-rider.vue';
import OptionalPreExBenefit from '@/pages/definitions/optional-pre-ex-benefit.vue';
import OptionalTravelInconvenience from '@/pages/definitions/optional-travel-inconvenience.vue';
import Outpatient from '@/pages/definitions/outpatient.vue';
import OverseasProviderDirectory from '@/pages/definitions/overseas-provider-directory.vue';
import Overview from '@/pages/definitions/overview.vue';
import Paraplegia from '@/pages/definitions/paraplegia.vue';
import PaymentPreference from '@/pages/definitions/payment-preference.vue';
import PeriodOfCoverage from '@/pages/definitions/period-of-coverage.vue';
import PermanentOrTemporaryParalysis from '@/pages/definitions/permanent-or-temporary-paralysis.vue';
import PersonalLiabilityRider from '@/pages/definitions/personal-liability-rider.vue';
import PersonalPropertyPac from '@/pages/definitions/personal-property-pac.vue';
import PetCoverage from '@/pages/definitions/pet-coverage.vue';
import PetServiceLocator from '@/pages/definitions/pet-service-locater.vue';
import PhysicalTherapy from '@/pages/definitions/physical-therapy.vue';
import PolicyMaximum from '@/pages/definitions/policy-maximum.vue';
import PolicyNumber from '@/pages/definitions/policy-number.vue';
import PoliticalEvacuation from '@/pages/definitions/political-evacuation.vue';
import PreExWaiver from '@/pages/definitions/pre-ex-waiver.vue';
import PreExistingCondition from '@/pages/definitions/pre-existing-condition.vue';
import PreExistingPeriod from '@/pages/definitions/pre-existing-period.vue';
import PreNotification from '@/pages/definitions/pre-notification.vue';
import PreTripDestinationInformation from '@/pages/definitions/pre-trip-destination-information.vue';
import Premium from '@/pages/definitions/premium.vue';
import PremiumCoveragePlansComprehensiveCoverage from '@/pages/definitions/premium-coverage-plans-comprehensive-coverage.vue';
import PremiumVsEconomy from '@/pages/definitions/premium-vs-economy.vue';
import PrescriptionDrugs from '@/pages/definitions/prescription-drugs.vue';
import PriceVsRiskFilter from '@/pages/definitions/price-vs-risk-filter.vue';
import PrimaryCoverageUpgrade from '@/pages/definitions/primary-coverage-upgrade.vue';
import PrimaryDestination from '@/pages/definitions/primary-destination.vue';
import ProductId from '@/pages/definitions/product-id.vue';
import ProductType from '@/pages/definitions/product-type.vue';
import ProfessionalPak from '@/pages/definitions/professional-pak.vue';
import ProviderDirectoryNetwork from '@/pages/definitions/provider-directorynetwork.vue';
import ProviderNetwork from '@/pages/definitions/provider-network.vue';
import ProviderReferrals from '@/pages/definitions/provider-referrals.vue';
import Quadriplegia from '@/pages/definitions/quadriplegia.vue';
import Quote from '@/pages/definitions/quote.vue';
import Rating from '@/pages/definitions/rating.vue';
import RecordId from '@/pages/definitions/record-id.vue';
import RefundOfPremiums from '@/pages/definitions/refund-of-premiums.vue';
import Renewability from '@/pages/definitions/renewability.vue';
import RentalCarCoverage from '@/pages/definitions/rental-car-coverage.vue';
import RepatriationOfRemains from '@/pages/definitions/repatriation-of-remains.vue';
import ReturnHomeCoverage from '@/pages/definitions/return-home-coverage.vue';
import ReturnOfMinorChildren from '@/pages/definitions/return-of-minor-children.vue';
import ReviewCoverage from '@/pages/definitions/review-coverage.vue';
import ScheduleBenefitPlan from '@/pages/definitions/schedule-benefit-plan.vue';
import ScheduleOfBenefitPlans from '@/pages/definitions/schedule-of-benefit-plans.vue';
import SchengenBorderAgreement from '@/pages/definitions/schengen-border-agreement.vue';
import SeatBeltAndAirbagBenefit from '@/pages/definitions/seat-belt-and-airbag-benefit.vue';
import SeeCoInsuranceAndScheduleBenefitPlansVsComprehensiveCoveragePlans from '@/pages/definitions/see-co-insurance-and-schedule-benefit-plans-vs-comprehensive-coverage-plans.vue';
import Specialty from '@/pages/definitions/specialty.vue';
import StolenPassportsVisas from '@/pages/definitions/stolen-passportsvisas.vue';
import StudentCoverage from '@/pages/definitions/student-coverage.vue';
import SurgicalTreatment from '@/pages/definitions/surgical-treatment.vue';
import TerrorismCoverage from '@/pages/definitions/terrorism-coverage.vue';
import Test from '@/pages/definitions/test.vue';
import TransportationPak from '@/pages/definitions/transportation-pak.vue';
import TravelBenefit from '@/pages/definitions/travel-benefit.vue';
import TravelConcierge from '@/pages/definitions/travel-concierge.vue';
import TravelDelay from '@/pages/definitions/travel-delay.vue';
import TravelInsurance from '@/pages/definitions/travel-insurance.vue';
import TripCancellation from '@/pages/definitions/trip-cancellation.vue';
import TripCancellationCoverage from '@/pages/definitions/trip-cancellation-coverage.vue';
import TripCancellationForAnyReason from '@/pages/definitions/trip-cancellation-for-any-reason.vue';
import TripCostForDef from '@/pages/definitions/trip-cost-for-def.vue';
import TripInterruption from '@/pages/definitions/trip-interruption.vue';
import TuitionReimbursement from '@/pages/definitions/tuition-reimbursement.vue';
import Underwriter from '@/pages/definitions/underwriter.vue';
import UrgentCare from '@/pages/definitions/urgent-care.vue';
import USCitizen from '@/pages/definitions/us-citizen.vue';
import USContact from '@/pages/definitions/us-contact.vue';
import USNetwork from '@/pages/definitions/us-network.vue';
import VisionCoverage from '@/pages/definitions/vision-coverage.vue';
import VisitorsInsurance from '@/pages/definitions/visitors-insurance.vue';
import WarCoverage from '@/pages/definitions/war-coverage.vue';
import YearEstablished from '@/pages/definitions/year-established.vue';

const routes = [
    {
        path: '/definition/1',
        name: 'one',
        component: one,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/24-hour-add',
        name: 'TwentyFourHourAdd',
        component: TwentyFourHourAdd,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/24-hour-assistance',
        name: 'TwentyFourHourAssistance',
        component: TwentyFourHourAssistance,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/24-hour-worldwide-assist',
        name: 'TwentyFourHourWorldwideAssist',
        component: TwentyFourHourWorldwideAssist,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/247-worldwide-travel-and-medical-assistance',
        name: 'TwentyFourSevenWorldwideTravelAndMedicalAssistance',
        component: TwentyFourSevenWorldwideTravelAndMedicalAssistance,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/a',
        name: 'A',
        component: A,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/aadeftest',
        name: 'Aadeftest',
        component: Aadeftest,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/accident',
        name: 'Accident',
        component: Accident,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/accidental-death-and-dismemberment-add',
        name: 'AccidentalDeathAndDismembermentAdd',
        component: AccidentalDeathAndDismembermentAdd,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/active-family-pak',
        name: 'ActiveFamilyPak',
        component: ActiveFamilyPak,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/acute-onset-emergency-of-pre-existing-conditions',
        name: 'AcuteOnsetEmergencyOfPreExistingConditions',
        component: AcuteOnsetEmergencyOfPreExistingConditions,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/adaptive-home-and-vehicle-benefit',
        name: 'AdaptiveHomeAndVehicleBenefit',
        component: AdaptiveHomeAndVehicleBenefit,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/add-commcarr-air-upgrade',
        name: 'AddCommcarrAirUpgrade',
        component: AddCommcarrAirUpgrade,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/administrator',
        name: 'Administrator',
        component: Administrator,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/admitted',
        name: 'Admitted',
        component: Admitted,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/adventure-sports-rider',
        name: 'AdventureSportsRider',
        component: AdventureSportsRider,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/adventurer-plus-pak',
        name: 'AdventurerPlusPak',
        component: AdventurerPlusPak,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/age',
        name: 'Age',
        component: Age,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/age-limit',
        name: 'AgeLimit',
        component: AgeLimit,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/agent',
        name: 'Agent',
        component: Agent,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/alcohol-or-drug-abuse',
        name: 'AlcoholOrDrugAbuse',
        component: AlcoholOrDrugAbuse,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/am-best-rating',
        name: 'AMBestRating',
        component: AMBestRating,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/amateur-sports',
        name: 'AmateurSports',
        component: AmateurSports,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/ambulance',
        name: 'Ambulance',
        component: Ambulance,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/amendment',
        name: 'Amendment',
        component: Amendment,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/application-fee',
        name: 'ApplicationFee',
        component: ApplicationFee,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/athletic-sport-coverage',
        name: 'AthleticSportCoverage',
        component: AthleticSportCoverage,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/atm-safe',
        name: 'ATMSafe',
        component: ATMSafe,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/automobile-coverage',
        name: 'AutomobileCoverage',
        component: AutomobileCoverage,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/available-for-non-us-citizen',
        name: 'AvailableForNonUSCitizen',
        component: AvailableForNonUSCitizen,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/baggage-delay-minimum-time',
        name: 'BaggageDelayMinimumTime',
        component: BaggageDelayMinimumTime,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/baggage-delay-or-loss',
        name: 'BaggageDelayOrLoss',
        component: BaggageDelayOrLoss,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/baggage-delayloss',
        name: 'BaggageDelayLoss',
        component: BaggageDelayLoss,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/bbb-rating',
        name: 'BBBRating',
        component: BBBRating,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/beneficiary',
        name: 'Beneficiary',
        component: Beneficiary,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/benefit-summary',
        name: 'BenefitSummary',
        component: BenefitSummary,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/brochure',
        name: 'Brochure',
        component: Brochure,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/cancel-for-any-reason',
        name: 'CancelForAnyReason',
        component: CancelForAnyReason,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/cancel-for-any-reason-departure-date',
        name: 'CancelForAnyReasonDepartureDate',
        component: CancelForAnyReasonDepartureDate,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/cancel-for-any-reason-eligibility',
        name: 'CancelForAnyReasonEligibility',
        component: CancelForAnyReasonEligibility,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/cancel-for-any-reason-initial-deposit-limits',
        name: 'CancelForAnyReasonInitialDepositLimits',
        component: CancelForAnyReasonInitialDepositLimits,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/cancel-for-any-reason-price-limit',
        name: 'CancelForAnyReasonPriceLimit',
        component: CancelForAnyReasonPriceLimit,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/cancel-for-work-reason',
        name: 'CancelForWorkReason',
        component: CancelForWorkReason,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/cancellation-details',
        name: 'CancellationDetails',
        component: CancellationDetails,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/carrier',
        name: 'Carrier',
        component: Carrier,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/carrier-filter',
        name: 'CarrierFilter',
        component: CarrierFilter,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/chart-number',
        name: 'ChartNumber',
        component: ChartNumber,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/citizen-return-rider',
        name: 'CitizenReturnRider',
        component: CitizenReturnRider,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/claim-submission',
        name: 'ClaimSubmission',
        component: ClaimSubmission,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/claims-review',
        name: 'ClaimsReview',
        component: ClaimsReview,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/co-insurance',
        name: 'CoInsurance',
        component: CoInsurance,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/co-pay-co-insurance',
        name: 'CoPayCoInsurance',
        component: CoPayCoInsurance,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/coma-benefit',
        name: 'ComaBenefit',
        component: ComaBenefit,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/common-carrier-add',
        name: 'CommonCarrierAdd',
        component: CommonCarrierAdd,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/complications-of-pregnancy',
        name: 'ComplicationsOfPregnancy',
        component: ComplicationsOfPregnancy,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/comprehensive-coverage-plans',
        name: 'ComprehensiveCoveragePlans',
        component: ComprehensiveCoveragePlans,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/continuing-coverage',
        name: 'ContinuingCoverage',
        component: ContinuingCoverage,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/coverage',
        name: 'Coverage',
        component: Coverage,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/covered-expenses',
        name: 'CoveredExpenses',
        component: CoveredExpenses,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/covid-19',
        name: 'Covid19',
        component: Covid19,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/cruise-coverage',
        name: 'CruiseCoverage',
        component: CruiseCoverage,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/deductible',
        name: 'Deductible',
        component: Deductible,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/deductible-choices',
        name: 'DeductibleChoices',
        component: DeductibleChoices,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/definition',
        name: 'Definition',
        component: Definition,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/dental-coverage-painaccident',
        name: 'DentalCoveragePainAccident',
        component: DentalCoveragePainAccident,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/dependent',
        name: 'Dependent',
        component: Dependent,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/direct-billing',
        name: 'DirectBilling',
        component: DirectBilling,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/doctor',
        name: 'Doctor',
        component: Doctor,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/ease-of-claims',
        name: 'EaseOfClaims',
        component: EaseOfClaims,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/eco-friendly-plans',
        name: 'EcoFriendlyPlans',
        component: EcoFriendlyPlans,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/economy-plans',
        name: 'EconomyPlans',
        component: EconomyPlans,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/effective-date',
        name: 'EffectiveDate',
        component: EffectiveDate,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/eligibility',
        name: 'Eligibility',
        component: Eligibility,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/emergency-dental-benefit',
        name: 'EmergencyDentalBenefit',
        component: EmergencyDentalBenefit,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/emergency-evac-repat-upgrade',
        name: 'EmergencyEvacRepatUpgrade',
        component: EmergencyEvacRepatUpgrade,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/emergency-medical-coverage-range',
        name: 'EmergencyMedicalCoverageRange',
        component: EmergencyMedicalCoverageRange,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/emergency-medical-evacuation',
        name: 'EmergencyMedicalEvacuation',
        component: EmergencyMedicalEvacuation,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/emergency-medical-reunion',
        name: 'EmergencyMedicalReunion',
        component: EmergencyMedicalReunion,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/emergency-medical-treatment',
        name: 'EmergencyMedicalTreatment',
        component: EmergencyMedicalTreatment,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/emergency-room',
        name: 'EmergencyRoom',
        component: EmergencyRoom,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/enhanced-add',
        name: 'EnchancedAdd',
        component: EnchancedAdd,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/enhanced-baggage',
        name: 'EnhancedBaggage',
        component: EnhancedBaggage,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/enhanced-crisis-response',
        name: 'EnhancedCrisisResponse',
        component: EnhancedCrisisResponse,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/enhanced-emergency-medical-evacuation',
        name: 'EnhancedEmergencyMedicalEvacuation',
        component: EnhancedEmergencyMedicalEvacuation,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/enrollment',
        name: 'Enrollment',
        component: Enrollment,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/evacuation-plus-rider',
        name: 'EvacuationPlusRider',
        component: EvacuationPlusRider,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/event-ticket-registration-fee-protection',
        name: 'EventTicketRegistrationFeeProtection',
        component: EventTicketRegistrationFeeProtection,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/evidence-of-insurability-eoi',
        name: 'EvidenceOfInsurabilityEOI',
        component: EvidenceOfInsurabilityEOI,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/excluded-activities',
        name: 'ExcludedActivities',
        component: ExcludedActivities,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/excluded-destination',
        name: 'ExcludedDestination',
        component: ExcludedDestination,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/excluded-reasons-for-trip-cancellation',
        name: 'ExcludedReasonsForTripCancellation',
        component: ExcludedReasonsForTripCancellation,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/excluded-states',
        name: 'ExcludedStates',
        component: ExcludedStates,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/exclusions',
        name: 'Exclusions',
        component: Exclusions,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/expiration-date',
        name: 'ExpirationDate',
        component: ExpirationDate,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/exposure-and-disappearance',
        name: 'ExposureAndDisappearance',
        component: ExposureAndDisappearance,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/f-visa-compliant',
        name: 'FVisaCompliant',
        component: FVisaCompliant,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/feature-filters',
        name: 'FeatureFilters',
        component: FeatureFilters,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/felonious-assault-benefit',
        name: 'FeloniousAssaultBenefit',
        component: FeloniousAssaultBenefit,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/financial-default',
        name: 'FinancialDefault',
        component: FinancialDefault,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/flight-accident-coverage',
        name: 'FlightAccidentCoverage',
        component: FlightAccidentCoverage,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/follow-me-home-coverage',
        name: 'FollowMeHomeCoverage',
        component: FollowMeHomeCoverage,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/frequent-fliers-coverage',
        name: 'FrequentFliersCoverage',
        component: FrequentFliersCoverage,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/group-travel-policy',
        name: 'GroupTravelPolicy',
        component: GroupTravelPolicy,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/guarantee-issue',
        name: 'GuaranteeIssue',
        component: GuaranteeIssue,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/h-visa-compliant',
        name: 'HVisaCompliant',
        component: HVisaCompliant,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/hazardous-and-extreme-sports-coverage',
        name: 'HazardousAndExtremeSportsCoverage',
        component: HazardousAndExtremeSportsCoverage,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/heart-care-plus-rider',
        name: 'HeartCarePlusRider',
        component: HeartCarePlusRider,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/hijacking-and-air-or-water-piracy-coverage',
        name: 'HijackingAndAirOrWaterPiracyCoverage',
        component: HijackingAndAirOrWaterPiracyCoverage,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/home-country',
        name: 'HomeCountry',
        component: HomeCountry,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/home-country-coverage',
        name: 'HomeCountryCoverage',
        component: HomeCountryCoverage,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/hospital',
        name: 'Hospital',
        component: Hospital,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/hospital-indemnity',
        name: 'HospitalIndemnity',
        component: HospitalIndemnity,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/hospital-room-board',
        name: 'HospitalRoomBoard',
        component: HospitalRoomBoard,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/identification-card',
        name: 'IdentificationCard',
        component: IdentificationCard,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/identity-theft-assistance',
        name: 'IdentityTheftAssistance',
        component: IdentityTheftAssistance,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/immediate-family-member',
        name: 'ImmediateFamilyMember',
        component: ImmediateFamilyMember,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/inclement-weather',
        name: 'InclementWeather',
        component: InclementWeather,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/initial-deposit-date',
        name: 'InitialDepositDate',
        component: InitialDepositDate,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/injury',
        name: 'Injury',
        component: Injury,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/inpatient',
        name: 'Inpatient',
        component: Inpatient,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/insured',
        name: 'Insured',
        component: Insured,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/intensive-care-unit-icu',
        name: 'IntensiveCareUnitICU',
        component: IntensiveCareUnitICU,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/international-contact',
        name: 'InternationalContact',
        component: InternationalContact,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/international-network',
        name: 'InternationalNetwork',
        component: InternationalNetwork,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/j1-visa-compliant',
        name: 'J1VisaCompliant',
        component: J1VisaCompliant,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/limb',
        name: 'Limb',
        component: Limb,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/lost-golf-rounds-equipment-rental',
        name: 'LostGolfRoundsEquipmentRental',
        component: LostGolfRoundsEquipmentRental,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/lost-ski-days-and-equipment-rental',
        name: 'LostSkiDaysAndEquipmentRental',
        component: LostSkiDaysAndEquipmentRental,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/lump-sum',
        name: 'LumpSum',
        component: LumpSum,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/maternity',
        name: 'Maternity',
        component: Maternity,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/max-duration-without-renewal',
        name: 'MaxDurationWithoutRenewal',
        component: MaxDurationWithoutRenewal,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/max-limit',
        name: 'MaxLimit',
        component: MaxLimit,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/max-trip-length',
        name: 'MaxTripLength',
        component: MaxTripLength,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/maximum-trip-cost',
        name: 'MaximumTripCost',
        component: MaximumTripCost,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/medical-emergency',
        name: 'MedicalEmergency',
        component: MedicalEmergency,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/medical-evacuation',
        name: 'MedicalEvacuationForDef',
        component: MedicalEvacuationForDef,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/medical-expense-upgrade',
        name: 'MedicalExpenseUpgrade',
        component: MedicalExpenseUpgrade,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/medical-monitoring',
        name: 'MedicalMonitoring',
        component: MedicalMonitoring,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/mental-illness',
        name: 'MentalIllness',
        component: MentalIllness,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/minimum-purchase-for-renewal-eligibility',
        name: 'MinimumPurchaseForRenewalEligibility',
        component: MinimumPurchaseForRenewalEligibility,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/minimum-trip-cost',
        name: 'MinimumTripCost',
        component: MinimumTripCost,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/minimum-trip-duration',
        name: 'MinimumTripDuration',
        component: MinimumTripDuration,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/missed-connection-benefit',
        name: 'MissedConnectionBenefit',
        component: MissedConnectionBenefit,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/monoplegia',
        name: 'Monoplegia',
        component: Monoplegia,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/n9-visa',
        name: 'N9Visa',
        component: N9Visa,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/natural-disaster-benefit',
        name: 'NaturalDisasterBenefit',
        component: NaturalDisasterBenefit,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/network-ppo',
        name: 'NetworkPPO',
        component: NetworkPPO,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/non-admitted-carrier',
        name: 'NonAdmittedCarrier',
        component: NonAdmittedCarrier,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/non-surgical-physician-visits',
        name: 'NonSurgicalPhysicianVisits',
        component: NonSurgicalPhysicianVisits,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/non-us-citizen',
        name: 'NonUSCitizen',
        component: NonUSCitizen,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/nurse-services',
        name: 'NurseServices',
        component: NurseServices,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/one-time-deductible',
        name: 'OneTimeDeductible',
        component: OneTimeDeductible,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/optional-bed-rest',
        name: 'OptionalBedRest',
        component: OptionalBedRest,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/optional-electronic-equipment',
        name: 'OptionalElectronicEquipment',
        component: OptionalElectronicEquipment,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/optional-express-delivery-charge',
        name: 'OptionalExpressDeliveryCharge',
        component: OptionalExpressDeliveryCharge,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/optional-medical',
        name: 'OptionalMedical',
        component: OptionalMedical,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/optional-policy-enhancement-rider',
        name: 'OptionalPolicyEnhancementRider',
        component: OptionalPolicyEnhancementRider,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/optional-pre-ex-benefit',
        name: 'OptionalPreExBenefit',
        component: OptionalPreExBenefit,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/optional-travel-inconvenience',
        name: 'OptionalTravelInconvenience',
        component: OptionalTravelInconvenience,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/outpatient',
        name: 'Outpatient',
        component: Outpatient,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/overseas-provider-directory',
        name: 'OverseasProviderDirectory',
        component: OverseasProviderDirectory,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/overview',
        name: 'Overview',
        component: Overview,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/paraplegia',
        name: 'Paraplegia',
        component: Paraplegia,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/payment-preference',
        name: 'PaymentPreference',
        component: PaymentPreference,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/period-of-coverage',
        name: 'PeriodOfCoverage',
        component: PeriodOfCoverage,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/permanent-or-temporary-paralysis',
        name: 'PermanentOrTemporaryParalysis',
        component: PermanentOrTemporaryParalysis,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/personal-liability-rider',
        name: 'PersonalLiabilityRider',
        component: PersonalLiabilityRider,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/personal-property-pac',
        name: 'PersonalPropertyPac',
        component: PersonalPropertyPac,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/pet-coverage',
        name: 'PetCoverage',
        component: PetCoverage,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/pet-service-locater',
        name: 'PetServiceLocator',
        component: PetServiceLocator,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/physical-therapy',
        name: 'PhysicalTherapy',
        component: PhysicalTherapy,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/policy-maximum',
        name: 'PolicyMaximum',
        component: PolicyMaximum,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/policy-number',
        name: 'PolicyNumber',
        component: PolicyNumber,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/political-evacuation',
        name: 'PoliticalEvacuation',
        component: PoliticalEvacuation,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/pre-ex-waiver',
        name: 'PreExWaiver',
        component: PreExWaiver,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/pre-existing-condition',
        name: 'PreExistingCondition',
        component: PreExistingCondition,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/pre-existing-period',
        name: 'PreExistingPeriod',
        component: PreExistingPeriod,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/pre-notification',
        name: 'PreNotification',
        component: PreNotification,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/pre-trip-destination-information',
        name: 'PreTripDestinationInformation',
        component: PreTripDestinationInformation,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/premium',
        name: 'Premium',
        component: Premium,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/premium-coverage-plans-comprehensive-coverage',
        name: 'PremiumCoveragePlansComprehensiveCoverage',
        component: PremiumCoveragePlansComprehensiveCoverage,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/premium-vs-economy',
        name: 'PremiumVsEconomy',
        component: PremiumVsEconomy,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/prescription-drugs',
        name: 'PrescriptionDrugs',
        component: PrescriptionDrugs,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/price-vs-risk-filter',
        name: 'PriceVsRiskFilter',
        component: PriceVsRiskFilter,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/primary-coverage-upgrade',
        name: 'PrimaryCoverageUpgrade',
        component: PrimaryCoverageUpgrade,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/primary-destination',
        name: 'PrimaryDestination',
        component: PrimaryDestination,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/product-id',
        name: 'ProductId',
        component: ProductId,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/product-type',
        name: 'ProductType',
        component: ProductType,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/professional-pak',
        name: 'ProfessionalPak',
        component: ProfessionalPak,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/provider-directorynetwork',
        name: 'ProviderDirectoryNetwork',
        component: ProviderDirectoryNetwork,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/provider-network',
        name: 'ProviderNetwork',
        component: ProviderNetwork,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/provider-referrals',
        name: 'ProviderReferrals',
        component: ProviderReferrals,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/quadriplegia',
        name: 'Quadriplegia',
        component: Quadriplegia,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/quote',
        name: 'Quote',
        component: Quote,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/rating',
        name: 'Rating',
        component: Rating,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/record-id',
        name: 'RecordId',
        component: RecordId,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/refund-of-premiums',
        name: 'RefundOfPremiums',
        component: RefundOfPremiums,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/renewability',
        name: 'Renewability',
        component: Renewability,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/rental-car-coverage',
        name: 'RentalCarCoverage',
        component: RentalCarCoverage,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/repatriation-of-remains',
        name: 'RepatriationOfRemains',
        component: RepatriationOfRemains,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/return-home-coverage',
        name: 'ReturnHomeCoverage',
        component: ReturnHomeCoverage,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/return-of-minor-children',
        name: 'ReturnOfMinorChildren',
        component: ReturnOfMinorChildren,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/review-coverage',
        name: 'ReviewCoverage',
        component: ReviewCoverage,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/schedule-benefit-plan',
        name: 'ScheduleBenefitPlan',
        component: ScheduleBenefitPlan,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/schedule-of-benefit-plans',
        name: 'ScheduleOfBenefitPlans',
        component: ScheduleOfBenefitPlans,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/schengen-border-agreement',
        name: 'SchengenBorderAgreement',
        component: SchengenBorderAgreement,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/seat-belt-and-airbag-benefit',
        name: 'SeatBeltAndAirbagBenefit',
        component: SeatBeltAndAirbagBenefit,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/see-co-insurance-and-schedule-benefit-plans-vs-comprehensive-coverage-plans',
        name: 'SeeCoInsuranceAndScheduleBenefitPlansVsComprehensiveCoveragePlans',
        component: SeeCoInsuranceAndScheduleBenefitPlansVsComprehensiveCoveragePlans,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/specialty',
        name: 'Specialty',
        component: Specialty,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/stolen-passportsvisas',
        name: 'StolenPassportsVisas',
        component: StolenPassportsVisas,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/student-coverage',
        name: 'StudentCoverage',
        component: StudentCoverage,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/surgical-treatment',
        name: 'SurgicalTreatment',
        component: SurgicalTreatment,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/terrorism-coverage',
        name: 'TerrorismCoverage',
        component: TerrorismCoverage,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/test',
        name: 'Test',
        component: Test,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/transportation-pak',
        name: 'TransportationPak',
        component: TransportationPak,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/travel-benefit',
        name: 'TravelBenefit',
        component: TravelBenefit,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/travel-concierge',
        name: 'TravelConcierge',
        component: TravelConcierge,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/travel-delay',
        name: 'TravelDelay',
        component: TravelDelay,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/travel-insurance',
        name: 'TravelInsurance',
        component: TravelInsurance,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/trip-cancellation',
        name: 'TripCancellation',
        component: TripCancellation,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/trip-cancellation-coverage',
        name: 'TripCancellationCoverage',
        component: TripCancellationCoverage,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/trip-cancellation-for-any-reason',
        name: 'TripCancellationForAnyReason',
        component: TripCancellationForAnyReason,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/trip-cost',
        name: 'TripCostForDef',
        component: TripCostForDef,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/trip-interruption',
        name: 'TripInterruption',
        component: TripInterruption,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/tuition-reimbursement',
        name: 'TuitionReimbursement',
        component: TuitionReimbursement,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/underwriter',
        name: 'Underwriter',
        component: Underwriter,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/urgent-care',
        name: 'UrgentCare',
        component: UrgentCare,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/us-citizen',
        name: 'USCitizen',
        component: USCitizen,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/us-contact',
        name: 'USContact',
        component: USContact,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/us-network',
        name: 'USNetwork',
        component: USNetwork,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/vision-coverage',
        name: 'VisionCoverage',
        component: VisionCoverage,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/visitors-insurance',
        name: 'VisitorsInsurance',
        component: VisitorsInsurance,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/war-coverage',
        name: 'WarCoverage',
        component: WarCoverage,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definition/year-established',
        name: 'YearEstablished',
        component: YearEstablished,
        meta: {
            parentPath: "definition",
        },
        props: {footer: true},
    },
    {
        path: '/definitions/glossarydetail/defid_1/accident',
        redirect: '/definition/accident',
    },
    {
        path: '/definitions/glossarydetail/defid_6/accidental-death-and-dismemberment-ad&d',
        redirect: '/definition/accidental-death-and-dismemberment-add',
    },
    {
        path: '/definitions/glossarydetail/defid_226/active-family-pak',
        redirect: '/definition/active-family-pak',
    },
    {
        path: '/definitions/glossarydetail/defid_4/acute-onset-emergency-of-pre-existing-conditions',
        redirect: '/definition/acute-onset-emergency-of-pre-existing-conditions',
    },
    {
        path: '/definitions/glossarydetail/defid_217/ad/d-commcarr-air-upgrade',
        redirect: '/definition/add-commcarr-air-upgrade',
    },
    {
        path: '/definitions/glossarydetail/defid_7/adaptive-home-and-vehicle-benefit',
        redirect: '/definition/adaptive-home-and-vehicle-benefit',
    },
    {
        path: '/definitions/glossarydetail/defid_5/administrator',
        redirect: '/definition/administrator',
    },
    {
        path: '/definitions/glossarydetail/defid_136/admitted',
        redirect: '/definition/admitted',
    },
    {
        path: '/definitions/glossarydetail/defid_184/adventure-sports-rider',
        redirect: '/definition/adventure-sports-rider',
    },
    {
        path: '/definitions/glossarydetail/defid_225/adventurer-plus-pak',
        redirect: '/definition/adventurer-plus-pak',
    },
    {
        path: '/definitions/glossarydetail/defid_2/age',
        redirect: '/definition/age',
    },
    {
        path: '/definitions/glossarydetail/defid_9/age-limit',
        redirect: '/definition/age-limit',
    },
    {
        path: '/definitions/glossarydetail/defid_8/agent',
        redirect: '/definition/agent',
    },
    {
        path: '/definitions/glossarydetail/defid_222/alcohol-or-drug-abuse',
        redirect: '/definition/alcohol-or-drug-abuse',
    },
    {
        path: '/definitions/glossarydetail/defid_111/am-best-rating',
        redirect: '/definition/am-best-rating',
    },
    {
        path: '/definitions/glossarydetail/defid_223/amateur-sports',
        redirect: '/definition/amateur-sports',
    },
    {
        path: '/definitions/glossarydetail/defid_212/ambulance',
        redirect: '/definition/ambulance',
    },
    {
        path: '/definitions/glossarydetail/defid_10/amendment',
        redirect: '/definition/amendment',
    },
    {
        path: '/definitions/glossarydetail/defid_167/application-fee',
        redirect: '/definition/application-fee',
    },
    {
        path: '/definitions/glossarydetail/defid_185/athletic-sport-coverage',
        redirect: '/definition/athletic-sport-coverage',
    },
    {
        path: '/definitions/glossarydetail/defid_140/atm-safe',
        redirect: '/definition/atm-safe',
    },
    {
        path: '/definitions/glossarydetail/defid_194/automobile-coverage',
        redirect: '/definition/automobile-coverage',
    },
    {
        path: '/definitions/glossarydetail/defid_166/baggage-delay-minimum-time',
        redirect: '/definition/baggage-delay-minimum-time',
    },
    {
        path: '/definitions/glossarydetail/defid_11/baggage-delay-or-loss',
        redirect: '/definition/baggage-delay-or-loss',
    },
    {
        path: '/definitions/glossarydetail/defid_110/bbb-rating',
        redirect: '/definition/bbb-rating',
    },
    {
        path: '/definitions/glossarydetail/defid_13/beneficiary',
        redirect: '/definition/beneficiary',
    },
    {
        path: '/definitions/glossarydetail/defid_12/benefit-summary',
        redirect: '/definition/benefit-summary',
    },
    {
        path: '/definitions/glossarydetail/defid_124/brochure',
        redirect: '/definition/brochure',
    },
    {
        path: '/definitions/glossarydetail/defid_220/cancel-for-any-reason',
        redirect: '/definition/cancel-for-any-reason',
    },
    {
        path: '/definitions/glossarydetail/defid_162/cancel-for-any-reason-departure-date',
        redirect: '/definition/cancel-for-any-reason-departure-date',
    },
    {
        path: '/definitions/glossarydetail/defid_165/cancel-for-any-reason-eligibility',
        redirect: '/definition/cancel-for-any-reason-eligibility',
    },
    {
        path: '/definitions/glossarydetail/defid_163/cancel-for-any-reason-initial-deposit-limits',
        redirect: '/definition/cancel-for-any-reason-initial-deposit-limits',
    },
    {
        path: '/definitions/glossarydetail/defid_164/cancel-for-any-reason-price-limit',
        redirect: '/definition/cancel-for-any-reason-price-limit',
    },
    {
        path: '/definitions/glossarydetail/defid_231/cancel-for-work-reason',
        redirect: '/definition/cancel-for-work-reason',
    },
    {
        path: '/definitions/glossarydetail/defid_123/cancellation-details',
        redirect: '/definition/cancellation-details',
    },
    {
        path: '/definitions/glossarydetail/defid_14/carrier',
        redirect: '/definition/carrier',
    },
    {
        path: '/definitions/glossarydetail/defid_179/carrier-filter',
        redirect: '/definition/carrier-filter',
    },
    {
        path: '/definitions/glossarydetail/defid_186/chart-number',
        redirect: '/definition/chart-number',
    },
    {
        path: '/definitions/glossarydetail/defid_183/citizen-return-rider',
        redirect: '/definition/citizen-return-rider',
    },
    {
        path: '/definitions/glossarydetail/defid_18/claim-submission',
        redirect: '/definition/claim-submission',
    },
    {
        path: '/definitions/glossarydetail/defid_207/claims-review',
        redirect: '/definition/claims-review',
    },
    {
        path: '/definitions/glossarydetail/defid_113/co-pay-co-insurance',
        redirect: '/definition/co-pay-co-insurance',
    },
    {
        path: '/definitions/glossarydetail/defid_22/coma-benefit',
        redirect: '/definition/coma-benefit',
    },
    {
        path: '/definitions/glossarydetail/defid_141/common-carrier-ad&d',
        redirect: '/definition/common-carrier-add',
    },
    {
        path: '/definitions/glossarydetail/defid_19/complications-of-pregnancy',
        redirect: '/definition/complications-of-pregnancy',
    },
    {
        path: '/definitions/glossarydetail/defid_102/comprehensive-coverage-plans',
        redirect: '/definition/comprehensive-coverage-plans',
    },
    {
        path: '/definitions/glossarydetail/defid_17/continuing-coverage',
        redirect: '/definition/continuing-coverage',
    },
    {
        path: '/definitions/glossarydetail/defid_157/coverage',
        redirect: '/definition/coverage',
    },
    {
        path: '/definitions/glossarydetail/defid_15/covered-expenses',
        redirect: '/definition/covered-expenses',
    },
    {
        path: '/definitions/glossarydetail/defid_237/covid-19',
        redirect: '/definition/covid-19',
    },
    {
        path: '/definitions/glossarydetail/defid_104/cruise-coverage',
        redirect: '/definition/cruise-coverage',
    },
    {
        path: '/definitions/glossarydetail/defid_24/deductible',
        redirect: '/definition/deductible',
    },
    {
        path: '/definitions/glossarydetail/defid_195/definition',
        redirect: '/definition/definition',
    },
    {
        path: '/definitions/glossarydetail/defid_133/dental-coverage-pain/accident',
        redirect: '/definition/dental-coverage-painaccident',
    },
    {
        path: '/definitions/glossarydetail/defid_26/dependent',
        redirect: '/definition/dependent',
    },
    {
        path: '/definitions/glossarydetail/defid_112/direct-billing',
        redirect: '/definition/direct-billing',
    },
    {
        path: '/definitions/glossarydetail/defid_27/doctor',
        redirect: '/definition/doctor',
    },
    {
        path: '/definitions/glossarydetail/defid_208/ease-of-claims',
        redirect: '/definition/ease-of-claims',
    },
    {
        path: '/definitions/glossarydetail/defid_144/eco-friendly-plans',
        redirect: '/definition/eco-friendly-plans',
    },
    {
        path: '/definitions/glossarydetail/defid_39/economy-plans',
        redirect: '/definition/economy-plans',
    },
    {
        path: '/definitions/glossarydetail/defid_29/effective-date',
        redirect: '/definition/effective-date',
    },
    {
        path: '/definitions/glossarydetail/defid_32/eligibility',
        redirect: '/definition/eligibility',
    },
    {
        path: '/definitions/glossarydetail/defid_23/emergency-dental-benefit',
        redirect: '/definition/emergency-dental-benefit',
    },
    {
        path: '/definitions/glossarydetail/defid_215/emergency-evac-repat-upgrade',
        redirect: '/definition/emergency-evac-repat-upgrade',
    },
    {
        path: '/definitions/glossarydetail/defid_116/emergency-medical-coverage-range',
        redirect: '/definition/emergency-medical-coverage-range',
    },
    {
        path: '/definitions/glossarydetail/defid_31/emergency-medical-evacuation',
        redirect: '/definition/emergency-medical-evacuation',
    },
    {
        path: '/definitions/glossarydetail/defid_115/emergency-medical-reunion',
        redirect: '/definition/emergency-medical-reunion',
    },
    {
        path: '/definitions/glossarydetail/defid_58/emergency-medical-treatment',
        redirect: '/definition/emergency-medical-treatment',
    },
    {
        path: '/definitions/glossarydetail/defid_210/emergency-room',
        redirect: '/definition/emergency-room',
    },
    {
        path: '/definitions/glossarydetail/defid_190/enhanced-ad&d',
        redirect: '/definition/enhanced-add',
    },
    {
        path: '/definitions/glossarydetail/defid_191/enhanced-baggage',
        redirect: '/definition/enhanced-baggage',
    },
    {
        path: '/definitions/glossarydetail/defid_200/enhanced-crisis-response',
        redirect: '/definition/enhanced-crisis-response',
    },
    {
        path: '/definitions/glossarydetail/defid_196/enhanced-emergency-medical-evacuation',
        redirect: '/definition/enhanced-emergency-medical-evacuation',
    },
    {
        path: '/definitions/glossarydetail/defid_28/enrollment',
        redirect: '/definition/enrollment',
    },
    {
        path: '/definitions/glossarydetail/defid_182/evacuation-plus-rider',
        redirect: '/definition/evacuation-plus-rider',
    },
    {
        path: '/definitions/glossarydetail/defid_241/event-ticket-registration-fee-protection',
        redirect: '/definition/event-ticket-registration-fee-protection',
    },
    {
        path: '/definitions/glossarydetail/defid_33/evidence-of-insurability-eoi',
        redirect: '/definition/evidence-of-insurability-eoi',
    },
    {
        path: '/definitions/glossarydetail/defid_118/excluded-activities',
        redirect: '/definition/excluded-activities',
    },
    {
        path: '/definitions/glossarydetail/defid_117/excluded-destination',
        redirect: '/definition/excluded-destination',
    },
    {
        path: '/definitions/glossarydetail/defid_161/excluded-reasons-for-trip-cancellation',
        redirect: '/definition/excluded-reasons-for-trip-cancellation',
    },
    {
        path: '/definitions/glossarydetail/defid_36/excluded-states',
        redirect: '/definition/excluded-states',
    },
    {
        path: '/definitions/glossarydetail/defid_35/exclusions',
        redirect: '/definition/exclusions',
    },
    {
        path: '/definitions/glossarydetail/defid_30/expiration-date',
        redirect: '/definition/expiration-date',
    },
    {
        path: '/definitions/glossarydetail/defid_34/exposure-and-disappearance',
        redirect: '/definition/exposure-and-disappearance',
    },
    {
        path: '/definitions/glossarydetail/defid_198/f-visa-compliant',
        redirect: '/definition/f-visa-compliant',
    },
    {
        path: '/definitions/glossarydetail/defid_180/feature-filters',
        redirect: '/definition/feature-filters',
    },
    {
        path: '/definitions/glossarydetail/defid_38/felonious-assault-benefit',
        redirect: '/definition/felonious-assault-benefit',
    },
    {
        path: '/definitions/glossarydetail/defid_40/financial-default',
        redirect: '/definition/financial-default',
    },
    {
        path: '/definitions/glossarydetail/defid_41/flight-accident-coverage',
        redirect: '/definition/flight-accident-coverage',
    },
    {
        path: '/definitions/glossarydetail/defid_37/follow-me-home-coverage',
        redirect: '/definition/follow-me-home-coverage',
    },
    {
        path: '/definitions/glossarydetail/defid_42/frequent-fliers-coverage',
        redirect: '/definition/frequent-fliers-coverage',
    },
    {
        path: '/definitions/glossarydetail/defid_44/group-travel-policy',
        redirect: '/definition/group-travel-policy',
    },
    {
        path: '/definitions/glossarydetail/defid_43/guarantee-issue',
        redirect: '/definition/guarantee-issue',
    },
    {
        path: '/definitions/glossarydetail/defid_147/h-visa-compliant',
        redirect: '/definition/h-visa-compliant',
    },
    {
        path: '/definitions/glossarydetail/defid_45/hazardous-and-extreme-sports-coverage',
        redirect: '/definition/hazardous-and-extreme-sports-coverage',
    },
    {
        path: '/definitions/glossarydetail/defid_193/heart-care-plus-rider',
        redirect: '/definition/heart-care-plus-rider',
    },
    {
        path: '/definitions/glossarydetail/defid_49/hijacking-and-air-or-water-piracy-coverage',
        redirect: '/definition/hijacking-and-air-or-water-piracy-coverage',
    },
    {
        path: '/definitions/glossarydetail/defid_46/home-country',
        redirect: '/definition/home-country',
    },
    {
        path: '/definitions/glossarydetail/defid_48/home-country-coverage',
        redirect: '/definition/home-country-coverage',
    },
    {
        path: '/definitions/glossarydetail/defid_50/hospital',
        redirect: '/definition/hospital',
    },
    {
        path: '/definitions/glossarydetail/defid_47/hospital-indemnity',
        redirect: '/definition/hospital-indemnity',
    },
    {
        path: '/definitions/glossarydetail/defid_99/hospital-room-&-board',
        redirect: '/definition/hospital-room-&-board',
    },
    {
        path: '/definitions/glossarydetail/defid_51/identification-card',
        redirect: '/definition/identification-card',
    },
    {
        path: '/definitions/glossarydetail/defid_100/identity-theft-assistance',
        redirect: '/definition/identity-theft-assistance',
    },
    {
        path: '/definitions/glossarydetail/defid_52/immediate-family-member',
        redirect: '/definition/immediate-family-member',
    },
    {
        path: '/definitions/glossarydetail/defid_53/inclement-weather',
        redirect: '/definition/inclement-weather',
    },
    {
        path: '/definitions/glossarydetail/defid_103/initial-deposit-date',
        redirect: '/definition/initial-deposit-date',
    },
    {
        path: '/definitions/glossarydetail/defid_54/injury',
        redirect: '/definition/injury',
    },
    {
        path: '/definitions/glossarydetail/defid_213/inpatient',
        redirect: '/definition/inpatient',
    },
    {
        path: '/definitions/glossarydetail/defid_56/insured',
        redirect: '/definition/insured',
    },
    {
        path: '/definitions/glossarydetail/defid_153/intensive-care-unit-icu',
        redirect: '/definition/intensive-care-unit-icu',
    },
    {
        path: '/definitions/glossarydetail/defid_125/international-contact',
        redirect: '/definition/international-contact',
    },
    {
        path: '/definitions/glossarydetail/defid_204/international-network',
        redirect: '/definition/international-network',
    },
    {
        path: '/definitions/glossarydetail/defid_146/j1-visa-compliant',
        redirect: '/definition/j1-visa-compliant',
    },
    {
        path: '/definitions/glossarydetail/defid_62/limb',
        redirect: '/definition/limb',
    },
    {
        path: '/definitions/glossarydetail/defid_232/lost-golf-rounds-&-equipment-rental',
        redirect: '/definition/lost-golf-rounds-&-equipment-rental',
    },
    {
        path: '/definitions/glossarydetail/defid_233/lost-ski-days-and-equipment-rental',
        redirect: '/definition/lost-ski-days-and-equipment-rental',
    },
    {
        path: '/definitions/glossarydetail/defid_57/lump-sum',
        redirect: '/definition/lump-sum',
    },
    {
        path: '/definitions/glossarydetail/defid_122/maternity',
        redirect: '/definition/maternity',
    },
    {
        path: '/definitions/glossarydetail/defid_129/max-duration-without-renewal',
        redirect: '/definition/max-duration-without-renewal',
    },
    {
        path: '/definitions/glossarydetail/defid_181/max-limit',
        redirect: '/definition/max-limit',
    },
    {
        path: '/definitions/glossarydetail/defid_227/max-trip-length',
        redirect: '/definition/max-trip-length',
    },
    {
        path: '/definitions/glossarydetail/defid_108/maximum-trip-cost',
        redirect: '/definition/maximum-trip-cost',
    },
    {
        path: '/definitions/glossarydetail/defid_60/medical-evacuation',
        redirect: '/definition/medical-evacuation',
    },
    {
        path: '/definitions/glossarydetail/defid_216/medical-expense-upgrade',
        redirect: '/definition/medical-expense-upgrade',
    },
    {
        path: '/definitions/glossarydetail/defid_61/medical-monitoring',
        redirect: '/definition/medical-monitoring',
    },
    {
        path: '/definitions/glossarydetail/defid_199/mental-illness',
        redirect: '/definition/mental-illness',
    },
    {
        path: '/definitions/glossarydetail/defid_128/minimum-purchase-for-renewal-eligibility',
        redirect: '/definition/minimum-purchase-for-renewal-eligibility',
    },
    {
        path: '/definitions/glossarydetail/defid_107/minimum-trip-duration',
        redirect: '/definition/minimum-trip-duration',
    },
    {
        path: '/definitions/glossarydetail/defid_63/missed-connection-benefit',
        redirect: '/definition/missed-connection-benefit',
    },
    {
        path: '/definitions/glossarydetail/defid_93/monoplegia',
        redirect: '/definition/monoplegia',
    },
    {
        path: '/definitions/glossarydetail/defid_148/n9-visa',
        redirect: '/definition/n9-visa',
    },
    {
        path: '/definitions/glossarydetail/defid_64/natural-disaster-benefit',
        redirect: '/definition/natural-disaster-benefit',
    },
    {
        path: '/definitions/glossarydetail/defid_65/network-ppo',
        redirect: '/definition/network-ppo',
    },
    {
        path: '/definitions/glossarydetail/defid_137/non-admitted-carrier',
        redirect: '/definition/non-admitted-carrier',
    },
    {
        path: '/definitions/glossarydetail/defid_155/non-surgical-physician-visits',
        redirect: '/definition/non-surgical-physician-visits',
    },
    {
        path: '/definitions/glossarydetail/defid_66/non-us-citizen',
        redirect: '/definition/non-us-citizen',
    },
    {
        path: '/definitions/glossarydetail/defid_228/nurse-services',
        redirect: '/definition/nurse-services',
    },
    {
        path: '/definitions/glossarydetail/defid_135/one-time-deductible',
        redirect: '/definition/one-time-deductible',
    },
    {
        path: '/definitions/glossarydetail/defid_242/optional-bed-rest',
        redirect: '/definition/optional-bed-rest',
    },
    {
        path: '/definitions/glossarydetail/defid_244/optional-electronic-equipment',
        redirect: '/definition/optional-electronic-equipment',
    },
    {
        path: '/definitions/glossarydetail/defid_187/optional-express-delivery-charge',
        redirect: '/definition/optional-express-delivery-charge',
    },
    {
        path: '/definitions/glossarydetail/defid_238/optional-medical:',
        redirect: '/definition/optional-medical',
    },
    {
        path: '/definitions/glossarydetail/defid_75/optional-policy-enhancement-rider',
        redirect: '/definition/optional-policy-enhancement-rider',
    },
    {
        path: '/definitions/glossarydetail/defid_192/optional-pre-ex-benefit',
        redirect: '/definition/optional-pre-ex-benefit',
    },
    {
        path: '/definitions/glossarydetail/defid_243/optional-travel-inconvenience',
        redirect: '/definition/optional-travel-inconvenience',
    },
    {
        path: '/definitions/glossarydetail/defid_214/outpatient',
        redirect: '/definition/outpatient',
    },
    {
        path: '/definitions/glossarydetail/defid_170/overseas-provider-directory',
        redirect: '/definition/overseas-provider-directory',
    },
    {
        path: '/definitions/glossarydetail/defid_127/overview',
        redirect: '/definition/overview',
    },
    {
        path: '/definitions/glossarydetail/defid_68/paraplegia',
        redirect: '/definition/paraplegia',
    },
    {
        path: '/definitions/glossarydetail/defid_218/payment-preference',
        redirect: '/definition/payment-preference',
    },
    {
        path: '/definitions/glossarydetail/defid_70/period-of-coverage',
        redirect: '/definition/period-of-coverage',
    },
    {
        path: '/definitions/glossarydetail/defid_67/permanent-or-temporary-paralysis',
        redirect: '/definition/permanent-or-temporary-paralysis',
    },
    {
        path: '/definitions/glossarydetail/defid_121/personal-liability-rider',
        redirect: '/definition/personal-liability-rider',
    },
    {
        path: '/definitions/glossarydetail/defid_236/personal-property-pac',
        redirect: '/definition/personal-property-pac',
    },
    {
        path: '/definitions/glossarydetail/defid_149/pet-coverage',
        redirect: '/definition/pet-coverage',
    },
    {
        path: '/definitions/glossarydetail/defid_169/pet-service-locater',
        redirect: '/definition/pet-service-locater',
    },
    {
        path: '/definitions/glossarydetail/defid_119/physical-therapy',
        redirect: '/definition/physical-therapy',
    },
    {
        path: '/definitions/glossarydetail/defid_209/policy-maximum',
        redirect: '/definition/policy-maximum',
    },
    {
        path: '/definitions/glossarydetail/defid_131/policy-number',
        redirect: '/definition/policy-number',
    },
    {
        path: '/definitions/glossarydetail/defid_73/political-evacuation',
        redirect: '/definition/political-evacuation',
    },
    {
        path: '/definitions/glossarydetail/defid_234/pre-ex-waiver',
        redirect: '/definition/pre-ex-waiver',
    },
    {
        path: '/definitions/glossarydetail/defid_69/pre-existing-condition',
        redirect: '/definition/pre-existing-condition',
    },
    {
        path: '/definitions/glossarydetail/defid_134/pre-existing-period',
        redirect: '/definition/pre-existing-period',
    },
    {
        path: '/definitions/glossarydetail/defid_71/pre-notification',
        redirect: '/definition/pre-notification',
    },
    {
        path: '/definitions/glossarydetail/defid_72/pre-trip-destination-information',
        redirect: '/definition/pre-trip-destination-information',
    },
    {
        path: '/definitions/glossarydetail/defid_3/premium',
        redirect: '/definition/premium',
    },
    {
        path: '/definitions/glossarydetail/defid_20/premium-coverage-plans-comprehensive-coverage',
        redirect: '/definition/premium-coverage-plans-comprehensive-coverage',
    },
    {
        path: '/definitions/glossarydetail/defid_156/premium-vs.-economy',
        redirect: '/definition/premium-vs.-economy',
    },
    {
        path: '/definitions/glossarydetail/defid_150/prescription-drugs',
        redirect: '/definition/prescription-drugs',
    },
    {
        path: '/definitions/glossarydetail/defid_173/price-vs.-risk-filter',
        redirect: '/definition/price-vs.-risk-filter',
    },
    {
        path: '/definitions/glossarydetail/defid_240/primary-coverage-upgrade',
        redirect: '/definition/primary-coverage-upgrade',
    },
    {
        path: '/definitions/glossarydetail/defid_176/primary-destination',
        redirect: '/definition/primary-destination',
    },
    {
        path: '/definitions/glossarydetail/defid_139/product-id',
        redirect: '/definition/product-id',
    },
    {
        path: '/definitions/glossarydetail/defid_174/product-type',
        redirect: '/definition/product-type',
    },
    {
        path: '/definitions/glossarydetail/defid_224/professional-pak',
        redirect: '/definition/professional-pak',
    },
    {
        path: '/definitions/glossarydetail/defid_130/provider-directory/network',
        redirect: '/definition/provider-directory-network',
    },
    {
        path: '/definitions/glossarydetail/defid_74/provider-referrals',
        redirect: '/definition/provider-referrals',
    },
    {
        path: '/definitions/glossarydetail/defid_77/quadriplegia',
        redirect: '/definition/quadriplegia',
    },
    {
        path: '/definitions/glossarydetail/defid_78/quote',
        redirect: '/definition/quote',
    },
    {
        path: '/definitions/glossarydetail/defid_79/rating',
        redirect: '/definition/rating',
    },
    {
        path: '/definitions/glossarydetail/defid_138/record-id',
        redirect: '/definition/record-id',
    },
    {
        path: '/definitions/glossarydetail/defid_82/refund-of-premiums',
        redirect: '/definition/refund-of-premiums',
    },
    {
        path: '/definitions/glossarydetail/defid_84/renewability',
        redirect: '/definition/renewability',
    },
    {
        path: '/definitions/glossarydetail/defid_145/rental-car-coverage',
        redirect: '/definition/rental-car-coverage',
    },
    {
        path: '/definitions/glossarydetail/defid_80/repatriation-of-remains',
        redirect: '/definition/repatriation-of-remains',
    },
    {
        path: '/definitions/glossarydetail/defid_197/return-home-coverage',
        redirect: '/definition/return-home-coverage',
    },
    {
        path: '/definitions/glossarydetail/defid_81/return-of-minor-children',
        redirect: '/definition/return-of-minor-children',
    },
    {
        path: '/definitions/glossarydetail/defid_83/review-coverage',
        redirect: '/definition/review-coverage',
    },
    {
        path: '/definitions/glossarydetail/defid_85/schedule-of-benefit-plans',
        redirect: '/definition/schedule-of-benefit-plans',
    },
    {
        path: '/definitions/glossarydetail/defid_86/schengen-border-agreement',
        redirect: '/definition/schengen-border-agreement',
    },
    {
        path: '/definitions/glossarydetail/defid_87/seat-belt-and-airbag-benefit',
        redirect: '/definition/seat-belt-and-airbag-benefit',
    },
    {
        path: '/definitions/glossarydetail/defid_201/specialty',
        redirect: '/definition/specialty',
    },
    {
        path: '/definitions/glossarydetail/defid_120/stolen-passports/visas',
        redirect: '/definition/stolen-passports/visas',
    },
    {
        path: '/definitions/glossarydetail/defid_88/student-coverage',
        redirect: '/definition/student-coverage',
    },
    {
        path: '/definitions/glossarydetail/defid_152/surgical-treatment',
        redirect: '/definition/surgical-treatment',
    },
    {
        path: '/definitions/glossarydetail/defid_92/terrorism-coverage',
        redirect: '/definition/terrorism-coverage',
    },
    {
        path: '/definitions/glossarydetail/defid_168/transportation-pak',
        redirect: '/definition/transportation-pak',
    },
    {
        path: '/definitions/glossarydetail/defid_219/transportation-pak',
        redirect: '/definition/transportation-pak',
    },
    {
        path: '/definitions/glossarydetail/defid_239/travel-benefit',
        redirect: '/definition/travel-benefit',
    },
    {
        path: '/definitions/glossarydetail/defid_229/travel-concierge',
        redirect: '/definition/travel-concierge',
    },
    {
        path: '/definitions/glossarydetail/defid_235/travel-delay',
        redirect: '/definition/travel-delay',
    },
    {
        path: '/definitions/glossarydetail/defid_89/travel-insurance',
        redirect: '/definition/travel-insurance',
    },
    {
        path: '/definitions/glossarydetail/defid_142/trip-cancellation',
        redirect: '/definition/trip-cancellation',
    },
    {
        path: '/definitions/glossarydetail/defid_143/trip-cancellation-for-any-reason',
        redirect: '/definition/trip-cancellation-for-any-reason',
    },
    {
        path: '/definitions/glossarydetail/defid_151/trip-cost',
        redirect: '/definition/trip-cost',
    },
    {
        path: '/definitions/glossarydetail/defid_91/trip-interruption',
        redirect: '/definition/trip-interruption',
    },
    {
        path: '/definitions/glossarydetail/defid_221/tuition-reimbursement',
        redirect: '/definition/tuition-reimbursement',
    },
    {
        path: '/definitions/glossarydetail/defid_158/underwriter',
        redirect: '/definition/underwriter',
    },
    {
        path: '/definitions/glossarydetail/defid_211/urgent-care',
        redirect: '/definition/urgent-care',
    },
    {
        path: '/definitions/glossarydetail/defid_94/us-citizen',
        redirect: '/definition/us-citizen',
    },
    {
        path: '/definitions/glossarydetail/defid_126/us-contact',
        redirect: '/definition/us-contact',
    },
    {
        path: '/definitions/glossarydetail/defid_205/us-network',
        redirect: '/definition/us-network',
    },
    {
        path: '/definitions/glossarydetail/defid_171/vision-coverage',
        redirect: '/definition/vision-coverage',
    },
    {
        path: '/definitions/glossarydetail/defid_95/visitors-insurance',
        redirect: '/definition/visitors-insurance',
    },
    {
        path: '/definitions/glossarydetail/defid_96/war-coverage',
        redirect: '/definition/war-coverage',
    },
    {
        path: '/definitions/glossarydetail/defid_203/year-established',
        redirect: '/definition/year-established',
    }
];

export default routes;
