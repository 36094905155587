export const priceConverter = (price) => {
  return new Intl.NumberFormat("en-US", {
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(price);
};

export const NumberConverter = (num) => {
  return new Intl.NumberFormat("en-US", {
    currency: "USD",
  }).format(num);
};

