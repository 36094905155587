import Footer from './components/footer.vue';
import Index from './mainHeader.vue';
import PlanListing from './components/compareHeader.vue';
import { useRoute } from 'vue-router';
import { ref, watchEffect } from 'vue';
export default {
  name: 'DefaultLayout',
  components: {
    Footer,
    Index
  },
  props: ['layout'],
  setup() {
    const route = useRoute();
    const CurrentRoute = ref('Index');
    const isFooterShow = ref(true);
    watchEffect(() => {
      if (route.matched[0]) {
        CurrentRoute.value = route.matched[0].props.default?.layout;
        isFooterShow.value = route.matched[0].props.default?.footer;
      }
    });
    return {
      route,
      CurrentRoute,
      isFooterShow
    };
  }
};