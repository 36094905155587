import { createRouter, createWebHistory } from "vue-router";

import { store } from "@/store/";

import BlogRoutes from './blogRoutes';
//Lazy Loading importing

const Index = () => import('@/pages/index.vue')
const Affiliate = () => import('@/pages/affiliate.vue')


const GetQuote = () => import("@/pages/getAQuote.vue");
const blog = () => import("@/pages/blogs.vue");
const SingleBlogPost = () => import("@/pages/sinleBlogPost.vue");
const faqList = () => import("@/pages/faqList.vue");
const faqInner = () => import("@/pages/faqInnerPage.vue");

const customerQuery = () => import("@/pages/userQuery.vue");
const pageNotFound = () => import("@/pages/notFound.vue");
const planMoved = () => import("@/pages/planMoved.vue");


import landingPages from "@/router/landingPages";
import itiraSteps from "@/router/itiraSteps";
import userDashboard from "@/router/userDashboard";
import authRoutes from "@/router/authRoutes";
import checkoutRoutes from "@/router/checkoutRoutes";
import mediakitRoutes from "@/router/mediakitRoutes";
import planListing from "@/router/planListing";
import blogRoutes from './blogRoutes.js';
import faqRoutes from './faqRoutes.js';
import definitionRoutes from './definitionRoutes.js';




const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
    props: { default: true, layout: "Index", footer: true },
  },


  {
    path: "/customer-query",
    name: "customerQuery",
    component: customerQuery,
    meta: {
      isAuth: true,
    },
    props: { default: true, layout: "Index", footer: true },
  },


  {
    path: "/get-quote",
    name: "getAQuote",
    component: GetQuote,
    props: { default: true, layout: "Index", footer: true },
  },




  {
    path: "/blogs",
    name: "blog",
    component: blog,
    props: { footer: true },
  },
 /* {
    path: "/blogs/:slug",
    name: "SingleBlogPost",
    component: SingleBlogPost,
    meta: {
      parentPath: "blog",
    },
    props: { footer: true },
  },*/
  {
    path: "/faq",
    name: "faqList",
    component: faqList,
    props: { default: true, layout: "Index", footer: true },
  },
  // {
  //   path: "/faq/:slug",
  //   name: "faqInner",
  //   component: faqInner,
  //   props: { default: true, layout: "Index", footer: true },
  // },

  ...blogRoutes,
  ...faqRoutes,
  ...authRoutes,
  ...landingPages,
  ...itiraSteps,
  ...planListing,
  ...checkoutRoutes,
  ...userDashboard,
  ...mediakitRoutes,
  ...definitionRoutes,


  // redirects
  {
    path: "/affiliates/garamchai",
    redirect: "/",
  },
  {
    path: "/faq/category_9/faqID_62/title_How-do-I-file-a-claim",
    redirect: "/blogs/how-to-file-a-travel-insurance-claim-a-step-by-step-guide",
  },
 {
    path: "/standard",
    redirect: "/blogs/the-mission-that-drives-us",
  },

{
    path: "/student-insurance",
    redirect: "/blogs/the-mission-that-drives-us",
  },

  {
    path: "/plan-moved",
    name: "planMoved",
    component: planMoved,
    props: { default: true, layout: "Index", footer: true },
  },

  {
    path: '/affiliateUnique_:uniqueId',
    name: "Affiliate",
    component: Affiliate,
    props: { default: true, layout: "Index", footer: true },
  },

  {
    path: "/:pathMatch(.*)*",
    name: "pageNotFound",
    component: pageNotFound,
    props: { default: true, layout: "Index", footer: true },
  },
];




export const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});



//all api hit need for eact routes

router.beforeEach((to, from, next) => {
  let { user, setIsComingFrom } = store.state.user;
  if ((to.name === "Login" || to.name === "Register") && user != null) {
    router.go(-1);
    return;
  }

  if (to.meta.isAuth) {
    if (to.meta.allowGuest) {
      next();
      return;
    }
    if (user != null) {
      if (!user.is_active) {
        next("/request-activate");
        return;
      } else {
        next();
        return;
      }
    } else {
      next("/login");
    }
    return;
  } else {
    next();
  }


});
