export default {
  STATE_STATUSES(state, payload) {
    state.status = payload;
  },
  setSocialLoader(state, payload) {
    state.socialLoader = payload;
  },
  setErrorMessage(state, payload) {
    state.errorMessage = payload;
  },
  setClearErrorMessage(state, payload) {
    state.errorMessage = payload;
  },

  setProvider(state, payload) {
    state.provider = payload;
  },
  setUser(state, payload) {
    state.user = payload;
  },
  logout(state) {
    state.user = null;
  },
  setIsComingFrom(state, payload) {
    state.setIsComingFrom = payload;
  },
  activeUser(state, payload) {
    state.user = payload;
  },
  setRedirectingPath(state, payload) {
    state.redirectPath = payload;
  },
  resetIsComingFrom(state) {
    state.setIsComingFrom = {
      status: false,
      plan_id: 0,
    };
  },
};
