const howG1GWorks = () => import("@/components/visitor/howG1GWorks.vue");
const Contact = () => import("@/pages/landingPages/contact.vue");
const OurMission = () => import("@/pages/landingPages/ourMission.vue");
const PersonalInfo = () => import("@/pages/landingPages/userPersonalInfo.vue");
const VisitorLP = () => import("@/pages/landingPages/visitorLandingPage.vue");
const TravelInsuranceLP = () => import("@/pages/landingPages/travelInsuranceLP.vue");
const AnnualTripLP = () => import("@/pages/landingPages/annualTrip.vue");
const TravelMedicalInsurance = () =>
    import("@/pages/landingPages/travelMedicalInsurance.vue");
const NomadInsurance = () => import("@/pages/landingPages/nomadInsurance.vue");
const ExpatInsurance = () => import("@/pages/landingPages/expatInsurance.vue");
const SchengenInsurance = () => import("@/pages/landingPages/schengenInsurance.vue");
const CFARInsurance = () => import("@/pages/landingPages/cfarInsurance.vue");
const AdventureSports = () => import("@/pages/landingPages/adventureSports.vue");
const CruiseInsurance = () => import("@/pages/landingPages/cruiseInsurance.vue");
const MedicalEvacuation = () => import("@/pages/landingPages/medicalEvacuation.vue");
const StudentMedicalLP = () => import("@/pages/landingPages/studentMedicalInsuranceLP.vue");
const VisaInsuranceLP = () => import("@/pages/landingPages/visaInsuranceLP.vue");
const PrivacyPolicy = () => import("@/pages/landingPages/privacyPolicy.vue");
const Accessibility = () => import("@/pages/landingPages/accessibility.vue");
const Terms = () => import("@/pages/landingPages/terms&conditions.vue");
const ContentGuidliens = () => import("@/pages/landingPages/contentGuidLines.vue");
const ReturnPolicy = () => import("@/pages/landingPages/returnPolicy.vue");
const About = () => import("@/pages/landingPages/about.vue");

// explained pages
const bestTravelInsurance = () => import("@/pages/landingPages/bestTravelInsurance.vue");
const BestTravelInsurancePlansForCovid19 = () =>
    import("@/pages/landingPages/bestTravelInsurancePlansforCovid19.vue");
const BestTravelInsuranceCompanies = () =>
    import("@/pages/landingPages/bestTravelInsuranceCompanies.vue");
const CFAR = () => import("@/pages/landingPages/cFAR.vue");
const TravelInsuranceExplained = () =>
    import("@/pages/landingPages/travelInsuranceExplained.vue");

//new landing pages
const GreenCardImmigrantHealthInsurance = () =>
    import("@/pages/landingPages/GreenCardImmigrantHealthInsurance.vue");


const KidnapRansomInsurance = () =>
    import("@/pages/landingPages/KidnapRansomInsurance.vue");
const GroupTravelInsurance = () =>
    import("@/pages/landingPages/GroupTravelInsurance.vue");


const ExpatriateHealthInsurancePlan = () =>
    import("@/pages/landingPages/ExpatriateHealthInsurancePlan.vue");


const ImmigrantMedicalInsurance = () =>
    import("@/pages/landingPages/ImmigrantMedicalInsurance.vue");
const BridgePlanInsurance = () =>
    import("@/pages/landingPages/BridgePlanInsurance.vue");





const routes = [

    {
        path: "/usa-visitor-health-insurance",
        name: "VisitorLP",
        component: VisitorLP,

        props: { default: true, layout: "Index", footer: true },
    },
    // redirects visitors-health-insurance to /usa-visitor-health-insurance
    {
        path: "/visitors-health-insurance",
        redirect: "/usa-visitor-health-insurance",
    },
    {
        path: "/travel-protection-insurance",
        name: "TravelInsuranceLP",
        component: TravelInsuranceLP,
        props: { default: true, layout: "Index", footer: true },
    },
    {
        path: "/annual-travel-insurance",
        name: "AnnualTripLP",
        component: AnnualTripLP,
        props: { default: true, layout: "Index", footer: true },
    },
    {
        path: "/student-medical-insurance",
        name: "StudentMedicalLP",
        component: StudentMedicalLP,
        props: { default: true, layout: "Index", footer: true },
    },
    {
        path: "/travel-medical-insurance",
        name: "TravelMedicalInsurance",
        component: TravelMedicalInsurance,
        props: { default: true, layout: "Index", footer: true },
    },
    {
        path: "/nomad-insurance",
        name: "NomadInsurance",
        component: NomadInsurance,
        props: { default: true, layout: "Index", footer: true },
    },
    {
        path: "/expat-insurance",
        name: "ExpatInsurance",
        component: ExpatInsurance,
        props: { default: true, layout: "Index", footer: true },
    },
    {
        path: "/schengen-visa-insurance",
        name: "SchengenInsurance",
        component: SchengenInsurance,
        props: { default: true, layout: "Index", footer: true },
    },
    {
        path: "/trip-cancellation-for-any-reason",
        name: "CFARInsurance",
        component: CFARInsurance,
        props: { default: true, layout: "Index", footer: true },
    },
    {
        path: "/adventure-travel-sports-insurance",
        name: "AdventureSports",
        component: AdventureSports,
        props: { default: true, layout: "Index", footer: true },
    },
    {
        path: "/cruise-insurance",
        name: "CruiseInsurance",
        component: CruiseInsurance,
        props: { default: true, layout: "Index", footer: true },
    },
    {
        path: "/emergency-medical-evacuation-insurance",
        name: "MedicalEvacuation",
        component: MedicalEvacuation,
        props: { default: true, layout: "Index", footer: true },
    },
    {
        path: "/visa-insurance",
        name: "VisaInsuranceLP",
        component: VisaInsuranceLP,
        props: { default: true, layout: "Index", footer: true },
    },
    {
        path: "/best-travel-insurance",
        name: "bestTravelInsurance",
        component: bestTravelInsurance,
        props: { footer: true },
    },
    {
        path: "/best-travel-insurance-plans-for-covid-19",
        name: "BestTravelInsurancePlansForCovid19",
        component: BestTravelInsurancePlansForCovid19,
        props: { footer: true },
    },
    {
        path: "/best-travel-insurance-companies",
        name: "BestTravelInsuranceCompanies",
        component: BestTravelInsuranceCompanies,
        props: { footer: true },
    },
    {
        path: "/travel-insurance-explained",
        name: "TravelInsuranceExplained",
        component: TravelInsuranceExplained,
        props: { footer: true },
    },
    // new landing pages

    {
        path: "/green-card-immigrant-health-insurance",
        name: "GreenCardImmigrantHealthInsurance",
        component: GreenCardImmigrantHealthInsurance,
        props: { default: true, layout: "Index", footer: true },
    },

{
        path: "/kidnap-ransom-insurance",
        name: "KidnapRansomInsurance",
        component: KidnapRansomInsurance,
        props: { default: true, layout: "Index", footer: true },
    },

{
        path: "/group-travel-insurance",
        name: "GroupTravelInsurance",
        component: GroupTravelInsurance,
        props: { default: true, layout: "Index", footer: true },
    },

{
        path: "/expatriate-health-insurance-plan",
        name: "ExpatriateHealthInsurancePlan",
        component: ExpatriateHealthInsurancePlan,
        props: { default: true, layout: "Index", footer: true },
    },
{
        path: "/immigrant-medical-insurance",
        name: "ImmigrantMedicalInsurance",
        component: ImmigrantMedicalInsurance,
        props: { default: true, layout: "Index", footer: true },
    },
    {
        path: "/bridge-plan-insurance",
        name: "BridgePlanInsurance",
        component: BridgePlanInsurance,
        props: { default: true, layout: "Index", footer: true },
    },















    {
        path: "/how-g1g-works-best-travel-insurance",
        name: "howG1GWorks",
        component: howG1GWorks,
        props: { default: true, layout: "Index", footer: true },
    },
    {
        path: "/cfar",
        name: "CFAR",
        component: CFAR,
        props: { footer: true },
    },
    {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
        props: { default: true, layout: "Index", footer: true },
    },
    {
        path: "/terms-conditions",
        name: "terms&conditions",
        component: Terms,
        props: { default: true, layout: "Index", footer: true },
    },
    {
        path: "/content-usage-guidelines",
        name: "contentGuidlines",
        component: ContentGuidliens,
        props: { default: true, layout: "Index", footer: true },
    },
    {
        path: "/refund-policy",
        name: "ReturnPolicy",
        component: ReturnPolicy,
        props: { default: true, layout: "Index", footer: true },
    },
    {
        path: "/accessibility",
        name: "Accessibility",
        component: Accessibility,
        props: { default: true, layout: "Index", footer: true },
    },
    {
        path: "/about-us",
        name: "About",
        component: About,
        props: { default: true, layout: "Index", footer: true },
    },
    {
        path: "/contact-us",
        name: "contact",
        component: Contact,
        props: { default: true, layout: "Index", footer: true },
    },{
        path: "/our-mission",
        name: "OurMission",
        component: OurMission,
        props: { default: true, layout: "Index", footer: true },
    },
    {
        path: "/user-info",
        name: "userPersonalInfo",
        component: PersonalInfo,
        props: { default: true, layout: "Index", footer: true },
    }
]

export default routes;
