export const getters = {
    allSports(state) {
        if (state.sportsAdventure != null) {
            const allSports = state.sportsAdventure.map(({name}) => {
                return {
                    sports:name,
                };
            });
            return allSports;
        }
    },
};
