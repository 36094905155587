import { baseURL, baseURLBetaG1G } from "./index";
import instance from "axios";
import { notify } from "@kyvg/vue3-notification";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

let betaG1g = "beta.g1g.com";

let createBaseURL = window.location.host == betaG1g ? baseURLBetaG1G : baseURL;

export const axios = instance.create({
  baseURL: createBaseURL,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

// get tokeb by local storage
const g1guser = () => {
  // let g1guser = JSON.parse(ls.get("g1g"));
  let g1guser = JSON.parse(localStorage.getItem("g1g"));

  if (!g1guser.user.user) return null;
  let { token } = g1guser.user.user;

  return token;
};

// //calling interceptor before fetch config
axios.interceptors.request.use(
  (config) => {
    config.headers.Authorization =
      g1guser() != null ? `Bearer ${g1guser()}` : null;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// // //calling interceptor api fetech by server reesponse
// axios.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     let { status } = error.response;

//     if (status === 404) {
//       notify({
//         title: status + ' Error',
//         text: error.response.config.url + ' Not found',
//         type: 'error',
//       });
//     }

//     if (status === 403) {
//       notify({
//         title: status + ' Not logged in',
//         text: 'Login again',
//         type: 'error',
//       });
//     }
//     if (status === 401) {
//       notify({
//         title: status + ' Unauthenticated user',
//         text: 'Login again',
//         type: 'error',
//       });
//     }

//     return Promise.reject(error);
//   }
// );
