import { actions } from "./actions";
import mutations from "./mutations";
import { STATE_STATUSES } from "@/helpers/constants";
import { getters } from "./getters";

const state = {
  user: null,
  status: STATE_STATUSES.INIT,
  socialLoader: STATE_STATUSES.INIT,
  provider: null,
  errorMessage: null,
  redirectPath: null,
  setIsComingFrom: {
    status: false,
    plan_id: 0,
  },
};

const user = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};


export default user;
