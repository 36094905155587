import { actions } from "./actions";
import mutations from "./mutations";

import { STATE_STATUSES } from "@/helpers/constants";
import { reactive } from "vue";

const state = reactive({
  claims: null,
  claim: null,
  types: null,
  reasons: null,
  status: STATE_STATUSES.INIT,

  //new claims flow

  claimsInfo: {
    selectedTypes: [],
  },

  //final create claims
  createClaim: {
    types: [],
  },
});

const claims = {
  namespaced: true,
  state,
  actions,
  mutations,
};
export default claims;
