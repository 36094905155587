export let countriesList= [
    {
        "id": 233,
        "name": "United States",
        "iso3": "USA",
        "iso2": "US",
        "states": [
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1399,
                "iso2": "DE",
                "name": "Delaware"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1400,
                "iso2": "AK",
                "name": "Alaska"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1401,
                "iso2": "MD",
                "name": "Maryland"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1404,
                "iso2": "NH",
                "name": "New Hampshire"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1406,
                "iso2": "KS",
                "name": "Kansas"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1407,
                "iso2": "TX",
                "name": "Texas"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1408,
                "iso2": "NE",
                "name": "Nebraska"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1409,
                "iso2": "VT",
                "name": "Vermont"
            },
            {
                "country_code": "UM",
                "country_id": 233,
                "id": 1410,
                "iso2": "UM-86",
                "name": "Jarvis Island"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1411,
                "iso2": "HI",
                "name": "Hawaii"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1412,
                "iso2": "GU",
                "name": "Guam"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1414,
                "iso2": "UT",
                "name": "Utah"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1415,
                "iso2": "OR",
                "name": "Oregon"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1416,
                "iso2": "CA",
                "name": "California"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1417,
                "iso2": "NJ",
                "name": "New Jersey"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1418,
                "iso2": "ND",
                "name": "North Dakota"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1419,
                "iso2": "KY",
                "name": "Kentucky"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1420,
                "iso2": "MN",
                "name": "Minnesota"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1421,
                "iso2": "OK",
                "name": "Oklahoma"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1422,
                "iso2": "PA",
                "name": "Pennsylvania"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1423,
                "iso2": "NM",
                "name": "New Mexico"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1424,
                "iso2": "AS",
                "name": "American Samoa"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1425,
                "iso2": "IL",
                "name": "Illinois"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1426,
                "iso2": "MI",
                "name": "Michigan"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1427,
                "iso2": "VA",
                "name": "Virginia"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1429,
                "iso2": "WV",
                "name": "West Virginia"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1430,
                "iso2": "MS",
                "name": "Mississippi"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1433,
                "iso2": "MA",
                "name": "Massachusetts"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1434,
                "iso2": "AZ",
                "name": "Arizona"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1435,
                "iso2": "CT",
                "name": "Connecticut"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1436,
                "iso2": "FL",
                "name": "Florida"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1437,
                "iso2": "DC",
                "name": "District of Columbia"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1440,
                "iso2": "IN",
                "name": "Indiana"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1441,
                "iso2": "WI",
                "name": "Wisconsin"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1442,
                "iso2": "WY",
                "name": "Wyoming"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1443,
                "iso2": "SC",
                "name": "South Carolina"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1444,
                "iso2": "AR",
                "name": "Arkansas"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1445,
                "iso2": "SD",
                "name": "South Dakota"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1446,
                "iso2": "MT",
                "name": "Montana"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1447,
                "iso2": "NC",
                "name": "North Carolina"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1449,
                "iso2": "PR",
                "name": "Puerto Rico"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1450,
                "iso2": "CO",
                "name": "Colorado"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1451,
                "iso2": "MO",
                "name": "Missouri"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1452,
                "iso2": "NY",
                "name": "New York"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1453,
                "iso2": "ME",
                "name": "Maine"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1454,
                "iso2": "TN",
                "name": "Tennessee"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1455,
                "iso2": "GA",
                "name": "Georgia"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1456,
                "iso2": "AL",
                "name": "Alabama"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1457,
                "iso2": "LA",
                "name": "Louisiana"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1458,
                "iso2": "NV",
                "name": "Nevada"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1459,
                "iso2": "IA",
                "name": "Iowa"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1460,
                "iso2": "ID",
                "name": "Idaho"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1461,
                "iso2": "RI",
                "name": "Rhode Island"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 1462,
                "iso2": "WA",
                "name": "Washington"
            },
            {
                "country_code": "US",
                "country_id": 233,
                "id": 4851,
                "iso2": "OH",
                "name": "Ohio"
            }
        ]
    },
    {
        "id": 232,
        "name": "United Kingdom",
        "iso3": "GBR",
        "iso2": "GB",
        "states": [
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2289,
                "iso2": "IOW",
                "name": "Isle of Wight"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2290,
                "iso2": "SHN",
                "name": "St Helens"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2291,
                "iso2": "BEN",
                "name": "London Borough of Brent"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2292,
                "iso2": "WLL",
                "name": "Walsall"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2293,
                "iso2": "TRF",
                "name": "Trafford"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2294,
                "iso2": "STH",
                "name": "City of Southampton"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2295,
                "iso2": "SHF",
                "name": "Sheffield"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2296,
                "iso2": "WSX",
                "name": "West Sussex"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2297,
                "iso2": "PTE",
                "name": "City of Peterborough"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2298,
                "iso2": "CAY",
                "name": "Caerphilly County Borough"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2299,
                "iso2": "VGL",
                "name": "Vale of Glamorgan"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2300,
                "iso2": "ZET",
                "name": "Shetland Islands"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2301,
                "iso2": "RCT",
                "name": "Rhondda Cynon Taf"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2302,
                "iso2": "POL",
                "name": "Poole"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2303,
                "iso2": "CBF",
                "name": "Central Bedfordshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2304,
                "iso2": "DOW",
                "name": "Down District Council"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2305,
                "iso2": "POR",
                "name": "City of Portsmouth"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2306,
                "iso2": "HRY",
                "name": "London Borough of Haringey"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2307,
                "iso2": "BEX",
                "name": "London Borough of Bexley"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2308,
                "iso2": "ROT",
                "name": "Rotherham"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2309,
                "iso2": "HPL",
                "name": "Hartlepool"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2310,
                "iso2": "TFW",
                "name": "Telford and Wrekin"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2311,
                "iso2": "BFS",
                "name": "Belfast district"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2312,
                "iso2": "CON",
                "name": "Cornwall"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2313,
                "iso2": "STN",
                "name": "London Borough of Sutton"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2314,
                "iso2": "OMH",
                "name": "Omagh District Council"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2315,
                "iso2": "BNB",
                "name": "Banbridge"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2316,
                "iso2": "CCG",
                "name": "Causeway Coast and Glens"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2317,
                "iso2": "NTA",
                "name": "Newtownabbey Borough Council"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2318,
                "iso2": "LCE",
                "name": "City of Leicester"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2319,
                "iso2": "ISL",
                "name": "London Borough of Islington"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2320,
                "iso2": "WGN",
                "name": "Metropolitan Borough of Wigan"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2321,
                "iso2": "OXF",
                "name": "Oxfordshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2322,
                "iso2": "MFT",
                "name": "Magherafelt District Council"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2323,
                "iso2": "SOS",
                "name": "Southend-on-Sea"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2324,
                "iso2": "ABC",
                "name": "Armagh, Banbridge and Craigavon"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2325,
                "iso2": "PKN",
                "name": "Perth and Kinross"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2326,
                "iso2": "WFT",
                "name": "London Borough of Waltham Forest"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2327,
                "iso2": "RCH",
                "name": "Rochdale"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2328,
                "iso2": "MTY",
                "name": "Merthyr Tydfil County Borough"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2329,
                "iso2": "BBD",
                "name": "Blackburn with Darwen"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2330,
                "iso2": "KWL",
                "name": "Knowsley"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2331,
                "iso2": "ARM",
                "name": "Armagh City and District Council"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2332,
                "iso2": "MDB",
                "name": "Middlesbrough"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2333,
                "iso2": "ERW",
                "name": "East Renfrewshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2334,
                "iso2": "CMA",
                "name": "Cumbria"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2335,
                "iso2": "SCT",
                "name": "Scotland"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2336,
                "iso2": "ENG",
                "name": "England"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2337,
                "iso2": "NIR",
                "name": "Northern Ireland"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2338,
                "iso2": "WLS",
                "name": "Wales"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2339,
                "iso2": "BAS",
                "name": "Bath and North East Somerset"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2340,
                "iso2": "LIV",
                "name": "Liverpool"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2341,
                "iso2": "SAW",
                "name": "Sandwell"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2342,
                "iso2": "BMH",
                "name": "Bournemouth"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2343,
                "iso2": "IOS",
                "name": "Isles of Scilly"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2344,
                "iso2": "FAL",
                "name": "Falkirk"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2345,
                "iso2": "DOR",
                "name": "Dorset"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2346,
                "iso2": "SCB",
                "name": "Scottish Borders"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2347,
                "iso2": "HAV",
                "name": "London Borough of Havering"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2348,
                "iso2": "MYL",
                "name": "Moyle District Council"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2349,
                "iso2": "CMD",
                "name": "London Borough of Camden"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2350,
                "iso2": "NYM",
                "name": "Newry and Mourne District Council"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2351,
                "iso2": "NTL",
                "name": "Neath Port Talbot County Borough"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2352,
                "iso2": "CWY",
                "name": "Conwy County Borough"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2353,
                "iso2": "ELS",
                "name": "Outer Hebrides"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2354,
                "iso2": "WLN",
                "name": "West Lothian"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2355,
                "iso2": "LIN",
                "name": "Lincolnshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2356,
                "iso2": "BDG",
                "name": "London Borough of Barking and Dagenham"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2357,
                "iso2": "WSM",
                "name": "City of Westminster"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2358,
                "iso2": "LEW",
                "name": "London Borough of Lewisham"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2359,
                "iso2": "NGM",
                "name": "City of Nottingham"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2360,
                "iso2": "MRY",
                "name": "Moray"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2361,
                "iso2": "BLY",
                "name": "Ballymoney"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2362,
                "iso2": "SLK",
                "name": "South Lanarkshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2363,
                "iso2": "BLA",
                "name": "Ballymena Borough"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2364,
                "iso2": "DNC",
                "name": "Doncaster"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2365,
                "iso2": "NBL",
                "name": "Northumberland"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2366,
                "iso2": "FMO",
                "name": "Fermanagh and Omagh"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2367,
                "iso2": "TAM",
                "name": "Tameside"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2368,
                "iso2": "KEC",
                "name": "Royal Borough of Kensington and Chelsea"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2369,
                "iso2": "HRT",
                "name": "Hertfordshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2370,
                "iso2": "ERY",
                "name": "East Riding of Yorkshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2371,
                "iso2": "KIR",
                "name": "Kirklees"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2372,
                "iso2": "SND",
                "name": "City of Sunderland"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2373,
                "iso2": "GLS",
                "name": "Gloucestershire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2374,
                "iso2": "EAY",
                "name": "East Ayrshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2375,
                "iso2": "UKM",
                "name": "United Kingdom"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2376,
                "iso2": "HIL",
                "name": "London Borough of Hillingdon"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2377,
                "iso2": "SAY",
                "name": "South Ayrshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2378,
                "iso2": "SH-AC",
                "name": "Ascension Island"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2379,
                "iso2": "GWN",
                "name": "Gwynedd"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2380,
                "iso2": "HNS",
                "name": "London Borough of Hounslow"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2381,
                "iso2": "MDW",
                "name": "Medway"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2382,
                "iso2": "LMV",
                "name": "Limavady Borough Council"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2383,
                "iso2": "HLD",
                "name": "Highland"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2384,
                "iso2": "NEL",
                "name": "North East Lincolnshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2385,
                "iso2": "HRW",
                "name": "London Borough of Harrow"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2386,
                "iso2": "SOM",
                "name": "Somerset"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2387,
                "iso2": "ANS",
                "name": "Angus"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2388,
                "iso2": "IVC",
                "name": "Inverclyde"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2389,
                "iso2": "DAL",
                "name": "Darlington"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2390,
                "iso2": "TWH",
                "name": "London Borough of Tower Hamlets"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2391,
                "iso2": "WIL",
                "name": "Wiltshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2392,
                "iso2": "AGB",
                "name": "Argyll and Bute"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2393,
                "iso2": "STB",
                "name": "Strabane District Council"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2394,
                "iso2": "SKP",
                "name": "Stockport"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2395,
                "iso2": "BNH",
                "name": "Brighton and Hove"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2396,
                "iso2": "LBH",
                "name": "London Borough of Lambeth"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2397,
                "iso2": "RDB",
                "name": "London Borough of Redbridge"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2398,
                "iso2": "MAN",
                "name": "Manchester"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2399,
                "iso2": "MUL",
                "name": "Mid Ulster"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2400,
                "iso2": "SGC",
                "name": "South Gloucestershire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2401,
                "iso2": "ABD",
                "name": "Aberdeenshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2402,
                "iso2": "MON",
                "name": "Monmouthshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2403,
                "iso2": "DBY",
                "name": "Derbyshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2404,
                "iso2": "GLG",
                "name": "Glasgow"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2405,
                "iso2": "BKM",
                "name": "Buckinghamshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2406,
                "iso2": "DUR",
                "name": "County Durham"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2407,
                "iso2": "SHR",
                "name": "Shropshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2408,
                "iso2": "WRL",
                "name": "Wirral"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2409,
                "iso2": "STY",
                "name": "South Tyneside"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2410,
                "iso2": "ESS",
                "name": "Essex"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2411,
                "iso2": "HCK",
                "name": "London Borough of Hackney"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2412,
                "iso2": "ANN",
                "name": "Antrim and Newtownabbey"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2413,
                "iso2": "BST",
                "name": "City of Bristol"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2414,
                "iso2": "ESX",
                "name": "East Sussex"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2415,
                "iso2": "DGY",
                "name": "Dumfries and Galloway"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2416,
                "iso2": "MIK",
                "name": "Milton Keynes"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2417,
                "iso2": "DRY",
                "name": "Derry City Council"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2418,
                "iso2": "NWM",
                "name": "London Borough of Newham"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2419,
                "iso2": "WOK",
                "name": "Wokingham"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2420,
                "iso2": "WRT",
                "name": "Warrington"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2421,
                "iso2": "STT",
                "name": "Stockton-on-Tees"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2422,
                "iso2": "SWD",
                "name": "Swindon"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2423,
                "iso2": "CAM",
                "name": "Cambridgeshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2424,
                "iso2": "LND",
                "name": "City of London"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2425,
                "iso2": "BIR",
                "name": "Birmingham"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2426,
                "iso2": "YOR",
                "name": "City of York"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2427,
                "iso2": "SLG",
                "name": "Slough"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2428,
                "iso2": "EDH",
                "name": "Edinburgh"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2429,
                "iso2": "MEA",
                "name": "Mid and East Antrim"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2430,
                "iso2": "NSM",
                "name": "North Somerset"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2431,
                "iso2": "GAT",
                "name": "Gateshead"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2432,
                "iso2": "SWK",
                "name": "London Borough of Southwark"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2433,
                "iso2": "SWA",
                "name": "City and County of Swansea"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2434,
                "iso2": "WND",
                "name": "London Borough of Wandsworth"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2435,
                "iso2": "HAM",
                "name": "Hampshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2436,
                "iso2": "WRX",
                "name": "Wrexham County Borough"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2437,
                "iso2": "FLN",
                "name": "Flintshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2438,
                "iso2": "COV",
                "name": "Coventry"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2439,
                "iso2": "CKF",
                "name": "Carrickfergus Borough Council"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2440,
                "iso2": "WDU",
                "name": "West Dunbartonshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2441,
                "iso2": "POW",
                "name": "Powys"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2442,
                "iso2": "CHW",
                "name": "Cheshire West and Chester"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2443,
                "iso2": "RFW",
                "name": "Renfrewshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2444,
                "iso2": "CHE",
                "name": "Cheshire East"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2445,
                "iso2": "CKT",
                "name": "Cookstown District Council"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2446,
                "iso2": "DRS",
                "name": "Derry City and Strabane"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2447,
                "iso2": "STS",
                "name": "Staffordshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2448,
                "iso2": "HMF",
                "name": "London Borough of Hammersmith and Fulham"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2449,
                "iso2": "CGV",
                "name": "Craigavon Borough Council"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2450,
                "iso2": "CLK",
                "name": "Clackmannanshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2451,
                "iso2": "BPL",
                "name": "Blackpool"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2452,
                "iso2": "BGE",
                "name": "Bridgend County Borough"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2453,
                "iso2": "NLN",
                "name": "North Lincolnshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2454,
                "iso2": "EDU",
                "name": "East Dunbartonshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2455,
                "iso2": "RDG",
                "name": "Reading"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2456,
                "iso2": "NTT",
                "name": "Nottinghamshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2457,
                "iso2": "DUD",
                "name": "Dudley"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2458,
                "iso2": "NET",
                "name": "Newcastle upon Tyne"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2459,
                "iso2": "BUR",
                "name": "Bury"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2460,
                "iso2": "LBC",
                "name": "Lisburn and Castlereagh"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2461,
                "iso2": "CLR",
                "name": "Coleraine Borough Council"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2462,
                "iso2": "ELN",
                "name": "East Lothian"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2463,
                "iso2": "ABE",
                "name": "Aberdeen"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2464,
                "iso2": "KEN",
                "name": "Kent"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2465,
                "iso2": "WKF",
                "name": "Wakefield"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2466,
                "iso2": "HAL",
                "name": "Halton"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2467,
                "iso2": "SFK",
                "name": "Suffolk"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2468,
                "iso2": "THR",
                "name": "Thurrock"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2469,
                "iso2": "SOL",
                "name": "Solihull"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2470,
                "iso2": "BRC",
                "name": "Bracknell Forest"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2471,
                "iso2": "WBK",
                "name": "West Berkshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2472,
                "iso2": "RUT",
                "name": "Rutland"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2473,
                "iso2": "NFK",
                "name": "Norfolk"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2474,
                "iso2": "ORK",
                "name": "Orkney Islands"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2475,
                "iso2": "KHL",
                "name": "City of Kingston upon Hull"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2476,
                "iso2": "ENF",
                "name": "London Borough of Enfield"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2477,
                "iso2": "OLD",
                "name": "Oldham"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2478,
                "iso2": "TOB",
                "name": "Torbay"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2479,
                "iso2": "FIF",
                "name": "Fife"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2480,
                "iso2": "NTH",
                "name": "Northamptonshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2481,
                "iso2": "KTT",
                "name": "Royal Borough of Kingston upon Thames"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2482,
                "iso2": "WNM",
                "name": "Windsor and Maidenhead"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2483,
                "iso2": "MRT",
                "name": "London Borough of Merton"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2484,
                "iso2": "CMN",
                "name": "Carmarthenshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2485,
                "iso2": "DER",
                "name": "City of Derby"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2486,
                "iso2": "PEM",
                "name": "Pembrokeshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2487,
                "iso2": "NLK",
                "name": "North Lanarkshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2488,
                "iso2": "STG",
                "name": "Stirling"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2489,
                "iso2": "WLV",
                "name": "City of Wolverhampton"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2490,
                "iso2": "BRY",
                "name": "London Borough of Bromley"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2491,
                "iso2": "DEV",
                "name": "Devon"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2492,
                "iso2": "GRE",
                "name": "Royal Borough of Greenwich"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2493,
                "iso2": "SLF",
                "name": "Salford"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2494,
                "iso2": "LSB",
                "name": "Lisburn City Council"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2495,
                "iso2": "LAN",
                "name": "Lancashire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2496,
                "iso2": "TOF",
                "name": "Torfaen"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2497,
                "iso2": "DEN",
                "name": "Denbighshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2498,
                "iso2": "ARD",
                "name": "Ards"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2499,
                "iso2": "BNS",
                "name": "Barnsley"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2500,
                "iso2": "HEF",
                "name": "Herefordshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2501,
                "iso2": "RIC",
                "name": "London Borough of Richmond upon Thames"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2502,
                "iso2": "SH-HL",
                "name": "Saint Helena"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2503,
                "iso2": "LDS",
                "name": "Leeds"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2504,
                "iso2": "BOL",
                "name": "Bolton"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2505,
                "iso2": "WAR",
                "name": "Warwickshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2506,
                "iso2": "STE",
                "name": "City of Stoke-on-Trent"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2507,
                "iso2": "BDF",
                "name": "Bedford"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2508,
                "iso2": "DGN",
                "name": "Dungannon and South Tyrone Borough Council"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2509,
                "iso2": "CGN",
                "name": "Ceredigion"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2510,
                "iso2": "WOR",
                "name": "Worcestershire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2511,
                "iso2": "DND",
                "name": "Dundee"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2512,
                "iso2": "CRY",
                "name": "London Borough of Croydon"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2513,
                "iso2": "NDN",
                "name": "North Down Borough Council"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2514,
                "iso2": "PLY",
                "name": "City of Plymouth"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2515,
                "iso2": "LRN",
                "name": "Larne Borough Council"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2516,
                "iso2": "LEC",
                "name": "Leicestershire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2517,
                "iso2": "CLD",
                "name": "Calderdale"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2518,
                "iso2": "SFT",
                "name": "Sefton"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2519,
                "iso2": "MLN",
                "name": "Midlothian"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2520,
                "iso2": "BNE",
                "name": "London Borough of Barnet"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2521,
                "iso2": "NTY",
                "name": "North Tyneside"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2522,
                "iso2": "NYK",
                "name": "North Yorkshire"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2523,
                "iso2": "AND",
                "name": "Ards and North Down"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2524,
                "iso2": "NWP",
                "name": "Newport"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2525,
                "iso2": "CSR",
                "name": "Castlereagh"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2526,
                "iso2": "SRY",
                "name": "Surrey"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2527,
                "iso2": "RCC",
                "name": "Redcar and Cleveland"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2528,
                "iso2": "CRF",
                "name": "City and County of Cardiff"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2529,
                "iso2": "BRD",
                "name": "Bradford"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2530,
                "iso2": "BGW",
                "name": "Blaenau Gwent County Borough"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2531,
                "iso2": "FER",
                "name": "Fermanagh District Council"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2532,
                "iso2": "EAL",
                "name": "London Borough of Ealing"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2533,
                "iso2": "ANT",
                "name": "Antrim"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2534,
                "iso2": "NMD",
                "name": "Newry, Mourne and Down"
            },
            {
                "country_code": "GB",
                "country_id": 232,
                "id": 2535,
                "iso2": "NAY",
                "name": "North Ayrshire"
            }
        ]
    },
    {
        "id": 1,
        "name": "Afghanistan",
        "iso3": "AFG",
        "iso2": "AF",
        "states": [
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3870,
                "iso2": "GHA",
                "name": "Ghazni"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3871,
                "iso2": "BDG",
                "name": "Badghis"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3872,
                "iso2": "BAM",
                "name": "Bamyan"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3873,
                "iso2": "HEL",
                "name": "Helmand"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3874,
                "iso2": "ZAB",
                "name": "Zabul"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3875,
                "iso2": "BGL",
                "name": "Baghlan"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3876,
                "iso2": "KNR",
                "name": "Kunar"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3877,
                "iso2": "PKA",
                "name": "Paktika"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3878,
                "iso2": "KHO",
                "name": "Khost"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3879,
                "iso2": "KAP",
                "name": "Kapisa"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3880,
                "iso2": "NUR",
                "name": "Nuristan"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3881,
                "iso2": "PAN",
                "name": "Panjshir"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3882,
                "iso2": "NAN",
                "name": "Nangarhar"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3883,
                "iso2": "SAM",
                "name": "Samangan"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3884,
                "iso2": "BAL",
                "name": "Balkh"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3885,
                "iso2": "SAR",
                "name": "Sar-e Pol"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3886,
                "iso2": "JOW",
                "name": "Jowzjan"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3887,
                "iso2": "HER",
                "name": "Herat"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3888,
                "iso2": "GHO",
                "name": "Ghōr"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3889,
                "iso2": "FYB",
                "name": "Faryab"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3890,
                "iso2": "KAN",
                "name": "Kandahar"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3891,
                "iso2": "LAG",
                "name": "Laghman"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3892,
                "iso2": "DAY",
                "name": "Daykundi"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3893,
                "iso2": "TAK",
                "name": "Takhar"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3894,
                "iso2": "PIA",
                "name": "Paktia"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3895,
                "iso2": "PAR",
                "name": "Parwan"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3896,
                "iso2": "NIM",
                "name": "Nimruz"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3897,
                "iso2": "LOG",
                "name": "Logar"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3898,
                "iso2": "URU",
                "name": "Urozgan"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3899,
                "iso2": "FRA",
                "name": "Farah"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3900,
                "iso2": "KDZ",
                "name": "Kunduz Province"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3901,
                "iso2": "BDS",
                "name": "Badakhshan"
            },
            {
                "country_code": "AF",
                "country_id": 1,
                "id": 3902,
                "iso2": "KAB",
                "name": "Kabul"
            }
        ]
    },
    {
        "id": 2,
        "name": "Aland Islands",
        "iso3": "ALA",
        "iso2": "AX",
        "states": []
    },
    {
        "id": 3,
        "name": "Albania",
        "iso3": "ALB",
        "iso2": "AL",
        "states": [
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 593,
                "iso2": "SK",
                "name": "Skrapar District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 594,
                "iso2": "KA",
                "name": "Kavajë District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 595,
                "iso2": "LE",
                "name": "Lezhë District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 596,
                "iso2": "LB",
                "name": "Librazhd District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 597,
                "iso2": "KO",
                "name": "Korçë District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 598,
                "iso2": "3",
                "name": "Elbasan County"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 599,
                "iso2": "LU",
                "name": "Lushnjë District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 600,
                "iso2": "HA",
                "name": "Has District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 601,
                "iso2": "7",
                "name": "Kukës County"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 602,
                "iso2": "MM",
                "name": "Malësi e Madhe District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 603,
                "iso2": "1",
                "name": "Berat County"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 604,
                "iso2": "5",
                "name": "Gjirokastër County"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 605,
                "iso2": "DI",
                "name": "Dibër District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 606,
                "iso2": "PG",
                "name": "Pogradec District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 607,
                "iso2": "BU",
                "name": "Bulqizë District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 608,
                "iso2": "DV",
                "name": "Devoll District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 609,
                "iso2": "8",
                "name": "Lezhë County"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 610,
                "iso2": "9",
                "name": "Dibër County"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 611,
                "iso2": "10",
                "name": "Shkodër County"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 612,
                "iso2": "KC",
                "name": "Kuçovë District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 613,
                "iso2": "VL",
                "name": "Vlorë District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 614,
                "iso2": "KR",
                "name": "Krujë District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 615,
                "iso2": "11",
                "name": "Tirana County"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 616,
                "iso2": "TE",
                "name": "Tepelenë District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 617,
                "iso2": "GR",
                "name": "Gramsh District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 618,
                "iso2": "DL",
                "name": "Delvinë District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 619,
                "iso2": "PQ",
                "name": "Peqin District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 620,
                "iso2": "PU",
                "name": "Pukë District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 621,
                "iso2": "GJ",
                "name": "Gjirokastër District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 622,
                "iso2": "KB",
                "name": "Kurbin District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 623,
                "iso2": "KU",
                "name": "Kukës District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 624,
                "iso2": "SR",
                "name": "Sarandë District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 625,
                "iso2": "PR",
                "name": "Përmet District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 626,
                "iso2": "SH",
                "name": "Shkodër District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 627,
                "iso2": "FR",
                "name": "Fier District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 628,
                "iso2": "ER",
                "name": "Kolonjë District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 629,
                "iso2": "BR",
                "name": "Berat District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 630,
                "iso2": "6",
                "name": "Korçë County"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 631,
                "iso2": "4",
                "name": "Fier County"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 632,
                "iso2": "2",
                "name": "Durrës County"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 633,
                "iso2": "TR",
                "name": "Tirana District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 634,
                "iso2": "12",
                "name": "Vlorë County"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 635,
                "iso2": "MT",
                "name": "Mat District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 636,
                "iso2": "TP",
                "name": "Tropojë District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 637,
                "iso2": "MK",
                "name": "Mallakastër District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 638,
                "iso2": "MR",
                "name": "Mirditë District"
            },
            {
                "country_code": "AL",
                "country_id": 3,
                "id": 639,
                "iso2": "DR",
                "name": "Durrës District"
            }
        ]
    },
    {
        "id": 4,
        "name": "Algeria",
        "iso3": "DZA",
        "iso2": "DZ",
        "states": [
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1098,
                "iso2": "17",
                "name": "Djelfa Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1099,
                "iso2": "39",
                "name": "El Oued Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1100,
                "iso2": "36",
                "name": "El Tarf Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1101,
                "iso2": "31",
                "name": "Oran Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1102,
                "iso2": "45",
                "name": "Naama Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1103,
                "iso2": "23",
                "name": "Annaba Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1104,
                "iso2": "10",
                "name": "Bouïra Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1105,
                "iso2": "2",
                "name": "Chlef Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1106,
                "iso2": "14",
                "name": "Tiaret Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1107,
                "iso2": "13",
                "name": "Tlemcen Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1108,
                "iso2": "8",
                "name": "Béchar Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1109,
                "iso2": "26",
                "name": "Médéa Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1110,
                "iso2": "21",
                "name": "Skikda Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1111,
                "iso2": "9",
                "name": "Blida Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1112,
                "iso2": "33",
                "name": "Illizi Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1113,
                "iso2": "18",
                "name": "Jijel Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1114,
                "iso2": "7",
                "name": "Biskra"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1115,
                "iso2": "42",
                "name": "Tipasa Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1116,
                "iso2": "34",
                "name": "Bordj Bou Arréridj Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1117,
                "iso2": "12",
                "name": "Tébessa Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1118,
                "iso2": "1",
                "name": "Adrar Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1119,
                "iso2": "44",
                "name": "Aïn Defla Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1120,
                "iso2": "37",
                "name": "Tindouf Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1121,
                "iso2": "25",
                "name": "Constantine Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1122,
                "iso2": "46",
                "name": "Aïn Témouchent Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1123,
                "iso2": "20",
                "name": "Saïda Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1124,
                "iso2": "29",
                "name": "Mascara Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1125,
                "iso2": "35",
                "name": "Boumerdès Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1126,
                "iso2": "40",
                "name": "Khenchela Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1127,
                "iso2": "47",
                "name": "Ghardaïa Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1128,
                "iso2": "6",
                "name": "Béjaïa Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1129,
                "iso2": "32",
                "name": "El Bayadh Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1130,
                "iso2": "48",
                "name": "Relizane Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1131,
                "iso2": "15",
                "name": "Tizi Ouzou Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1132,
                "iso2": "43",
                "name": "Mila Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1133,
                "iso2": "38",
                "name": "Tissemsilt Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1134,
                "iso2": "28",
                "name": "M'Sila Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1135,
                "iso2": "11",
                "name": "Tamanghasset Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1136,
                "iso2": "4",
                "name": "Oum El Bouaghi Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1137,
                "iso2": "24",
                "name": "Guelma Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1138,
                "iso2": "3",
                "name": "Laghouat Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1139,
                "iso2": "30",
                "name": "Ouargla Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1140,
                "iso2": "27",
                "name": "Mostaganem Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1141,
                "iso2": "19",
                "name": "Sétif Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1142,
                "iso2": "5",
                "name": "Batna Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1143,
                "iso2": "41",
                "name": "Souk Ahras Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 1144,
                "iso2": "16",
                "name": "Algiers Province"
            },
            {
                "country_code": "DZ",
                "country_id": 4,
                "id": 4902,
                "iso2": "22",
                "name": "Sidi Bel Abbès"
            }
        ]
    },
    {
        "id": 5,
        "name": "American Samoa",
        "iso3": "ASM",
        "iso2": "AS",
        "states": []
    },
    {
        "id": 6,
        "name": "Andorra",
        "iso3": "AND",
        "iso2": "AD",
        "states": [
            {
                "country_code": "AD",
                "country_id": 6,
                "id": 487,
                "iso2": "3",
                "name": "Encamp"
            },
            {
                "country_code": "AD",
                "country_id": 6,
                "id": 488,
                "iso2": "7",
                "name": "Andorra la Vella"
            },
            {
                "country_code": "AD",
                "country_id": 6,
                "id": 489,
                "iso2": "2",
                "name": "Canillo"
            },
            {
                "country_code": "AD",
                "country_id": 6,
                "id": 490,
                "iso2": "6",
                "name": "Sant Julià de Lòria"
            },
            {
                "country_code": "AD",
                "country_id": 6,
                "id": 491,
                "iso2": "5",
                "name": "Ordino"
            },
            {
                "country_code": "AD",
                "country_id": 6,
                "id": 492,
                "iso2": "8",
                "name": "Escaldes-Engordany"
            },
            {
                "country_code": "AD",
                "country_id": 6,
                "id": 493,
                "iso2": "4",
                "name": "La Massana"
            }
        ]
    },
    {
        "id": 7,
        "name": "Angola",
        "iso3": "AGO",
        "iso2": "AO",
        "states": [
            {
                "country_code": "AO",
                "country_id": 7,
                "id": 212,
                "iso2": "BIE",
                "name": "Bié Province"
            },
            {
                "country_code": "AO",
                "country_id": 7,
                "id": 213,
                "iso2": "HUA",
                "name": "Huambo Province"
            },
            {
                "country_code": "AO",
                "country_id": 7,
                "id": 214,
                "iso2": "ZAI",
                "name": "Zaire Province"
            },
            {
                "country_code": "AO",
                "country_id": 7,
                "id": 215,
                "iso2": "CNN",
                "name": "Cunene Province"
            },
            {
                "country_code": "AO",
                "country_id": 7,
                "id": 216,
                "iso2": "CUS",
                "name": "Cuanza Sul"
            },
            {
                "country_code": "AO",
                "country_id": 7,
                "id": 217,
                "iso2": "CNO",
                "name": "Cuanza Norte Province"
            },
            {
                "country_code": "AO",
                "country_id": 7,
                "id": 218,
                "iso2": "BGU",
                "name": "Benguela Province"
            },
            {
                "country_code": "AO",
                "country_id": 7,
                "id": 219,
                "iso2": "MOX",
                "name": "Moxico Province"
            },
            {
                "country_code": "AO",
                "country_id": 7,
                "id": 220,
                "iso2": "LSU",
                "name": "Lunda Sul Province"
            },
            {
                "country_code": "AO",
                "country_id": 7,
                "id": 221,
                "iso2": "BGO",
                "name": "Bengo Province"
            },
            {
                "country_code": "AO",
                "country_id": 7,
                "id": 222,
                "iso2": "LUA",
                "name": "Luanda Province"
            },
            {
                "country_code": "AO",
                "country_id": 7,
                "id": 223,
                "iso2": "LNO",
                "name": "Lunda Norte Province"
            },
            {
                "country_code": "AO",
                "country_id": 7,
                "id": 224,
                "iso2": "UIG",
                "name": "Uíge Province"
            },
            {
                "country_code": "AO",
                "country_id": 7,
                "id": 225,
                "iso2": "HUI",
                "name": "Huíla Province"
            },
            {
                "country_code": "AO",
                "country_id": 7,
                "id": 226,
                "iso2": "CCU",
                "name": "Cuando Cubango Province"
            },
            {
                "country_code": "AO",
                "country_id": 7,
                "id": 227,
                "iso2": "MAL",
                "name": "Malanje Province"
            },
            {
                "country_code": "AO",
                "country_id": 7,
                "id": 228,
                "iso2": "CAB",
                "name": "Cabinda Province"
            }
        ]
    },
    {
        "id": 8,
        "name": "Anguilla",
        "iso3": "AIA",
        "iso2": "AI",
        "states": []
    },
    {
        "id": 9,
        "name": "Antarctica",
        "iso3": "ATA",
        "iso2": "AQ",
        "states": []
    },
    {
        "id": 10,
        "name": "Antigua And Barbuda",
        "iso3": "ATG",
        "iso2": "AG",
        "states": [
            {
                "country_code": "AG",
                "country_id": 10,
                "id": 3703,
                "iso2": "11",
                "name": "Redonda"
            },
            {
                "country_code": "AG",
                "country_id": 10,
                "id": 3704,
                "iso2": "7",
                "name": "Saint Peter Parish"
            },
            {
                "country_code": "AG",
                "country_id": 10,
                "id": 3705,
                "iso2": "6",
                "name": "Saint Paul Parish"
            },
            {
                "country_code": "AG",
                "country_id": 10,
                "id": 3706,
                "iso2": "4",
                "name": "Saint John Parish"
            },
            {
                "country_code": "AG",
                "country_id": 10,
                "id": 3707,
                "iso2": "5",
                "name": "Saint Mary Parish"
            },
            {
                "country_code": "AG",
                "country_id": 10,
                "id": 3708,
                "iso2": "10",
                "name": "Barbuda"
            },
            {
                "country_code": "AG",
                "country_id": 10,
                "id": 3709,
                "iso2": "3",
                "name": "Saint George Parish"
            },
            {
                "country_code": "AG",
                "country_id": 10,
                "id": 3710,
                "iso2": "8",
                "name": "Saint Philip Parish"
            }
        ]
    },
    {
        "id": 11,
        "name": "Argentina",
        "iso3": "ARG",
        "iso2": "AR",
        "states": [
            {
                "country_code": "AR",
                "country_id": 11,
                "id": 3634,
                "iso2": "J",
                "name": "San Juan Province"
            },
            {
                "country_code": "AR",
                "country_id": 11,
                "id": 3635,
                "iso2": "G",
                "name": "Santiago del Estero Province"
            },
            {
                "country_code": "AR",
                "country_id": 11,
                "id": 3636,
                "iso2": "D",
                "name": "San Luis Province"
            },
            {
                "country_code": "AR",
                "country_id": 11,
                "id": 3637,
                "iso2": "T",
                "name": "Tucumán Province"
            },
            {
                "country_code": "AR",
                "country_id": 11,
                "id": 3638,
                "iso2": "W",
                "name": "Corrientes"
            },
            {
                "country_code": "AR",
                "country_id": 11,
                "id": 3639,
                "iso2": "R",
                "name": "Río Negro Province"
            },
            {
                "country_code": "AR",
                "country_id": 11,
                "id": 3640,
                "iso2": "H",
                "name": "Chaco Province"
            },
            {
                "country_code": "AR",
                "country_id": 11,
                "id": 3641,
                "iso2": "S",
                "name": "Santa Fe Province"
            },
            {
                "country_code": "AR",
                "country_id": 11,
                "id": 3642,
                "iso2": "X",
                "name": "Córdoba Province"
            },
            {
                "country_code": "AR",
                "country_id": 11,
                "id": 3643,
                "iso2": "A",
                "name": "Salta Province"
            },
            {
                "country_code": "AR",
                "country_id": 11,
                "id": 3644,
                "iso2": "N",
                "name": "Misiones Province"
            },
            {
                "country_code": "AR",
                "country_id": 11,
                "id": 3645,
                "iso2": "Y",
                "name": "Jujuy Province"
            },
            {
                "country_code": "AR",
                "country_id": 11,
                "id": 3646,
                "iso2": "M",
                "name": "Mendoza"
            },
            {
                "country_code": "AR",
                "country_id": 11,
                "id": 3647,
                "iso2": "K",
                "name": "Catamarca Province"
            },
            {
                "country_code": "AR",
                "country_id": 11,
                "id": 3648,
                "iso2": "Q",
                "name": "Neuquén Province"
            },
            {
                "country_code": "AR",
                "country_id": 11,
                "id": 3649,
                "iso2": "Z",
                "name": "Santa Cruz Province"
            },
            {
                "country_code": "AR",
                "country_id": 11,
                "id": 3650,
                "iso2": "V",
                "name": "Tierra del Fuego Province"
            },
            {
                "country_code": "AR",
                "country_id": 11,
                "id": 3651,
                "iso2": "U",
                "name": "Chubut Province"
            },
            {
                "country_code": "AR",
                "country_id": 11,
                "id": 3652,
                "iso2": "P",
                "name": "Formosa Province"
            },
            {
                "country_code": "AR",
                "country_id": 11,
                "id": 3653,
                "iso2": "F",
                "name": "La Rioja Province"
            },
            {
                "country_code": "AR",
                "country_id": 11,
                "id": 3654,
                "iso2": "E",
                "name": "Entre Ríos Province"
            },
            {
                "country_code": "AR",
                "country_id": 11,
                "id": 3655,
                "iso2": "L",
                "name": "La Pampa"
            },
            {
                "country_code": "AR",
                "country_id": 11,
                "id": 3656,
                "iso2": "B",
                "name": "Buenos Aires Province"
            },
            {
                "country_code": "AR",
                "country_id": 11,
                "id": 4880,
                "iso2": "C",
                "name": "Autonomous City Of Buenos Aires"
            }
        ]
    },
    {
        "id": 12,
        "name": "Armenia",
        "iso3": "ARM",
        "iso2": "AM",
        "states": [
            {
                "country_code": "AM",
                "country_id": 12,
                "id": 2023,
                "iso2": "AG",
                "name": "Aragatsotn Region"
            },
            {
                "country_code": "AM",
                "country_id": 12,
                "id": 2024,
                "iso2": "AR",
                "name": "Ararat Province"
            },
            {
                "country_code": "AM",
                "country_id": 12,
                "id": 2025,
                "iso2": "VD",
                "name": "Vayots Dzor Region"
            },
            {
                "country_code": "AM",
                "country_id": 12,
                "id": 2026,
                "iso2": "AV",
                "name": "Armavir Region"
            },
            {
                "country_code": "AM",
                "country_id": 12,
                "id": 2027,
                "iso2": "SU",
                "name": "Syunik Province"
            },
            {
                "country_code": "AM",
                "country_id": 12,
                "id": 2028,
                "iso2": "GR",
                "name": "Gegharkunik Province"
            },
            {
                "country_code": "AM",
                "country_id": 12,
                "id": 2029,
                "iso2": "LO",
                "name": "Lori Region"
            },
            {
                "country_code": "AM",
                "country_id": 12,
                "id": 2030,
                "iso2": "ER",
                "name": "Yerevan"
            },
            {
                "country_code": "AM",
                "country_id": 12,
                "id": 2031,
                "iso2": "SH",
                "name": "Shirak Region"
            },
            {
                "country_code": "AM",
                "country_id": 12,
                "id": 2032,
                "iso2": "TV",
                "name": "Tavush Region"
            },
            {
                "country_code": "AM",
                "country_id": 12,
                "id": 2033,
                "iso2": "KT",
                "name": "Kotayk Region"
            }
        ]
    },
    {
        "id": 13,
        "name": "Aruba",
        "iso3": "ABW",
        "iso2": "AW",
        "states": []
    },
    {
        "id": 14,
        "name": "Australia",
        "iso3": "AUS",
        "iso2": "AU",
        "states": [
            {
                "country_code": "AU",
                "country_id": 14,
                "id": 3903,
                "iso2": "VIC",
                "name": "Victoria"
            },
            {
                "country_code": "AU",
                "country_id": 14,
                "id": 3904,
                "iso2": "SA",
                "name": "South Australia"
            },
            {
                "country_code": "AU",
                "country_id": 14,
                "id": 3905,
                "iso2": "QLD",
                "name": "Queensland"
            },
            {
                "country_code": "AU",
                "country_id": 14,
                "id": 3906,
                "iso2": "WA",
                "name": "Western Australia"
            },
            {
                "country_code": "AU",
                "country_id": 14,
                "id": 3907,
                "iso2": "ACT",
                "name": "Australian Capital Territory"
            },
            {
                "country_code": "AU",
                "country_id": 14,
                "id": 3908,
                "iso2": "TAS",
                "name": "Tasmania"
            },
            {
                "country_code": "AU",
                "country_id": 14,
                "id": 3909,
                "iso2": "NSW",
                "name": "New South Wales"
            },
            {
                "country_code": "AU",
                "country_id": 14,
                "id": 3910,
                "iso2": "NT",
                "name": "Northern Territory"
            }
        ]
    },
    {
        "id": 15,
        "name": "Austria",
        "iso3": "AUT",
        "iso2": "AT",
        "states": [
            {
                "country_code": "AT",
                "country_id": 15,
                "id": 2057,
                "iso2": "2",
                "name": "Carinthia"
            },
            {
                "country_code": "AT",
                "country_id": 15,
                "id": 2058,
                "iso2": "4",
                "name": "Upper Austria"
            },
            {
                "country_code": "AT",
                "country_id": 15,
                "id": 2059,
                "iso2": "6",
                "name": "Styria"
            },
            {
                "country_code": "AT",
                "country_id": 15,
                "id": 2060,
                "iso2": "9",
                "name": "Vienna"
            },
            {
                "country_code": "AT",
                "country_id": 15,
                "id": 2061,
                "iso2": "5",
                "name": "Salzburg"
            },
            {
                "country_code": "AT",
                "country_id": 15,
                "id": 2062,
                "iso2": "1",
                "name": "Burgenland"
            },
            {
                "country_code": "AT",
                "country_id": 15,
                "id": 2063,
                "iso2": "8",
                "name": "Vorarlberg"
            },
            {
                "country_code": "AT",
                "country_id": 15,
                "id": 2064,
                "iso2": "7",
                "name": "Tyrol"
            },
            {
                "country_code": "AT",
                "country_id": 15,
                "id": 2065,
                "iso2": "3",
                "name": "Lower Austria"
            }
        ]
    },
    {
        "id": 16,
        "name": "Azerbaijan",
        "iso3": "AZE",
        "iso2": "AZ",
        "states": [
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 518,
                "iso2": "SA",
                "name": "Shaki"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 519,
                "iso2": "TAR",
                "name": "Tartar District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 520,
                "iso2": "SR",
                "name": "Shirvan"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 521,
                "iso2": "QAZ",
                "name": "Qazakh District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 522,
                "iso2": "SAD",
                "name": "Sadarak District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 523,
                "iso2": "YEV",
                "name": "Yevlakh District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 524,
                "iso2": "XCI",
                "name": "Khojali District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 525,
                "iso2": "KAL",
                "name": "Kalbajar District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 526,
                "iso2": "QAX",
                "name": "Qakh District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 527,
                "iso2": "FUZ",
                "name": "Fizuli District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 528,
                "iso2": "AST",
                "name": "Astara District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 529,
                "iso2": "SMI",
                "name": "Shamakhi District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 530,
                "iso2": "NEF",
                "name": "Neftchala District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 531,
                "iso2": "GOY",
                "name": "Goychay"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 532,
                "iso2": "BIL",
                "name": "Bilasuvar District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 533,
                "iso2": "TOV",
                "name": "Tovuz District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 534,
                "iso2": "ORD",
                "name": "Ordubad District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 535,
                "iso2": "SAR",
                "name": "Sharur District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 536,
                "iso2": "SMX",
                "name": "Samukh District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 537,
                "iso2": "XIZ",
                "name": "Khizi District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 538,
                "iso2": "YE",
                "name": "Yevlakh"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 539,
                "iso2": "UCA",
                "name": "Ujar District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 540,
                "iso2": "ABS",
                "name": "Absheron District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 541,
                "iso2": "LAC",
                "name": "Lachin District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 542,
                "iso2": "QAB",
                "name": "Qabala District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 543,
                "iso2": "AGA",
                "name": "Agstafa District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 544,
                "iso2": "IMI",
                "name": "Imishli District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 545,
                "iso2": "SAL",
                "name": "Salyan District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 546,
                "iso2": "LER",
                "name": "Lerik District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 547,
                "iso2": "AGU",
                "name": "Agsu District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 548,
                "iso2": "QBI",
                "name": "Qubadli District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 549,
                "iso2": "KUR",
                "name": "Kurdamir District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 550,
                "iso2": "YAR",
                "name": "Yardymli District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 551,
                "iso2": "GOR",
                "name": "Goranboy District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 552,
                "iso2": "BA",
                "name": "Baku"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 553,
                "iso2": "AGS",
                "name": "Agdash District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 554,
                "iso2": "BEY",
                "name": "Beylagan District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 555,
                "iso2": "MAS",
                "name": "Masally District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 556,
                "iso2": "OGU",
                "name": "Oghuz District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 557,
                "iso2": "SAT",
                "name": "Saatly District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 558,
                "iso2": "LA",
                "name": "Lankaran District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 559,
                "iso2": "AGM",
                "name": "Agdam District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 560,
                "iso2": "BAL",
                "name": "Balakan District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 561,
                "iso2": "DAS",
                "name": "Dashkasan District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 562,
                "iso2": "NX",
                "name": "Nakhchivan Autonomous Republic"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 563,
                "iso2": "QBA",
                "name": "Quba District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 564,
                "iso2": "ISM",
                "name": "Ismailli District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 565,
                "iso2": "SAB",
                "name": "Sabirabad District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 566,
                "iso2": "ZAQ",
                "name": "Zaqatala District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 567,
                "iso2": "KAN",
                "name": "Kangarli District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 568,
                "iso2": "XVD",
                "name": "Martuni"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 569,
                "iso2": "BAR",
                "name": "Barda District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 570,
                "iso2": "CAB",
                "name": "Jabrayil District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 571,
                "iso2": "HAC",
                "name": "Hajigabul District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 572,
                "iso2": "CUL",
                "name": "Julfa District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 573,
                "iso2": "QOB",
                "name": "Gobustan District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 574,
                "iso2": "GYG",
                "name": "Goygol District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 575,
                "iso2": "BAB",
                "name": "Babek District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 576,
                "iso2": "ZAR",
                "name": "Zardab District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 577,
                "iso2": "AGC",
                "name": "Aghjabadi District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 578,
                "iso2": "CAL",
                "name": "Jalilabad District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 579,
                "iso2": "SAH",
                "name": "Shahbuz District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 580,
                "iso2": "MI",
                "name": "Mingachevir"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 581,
                "iso2": "ZAN",
                "name": "Zangilan District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 582,
                "iso2": "SM",
                "name": "Sumqayit"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 583,
                "iso2": "SKR",
                "name": "Shamkir District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 584,
                "iso2": "SIY",
                "name": "Siazan District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 585,
                "iso2": "GA",
                "name": "Ganja"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 586,
                "iso2": "SAK",
                "name": "Shaki District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 587,
                "iso2": "LAN",
                "name": "Lankaran"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 588,
                "iso2": "QUS",
                "name": "Qusar District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 589,
                "iso2": "GAD",
                "name": "Gədəbəy"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 590,
                "iso2": "XAC",
                "name": "Khachmaz District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 591,
                "iso2": "SBN",
                "name": "Shabran District"
            },
            {
                "country_code": "AZ",
                "country_id": 16,
                "id": 592,
                "iso2": "SUS",
                "name": "Shusha District"
            }
        ]
    },
    {
        "id": 17,
        "name": "Bahamas The",
        "iso3": "BHS",
        "iso2": "BS",
        "states": [
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3593,
                "iso2": "BY",
                "name": "Berry Islands"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3594,
                "iso2": "NB",
                "name": "Nichollstown and Berry Islands"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3595,
                "iso2": "GT",
                "name": "Green Turtle Cay"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3596,
                "iso2": "CE",
                "name": "Central Eleuthera"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3597,
                "iso2": "GH",
                "name": "Governor's Harbour"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3598,
                "iso2": "HR",
                "name": "High Rock"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3599,
                "iso2": "WG",
                "name": "West Grand Bahama"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3600,
                "iso2": "RC",
                "name": "Rum Cay District"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3601,
                "iso2": "AK",
                "name": "Acklins"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3602,
                "iso2": "NE",
                "name": "North Eleuthera"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3603,
                "iso2": "CO",
                "name": "Central Abaco"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3604,
                "iso2": "MH",
                "name": "Marsh Harbour"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3605,
                "iso2": "BP",
                "name": "Black Point"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3606,
                "iso2": "SP",
                "name": "Sandy Point"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3607,
                "iso2": "SE",
                "name": "South Eleuthera"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3608,
                "iso2": "SO",
                "name": "South Abaco"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3609,
                "iso2": "IN",
                "name": "Inagua"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3610,
                "iso2": "LI",
                "name": "Long Island"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3611,
                "iso2": "CI",
                "name": "Cat Island"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3612,
                "iso2": "EX",
                "name": "Exuma"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3613,
                "iso2": "HI",
                "name": "Harbour Island"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3614,
                "iso2": "EG",
                "name": "East Grand Bahama"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3615,
                "iso2": "RI",
                "name": "Ragged Island"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3616,
                "iso2": "NO",
                "name": "North Abaco"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3617,
                "iso2": "NS",
                "name": "North Andros"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3618,
                "iso2": "KB",
                "name": "Kemps Bay"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3619,
                "iso2": "FC",
                "name": "Fresh Creek"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3620,
                "iso2": "SR",
                "name": "San Salvador and Rum Cay"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3621,
                "iso2": "CK",
                "name": "Crooked Island"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3622,
                "iso2": "SA",
                "name": "South Andros"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3623,
                "iso2": "RS",
                "name": "Rock Sound"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3624,
                "iso2": "HT",
                "name": "Hope Town"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3625,
                "iso2": "MC",
                "name": "Mangrove Cay"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3626,
                "iso2": "FP",
                "name": "Freeport"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3627,
                "iso2": "SS",
                "name": "San Salvador Island"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3628,
                "iso2": "AC",
                "name": "Acklins and Crooked Islands"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3629,
                "iso2": "BI",
                "name": "Bimini"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3630,
                "iso2": "SW",
                "name": "Spanish Wells"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3631,
                "iso2": "CS",
                "name": "Central Andros"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3632,
                "iso2": "GC",
                "name": "Grand Cay"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 3633,
                "iso2": "MG",
                "name": "Mayaguana District"
            },
            {
                "country_code": "BS",
                "country_id": 17,
                "id": 4881,
                "iso2": "NP",
                "name": "New Providence"
            }
        ]
    },
    {
        "id": 18,
        "name": "Bahrain",
        "iso3": "BHR",
        "iso2": "BH",
        "states": [
            {
                "country_code": "BH",
                "country_id": 18,
                "id": 1992,
                "iso2": "13",
                "name": "Capital Governorate"
            },
            {
                "country_code": "BH",
                "country_id": 18,
                "id": 1993,
                "iso2": "14",
                "name": "Southern Governorate"
            },
            {
                "country_code": "BH",
                "country_id": 18,
                "id": 1994,
                "iso2": "17",
                "name": "Northern Governorate"
            },
            {
                "country_code": "BH",
                "country_id": 18,
                "id": 1995,
                "iso2": "15",
                "name": "Muharraq Governorate"
            },
            {
                "country_code": "BH",
                "country_id": 18,
                "id": 1996,
                "iso2": "16",
                "name": "Central Governorate"
            }
        ]
    },
    {
        "id": 19,
        "name": "Bangladesh",
        "iso3": "BGD",
        "iso2": "BD",
        "states": [
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 750,
                "iso2": "F",
                "name": "Rangpur Division"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 751,
                "iso2": "11",
                "name": "Cox's Bazar District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 752,
                "iso2": "1",
                "name": "Bandarban District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 753,
                "iso2": "E",
                "name": "Rajshahi Division"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 754,
                "iso2": "49",
                "name": "Pabna District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 755,
                "iso2": "57",
                "name": "Sherpur District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 756,
                "iso2": "7",
                "name": "Bhola District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 757,
                "iso2": "22",
                "name": "Jessore District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 758,
                "iso2": "H",
                "name": "Mymensingh Division"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 759,
                "iso2": "55",
                "name": "Rangpur District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 760,
                "iso2": "C",
                "name": "Dhaka Division"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 761,
                "iso2": "45",
                "name": "Chapai Nawabganj District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 762,
                "iso2": "15",
                "name": "Faridpur District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 763,
                "iso2": "8",
                "name": "Comilla District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 764,
                "iso2": "41",
                "name": "Netrokona District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 765,
                "iso2": "G",
                "name": "Sylhet Division"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 766,
                "iso2": "34",
                "name": "Mymensingh District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 767,
                "iso2": "60",
                "name": "Sylhet District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 768,
                "iso2": "9",
                "name": "Chandpur District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 769,
                "iso2": "43",
                "name": "Narail District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 770,
                "iso2": "40",
                "name": "Narayanganj District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 771,
                "iso2": "13",
                "name": "Dhaka District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 772,
                "iso2": "46",
                "name": "Nilphamari District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 773,
                "iso2": "53",
                "name": "Rajbari District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 774,
                "iso2": "30",
                "name": "Kushtia District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 775,
                "iso2": "D",
                "name": "Khulna Division"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 776,
                "iso2": "39",
                "name": "Meherpur District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 777,
                "iso2": "51",
                "name": "Patuakhali District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 778,
                "iso2": "25",
                "name": "Jhalokati District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 779,
                "iso2": "26",
                "name": "Kishoreganj District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 780,
                "iso2": "32",
                "name": "Lalmonirhat District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 781,
                "iso2": "59",
                "name": "Sirajganj District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 782,
                "iso2": "63",
                "name": "Tangail District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 783,
                "iso2": "14",
                "name": "Dinajpur District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 784,
                "iso2": "2",
                "name": "Barguna District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 785,
                "iso2": "10",
                "name": "Chittagong District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 786,
                "iso2": "29",
                "name": "Khagrachari District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 787,
                "iso2": "44",
                "name": "Natore District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 788,
                "iso2": "12",
                "name": "Chuadanga District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 789,
                "iso2": "23",
                "name": "Jhenaidah District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 790,
                "iso2": "35",
                "name": "Munshiganj District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 791,
                "iso2": "50",
                "name": "Pirojpur District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 792,
                "iso2": "17",
                "name": "Gopalganj District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 793,
                "iso2": "28",
                "name": "Kurigram District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 794,
                "iso2": "38",
                "name": "Moulvibazar District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 795,
                "iso2": "19",
                "name": "Gaibandha District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 796,
                "iso2": "5",
                "name": "Bagerhat District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 797,
                "iso2": "3",
                "name": "Bogra District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 798,
                "iso2": "18",
                "name": "Gazipur District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 799,
                "iso2": "58",
                "name": "Satkhira District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 800,
                "iso2": "52",
                "name": "Panchagarh District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 801,
                "iso2": "62",
                "name": "Shariatpur District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 802,
                "iso2": "33",
                "name": "Bahadia"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 803,
                "iso2": "B",
                "name": "Chittagong Division"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 804,
                "iso2": "64",
                "name": "Thakurgaon District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 805,
                "iso2": "20",
                "name": "Habiganj District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 806,
                "iso2": "24",
                "name": "Joypurhat District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 807,
                "iso2": "A",
                "name": "Barisal Division"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 808,
                "iso2": "21",
                "name": "Jamalpur District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 809,
                "iso2": "56",
                "name": "Rangamati Hill District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 810,
                "iso2": "4",
                "name": "Brahmanbaria District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 811,
                "iso2": "27",
                "name": "Khulna District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 812,
                "iso2": "61",
                "name": "Sunamganj District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 813,
                "iso2": "54",
                "name": "Rajshahi District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 814,
                "iso2": "48",
                "name": "Naogaon District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 815,
                "iso2": "47",
                "name": "Noakhali District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 816,
                "iso2": "16",
                "name": "Feni District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 817,
                "iso2": "36",
                "name": "Madaripur District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 818,
                "iso2": "6",
                "name": "Barisal District"
            },
            {
                "country_code": "BD",
                "country_id": 19,
                "id": 819,
                "iso2": "31",
                "name": "Lakshmipur District"
            }
        ]
    },
    {
        "id": 20,
        "name": "Barbados",
        "iso3": "BRB",
        "iso2": "BB",
        "states": [
            {
                "country_code": "BB",
                "country_id": 20,
                "id": 1220,
                "iso2": "10",
                "name": "Saint Philip"
            },
            {
                "country_code": "BB",
                "country_id": 20,
                "id": 1221,
                "iso2": "7",
                "name": "Saint Lucy"
            },
            {
                "country_code": "BB",
                "country_id": 20,
                "id": 1222,
                "iso2": "9",
                "name": "Saint Peter"
            },
            {
                "country_code": "BB",
                "country_id": 20,
                "id": 1223,
                "iso2": "6",
                "name": "Saint Joseph"
            },
            {
                "country_code": "BB",
                "country_id": 20,
                "id": 1224,
                "iso2": "4",
                "name": "Saint James"
            },
            {
                "country_code": "BB",
                "country_id": 20,
                "id": 1225,
                "iso2": "11",
                "name": "Saint Thomas"
            },
            {
                "country_code": "BB",
                "country_id": 20,
                "id": 1226,
                "iso2": "3",
                "name": "Saint George"
            },
            {
                "country_code": "BB",
                "country_id": 20,
                "id": 1227,
                "iso2": "5",
                "name": "Saint John"
            },
            {
                "country_code": "BB",
                "country_id": 20,
                "id": 1228,
                "iso2": "1",
                "name": "Christ Church"
            },
            {
                "country_code": "BB",
                "country_id": 20,
                "id": 1229,
                "iso2": "2",
                "name": "Saint Andrew"
            },
            {
                "country_code": "BB",
                "country_id": 20,
                "id": 1230,
                "iso2": "8",
                "name": "Saint Michael"
            }
        ]
    },
    {
        "id": 21,
        "name": "Belarus",
        "iso3": "BLR",
        "iso2": "BY",
        "states": [
            {
                "country_code": "BY",
                "country_id": 21,
                "id": 2954,
                "iso2": "MA",
                "name": "Mogilev Region"
            },
            {
                "country_code": "BY",
                "country_id": 21,
                "id": 2955,
                "iso2": "HO",
                "name": "Gomel Region"
            },
            {
                "country_code": "BY",
                "country_id": 21,
                "id": 2956,
                "iso2": "HR",
                "name": "Grodno Region"
            },
            {
                "country_code": "BY",
                "country_id": 21,
                "id": 2957,
                "iso2": "MI",
                "name": "Minsk Region"
            },
            {
                "country_code": "BY",
                "country_id": 21,
                "id": 2958,
                "iso2": "HM",
                "name": "Minsk"
            },
            {
                "country_code": "BY",
                "country_id": 21,
                "id": 2959,
                "iso2": "BR",
                "name": "Brest Region"
            },
            {
                "country_code": "BY",
                "country_id": 21,
                "id": 2960,
                "iso2": "VI",
                "name": "Vitebsk Region"
            }
        ]
    },
    {
        "id": 22,
        "name": "Belgium",
        "iso3": "BEL",
        "iso2": "BE",
        "states": [
            {
                "country_code": "BE",
                "country_id": 22,
                "id": 1372,
                "iso2": "VLI",
                "name": "Limburg"
            },
            {
                "country_code": "BE",
                "country_id": 22,
                "id": 1373,
                "iso2": "VLG",
                "name": "Flanders"
            },
            {
                "country_code": "BE",
                "country_id": 22,
                "id": 1374,
                "iso2": "VBR",
                "name": "Flemish Brabant"
            },
            {
                "country_code": "BE",
                "country_id": 22,
                "id": 1375,
                "iso2": "WHT",
                "name": "Hainaut"
            },
            {
                "country_code": "BE",
                "country_id": 22,
                "id": 1376,
                "iso2": "BRU",
                "name": "Brussels-Capital Region"
            },
            {
                "country_code": "BE",
                "country_id": 22,
                "id": 1377,
                "iso2": "VOV",
                "name": "East Flanders"
            },
            {
                "country_code": "BE",
                "country_id": 22,
                "id": 1378,
                "iso2": "WNA",
                "name": "Namur"
            },
            {
                "country_code": "BE",
                "country_id": 22,
                "id": 1379,
                "iso2": "WLX",
                "name": "Luxembourg"
            },
            {
                "country_code": "BE",
                "country_id": 22,
                "id": 1380,
                "iso2": "WAL",
                "name": "Wallonia"
            },
            {
                "country_code": "BE",
                "country_id": 22,
                "id": 1381,
                "iso2": "VAN",
                "name": "Antwerp"
            },
            {
                "country_code": "BE",
                "country_id": 22,
                "id": 1382,
                "iso2": "WBR",
                "name": "Walloon Brabant"
            },
            {
                "country_code": "BE",
                "country_id": 22,
                "id": 1383,
                "iso2": "VWV",
                "name": "West Flanders"
            },
            {
                "country_code": "BE",
                "country_id": 22,
                "id": 1384,
                "iso2": "WLG",
                "name": "Liège"
            }
        ]
    },
    {
        "id": 23,
        "name": "Belize",
        "iso3": "BLZ",
        "iso2": "BZ",
        "states": [
            {
                "country_code": "BZ",
                "country_id": 23,
                "id": 264,
                "iso2": "BZ",
                "name": "Belize District"
            },
            {
                "country_code": "BZ",
                "country_id": 23,
                "id": 265,
                "iso2": "SC",
                "name": "Stann Creek District"
            },
            {
                "country_code": "BZ",
                "country_id": 23,
                "id": 266,
                "iso2": "CZL",
                "name": "Corozal District"
            },
            {
                "country_code": "BZ",
                "country_id": 23,
                "id": 267,
                "iso2": "TOL",
                "name": "Toledo District"
            },
            {
                "country_code": "BZ",
                "country_id": 23,
                "id": 268,
                "iso2": "OW",
                "name": "Orange Walk District"
            },
            {
                "country_code": "BZ",
                "country_id": 23,
                "id": 269,
                "iso2": "CY",
                "name": "Cayo District"
            }
        ]
    },
    {
        "id": 24,
        "name": "Benin",
        "iso3": "BEN",
        "iso2": "BJ",
        "states": [
            {
                "country_code": "BJ",
                "country_id": 24,
                "id": 3070,
                "iso2": "CO",
                "name": "Collines Department"
            },
            {
                "country_code": "BJ",
                "country_id": 24,
                "id": 3071,
                "iso2": "KO",
                "name": "Kouffo Department"
            },
            {
                "country_code": "BJ",
                "country_id": 24,
                "id": 3072,
                "iso2": "DO",
                "name": "Donga Department"
            },
            {
                "country_code": "BJ",
                "country_id": 24,
                "id": 3073,
                "iso2": "ZO",
                "name": "Zou Department"
            },
            {
                "country_code": "BJ",
                "country_id": 24,
                "id": 3074,
                "iso2": "PL",
                "name": "Plateau Department"
            },
            {
                "country_code": "BJ",
                "country_id": 24,
                "id": 3075,
                "iso2": "MO",
                "name": "Mono Department"
            },
            {
                "country_code": "BJ",
                "country_id": 24,
                "id": 3076,
                "iso2": "AK",
                "name": "Atakora Department"
            },
            {
                "country_code": "BJ",
                "country_id": 24,
                "id": 3077,
                "iso2": "AL",
                "name": "Alibori Department"
            },
            {
                "country_code": "BJ",
                "country_id": 24,
                "id": 3078,
                "iso2": "BO",
                "name": "Borgou Department"
            },
            {
                "country_code": "BJ",
                "country_id": 24,
                "id": 3079,
                "iso2": "AQ",
                "name": "Atlantique Department"
            },
            {
                "country_code": "BJ",
                "country_id": 24,
                "id": 3080,
                "iso2": "OU",
                "name": "Ouémé Department"
            },
            {
                "country_code": "BJ",
                "country_id": 24,
                "id": 3081,
                "iso2": "LI",
                "name": "Littoral Department"
            }
        ]
    },
    {
        "id": 25,
        "name": "Bermuda",
        "iso3": "BMU",
        "iso2": "BM",
        "states": [
            {
                "country_code": "BM",
                "country_id": 25,
                "id": 4860,
                "iso2": "DEV",
                "name": "Devonshire Parish"
            },
            {
                "country_code": "BM",
                "country_id": 25,
                "id": 4861,
                "iso2": "HA",
                "name": "Hamilton Parish"
            },
            {
                "country_code": "BM",
                "country_id": 25,
                "id": 4862,
                "iso2": "HAM",
                "name": "Hamilton Municipality"
            },
            {
                "country_code": "BM",
                "country_id": 25,
                "id": 4863,
                "iso2": "PAG",
                "name": "Paget Parish"
            },
            {
                "country_code": "BM",
                "country_id": 25,
                "id": 4864,
                "iso2": "PEM",
                "name": "Pembroke Parish"
            },
            {
                "country_code": "BM",
                "country_id": 25,
                "id": 4865,
                "iso2": "SG",
                "name": "Saint George's Municipality"
            },
            {
                "country_code": "BM",
                "country_id": 25,
                "id": 4866,
                "iso2": "SGE",
                "name": "Saint George's Parish"
            },
            {
                "country_code": "BM",
                "country_id": 25,
                "id": 4867,
                "iso2": "SAN",
                "name": "Sandys Parish"
            },
            {
                "country_code": "BM",
                "country_id": 25,
                "id": 4868,
                "iso2": "SMI",
                "name": "Smith's Parish,"
            },
            {
                "country_code": "BM",
                "country_id": 25,
                "id": 4869,
                "iso2": "SOU",
                "name": "Southampton Parish"
            },
            {
                "country_code": "BM",
                "country_id": 25,
                "id": 4870,
                "iso2": "WAR",
                "name": "Warwick Parish"
            }
        ]
    },
    {
        "id": 26,
        "name": "Bhutan",
        "iso3": "BTN",
        "iso2": "BT",
        "states": [
            {
                "country_code": "BT",
                "country_id": 26,
                "id": 229,
                "iso2": "GA",
                "name": "Gasa District"
            },
            {
                "country_code": "BT",
                "country_id": 26,
                "id": 230,
                "iso2": "21",
                "name": "Tsirang District"
            },
            {
                "country_code": "BT",
                "country_id": 26,
                "id": 231,
                "iso2": "24",
                "name": "Wangdue Phodrang District"
            },
            {
                "country_code": "BT",
                "country_id": 26,
                "id": 232,
                "iso2": "13",
                "name": "Haa District"
            },
            {
                "country_code": "BT",
                "country_id": 26,
                "id": 233,
                "iso2": "34",
                "name": "Zhemgang District"
            },
            {
                "country_code": "BT",
                "country_id": 26,
                "id": 234,
                "iso2": "44",
                "name": "Lhuntse District"
            },
            {
                "country_code": "BT",
                "country_id": 26,
                "id": 235,
                "iso2": "23",
                "name": "Punakha District"
            },
            {
                "country_code": "BT",
                "country_id": 26,
                "id": 236,
                "iso2": "41",
                "name": "Trashigang District"
            },
            {
                "country_code": "BT",
                "country_id": 26,
                "id": 237,
                "iso2": "11",
                "name": "Paro District"
            },
            {
                "country_code": "BT",
                "country_id": 26,
                "id": 238,
                "iso2": "22",
                "name": "Dagana District"
            },
            {
                "country_code": "BT",
                "country_id": 26,
                "id": 239,
                "iso2": "12",
                "name": "Chukha District"
            },
            {
                "country_code": "BT",
                "country_id": 26,
                "id": 240,
                "iso2": "33",
                "name": "Bumthang District"
            },
            {
                "country_code": "BT",
                "country_id": 26,
                "id": 241,
                "iso2": "15",
                "name": "Thimphu District"
            },
            {
                "country_code": "BT",
                "country_id": 26,
                "id": 242,
                "iso2": "42",
                "name": "Mongar District"
            },
            {
                "country_code": "BT",
                "country_id": 26,
                "id": 243,
                "iso2": "45",
                "name": "Samdrup Jongkhar District"
            },
            {
                "country_code": "BT",
                "country_id": 26,
                "id": 244,
                "iso2": "43",
                "name": "Pemagatshel District"
            },
            {
                "country_code": "BT",
                "country_id": 26,
                "id": 245,
                "iso2": "32",
                "name": "Trongsa District"
            },
            {
                "country_code": "BT",
                "country_id": 26,
                "id": 246,
                "iso2": "14",
                "name": "Samtse District"
            },
            {
                "country_code": "BT",
                "country_id": 26,
                "id": 247,
                "iso2": "31",
                "name": "Sarpang District"
            }
        ]
    },
    {
        "id": 27,
        "name": "Bolivia",
        "iso3": "BOL",
        "iso2": "BO",
        "states": [
            {
                "country_code": "BO",
                "country_id": 27,
                "id": 3375,
                "iso2": "B",
                "name": "Beni Department"
            },
            {
                "country_code": "BO",
                "country_id": 27,
                "id": 3376,
                "iso2": "O",
                "name": "Oruro Department"
            },
            {
                "country_code": "BO",
                "country_id": 27,
                "id": 3377,
                "iso2": "S",
                "name": "Santa Cruz Department"
            },
            {
                "country_code": "BO",
                "country_id": 27,
                "id": 3378,
                "iso2": "T",
                "name": "Tarija Department"
            },
            {
                "country_code": "BO",
                "country_id": 27,
                "id": 3379,
                "iso2": "N",
                "name": "Pando Department"
            },
            {
                "country_code": "BO",
                "country_id": 27,
                "id": 3380,
                "iso2": "L",
                "name": "La Paz Department"
            },
            {
                "country_code": "BO",
                "country_id": 27,
                "id": 3381,
                "iso2": "C",
                "name": "Cochabamba Department"
            },
            {
                "country_code": "BO",
                "country_id": 27,
                "id": 3382,
                "iso2": "H",
                "name": "Chuquisaca Department"
            },
            {
                "country_code": "BO",
                "country_id": 27,
                "id": 3383,
                "iso2": "P",
                "name": "Potosí Department"
            }
        ]
    },
    {
        "id": 28,
        "name": "Bosnia and Herzegovina",
        "iso3": "BIH",
        "iso2": "BA",
        "states": [
            {
                "country_code": "BA",
                "country_id": 28,
                "id": 460,
                "iso2": "BRC",
                "name": "Brčko District"
            },
            {
                "country_code": "BA",
                "country_id": 28,
                "id": 461,
                "iso2": "3",
                "name": "Tuzla Canton"
            },
            {
                "country_code": "BA",
                "country_id": 28,
                "id": 462,
                "iso2": "6",
                "name": "Central Bosnia Canton"
            },
            {
                "country_code": "BA",
                "country_id": 28,
                "id": 463,
                "iso2": "7",
                "name": "Herzegovina-Neretva Canton"
            },
            {
                "country_code": "BA",
                "country_id": 28,
                "id": 464,
                "iso2": "2",
                "name": "Posavina Canton"
            },
            {
                "country_code": "BA",
                "country_id": 28,
                "id": 465,
                "iso2": "1",
                "name": "Una-Sana Canton"
            },
            {
                "country_code": "BA",
                "country_id": 28,
                "id": 466,
                "iso2": "9",
                "name": "Sarajevo Canton"
            },
            {
                "country_code": "BA",
                "country_id": 28,
                "id": 467,
                "iso2": "BIH",
                "name": "Federation of Bosnia and Herzegovina"
            },
            {
                "country_code": "BA",
                "country_id": 28,
                "id": 468,
                "iso2": "4",
                "name": "Zenica-Doboj Canton"
            },
            {
                "country_code": "BA",
                "country_id": 28,
                "id": 469,
                "iso2": "8",
                "name": "West Herzegovina Canton"
            },
            {
                "country_code": "BA",
                "country_id": 28,
                "id": 470,
                "iso2": "SRP",
                "name": "Republika Srpska"
            },
            {
                "country_code": "BA",
                "country_id": 28,
                "id": 471,
                "iso2": "10",
                "name": "Canton 10"
            },
            {
                "country_code": "BA",
                "country_id": 28,
                "id": 472,
                "iso2": "5",
                "name": "Bosnian Podrinje Canton"
            }
        ]
    },
    {
        "id": 29,
        "name": "Botswana",
        "iso3": "BWA",
        "iso2": "BW",
        "states": [
            {
                "country_code": "BW",
                "country_id": 29,
                "id": 3060,
                "iso2": "NG",
                "name": "Ngamiland"
            },
            {
                "country_code": "BW",
                "country_id": 29,
                "id": 3061,
                "iso2": "GH",
                "name": "Ghanzi District"
            },
            {
                "country_code": "BW",
                "country_id": 29,
                "id": 3062,
                "iso2": "KL",
                "name": "Kgatleng District"
            },
            {
                "country_code": "BW",
                "country_id": 29,
                "id": 3063,
                "iso2": "SO",
                "name": "Southern District"
            },
            {
                "country_code": "BW",
                "country_id": 29,
                "id": 3064,
                "iso2": "SE",
                "name": "South-East District"
            },
            {
                "country_code": "BW",
                "country_id": 29,
                "id": 3065,
                "iso2": "NW",
                "name": "North-West District"
            },
            {
                "country_code": "BW",
                "country_id": 29,
                "id": 3066,
                "iso2": "KG",
                "name": "Kgalagadi District"
            },
            {
                "country_code": "BW",
                "country_id": 29,
                "id": 3067,
                "iso2": "CE",
                "name": "Central District"
            },
            {
                "country_code": "BW",
                "country_id": 29,
                "id": 3068,
                "iso2": "NE",
                "name": "North-East District"
            },
            {
                "country_code": "BW",
                "country_id": 29,
                "id": 3069,
                "iso2": "KW",
                "name": "Kweneng District"
            }
        ]
    },
    {
        "id": 30,
        "name": "Bouvet Island",
        "iso3": "BVT",
        "iso2": "BV",
        "states": []
    },
    {
        "id": 31,
        "name": "Brazil",
        "iso3": "BRA",
        "iso2": "BR",
        "states": [
            {
                "country_code": "BR",
                "country_id": 31,
                "id": 1997,
                "iso2": "RJ",
                "name": "Rio de Janeiro"
            },
            {
                "country_code": "BR",
                "country_id": 31,
                "id": 1998,
                "iso2": "MG",
                "name": "Minas Gerais"
            },
            {
                "country_code": "BR",
                "country_id": 31,
                "id": 1999,
                "iso2": "AP",
                "name": "Amapá"
            },
            {
                "country_code": "BR",
                "country_id": 31,
                "id": 2000,
                "iso2": "GO",
                "name": "Goiás"
            },
            {
                "country_code": "BR",
                "country_id": 31,
                "id": 2001,
                "iso2": "RS",
                "name": "Rio Grande do Sul"
            },
            {
                "country_code": "BR",
                "country_id": 31,
                "id": 2002,
                "iso2": "BA",
                "name": "Bahia"
            },
            {
                "country_code": "BR",
                "country_id": 31,
                "id": 2003,
                "iso2": "SE",
                "name": "Sergipe"
            },
            {
                "country_code": "BR",
                "country_id": 31,
                "id": 2004,
                "iso2": "AM",
                "name": "Amazonas"
            },
            {
                "country_code": "BR",
                "country_id": 31,
                "id": 2005,
                "iso2": "PB",
                "name": "Paraíba"
            },
            {
                "country_code": "BR",
                "country_id": 31,
                "id": 2006,
                "iso2": "PE",
                "name": "Pernambuco"
            },
            {
                "country_code": "BR",
                "country_id": 31,
                "id": 2007,
                "iso2": "AL",
                "name": "Alagoas"
            },
            {
                "country_code": "BR",
                "country_id": 31,
                "id": 2008,
                "iso2": "PI",
                "name": "Piauí"
            },
            {
                "country_code": "BR",
                "country_id": 31,
                "id": 2009,
                "iso2": "PA",
                "name": "Pará"
            },
            {
                "country_code": "BR",
                "country_id": 31,
                "id": 2010,
                "iso2": "MS",
                "name": "Mato Grosso do Sul"
            },
            {
                "country_code": "BR",
                "country_id": 31,
                "id": 2011,
                "iso2": "MT",
                "name": "Mato Grosso"
            },
            {
                "country_code": "BR",
                "country_id": 31,
                "id": 2012,
                "iso2": "AC",
                "name": "Acre"
            },
            {
                "country_code": "BR",
                "country_id": 31,
                "id": 2013,
                "iso2": "RO",
                "name": "Rondônia"
            },
            {
                "country_code": "BR",
                "country_id": 31,
                "id": 2014,
                "iso2": "SC",
                "name": "Santa Catarina"
            },
            {
                "country_code": "BR",
                "country_id": 31,
                "id": 2015,
                "iso2": "MA",
                "name": "Maranhão"
            },
            {
                "country_code": "BR",
                "country_id": 31,
                "id": 2016,
                "iso2": "CE",
                "name": "Ceará"
            },
            {
                "country_code": "BR",
                "country_id": 31,
                "id": 2017,
                "iso2": "DF",
                "name": "Federal District"
            },
            {
                "country_code": "BR",
                "country_id": 31,
                "id": 2018,
                "iso2": "ES",
                "name": "Espírito Santo"
            },
            {
                "country_code": "BR",
                "country_id": 31,
                "id": 2019,
                "iso2": "RN",
                "name": "Rio Grande do Norte"
            },
            {
                "country_code": "BR",
                "country_id": 31,
                "id": 2020,
                "iso2": "TO",
                "name": "Tocantins"
            },
            {
                "country_code": "BR",
                "country_id": 31,
                "id": 2021,
                "iso2": "SP",
                "name": "São Paulo"
            },
            {
                "country_code": "BR",
                "country_id": 31,
                "id": 2022,
                "iso2": "PR",
                "name": "Paraná"
            },
            {
                "country_code": "BR",
                "country_id": 31,
                "id": 4858,
                "iso2": "RR",
                "name": "Roraima"
            }
        ]
    },
    {
        "id": 32,
        "name": "British Indian Ocean Territory",
        "iso3": "IOT",
        "iso2": "IO",
        "states": []
    },
    {
        "id": 33,
        "name": "Brunei",
        "iso3": "BRN",
        "iso2": "BN",
        "states": [
            {
                "country_code": "BN",
                "country_id": 33,
                "id": 1216,
                "iso2": "BM",
                "name": "Brunei-Muara District"
            },
            {
                "country_code": "BN",
                "country_id": 33,
                "id": 1217,
                "iso2": "BE",
                "name": "Belait District"
            },
            {
                "country_code": "BN",
                "country_id": 33,
                "id": 1218,
                "iso2": "TE",
                "name": "Temburong District"
            },
            {
                "country_code": "BN",
                "country_id": 33,
                "id": 1219,
                "iso2": "TU",
                "name": "Tutong District"
            }
        ]
    },
    {
        "id": 34,
        "name": "Bulgaria",
        "iso3": "BGR",
        "iso2": "BG",
        "states": [
            {
                "country_code": "BG",
                "country_id": 34,
                "id": 4693,
                "iso2": "7",
                "name": "Gabrovo Province"
            },
            {
                "country_code": "BG",
                "country_id": 34,
                "id": 4694,
                "iso2": "21",
                "name": "Smolyan Province"
            },
            {
                "country_code": "BG",
                "country_id": 34,
                "id": 4695,
                "iso2": "14",
                "name": "Pernik Province"
            },
            {
                "country_code": "BG",
                "country_id": 34,
                "id": 4696,
                "iso2": "12",
                "name": "Montana Province"
            },
            {
                "country_code": "BG",
                "country_id": 34,
                "id": 4697,
                "iso2": "5",
                "name": "Vidin Province"
            },
            {
                "country_code": "BG",
                "country_id": 34,
                "id": 4698,
                "iso2": "17",
                "name": "Razgrad Province"
            },
            {
                "country_code": "BG",
                "country_id": 34,
                "id": 4699,
                "iso2": "1",
                "name": "Blagoevgrad Province"
            },
            {
                "country_code": "BG",
                "country_id": 34,
                "id": 4700,
                "iso2": "20",
                "name": "Sliven Province"
            },
            {
                "country_code": "BG",
                "country_id": 34,
                "id": 4701,
                "iso2": "16",
                "name": "Plovdiv Province"
            },
            {
                "country_code": "BG",
                "country_id": 34,
                "id": 4702,
                "iso2": "9",
                "name": "Kardzhali Province"
            },
            {
                "country_code": "BG",
                "country_id": 34,
                "id": 4703,
                "iso2": "10",
                "name": "Kyustendil Province"
            },
            {
                "country_code": "BG",
                "country_id": 34,
                "id": 4704,
                "iso2": "26",
                "name": "Haskovo Province"
            },
            {
                "country_code": "BG",
                "country_id": 34,
                "id": 4705,
                "iso2": "22",
                "name": "Sofia City Province"
            },
            {
                "country_code": "BG",
                "country_id": 34,
                "id": 4706,
                "iso2": "15",
                "name": "Pleven Province"
            },
            {
                "country_code": "BG",
                "country_id": 34,
                "id": 4707,
                "iso2": "24",
                "name": "Stara Zagora Province"
            },
            {
                "country_code": "BG",
                "country_id": 34,
                "id": 4708,
                "iso2": "19",
                "name": "Silistra Province"
            },
            {
                "country_code": "BG",
                "country_id": 34,
                "id": 4709,
                "iso2": "4",
                "name": "Veliko Tarnovo Province"
            },
            {
                "country_code": "BG",
                "country_id": 34,
                "id": 4710,
                "iso2": "11",
                "name": "Lovech Province"
            },
            {
                "country_code": "BG",
                "country_id": 34,
                "id": 4711,
                "iso2": "6",
                "name": "Vratsa Province"
            },
            {
                "country_code": "BG",
                "country_id": 34,
                "id": 4712,
                "iso2": "13",
                "name": "Pazardzhik Province"
            },
            {
                "country_code": "BG",
                "country_id": 34,
                "id": 4713,
                "iso2": "18",
                "name": "Ruse Province"
            },
            {
                "country_code": "BG",
                "country_id": 34,
                "id": 4714,
                "iso2": "25",
                "name": "Targovishte Province"
            },
            {
                "country_code": "BG",
                "country_id": 34,
                "id": 4715,
                "iso2": "2",
                "name": "Burgas Province"
            },
            {
                "country_code": "BG",
                "country_id": 34,
                "id": 4716,
                "iso2": "28",
                "name": "Yambol Province"
            },
            {
                "country_code": "BG",
                "country_id": 34,
                "id": 4717,
                "iso2": "3",
                "name": "Varna Province"
            },
            {
                "country_code": "BG",
                "country_id": 34,
                "id": 4718,
                "iso2": "8",
                "name": "Dobrich Province"
            },
            {
                "country_code": "BG",
                "country_id": 34,
                "id": 4719,
                "iso2": "23",
                "name": "Sofia Province"
            },
            {
                "country_code": "BG",
                "country_id": 34,
                "id": 4882,
                "iso2": "27",
                "name": "Shumen"
            }
        ]
    },
    {
        "id": 35,
        "name": "Burkina Faso",
        "iso3": "BFA",
        "iso2": "BF",
        "states": [
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3112,
                "iso2": "KEN",
                "name": "Kénédougou Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3113,
                "iso2": "NAM",
                "name": "Namentenga Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3114,
                "iso2": "12",
                "name": "Sahel Region"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3115,
                "iso2": "6",
                "name": "Centre-Ouest Region"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3116,
                "iso2": "NAO",
                "name": "Nahouri Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3117,
                "iso2": "PAS",
                "name": "Passoré Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3118,
                "iso2": "ZOU",
                "name": "Zoundwéogo Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3119,
                "iso2": "SIS",
                "name": "Sissili Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3120,
                "iso2": "BAN",
                "name": "Banwa Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3121,
                "iso2": "BGR",
                "name": "Bougouriba Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3122,
                "iso2": "GNA",
                "name": "Gnagna Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3123,
                "iso2": "MOU",
                "name": "Mouhoun"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3124,
                "iso2": "YAG",
                "name": "Yagha Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3125,
                "iso2": "11",
                "name": "Plateau-Central Region"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3126,
                "iso2": "SMT",
                "name": "Sanmatenga Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3127,
                "iso2": "5",
                "name": "Centre-Nord Region"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3128,
                "iso2": "TAP",
                "name": "Tapoa Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3129,
                "iso2": "HOU",
                "name": "Houet Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3130,
                "iso2": "ZON",
                "name": "Zondoma Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3131,
                "iso2": "BLG",
                "name": "Boulgou"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3132,
                "iso2": "KMD",
                "name": "Komondjari Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3133,
                "iso2": "KOP",
                "name": "Koulpélogo Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3134,
                "iso2": "TUI",
                "name": "Tuy Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3135,
                "iso2": "IOB",
                "name": "Ioba Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3136,
                "iso2": "3",
                "name": "Centre"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3137,
                "iso2": "SOR",
                "name": "Sourou Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3138,
                "iso2": "1",
                "name": "Boucle du Mouhoun Region"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3139,
                "iso2": "SEN",
                "name": "Séno Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3140,
                "iso2": "13",
                "name": "Sud-Ouest Region"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3141,
                "iso2": "OUB",
                "name": "Oubritenga Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3142,
                "iso2": "NAY",
                "name": "Nayala Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3143,
                "iso2": "GOU",
                "name": "Gourma Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3144,
                "iso2": "OUD",
                "name": "Oudalan Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3145,
                "iso2": "ZIR",
                "name": "Ziro Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3146,
                "iso2": "KOS",
                "name": "Kossi Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3147,
                "iso2": "KOW",
                "name": "Kourwéogo Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3148,
                "iso2": "GAN",
                "name": "Ganzourgou Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3149,
                "iso2": "7",
                "name": "Centre-Sud Region"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3150,
                "iso2": "YAT",
                "name": "Yatenga Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3151,
                "iso2": "LOR",
                "name": "Loroum Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3152,
                "iso2": "BAZ",
                "name": "Bazèga Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3153,
                "iso2": "2",
                "name": "Cascades Region"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3154,
                "iso2": "SNG",
                "name": "Sanguié Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3155,
                "iso2": "BAM",
                "name": "Bam Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3156,
                "iso2": "NOU",
                "name": "Noumbiel Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3157,
                "iso2": "KMP",
                "name": "Kompienga Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3158,
                "iso2": "8",
                "name": "Est Region"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3159,
                "iso2": "LER",
                "name": "Léraba Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3160,
                "iso2": "BAL",
                "name": "Balé Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3161,
                "iso2": "KOT",
                "name": "Kouritenga Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3162,
                "iso2": "4",
                "name": "Centre-Est Region"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3163,
                "iso2": "PON",
                "name": "Poni Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3164,
                "iso2": "10",
                "name": "Nord Region, Burkina Faso"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3165,
                "iso2": "9",
                "name": "Hauts-Bassins Region"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3166,
                "iso2": "SOM",
                "name": "Soum Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3167,
                "iso2": "COM",
                "name": "Comoé Province"
            },
            {
                "country_code": "BF",
                "country_id": 35,
                "id": 3168,
                "iso2": "KAD",
                "name": "Kadiogo Province"
            }
        ]
    },
    {
        "id": 36,
        "name": "Burundi",
        "iso3": "BDI",
        "iso2": "BI",
        "states": [
            {
                "country_code": "BI",
                "country_id": 36,
                "id": 3185,
                "iso2": "RM",
                "name": "Rumonge Province"
            },
            {
                "country_code": "BI",
                "country_id": 36,
                "id": 3186,
                "iso2": "MY",
                "name": "Muyinga Province"
            },
            {
                "country_code": "BI",
                "country_id": 36,
                "id": 3187,
                "iso2": "MW",
                "name": "Mwaro Province"
            },
            {
                "country_code": "BI",
                "country_id": 36,
                "id": 3188,
                "iso2": "MA",
                "name": "Makamba Province"
            },
            {
                "country_code": "BI",
                "country_id": 36,
                "id": 3189,
                "iso2": "RT",
                "name": "Rutana Province"
            },
            {
                "country_code": "BI",
                "country_id": 36,
                "id": 3190,
                "iso2": "CI",
                "name": "Cibitoke Province"
            },
            {
                "country_code": "BI",
                "country_id": 36,
                "id": 3191,
                "iso2": "RY",
                "name": "Ruyigi Province"
            },
            {
                "country_code": "BI",
                "country_id": 36,
                "id": 3192,
                "iso2": "KY",
                "name": "Kayanza Province"
            },
            {
                "country_code": "BI",
                "country_id": 36,
                "id": 3193,
                "iso2": "MU",
                "name": "Muramvya Province"
            },
            {
                "country_code": "BI",
                "country_id": 36,
                "id": 3194,
                "iso2": "KR",
                "name": "Karuzi Province"
            },
            {
                "country_code": "BI",
                "country_id": 36,
                "id": 3195,
                "iso2": "KI",
                "name": "Kirundo Province"
            },
            {
                "country_code": "BI",
                "country_id": 36,
                "id": 3196,
                "iso2": "BB",
                "name": "Bubanza Province"
            },
            {
                "country_code": "BI",
                "country_id": 36,
                "id": 3197,
                "iso2": "GI",
                "name": "Gitega Province"
            },
            {
                "country_code": "BI",
                "country_id": 36,
                "id": 3198,
                "iso2": "BM",
                "name": "Bujumbura Mairie Province"
            },
            {
                "country_code": "BI",
                "country_id": 36,
                "id": 3199,
                "iso2": "NG",
                "name": "Ngozi Province"
            },
            {
                "country_code": "BI",
                "country_id": 36,
                "id": 3200,
                "iso2": "BL",
                "name": "Bujumbura Rural Province"
            },
            {
                "country_code": "BI",
                "country_id": 36,
                "id": 3201,
                "iso2": "CA",
                "name": "Cankuzo Province"
            },
            {
                "country_code": "BI",
                "country_id": 36,
                "id": 3202,
                "iso2": "BR",
                "name": "Bururi Province"
            }
        ]
    },
    {
        "id": 37,
        "name": "Cambodia",
        "iso3": "KHM",
        "iso2": "KH",
        "states": [
            {
                "country_code": "KH",
                "country_id": 37,
                "id": 3972,
                "iso2": "20",
                "name": "Svay Rieng Province"
            },
            {
                "country_code": "KH",
                "country_id": 37,
                "id": 3973,
                "iso2": "13",
                "name": "Preah Vihear Province"
            },
            {
                "country_code": "KH",
                "country_id": 37,
                "id": 3974,
                "iso2": "14",
                "name": "Prey Veng Province"
            },
            {
                "country_code": "KH",
                "country_id": 37,
                "id": 3975,
                "iso2": "21",
                "name": "Takéo Province"
            },
            {
                "country_code": "KH",
                "country_id": 37,
                "id": 3976,
                "iso2": "2",
                "name": "Battambang Province"
            },
            {
                "country_code": "KH",
                "country_id": 37,
                "id": 3977,
                "iso2": "15",
                "name": "Pursat Province"
            },
            {
                "country_code": "KH",
                "country_id": 37,
                "id": 3978,
                "iso2": "23",
                "name": "Kep Province"
            },
            {
                "country_code": "KH",
                "country_id": 37,
                "id": 3979,
                "iso2": "4",
                "name": "Kampong Chhnang Province"
            },
            {
                "country_code": "KH",
                "country_id": 37,
                "id": 3980,
                "iso2": "24",
                "name": "Pailin Province"
            },
            {
                "country_code": "KH",
                "country_id": 37,
                "id": 3981,
                "iso2": "7",
                "name": "Kampot Province"
            },
            {
                "country_code": "KH",
                "country_id": 37,
                "id": 3982,
                "iso2": "9",
                "name": "Koh Kong Province"
            },
            {
                "country_code": "KH",
                "country_id": 37,
                "id": 3983,
                "iso2": "8",
                "name": "Kandal Province"
            },
            {
                "country_code": "KH",
                "country_id": 37,
                "id": 3984,
                "iso2": "1",
                "name": "Banteay Meanchey Province"
            },
            {
                "country_code": "KH",
                "country_id": 37,
                "id": 3985,
                "iso2": "11",
                "name": "Mondulkiri Province"
            },
            {
                "country_code": "KH",
                "country_id": 37,
                "id": 3986,
                "iso2": "10",
                "name": "Kratié Province"
            },
            {
                "country_code": "KH",
                "country_id": 37,
                "id": 3987,
                "iso2": "22",
                "name": "Oddar Meanchey Province"
            },
            {
                "country_code": "KH",
                "country_id": 37,
                "id": 3988,
                "iso2": "5",
                "name": "Kampong Speu Province"
            },
            {
                "country_code": "KH",
                "country_id": 37,
                "id": 3989,
                "iso2": "18",
                "name": "Sihanoukville Province"
            },
            {
                "country_code": "KH",
                "country_id": 37,
                "id": 3990,
                "iso2": "16",
                "name": "Ratanakiri Province"
            },
            {
                "country_code": "KH",
                "country_id": 37,
                "id": 3991,
                "iso2": "3",
                "name": "Kampong Cham Province"
            },
            {
                "country_code": "KH",
                "country_id": 37,
                "id": 3992,
                "iso2": "17",
                "name": "Siem Reap Province"
            },
            {
                "country_code": "KH",
                "country_id": 37,
                "id": 3993,
                "iso2": "19",
                "name": "Stung Treng Province"
            },
            {
                "country_code": "KH",
                "country_id": 37,
                "id": 3994,
                "iso2": "12",
                "name": "Phnom Penh"
            }
        ]
    },
    {
        "id": 38,
        "name": "Cameroon",
        "iso3": "CMR",
        "iso2": "CM",
        "states": [
            {
                "country_code": "CM",
                "country_id": 38,
                "id": 2656,
                "iso2": "EN",
                "name": "Far North"
            },
            {
                "country_code": "CM",
                "country_id": 38,
                "id": 2657,
                "iso2": "NW",
                "name": "Northwest"
            },
            {
                "country_code": "CM",
                "country_id": 38,
                "id": 2658,
                "iso2": "SW",
                "name": "Southwest"
            },
            {
                "country_code": "CM",
                "country_id": 38,
                "id": 2659,
                "iso2": "SU",
                "name": "South"
            },
            {
                "country_code": "CM",
                "country_id": 38,
                "id": 2660,
                "iso2": "CE",
                "name": "Centre"
            },
            {
                "country_code": "CM",
                "country_id": 38,
                "id": 2661,
                "iso2": "ES",
                "name": "East"
            },
            {
                "country_code": "CM",
                "country_id": 38,
                "id": 2662,
                "iso2": "LT",
                "name": "Littoral"
            },
            {
                "country_code": "CM",
                "country_id": 38,
                "id": 2663,
                "iso2": "AD",
                "name": "Adamawa"
            },
            {
                "country_code": "CM",
                "country_id": 38,
                "id": 2664,
                "iso2": "OU",
                "name": "West"
            },
            {
                "country_code": "CM",
                "country_id": 38,
                "id": 2665,
                "iso2": "NO",
                "name": "North"
            }
        ]
    },
    {
        "id": 39,
        "name": "Canada",
        "iso3": "CAN",
        "iso2": "CA",
        "states": [
            {
                "country_code": "CA",
                "country_id": 39,
                "id": 866,
                "iso2": "ON",
                "name": "Ontario"
            },
            {
                "country_code": "CA",
                "country_id": 39,
                "id": 867,
                "iso2": "MB",
                "name": "Manitoba"
            },
            {
                "country_code": "CA",
                "country_id": 39,
                "id": 868,
                "iso2": "NB",
                "name": "New Brunswick"
            },
            {
                "country_code": "CA",
                "country_id": 39,
                "id": 869,
                "iso2": "YT",
                "name": "Yukon"
            },
            {
                "country_code": "CA",
                "country_id": 39,
                "id": 870,
                "iso2": "SK",
                "name": "Saskatchewan"
            },
            {
                "country_code": "CA",
                "country_id": 39,
                "id": 871,
                "iso2": "PE",
                "name": "Prince Edward Island"
            },
            {
                "country_code": "CA",
                "country_id": 39,
                "id": 872,
                "iso2": "AB",
                "name": "Alberta"
            },
            {
                "country_code": "CA",
                "country_id": 39,
                "id": 873,
                "iso2": "QC",
                "name": "Quebec"
            },
            {
                "country_code": "CA",
                "country_id": 39,
                "id": 874,
                "iso2": "NS",
                "name": "Nova Scotia"
            },
            {
                "country_code": "CA",
                "country_id": 39,
                "id": 875,
                "iso2": "BC",
                "name": "British Columbia"
            },
            {
                "country_code": "CA",
                "country_id": 39,
                "id": 876,
                "iso2": "NU",
                "name": "Nunavut"
            },
            {
                "country_code": "CA",
                "country_id": 39,
                "id": 877,
                "iso2": "NL",
                "name": "Newfoundland and Labrador"
            },
            {
                "country_code": "CA",
                "country_id": 39,
                "id": 878,
                "iso2": "NT",
                "name": "Northwest Territories"
            }
        ]
    },
    {
        "id": 40,
        "name": "Cape Verde",
        "iso3": "CPV",
        "iso2": "CV",
        "states": [
            {
                "country_code": "CV",
                "country_id": 40,
                "id": 2982,
                "iso2": "RB",
                "name": "Ribeira Brava Municipality"
            },
            {
                "country_code": "CV",
                "country_id": 40,
                "id": 2983,
                "iso2": "TA",
                "name": "Tarrafal"
            },
            {
                "country_code": "CV",
                "country_id": 40,
                "id": 2984,
                "iso2": "RS",
                "name": "Ribeira Grande de Santiago"
            },
            {
                "country_code": "CV",
                "country_id": 40,
                "id": 2985,
                "iso2": "CA",
                "name": "Santa Catarina"
            },
            {
                "country_code": "CV",
                "country_id": 40,
                "id": 2986,
                "iso2": "SD",
                "name": "São Domingos"
            },
            {
                "country_code": "CV",
                "country_id": 40,
                "id": 2987,
                "iso2": "MO",
                "name": "Mosteiros"
            },
            {
                "country_code": "CV",
                "country_id": 40,
                "id": 2988,
                "iso2": "PR",
                "name": "Praia"
            },
            {
                "country_code": "CV",
                "country_id": 40,
                "id": 2989,
                "iso2": "PN",
                "name": "Porto Novo"
            },
            {
                "country_code": "CV",
                "country_id": 40,
                "id": 2990,
                "iso2": "SM",
                "name": "São Miguel"
            },
            {
                "country_code": "CV",
                "country_id": 40,
                "id": 2991,
                "iso2": "MA",
                "name": "Maio Municipality"
            },
            {
                "country_code": "CV",
                "country_id": 40,
                "id": 2992,
                "iso2": "S",
                "name": "Sotavento Islands"
            },
            {
                "country_code": "CV",
                "country_id": 40,
                "id": 2993,
                "iso2": "SO",
                "name": "São Lourenço dos Órgãos"
            },
            {
                "country_code": "CV",
                "country_id": 40,
                "id": 2994,
                "iso2": "B",
                "name": "Barlavento Islands"
            },
            {
                "country_code": "CV",
                "country_id": 40,
                "id": 2995,
                "iso2": "CF",
                "name": "Santa Catarina do Fogo"
            },
            {
                "country_code": "CV",
                "country_id": 40,
                "id": 2996,
                "iso2": "BR",
                "name": "Brava"
            },
            {
                "country_code": "CV",
                "country_id": 40,
                "id": 2997,
                "iso2": "PA",
                "name": "Paul"
            },
            {
                "country_code": "CV",
                "country_id": 40,
                "id": 2998,
                "iso2": "SL",
                "name": "Sal"
            },
            {
                "country_code": "CV",
                "country_id": 40,
                "id": 2999,
                "iso2": "BV",
                "name": "Boa Vista"
            },
            {
                "country_code": "CV",
                "country_id": 40,
                "id": 3000,
                "iso2": "SF",
                "name": "São Filipe"
            },
            {
                "country_code": "CV",
                "country_id": 40,
                "id": 3001,
                "iso2": "SV",
                "name": "São Vicente"
            },
            {
                "country_code": "CV",
                "country_id": 40,
                "id": 3002,
                "iso2": "RG",
                "name": "Ribeira Grande"
            },
            {
                "country_code": "CV",
                "country_id": 40,
                "id": 3003,
                "iso2": "TS",
                "name": "Tarrafal de São Nicolau"
            },
            {
                "country_code": "CV",
                "country_id": 40,
                "id": 3004,
                "iso2": "CR",
                "name": "Santa Cruz"
            }
        ]
    },
    {
        "id": 41,
        "name": "Cayman Islands",
        "iso3": "CYM",
        "iso2": "KY",
        "states": []
    },
    {
        "id": 42,
        "name": "Central African Republic",
        "iso3": "CAF",
        "iso2": "CF",
        "states": [
            {
                "country_code": "CF",
                "country_id": 42,
                "id": 1252,
                "iso2": "SE",
                "name": "Sangha-Mbaéré"
            },
            {
                "country_code": "CF",
                "country_id": 42,
                "id": 1253,
                "iso2": "KB",
                "name": "Nana-Grébizi Economic Prefecture"
            },
            {
                "country_code": "CF",
                "country_id": 42,
                "id": 1254,
                "iso2": "AC",
                "name": "Ouham Prefecture"
            },
            {
                "country_code": "CF",
                "country_id": 42,
                "id": 1255,
                "iso2": "MP",
                "name": "Ombella-M'Poko Prefecture"
            },
            {
                "country_code": "CF",
                "country_id": 42,
                "id": 1256,
                "iso2": "LB",
                "name": "Lobaye Prefecture"
            },
            {
                "country_code": "CF",
                "country_id": 42,
                "id": 1257,
                "iso2": "HS",
                "name": "Mambéré-Kadéï"
            },
            {
                "country_code": "CF",
                "country_id": 42,
                "id": 1258,
                "iso2": "HM",
                "name": "Haut-Mbomou Prefecture"
            },
            {
                "country_code": "CF",
                "country_id": 42,
                "id": 1259,
                "iso2": "BB",
                "name": "Bamingui-Bangoran Prefecture"
            },
            {
                "country_code": "CF",
                "country_id": 42,
                "id": 1260,
                "iso2": "NM",
                "name": "Nana-Mambéré Prefecture"
            },
            {
                "country_code": "CF",
                "country_id": 42,
                "id": 1261,
                "iso2": "VK",
                "name": "Vakaga Prefecture"
            },
            {
                "country_code": "CF",
                "country_id": 42,
                "id": 1262,
                "iso2": "BGF",
                "name": "Bangui"
            },
            {
                "country_code": "CF",
                "country_id": 42,
                "id": 1263,
                "iso2": "KG",
                "name": "Kémo Prefecture"
            },
            {
                "country_code": "CF",
                "country_id": 42,
                "id": 1264,
                "iso2": "BK",
                "name": "Basse-Kotto Prefecture"
            },
            {
                "country_code": "CF",
                "country_id": 42,
                "id": 1265,
                "iso2": "UK",
                "name": "Ouaka Prefecture"
            },
            {
                "country_code": "CF",
                "country_id": 42,
                "id": 1266,
                "iso2": "MB",
                "name": "Mbomou Prefecture"
            },
            {
                "country_code": "CF",
                "country_id": 42,
                "id": 1267,
                "iso2": "OP",
                "name": "Ouham-Pendé Prefecture"
            },
            {
                "country_code": "CF",
                "country_id": 42,
                "id": 1268,
                "iso2": "HK",
                "name": "Haute-Kotto Prefecture"
            }
        ]
    },
    {
        "id": 43,
        "name": "Chad",
        "iso3": "TCD",
        "iso2": "TD",
        "states": [
            {
                "country_code": "TD",
                "country_id": 43,
                "id": 3570,
                "iso2": "MC",
                "name": "Moyen-Chari Region"
            },
            {
                "country_code": "TD",
                "country_id": 43,
                "id": 3571,
                "iso2": "MO",
                "name": "Mayo-Kebbi Ouest Region"
            },
            {
                "country_code": "TD",
                "country_id": 43,
                "id": 3572,
                "iso2": "SI",
                "name": "Sila Region"
            },
            {
                "country_code": "TD",
                "country_id": 43,
                "id": 3573,
                "iso2": "HL",
                "name": "Hadjer-Lamis"
            },
            {
                "country_code": "TD",
                "country_id": 43,
                "id": 3574,
                "iso2": "BO",
                "name": "Borkou"
            },
            {
                "country_code": "TD",
                "country_id": 43,
                "id": 3575,
                "iso2": "EE",
                "name": "Ennedi-Est"
            },
            {
                "country_code": "TD",
                "country_id": 43,
                "id": 3576,
                "iso2": "GR",
                "name": "Guéra Region"
            },
            {
                "country_code": "TD",
                "country_id": 43,
                "id": 3577,
                "iso2": "LC",
                "name": "Lac Region"
            },
            {
                "country_code": "TD",
                "country_id": 43,
                "id": 3578,
                "iso2": "EN",
                "name": "Ennedi Region"
            },
            {
                "country_code": "TD",
                "country_id": 43,
                "id": 3579,
                "iso2": "TA",
                "name": "Tandjilé Region"
            },
            {
                "country_code": "TD",
                "country_id": 43,
                "id": 3580,
                "iso2": "ME",
                "name": "Mayo-Kebbi Est Region"
            },
            {
                "country_code": "TD",
                "country_id": 43,
                "id": 3581,
                "iso2": "WF",
                "name": "Wadi Fira Region"
            },
            {
                "country_code": "TD",
                "country_id": 43,
                "id": 3582,
                "iso2": "OD",
                "name": "Ouaddaï Region"
            },
            {
                "country_code": "TD",
                "country_id": 43,
                "id": 3583,
                "iso2": "BG",
                "name": "Bahr el Gazel"
            },
            {
                "country_code": "TD",
                "country_id": 43,
                "id": 3584,
                "iso2": "EO",
                "name": "Ennedi-Ouest"
            },
            {
                "country_code": "TD",
                "country_id": 43,
                "id": 3585,
                "iso2": "LO",
                "name": "Logone Occidental Region"
            },
            {
                "country_code": "TD",
                "country_id": 43,
                "id": 3586,
                "iso2": "ND",
                "name": "N'Djamena"
            },
            {
                "country_code": "TD",
                "country_id": 43,
                "id": 3587,
                "iso2": "TI",
                "name": "Tibesti Region"
            },
            {
                "country_code": "TD",
                "country_id": 43,
                "id": 3588,
                "iso2": "KA",
                "name": "Kanem Region"
            },
            {
                "country_code": "TD",
                "country_id": 43,
                "id": 3589,
                "iso2": "MA",
                "name": "Mandoul Region"
            },
            {
                "country_code": "TD",
                "country_id": 43,
                "id": 3590,
                "iso2": "BA",
                "name": "Batha Region"
            },
            {
                "country_code": "TD",
                "country_id": 43,
                "id": 3591,
                "iso2": "LR",
                "name": "Logone Oriental Region"
            },
            {
                "country_code": "TD",
                "country_id": 43,
                "id": 3592,
                "iso2": "SA",
                "name": "Salamat Region"
            }
        ]
    },
    {
        "id": 44,
        "name": "Chile",
        "iso3": "CHL",
        "iso2": "CL",
        "states": [
            {
                "country_code": "CL",
                "country_id": 44,
                "id": 2823,
                "iso2": "AT",
                "name": "Atacama Region"
            },
            {
                "country_code": "CL",
                "country_id": 44,
                "id": 2824,
                "iso2": "RM",
                "name": "Santiago Metropolitan Region"
            },
            {
                "country_code": "CL",
                "country_id": 44,
                "id": 2825,
                "iso2": "CO",
                "name": "Coquimbo Region"
            },
            {
                "country_code": "CL",
                "country_id": 44,
                "id": 2826,
                "iso2": "AR",
                "name": "Araucanía Region"
            },
            {
                "country_code": "CL",
                "country_id": 44,
                "id": 2827,
                "iso2": "BI",
                "name": "Bío Bío Region"
            },
            {
                "country_code": "CL",
                "country_id": 44,
                "id": 2828,
                "iso2": "AI",
                "name": "Aysén Region"
            },
            {
                "country_code": "CL",
                "country_id": 44,
                "id": 2829,
                "iso2": "AP",
                "name": "Arica y Parinacota Region"
            },
            {
                "country_code": "CL",
                "country_id": 44,
                "id": 2830,
                "iso2": "VS",
                "name": "Valparaíso"
            },
            {
                "country_code": "CL",
                "country_id": 44,
                "id": 2831,
                "iso2": "NB",
                "name": "Ñuble Region"
            },
            {
                "country_code": "CL",
                "country_id": 44,
                "id": 2832,
                "iso2": "AN",
                "name": "Antofagasta Region"
            },
            {
                "country_code": "CL",
                "country_id": 44,
                "id": 2833,
                "iso2": "ML",
                "name": "Maule Region"
            },
            {
                "country_code": "CL",
                "country_id": 44,
                "id": 2834,
                "iso2": "LR",
                "name": "Los Ríos Region"
            },
            {
                "country_code": "CL",
                "country_id": 44,
                "id": 2835,
                "iso2": "LL",
                "name": "Los Lagos Region"
            },
            {
                "country_code": "CL",
                "country_id": 44,
                "id": 2836,
                "iso2": "MA",
                "name": "Magellan and the Chilean Antarctic Region"
            },
            {
                "country_code": "CL",
                "country_id": 44,
                "id": 2837,
                "iso2": "TA",
                "name": "Tarapacá Region"
            },
            {
                "country_code": "CL",
                "country_id": 44,
                "id": 2838,
                "iso2": "LI",
                "name": "O'Higgins"
            }
        ]
    },
    {
        "id": 45,
        "name": "China",
        "iso3": "CHN",
        "iso2": "CN",
        "states": [
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2247,
                "iso2": "ZJ",
                "name": "Zhejiang"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2248,
                "iso2": "FJ",
                "name": "Fujian"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2249,
                "iso2": "SH",
                "name": "Shanghai"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2250,
                "iso2": "JS",
                "name": "Jiangsu"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2251,
                "iso2": "AH",
                "name": "Anhui"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2252,
                "iso2": "SD",
                "name": "Shandong"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2253,
                "iso2": "JL",
                "name": "Jilin"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2254,
                "iso2": "SX",
                "name": "Shanxi"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2255,
                "iso2": "TW",
                "name": "Taiwan Province, People's Republic of China"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2256,
                "iso2": "JX",
                "name": "Jiangxi"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2257,
                "iso2": "BJ",
                "name": "Beijing"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2258,
                "iso2": "HN",
                "name": "Hunan"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2259,
                "iso2": "HA",
                "name": "Henan"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2260,
                "iso2": "YN",
                "name": "Yunnan"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2261,
                "iso2": "GZ",
                "name": "Guizhou"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2262,
                "iso2": "NX",
                "name": "Ningxia Hui Autonomous Region"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2263,
                "iso2": "XJ",
                "name": "Xinjiang"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2264,
                "iso2": "XZ",
                "name": "Tibet Autonomous Region"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2265,
                "iso2": "HL",
                "name": "Heilongjiang"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2266,
                "iso2": "MO",
                "name": "Macau"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2267,
                "iso2": "HK",
                "name": "Hong Kong"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2268,
                "iso2": "LN",
                "name": "Liaoning"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2269,
                "iso2": "NM",
                "name": "Inner Mongolia"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2270,
                "iso2": "QH",
                "name": "Qinghai"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2271,
                "iso2": "CQ",
                "name": "Chongqing"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2272,
                "iso2": "SN",
                "name": "Shaanxi"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2273,
                "iso2": "HI",
                "name": "Hainan"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2274,
                "iso2": "HB",
                "name": "Hubei"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2275,
                "iso2": "GS",
                "name": "Gansu"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2276,
                "iso2": "TW-KEE",
                "name": "Keelung"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2277,
                "iso2": "SC",
                "name": "Sichuan"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2278,
                "iso2": "GX",
                "name": "Guangxi Zhuang Autonomous Region"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2279,
                "iso2": "GD",
                "name": "Guangdong"
            },
            {
                "country_code": "CN",
                "country_id": 45,
                "id": 2280,
                "iso2": "HE",
                "name": "Hebei"
            }
        ]
    },
    {
        "id": 46,
        "name": "Christmas Island",
        "iso3": "CXR",
        "iso2": "CX",
        "states": []
    },
    {
        "id": 47,
        "name": "Cocos (Keeling) Islands",
        "iso3": "CCK",
        "iso2": "CC",
        "states": []
    },
    {
        "id": 48,
        "name": "Colombia",
        "iso3": "COL",
        "iso2": "CO",
        "states": [
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2874,
                "iso2": "QUI",
                "name": "Quindío Department"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2875,
                "iso2": "CUN",
                "name": "Cundinamarca Department"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2876,
                "iso2": "CHO",
                "name": "Chocó Department"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2877,
                "iso2": "NSA",
                "name": "Norte de Santander Department"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2878,
                "iso2": "MET",
                "name": "Meta"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2879,
                "iso2": "RIS",
                "name": "Risaralda Department"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2880,
                "iso2": "ATL",
                "name": "Atlántico Department"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2881,
                "iso2": "ARA",
                "name": "Arauca Department"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2882,
                "iso2": "GUA",
                "name": "Guainía Department"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2883,
                "iso2": "TOL",
                "name": "Tolima Department"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2884,
                "iso2": "CAU",
                "name": "Cauca Department"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2885,
                "iso2": "VAU",
                "name": "Vaupés Department"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2886,
                "iso2": "MAG",
                "name": "Magdalena Department"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2887,
                "iso2": "CAL",
                "name": "Caldas Department"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2888,
                "iso2": "GUV",
                "name": "Guaviare Department"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2889,
                "iso2": "LAG",
                "name": "La Guajira Department"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2890,
                "iso2": "ANT",
                "name": "Antioquia Department"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2891,
                "iso2": "CAQ",
                "name": "Caquetá Department"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2892,
                "iso2": "CAS",
                "name": "Casanare Department"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2893,
                "iso2": "BOL",
                "name": "Bolívar Department"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2894,
                "iso2": "VID",
                "name": "Vichada Department"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2895,
                "iso2": "AMA",
                "name": "Amazonas Department"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2896,
                "iso2": "PUT",
                "name": "Putumayo Department"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2897,
                "iso2": "NAR",
                "name": "Nariño Department"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2898,
                "iso2": "COR",
                "name": "Córdoba Department"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2899,
                "iso2": "CES",
                "name": "Cesar Department"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2900,
                "iso2": "SAP",
                "name": "Archipelago of Saint Andréws, Providence and Saint Catalina"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2901,
                "iso2": "SAN",
                "name": "Santander Department"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2902,
                "iso2": "SUC",
                "name": "Sucre Department"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2903,
                "iso2": "BOY",
                "name": "Boyacá Department"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 2904,
                "iso2": "VAC",
                "name": "Valle del Cauca Department"
            },
            {
                "country_code": "CO",
                "country_id": 48,
                "id": 4871,
                "iso2": "HUI",
                "name": "Huila Department"
            }
        ]
    },
    {
        "id": 49,
        "name": "Comoros",
        "iso3": "COM",
        "iso2": "KM",
        "states": [
            {
                "country_code": "KM",
                "country_id": 49,
                "id": 2820,
                "iso2": "M",
                "name": "Mohéli"
            },
            {
                "country_code": "KM",
                "country_id": 49,
                "id": 2821,
                "iso2": "A",
                "name": "Anjouan"
            },
            {
                "country_code": "KM",
                "country_id": 49,
                "id": 2822,
                "iso2": "G",
                "name": "Grande Comore"
            }
        ]
    },
    {
        "id": 50,
        "name": "Congo",
        "iso3": "COG",
        "iso2": "CG",
        "states": [
            {
                "country_code": "CG",
                "country_id": 50,
                "id": 2862,
                "iso2": "14",
                "name": "Plateaux Department"
            },
            {
                "country_code": "CG",
                "country_id": 50,
                "id": 2863,
                "iso2": "16",
                "name": "Pointe-Noire"
            },
            {
                "country_code": "CG",
                "country_id": 50,
                "id": 2864,
                "iso2": "8",
                "name": "Cuvette Department"
            },
            {
                "country_code": "CG",
                "country_id": 50,
                "id": 2865,
                "iso2": "7",
                "name": "Likouala Department"
            },
            {
                "country_code": "CG",
                "country_id": 50,
                "id": 2866,
                "iso2": "11",
                "name": "Bouenza Department"
            },
            {
                "country_code": "CG",
                "country_id": 50,
                "id": 2867,
                "iso2": "5",
                "name": "Kouilou Department"
            },
            {
                "country_code": "CG",
                "country_id": 50,
                "id": 2868,
                "iso2": "2",
                "name": "Lékoumou Department"
            },
            {
                "country_code": "CG",
                "country_id": 50,
                "id": 2869,
                "iso2": "15",
                "name": "Cuvette-Ouest Department"
            },
            {
                "country_code": "CG",
                "country_id": 50,
                "id": 2870,
                "iso2": "BZV",
                "name": "Brazzaville"
            },
            {
                "country_code": "CG",
                "country_id": 50,
                "id": 2871,
                "iso2": "13",
                "name": "Sangha Department"
            },
            {
                "country_code": "CG",
                "country_id": 50,
                "id": 2872,
                "iso2": "9",
                "name": "Niari Department"
            },
            {
                "country_code": "CG",
                "country_id": 50,
                "id": 2873,
                "iso2": "12",
                "name": "Pool Department"
            }
        ]
    },
    {
        "id": 51,
        "name": "Congo The Democratic Republic Of The",
        "iso3": "COD",
        "iso2": "CD",
        "states": [
            {
                "country_code": "CD",
                "country_id": 51,
                "id": 2732,
                "iso2": "TU",
                "name": "Tshuapa District"
            },
            {
                "country_code": "CD",
                "country_id": 51,
                "id": 2733,
                "iso2": "TA",
                "name": "Tanganyika Province"
            },
            {
                "country_code": "CD",
                "country_id": 51,
                "id": 2734,
                "iso2": "HU",
                "name": "Haut-Uele"
            },
            {
                "country_code": "CD",
                "country_id": 51,
                "id": 2735,
                "iso2": "KE",
                "name": "Kasaï-Oriental"
            },
            {
                "country_code": "CD",
                "country_id": 51,
                "id": 2736,
                "iso2": "OR",
                "name": "Orientale Province"
            },
            {
                "country_code": "CD",
                "country_id": 51,
                "id": 2737,
                "iso2": "KW",
                "name": "Kasaï-Occidental"
            },
            {
                "country_code": "CD",
                "country_id": 51,
                "id": 2738,
                "iso2": "SK",
                "name": "South Kivu"
            },
            {
                "country_code": "CD",
                "country_id": 51,
                "id": 2739,
                "iso2": "NU",
                "name": "Nord-Ubangi District"
            },
            {
                "country_code": "CD",
                "country_id": 51,
                "id": 2740,
                "iso2": "KG",
                "name": "Kwango District"
            },
            {
                "country_code": "CD",
                "country_id": 51,
                "id": 2741,
                "iso2": "KN",
                "name": "Kinshasa"
            },
            {
                "country_code": "CD",
                "country_id": 51,
                "id": 2742,
                "iso2": "KA",
                "name": "Katanga Province"
            },
            {
                "country_code": "CD",
                "country_id": 51,
                "id": 2743,
                "iso2": "SA",
                "name": "Sankuru District"
            },
            {
                "country_code": "CD",
                "country_id": 51,
                "id": 2744,
                "iso2": "EQ",
                "name": "Équateur"
            },
            {
                "country_code": "CD",
                "country_id": 51,
                "id": 2745,
                "iso2": "MA",
                "name": "Maniema"
            },
            {
                "country_code": "CD",
                "country_id": 51,
                "id": 2746,
                "iso2": "BC",
                "name": "Bas-Congo province"
            },
            {
                "country_code": "CD",
                "country_id": 51,
                "id": 2747,
                "iso2": "LO",
                "name": "Lomami Province"
            },
            {
                "country_code": "CD",
                "country_id": 51,
                "id": 2748,
                "iso2": "SU",
                "name": "Sud-Ubangi"
            },
            {
                "country_code": "CD",
                "country_id": 51,
                "id": 2749,
                "iso2": "NK",
                "name": "North Kivu"
            },
            {
                "country_code": "CD",
                "country_id": 51,
                "id": 2750,
                "iso2": "HK",
                "name": "Haut-Katanga Province"
            },
            {
                "country_code": "CD",
                "country_id": 51,
                "id": 2751,
                "iso2": "IT",
                "name": "Ituri Interim Administration"
            },
            {
                "country_code": "CD",
                "country_id": 51,
                "id": 2752,
                "iso2": "MO",
                "name": "Mongala District"
            },
            {
                "country_code": "CD",
                "country_id": 51,
                "id": 2753,
                "iso2": "BU",
                "name": "Bas-Uele"
            },
            {
                "country_code": "CD",
                "country_id": 51,
                "id": 2754,
                "iso2": "BN",
                "name": "Bandundu Province"
            },
            {
                "country_code": "CD",
                "country_id": 51,
                "id": 2755,
                "iso2": "MN",
                "name": "Mai-Ndombe Province"
            },
            {
                "country_code": "CD",
                "country_id": 51,
                "id": 2756,
                "iso2": "TO",
                "name": "Tshopo District"
            },
            {
                "country_code": "CD",
                "country_id": 51,
                "id": 2757,
                "iso2": "KS",
                "name": "Kasaï District"
            },
            {
                "country_code": "CD",
                "country_id": 51,
                "id": 2758,
                "iso2": "HL",
                "name": "Haut-Lomami District"
            },
            {
                "country_code": "CD",
                "country_id": 51,
                "id": 2759,
                "iso2": "KL",
                "name": "Kwilu District"
            }
        ]
    },
    {
        "id": 52,
        "name": "Cook Islands",
        "iso3": "COK",
        "iso2": "CK",
        "states": []
    },
    {
        "id": 53,
        "name": "Costa Rica",
        "iso3": "CRI",
        "iso2": "CR",
        "states": [
            {
                "country_code": "CR",
                "country_id": 53,
                "id": 1209,
                "iso2": "G",
                "name": "Guanacaste Province"
            },
            {
                "country_code": "CR",
                "country_id": 53,
                "id": 1210,
                "iso2": "P",
                "name": "Puntarenas Province"
            },
            {
                "country_code": "CR",
                "country_id": 53,
                "id": 1211,
                "iso2": "C",
                "name": "Provincia de Cartago"
            },
            {
                "country_code": "CR",
                "country_id": 53,
                "id": 1212,
                "iso2": "H",
                "name": "Heredia Province"
            },
            {
                "country_code": "CR",
                "country_id": 53,
                "id": 1213,
                "iso2": "L",
                "name": "Limón Province"
            },
            {
                "country_code": "CR",
                "country_id": 53,
                "id": 1214,
                "iso2": "SJ",
                "name": "San José Province"
            },
            {
                "country_code": "CR",
                "country_id": 53,
                "id": 1215,
                "iso2": "A",
                "name": "Alajuela Province"
            }
        ]
    },
    {
        "id": 54,
        "name": "Cote D'Ivoire (Ivory Coast)",
        "iso3": "CIV",
        "iso2": "CI",
        "states": [
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2625,
                "iso2": "3",
                "name": "Savanes Region"
            },
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2626,
                "iso2": "16",
                "name": "Agnéby"
            },
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2627,
                "iso2": "LG",
                "name": "Lagunes District"
            },
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2628,
                "iso2": "15",
                "name": "Sud-Bandama"
            },
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2629,
                "iso2": "MG",
                "name": "Montagnes District"
            },
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2630,
                "iso2": "5",
                "name": "Moyen-Comoé"
            },
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2631,
                "iso2": "12",
                "name": "Marahoué Region"
            },
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2632,
                "iso2": "LC",
                "name": "Lacs District"
            },
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2633,
                "iso2": "18",
                "name": "Fromager"
            },
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2634,
                "iso2": "AB",
                "name": "Abidjan"
            },
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2635,
                "iso2": "9",
                "name": "Bas-Sassandra Region"
            },
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2636,
                "iso2": "17",
                "name": "Bafing Region"
            },
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2637,
                "iso2": "VB",
                "name": "Vallée du Bandama District"
            },
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2638,
                "iso2": "2",
                "name": "Haut-Sassandra"
            },
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2639,
                "iso2": "1",
                "name": "Lagunes region"
            },
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2640,
                "iso2": "7",
                "name": "Lacs Region"
            },
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2641,
                "iso2": "ZZ",
                "name": "Zanzan Region"
            },
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2642,
                "iso2": "10",
                "name": "Denguélé Region"
            },
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2643,
                "iso2": "BS",
                "name": "Bas-Sassandra District"
            },
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2644,
                "iso2": "DN",
                "name": "Denguélé District"
            },
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2645,
                "iso2": "6",
                "name": "Dix-Huit Montagnes"
            },
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2646,
                "iso2": "19",
                "name": "Moyen-Cavally"
            },
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2647,
                "iso2": "4",
                "name": "Vallée du Bandama Region"
            },
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2648,
                "iso2": "SM",
                "name": "Sassandra-Marahoué District"
            },
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2649,
                "iso2": "14",
                "name": "Worodougou"
            },
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2650,
                "iso2": "WR",
                "name": "Woroba District"
            },
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2651,
                "iso2": "GD",
                "name": "Gôh-Djiboua District"
            },
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2652,
                "iso2": "13",
                "name": "Sud-Comoé"
            },
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2653,
                "iso2": "YM",
                "name": "Yamoussoukro"
            },
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2654,
                "iso2": "CM",
                "name": "Comoé District"
            },
            {
                "country_code": "CI",
                "country_id": 54,
                "id": 2655,
                "iso2": "11",
                "name": "N'zi-Comoé"
            }
        ]
    },
    {
        "id": 55,
        "name": "Croatia (Hrvatska)",
        "iso3": "HRV",
        "iso2": "HR",
        "states": [
            {
                "country_code": "HR",
                "country_id": 55,
                "id": 724,
                "iso2": "11",
                "name": "Požega-Slavonia County"
            },
            {
                "country_code": "HR",
                "country_id": 55,
                "id": 725,
                "iso2": "17",
                "name": "Split-Dalmatia County"
            },
            {
                "country_code": "HR",
                "country_id": 55,
                "id": 726,
                "iso2": "20",
                "name": "Međimurje County"
            },
            {
                "country_code": "HR",
                "country_id": 55,
                "id": 727,
                "iso2": "13",
                "name": "Zadar County"
            },
            {
                "country_code": "HR",
                "country_id": 55,
                "id": 728,
                "iso2": "19",
                "name": "Dubrovnik-Neretva County"
            },
            {
                "country_code": "HR",
                "country_id": 55,
                "id": 729,
                "iso2": "2",
                "name": "Krapina-Zagorje County"
            },
            {
                "country_code": "HR",
                "country_id": 55,
                "id": 730,
                "iso2": "15",
                "name": "Šibenik-Knin County"
            },
            {
                "country_code": "HR",
                "country_id": 55,
                "id": 731,
                "iso2": "9",
                "name": "Lika-Senj County"
            },
            {
                "country_code": "HR",
                "country_id": 55,
                "id": 732,
                "iso2": "10",
                "name": "Virovitica-Podravina County"
            },
            {
                "country_code": "HR",
                "country_id": 55,
                "id": 733,
                "iso2": "3",
                "name": "Sisak-Moslavina County"
            },
            {
                "country_code": "HR",
                "country_id": 55,
                "id": 734,
                "iso2": "7",
                "name": "Bjelovar-Bilogora County"
            },
            {
                "country_code": "HR",
                "country_id": 55,
                "id": 735,
                "iso2": "8",
                "name": "Primorje-Gorski Kotar County"
            },
            {
                "country_code": "HR",
                "country_id": 55,
                "id": 736,
                "iso2": "1",
                "name": "Zagreb County"
            },
            {
                "country_code": "HR",
                "country_id": 55,
                "id": 737,
                "iso2": "12",
                "name": "Brod-Posavina County"
            },
            {
                "country_code": "HR",
                "country_id": 55,
                "id": 738,
                "iso2": "21",
                "name": "Zagreb"
            },
            {
                "country_code": "HR",
                "country_id": 55,
                "id": 739,
                "iso2": "5",
                "name": "Varaždin County"
            },
            {
                "country_code": "HR",
                "country_id": 55,
                "id": 740,
                "iso2": "14",
                "name": "Osijek-Baranja County"
            },
            {
                "country_code": "HR",
                "country_id": 55,
                "id": 741,
                "iso2": "16",
                "name": "Vukovar-Syrmia County"
            },
            {
                "country_code": "HR",
                "country_id": 55,
                "id": 742,
                "iso2": "6",
                "name": "Koprivnica-Križevci County"
            },
            {
                "country_code": "HR",
                "country_id": 55,
                "id": 743,
                "iso2": "18",
                "name": "Istria County"
            }
        ]
    },
    {
        "id": 56,
        "name": "Cuba",
        "iso3": "CUB",
        "iso2": "CU",
        "states": [
            {
                "country_code": "CU",
                "country_id": 56,
                "id": 272,
                "iso2": "3",
                "name": "Havana Province"
            },
            {
                "country_code": "CU",
                "country_id": 56,
                "id": 273,
                "iso2": "13",
                "name": "Santiago de Cuba Province"
            },
            {
                "country_code": "CU",
                "country_id": 56,
                "id": 274,
                "iso2": "7",
                "name": "Sancti Spíritus Province"
            },
            {
                "country_code": "CU",
                "country_id": 56,
                "id": 275,
                "iso2": "12",
                "name": "Granma Province"
            },
            {
                "country_code": "CU",
                "country_id": 56,
                "id": 276,
                "iso2": "16",
                "name": "Mayabeque Province"
            },
            {
                "country_code": "CU",
                "country_id": 56,
                "id": 277,
                "iso2": "1",
                "name": "Pinar del Río Province"
            },
            {
                "country_code": "CU",
                "country_id": 56,
                "id": 278,
                "iso2": "99",
                "name": "Isla de la Juventud"
            },
            {
                "country_code": "CU",
                "country_id": 56,
                "id": 279,
                "iso2": "11",
                "name": "Holguín Province"
            },
            {
                "country_code": "CU",
                "country_id": 56,
                "id": 280,
                "iso2": "5",
                "name": "Villa Clara Province"
            },
            {
                "country_code": "CU",
                "country_id": 56,
                "id": 281,
                "iso2": "10",
                "name": "Las Tunas Province"
            },
            {
                "country_code": "CU",
                "country_id": 56,
                "id": 282,
                "iso2": "8",
                "name": "Ciego de Ávila Province"
            },
            {
                "country_code": "CU",
                "country_id": 56,
                "id": 283,
                "iso2": "15",
                "name": "Artemisa Province"
            },
            {
                "country_code": "CU",
                "country_id": 56,
                "id": 284,
                "iso2": "4",
                "name": "Matanzas Province"
            },
            {
                "country_code": "CU",
                "country_id": 56,
                "id": 285,
                "iso2": "14",
                "name": "Guantánamo Province"
            },
            {
                "country_code": "CU",
                "country_id": 56,
                "id": 286,
                "iso2": "9",
                "name": "Camagüey Province"
            },
            {
                "country_code": "CU",
                "country_id": 56,
                "id": 287,
                "iso2": "6",
                "name": "Cienfuegos Province"
            }
        ]
    },
    {
        "id": 57,
        "name": "Cyprus",
        "iso3": "CYP",
        "iso2": "CY",
        "states": [
            {
                "country_code": "CY",
                "country_id": 57,
                "id": 744,
                "iso2": "6",
                "name": "Kyrenia District"
            },
            {
                "country_code": "CY",
                "country_id": 57,
                "id": 745,
                "iso2": "1",
                "name": "Nicosia District"
            },
            {
                "country_code": "CY",
                "country_id": 57,
                "id": 746,
                "iso2": "5",
                "name": "Paphos District"
            },
            {
                "country_code": "CY",
                "country_id": 57,
                "id": 747,
                "iso2": "3",
                "name": "Larnaca District"
            },
            {
                "country_code": "CY",
                "country_id": 57,
                "id": 748,
                "iso2": "2",
                "name": "Limassol District"
            },
            {
                "country_code": "CY",
                "country_id": 57,
                "id": 749,
                "iso2": "4",
                "name": "Famagusta District"
            }
        ]
    },
    {
        "id": 58,
        "name": "Czech Republic",
        "iso3": "CZE",
        "iso2": "CZ",
        "states": [
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4542,
                "iso2": "644",
                "name": "Břeclav District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4543,
                "iso2": "312",
                "name": "Český Krumlov District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4544,
                "iso2": "323",
                "name": "Plzeň-City District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4545,
                "iso2": "643",
                "name": "Brno-Country District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4546,
                "iso2": "20B",
                "name": "Příbram District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4547,
                "iso2": "532",
                "name": "Pardubice District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4548,
                "iso2": "804",
                "name": "Nový Jičín District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4549,
                "iso2": "112",
                "name": "Prague 12"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4550,
                "iso2": "523",
                "name": "Náchod District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4551,
                "iso2": "713",
                "name": "Prostějov District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4552,
                "iso2": "72",
                "name": "Zlín Region"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4553,
                "iso2": "422",
                "name": "Chomutov District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4554,
                "iso2": "20",
                "name": "Central Bohemian Region"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4555,
                "iso2": "113",
                "name": "Prague 13"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4556,
                "iso2": "311",
                "name": "České Budějovice District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4557,
                "iso2": "105",
                "name": "Prague 5"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4558,
                "iso2": "20C",
                "name": "Rakovník District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4559,
                "iso2": "802",
                "name": "Frýdek-Místek District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4560,
                "iso2": "314",
                "name": "Písek District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4561,
                "iso2": "645",
                "name": "Hodonín District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4562,
                "iso2": "101",
                "name": "Prague 1"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4563,
                "iso2": "724",
                "name": "Zlín District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4564,
                "iso2": "325",
                "name": "Plzeň-North District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4565,
                "iso2": "317",
                "name": "Tábor District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4566,
                "iso2": "109",
                "name": "Prague 9"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4567,
                "iso2": "116",
                "name": "Prague 16"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4568,
                "iso2": "642",
                "name": "Brno-City District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4569,
                "iso2": "106",
                "name": "Prague 6"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4570,
                "iso2": "111",
                "name": "Prague 11"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4571,
                "iso2": "533",
                "name": "Svitavy District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4572,
                "iso2": "723",
                "name": "Vsetín District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4573,
                "iso2": "411",
                "name": "Cheb District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4574,
                "iso2": "712",
                "name": "Olomouc District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4575,
                "iso2": "63",
                "name": "Vysočina Region"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4576,
                "iso2": "42",
                "name": "Ústí nad Labem Region"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4577,
                "iso2": "120",
                "name": "Horní Počernice"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4578,
                "iso2": "315",
                "name": "Prachatice District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4579,
                "iso2": "525",
                "name": "Trutnov District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4580,
                "iso2": "521",
                "name": "Hradec Králové District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4581,
                "iso2": "41",
                "name": "Karlovy Vary Region"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4582,
                "iso2": "208",
                "name": "Nymburk District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4583,
                "iso2": "326",
                "name": "Rokycany District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4584,
                "iso2": "806",
                "name": "Ostrava-City District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4585,
                "iso2": "114",
                "name": "Prague 14"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4586,
                "iso2": "803",
                "name": "Karviná District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4587,
                "iso2": "104",
                "name": "Prague 4"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4588,
                "iso2": "53",
                "name": "Pardubice Region"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4589,
                "iso2": "71",
                "name": "Olomouc Region"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4590,
                "iso2": "513",
                "name": "Liberec District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4591,
                "iso2": "322",
                "name": "Klatovy District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4592,
                "iso2": "722",
                "name": "Uherské Hradiště District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4593,
                "iso2": "721",
                "name": "Kroměříž District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4594,
                "iso2": "108",
                "name": "Prague 8"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4595,
                "iso2": "413",
                "name": "Sokolov District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4596,
                "iso2": "514",
                "name": "Semily District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4597,
                "iso2": "634",
                "name": "Třebíč District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4598,
                "iso2": "10",
                "name": "Prague"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4599,
                "iso2": "427",
                "name": "Ústí nad Labem District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4600,
                "iso2": "80",
                "name": "Moravian-Silesian Region"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4601,
                "iso2": "51",
                "name": "Liberec Region"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4602,
                "iso2": "64",
                "name": "South Moravian Region"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4603,
                "iso2": "110",
                "name": "Prague 10"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4604,
                "iso2": "412",
                "name": "Karlovy Vary District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4605,
                "iso2": "423",
                "name": "Litoměřice District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4606,
                "iso2": "209",
                "name": "Prague-East District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4607,
                "iso2": "32",
                "name": "Plzeň Region"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4608,
                "iso2": "324",
                "name": "Plzeň-South District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4609,
                "iso2": "421",
                "name": "Děčín District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4610,
                "iso2": "107",
                "name": "Prague 7"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4611,
                "iso2": "631",
                "name": "Havlíčkův Brod District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4612,
                "iso2": "512",
                "name": "Jablonec nad Nisou District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4613,
                "iso2": "632",
                "name": "Jihlava District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4614,
                "iso2": "52",
                "name": "Hradec Králové Region"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4615,
                "iso2": "641",
                "name": "Blansko District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4616,
                "iso2": "102",
                "name": "Prague 2"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4617,
                "iso2": "424",
                "name": "Louny District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4618,
                "iso2": "204",
                "name": "Kolín District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4619,
                "iso2": "20A",
                "name": "Prague-West District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4620,
                "iso2": "202",
                "name": "Beroun District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4621,
                "iso2": "426",
                "name": "Teplice District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4622,
                "iso2": "646",
                "name": "Vyškov District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4623,
                "iso2": "805",
                "name": "Opava District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4624,
                "iso2": "313",
                "name": "Jindřichův Hradec District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4625,
                "iso2": "711",
                "name": "Jeseník District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4626,
                "iso2": "714",
                "name": "Přerov District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4627,
                "iso2": "201",
                "name": "Benešov District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4628,
                "iso2": "316",
                "name": "Strakonice District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4629,
                "iso2": "425",
                "name": "Most District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4630,
                "iso2": "647",
                "name": "Znojmo District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4631,
                "iso2": "203",
                "name": "Kladno District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4632,
                "iso2": "121",
                "name": "Prague 21"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4633,
                "iso2": "511",
                "name": "Česká Lípa District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4634,
                "iso2": "531",
                "name": "Chrudim District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4635,
                "iso2": "103",
                "name": "Prague 3"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4636,
                "iso2": "524",
                "name": "Rychnov nad Kněžnou District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4637,
                "iso2": "115",
                "name": "Prague 15"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4638,
                "iso2": "206",
                "name": "Mělník District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4639,
                "iso2": "31",
                "name": "South Bohemian Region"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4640,
                "iso2": "522",
                "name": "Jičín District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4641,
                "iso2": "321",
                "name": "Domažlice District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4642,
                "iso2": "715",
                "name": "Šumperk District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4643,
                "iso2": "207",
                "name": "Mladá Boleslav District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4644,
                "iso2": "801",
                "name": "Bruntál District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4645,
                "iso2": "633",
                "name": "Pelhřimov District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4646,
                "iso2": "327",
                "name": "Tachov District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4647,
                "iso2": "534",
                "name": "Ústí nad Orlicí District"
            },
            {
                "country_code": "CZ",
                "country_id": 58,
                "id": 4648,
                "iso2": "635",
                "name": "Žďár nad Sázavou District"
            }
        ]
    },
    {
        "id": 59,
        "name": "Denmark",
        "iso3": "DNK",
        "iso2": "DK",
        "states": [
            {
                "country_code": "DK",
                "country_id": 59,
                "id": 1528,
                "iso2": "85",
                "name": "Region Zealand"
            },
            {
                "country_code": "DK",
                "country_id": 59,
                "id": 1529,
                "iso2": "83",
                "name": "Region of Southern Denmark"
            },
            {
                "country_code": "DK",
                "country_id": 59,
                "id": 1530,
                "iso2": "84",
                "name": "Capital Region of Denmark"
            },
            {
                "country_code": "DK",
                "country_id": 59,
                "id": 1531,
                "iso2": "82",
                "name": "Central Denmark Region"
            },
            {
                "country_code": "DK",
                "country_id": 59,
                "id": 1532,
                "iso2": "81",
                "name": "North Denmark Region"
            }
        ]
    },
    {
        "id": 60,
        "name": "Djibouti",
        "iso3": "DJI",
        "iso2": "DJ",
        "states": [
            {
                "country_code": "DJ",
                "country_id": 60,
                "id": 2928,
                "iso2": "OB",
                "name": "Obock Region"
            },
            {
                "country_code": "DJ",
                "country_id": 60,
                "id": 2929,
                "iso2": "DJ",
                "name": "Djibouti"
            },
            {
                "country_code": "DJ",
                "country_id": 60,
                "id": 2930,
                "iso2": "DI",
                "name": "Dikhil Region"
            },
            {
                "country_code": "DJ",
                "country_id": 60,
                "id": 2931,
                "iso2": "TA",
                "name": "Tadjourah Region"
            },
            {
                "country_code": "DJ",
                "country_id": 60,
                "id": 2932,
                "iso2": "AR",
                "name": "Arta Region"
            },
            {
                "country_code": "DJ",
                "country_id": 60,
                "id": 2933,
                "iso2": "AS",
                "name": "Ali Sabieh Region"
            }
        ]
    },
    {
        "id": 61,
        "name": "Dominica",
        "iso3": "DMA",
        "iso2": "DM",
        "states": [
            {
                "country_code": "DM",
                "country_id": 61,
                "id": 4076,
                "iso2": "5",
                "name": "Saint John Parish"
            },
            {
                "country_code": "DM",
                "country_id": 61,
                "id": 4077,
                "iso2": "8",
                "name": "Saint Mark Parish"
            },
            {
                "country_code": "DM",
                "country_id": 61,
                "id": 4078,
                "iso2": "3",
                "name": "Saint David Parish"
            },
            {
                "country_code": "DM",
                "country_id": 61,
                "id": 4079,
                "iso2": "4",
                "name": "Saint George Parish"
            },
            {
                "country_code": "DM",
                "country_id": 61,
                "id": 4080,
                "iso2": "9",
                "name": "Saint Patrick Parish"
            },
            {
                "country_code": "DM",
                "country_id": 61,
                "id": 4081,
                "iso2": "11",
                "name": "Saint Peter Parish"
            },
            {
                "country_code": "DM",
                "country_id": 61,
                "id": 4082,
                "iso2": "2",
                "name": "Saint Andrew Parish"
            },
            {
                "country_code": "DM",
                "country_id": 61,
                "id": 4083,
                "iso2": "7",
                "name": "Saint Luke Parish"
            },
            {
                "country_code": "DM",
                "country_id": 61,
                "id": 4084,
                "iso2": "10",
                "name": "Saint Paul Parish"
            },
            {
                "country_code": "DM",
                "country_id": 61,
                "id": 4085,
                "iso2": "6",
                "name": "Saint Joseph Parish"
            }
        ]
    },
    {
        "id": 62,
        "name": "Dominican Republic",
        "iso3": "DOM",
        "iso2": "DO",
        "states": [
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4086,
                "iso2": "8",
                "name": "El Seibo Province"
            },
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4087,
                "iso2": "12",
                "name": "La Romana Province"
            },
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4088,
                "iso2": "24",
                "name": "Sánchez Ramírez Province"
            },
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4089,
                "iso2": "19",
                "name": "Hermanas Mirabal Province"
            },
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4090,
                "iso2": "4",
                "name": "Barahona Province"
            },
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4091,
                "iso2": "21",
                "name": "San Cristóbal Province"
            },
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4092,
                "iso2": "18",
                "name": "Puerto Plata Province"
            },
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4093,
                "iso2": "32",
                "name": "Santo Domingo Province"
            },
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4094,
                "iso2": "14",
                "name": "María Trinidad Sánchez Province"
            },
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4095,
                "iso2": "1",
                "name": "Distrito Nacional"
            },
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4096,
                "iso2": "17",
                "name": "Peravia Province"
            },
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4097,
                "iso2": "10",
                "name": "Independencia"
            },
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4098,
                "iso2": "22",
                "name": "San Juan Province"
            },
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4099,
                "iso2": "28",
                "name": "Monseñor Nouel Province"
            },
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4100,
                "iso2": "26",
                "name": "Santiago Rodríguez Province"
            },
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4101,
                "iso2": "16",
                "name": "Pedernales Province"
            },
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4102,
                "iso2": "9",
                "name": "Espaillat Province"
            },
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4103,
                "iso2": "20",
                "name": "Samaná Province"
            },
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4104,
                "iso2": "27",
                "name": "Valverde Province"
            },
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4105,
                "iso2": "3",
                "name": "Baoruco Province"
            },
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4106,
                "iso2": "30",
                "name": "Hato Mayor Province"
            },
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4107,
                "iso2": "5",
                "name": "Dajabón Province"
            },
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4108,
                "iso2": "25",
                "name": "Santiago Province"
            },
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4109,
                "iso2": "11",
                "name": "La Altagracia Province"
            },
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4110,
                "iso2": "23",
                "name": "San Pedro de Macorís"
            },
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4111,
                "iso2": "29",
                "name": "Monte Plata Province"
            },
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4112,
                "iso2": "31",
                "name": "San José de Ocoa Province"
            },
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4113,
                "iso2": "6",
                "name": "Duarte Province"
            },
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4114,
                "iso2": "2",
                "name": "Azua Province"
            },
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4115,
                "iso2": "15",
                "name": "Monte Cristi Province"
            },
            {
                "country_code": "DO",
                "country_id": 62,
                "id": 4116,
                "iso2": "13",
                "name": "La Vega Province"
            }
        ]
    },
    {
        "id": 63,
        "name": "East Timor",
        "iso3": "TLS",
        "iso2": "TL",
        "states": [
            {
                "country_code": "TL",
                "country_id": 63,
                "id": 4514,
                "iso2": "VI",
                "name": "Viqueque Municipality"
            },
            {
                "country_code": "TL",
                "country_id": 63,
                "id": 4515,
                "iso2": "LI",
                "name": "Liquiçá Municipality"
            },
            {
                "country_code": "TL",
                "country_id": 63,
                "id": 4516,
                "iso2": "ER",
                "name": "Ermera District"
            },
            {
                "country_code": "TL",
                "country_id": 63,
                "id": 4517,
                "iso2": "MT",
                "name": "Manatuto District"
            },
            {
                "country_code": "TL",
                "country_id": 63,
                "id": 4518,
                "iso2": "AN",
                "name": "Ainaro Municipality"
            },
            {
                "country_code": "TL",
                "country_id": 63,
                "id": 4519,
                "iso2": "MF",
                "name": "Manufahi Municipality"
            },
            {
                "country_code": "TL",
                "country_id": 63,
                "id": 4520,
                "iso2": "AL",
                "name": "Aileu municipality"
            },
            {
                "country_code": "TL",
                "country_id": 63,
                "id": 4521,
                "iso2": "BA",
                "name": "Baucau Municipality"
            },
            {
                "country_code": "TL",
                "country_id": 63,
                "id": 4522,
                "iso2": "CO",
                "name": "Cova Lima Municipality"
            },
            {
                "country_code": "TL",
                "country_id": 63,
                "id": 4523,
                "iso2": "LA",
                "name": "Lautém Municipality"
            },
            {
                "country_code": "TL",
                "country_id": 63,
                "id": 4524,
                "iso2": "DI",
                "name": "Dili municipality"
            },
            {
                "country_code": "TL",
                "country_id": 63,
                "id": 4525,
                "iso2": "BO",
                "name": "Bobonaro Municipality"
            }
        ]
    },
    {
        "id": 64,
        "name": "Ecuador",
        "iso3": "ECU",
        "iso2": "EC",
        "states": [
            {
                "country_code": "EC",
                "country_id": 64,
                "id": 2905,
                "iso2": "W",
                "name": "Galápagos Province"
            },
            {
                "country_code": "EC",
                "country_id": 64,
                "id": 2906,
                "iso2": "U",
                "name": "Sucumbíos Province"
            },
            {
                "country_code": "EC",
                "country_id": 64,
                "id": 2907,
                "iso2": "Y",
                "name": "Pastaza Province"
            },
            {
                "country_code": "EC",
                "country_id": 64,
                "id": 2908,
                "iso2": "T",
                "name": "Tungurahua Province"
            },
            {
                "country_code": "EC",
                "country_id": 64,
                "id": 2909,
                "iso2": "Z",
                "name": "Zamora-Chinchipe Province"
            },
            {
                "country_code": "EC",
                "country_id": 64,
                "id": 2910,
                "iso2": "R",
                "name": "Los Ríos Province"
            },
            {
                "country_code": "EC",
                "country_id": 64,
                "id": 2911,
                "iso2": "I",
                "name": "Imbabura Province"
            },
            {
                "country_code": "EC",
                "country_id": 64,
                "id": 2912,
                "iso2": "SE",
                "name": "Santa Elena Province"
            },
            {
                "country_code": "EC",
                "country_id": 64,
                "id": 2913,
                "iso2": "M",
                "name": "Manabí Province"
            },
            {
                "country_code": "EC",
                "country_id": 64,
                "id": 2914,
                "iso2": "G",
                "name": "Guayas Province"
            },
            {
                "country_code": "EC",
                "country_id": 64,
                "id": 2915,
                "iso2": "C",
                "name": "Carchi Province"
            },
            {
                "country_code": "EC",
                "country_id": 64,
                "id": 2916,
                "iso2": "N",
                "name": "Napo Province"
            },
            {
                "country_code": "EC",
                "country_id": 64,
                "id": 2917,
                "iso2": "F",
                "name": "Cañar Province"
            },
            {
                "country_code": "EC",
                "country_id": 64,
                "id": 2918,
                "iso2": "S",
                "name": "Morona-Santiago Province"
            },
            {
                "country_code": "EC",
                "country_id": 64,
                "id": 2919,
                "iso2": "SD",
                "name": "Santo Domingo de los Tsáchilas Province"
            },
            {
                "country_code": "EC",
                "country_id": 64,
                "id": 2920,
                "iso2": "B",
                "name": "Bolívar Province"
            },
            {
                "country_code": "EC",
                "country_id": 64,
                "id": 2921,
                "iso2": "X",
                "name": "Cotopaxi Province"
            },
            {
                "country_code": "EC",
                "country_id": 64,
                "id": 2922,
                "iso2": "E",
                "name": "Esmeraldas"
            },
            {
                "country_code": "EC",
                "country_id": 64,
                "id": 2923,
                "iso2": "A",
                "name": "Azuay Province"
            },
            {
                "country_code": "EC",
                "country_id": 64,
                "id": 2924,
                "iso2": "O",
                "name": "El Oro Province"
            },
            {
                "country_code": "EC",
                "country_id": 64,
                "id": 2925,
                "iso2": "H",
                "name": "Chimborazo Province"
            },
            {
                "country_code": "EC",
                "country_id": 64,
                "id": 2926,
                "iso2": "D",
                "name": "Orellana Province"
            },
            {
                "country_code": "EC",
                "country_id": 64,
                "id": 2927,
                "iso2": "P",
                "name": "Pichincha Province"
            }
        ]
    },
    {
        "id": 65,
        "name": "Egypt",
        "iso3": "EGY",
        "iso2": "EG",
        "states": [
            {
                "country_code": "EG",
                "country_id": 65,
                "id": 3222,
                "iso2": "KFS",
                "name": "Kafr el-Sheikh Governorate"
            },
            {
                "country_code": "EG",
                "country_id": 65,
                "id": 3223,
                "iso2": "C",
                "name": "Cairo Governorate"
            },
            {
                "country_code": "EG",
                "country_id": 65,
                "id": 3224,
                "iso2": "DT",
                "name": "Damietta Governorate"
            },
            {
                "country_code": "EG",
                "country_id": 65,
                "id": 3225,
                "iso2": "ASN",
                "name": "Aswan Governorate"
            },
            {
                "country_code": "EG",
                "country_id": 65,
                "id": 3226,
                "iso2": "SHG",
                "name": "Sohag Governorate"
            },
            {
                "country_code": "EG",
                "country_id": 65,
                "id": 3227,
                "iso2": "SIN",
                "name": "North Sinai Governorate"
            },
            {
                "country_code": "EG",
                "country_id": 65,
                "id": 3228,
                "iso2": "MNF",
                "name": "Monufia Governorate"
            },
            {
                "country_code": "EG",
                "country_id": 65,
                "id": 3229,
                "iso2": "PTS",
                "name": "Port Said Governorate"
            },
            {
                "country_code": "EG",
                "country_id": 65,
                "id": 3230,
                "iso2": "BNS",
                "name": "Beni Suef Governorate"
            },
            {
                "country_code": "EG",
                "country_id": 65,
                "id": 3231,
                "iso2": "MT",
                "name": "Matrouh Governorate"
            },
            {
                "country_code": "EG",
                "country_id": 65,
                "id": 3232,
                "iso2": "KB",
                "name": "Qalyubia Governorate"
            },
            {
                "country_code": "EG",
                "country_id": 65,
                "id": 3233,
                "iso2": "SUZ",
                "name": "Suez Governorate"
            },
            {
                "country_code": "EG",
                "country_id": 65,
                "id": 3234,
                "iso2": "GH",
                "name": "Gharbia Governorate"
            },
            {
                "country_code": "EG",
                "country_id": 65,
                "id": 3235,
                "iso2": "ALX",
                "name": "Alexandria Governorate"
            },
            {
                "country_code": "EG",
                "country_id": 65,
                "id": 3236,
                "iso2": "AST",
                "name": "Asyut Governorate"
            },
            {
                "country_code": "EG",
                "country_id": 65,
                "id": 3237,
                "iso2": "JS",
                "name": "South Sinai Governorate"
            },
            {
                "country_code": "EG",
                "country_id": 65,
                "id": 3238,
                "iso2": "FYM",
                "name": "Faiyum Governorate"
            },
            {
                "country_code": "EG",
                "country_id": 65,
                "id": 3239,
                "iso2": "GZ",
                "name": "Giza Governorate"
            },
            {
                "country_code": "EG",
                "country_id": 65,
                "id": 3240,
                "iso2": "BA",
                "name": "Red Sea Governorate"
            },
            {
                "country_code": "EG",
                "country_id": 65,
                "id": 3241,
                "iso2": "BH",
                "name": "Beheira Governorate"
            },
            {
                "country_code": "EG",
                "country_id": 65,
                "id": 3242,
                "iso2": "LX",
                "name": "Luxor Governorate"
            },
            {
                "country_code": "EG",
                "country_id": 65,
                "id": 3243,
                "iso2": "MN",
                "name": "Minya Governorate"
            },
            {
                "country_code": "EG",
                "country_id": 65,
                "id": 3244,
                "iso2": "IS",
                "name": "Ismailia Governorate"
            },
            {
                "country_code": "EG",
                "country_id": 65,
                "id": 3245,
                "iso2": "DK",
                "name": "Dakahlia Governorate"
            },
            {
                "country_code": "EG",
                "country_id": 65,
                "id": 3246,
                "iso2": "WAD",
                "name": "New Valley Governorate"
            },
            {
                "country_code": "EG",
                "country_id": 65,
                "id": 3247,
                "iso2": "KN",
                "name": "Qena Governorate"
            }
        ]
    },
    {
        "id": 66,
        "name": "El Salvador",
        "iso3": "SLV",
        "iso2": "SV",
        "states": [
            {
                "country_code": "SV",
                "country_id": 66,
                "id": 4127,
                "iso2": "SV",
                "name": "San Vicente Department"
            },
            {
                "country_code": "SV",
                "country_id": 66,
                "id": 4128,
                "iso2": "SA",
                "name": "Santa Ana Department"
            },
            {
                "country_code": "SV",
                "country_id": 66,
                "id": 4129,
                "iso2": "US",
                "name": "Usulután Department"
            },
            {
                "country_code": "SV",
                "country_id": 66,
                "id": 4130,
                "iso2": "MO",
                "name": "Morazán Department"
            },
            {
                "country_code": "SV",
                "country_id": 66,
                "id": 4131,
                "iso2": "CH",
                "name": "Chalatenango Department"
            },
            {
                "country_code": "SV",
                "country_id": 66,
                "id": 4132,
                "iso2": "CA",
                "name": "Cabañas Department"
            },
            {
                "country_code": "SV",
                "country_id": 66,
                "id": 4133,
                "iso2": "SS",
                "name": "San Salvador Department"
            },
            {
                "country_code": "SV",
                "country_id": 66,
                "id": 4134,
                "iso2": "LI",
                "name": "La Libertad Department"
            },
            {
                "country_code": "SV",
                "country_id": 66,
                "id": 4135,
                "iso2": "SM",
                "name": "San Miguel Department"
            },
            {
                "country_code": "SV",
                "country_id": 66,
                "id": 4136,
                "iso2": "PA",
                "name": "La Paz Department"
            },
            {
                "country_code": "SV",
                "country_id": 66,
                "id": 4137,
                "iso2": "CU",
                "name": "Cuscatlán Department"
            },
            {
                "country_code": "SV",
                "country_id": 66,
                "id": 4138,
                "iso2": "UN",
                "name": "La Unión Department"
            },
            {
                "country_code": "SV",
                "country_id": 66,
                "id": 4139,
                "iso2": "AH",
                "name": "Ahuachapán Department"
            },
            {
                "country_code": "SV",
                "country_id": 66,
                "id": 4140,
                "iso2": "SO",
                "name": "Sonsonate Department"
            }
        ]
    },
    {
        "id": 67,
        "name": "Equatorial Guinea",
        "iso3": "GNQ",
        "iso2": "GQ",
        "states": [
            {
                "country_code": "GQ",
                "country_id": 67,
                "id": 3438,
                "iso2": "C",
                "name": "Río Muni"
            },
            {
                "country_code": "GQ",
                "country_id": 67,
                "id": 3439,
                "iso2": "KN",
                "name": "Kié-Ntem Province"
            },
            {
                "country_code": "GQ",
                "country_id": 67,
                "id": 3440,
                "iso2": "WN",
                "name": "Wele-Nzas Province"
            },
            {
                "country_code": "GQ",
                "country_id": 67,
                "id": 3441,
                "iso2": "LI",
                "name": "Litoral Province"
            },
            {
                "country_code": "GQ",
                "country_id": 67,
                "id": 3442,
                "iso2": "I",
                "name": "Insular Region"
            },
            {
                "country_code": "GQ",
                "country_id": 67,
                "id": 3443,
                "iso2": "BS",
                "name": "Bioko Sur Province"
            },
            {
                "country_code": "GQ",
                "country_id": 67,
                "id": 3444,
                "iso2": "AN",
                "name": "Annobón Province"
            },
            {
                "country_code": "GQ",
                "country_id": 67,
                "id": 3445,
                "iso2": "CS",
                "name": "Centro Sur Province"
            },
            {
                "country_code": "GQ",
                "country_id": 67,
                "id": 3446,
                "iso2": "BN",
                "name": "Bioko Norte Province"
            }
        ]
    },
    {
        "id": 68,
        "name": "Eritrea",
        "iso3": "ERI",
        "iso2": "ER",
        "states": [
            {
                "country_code": "ER",
                "country_id": 68,
                "id": 3424,
                "iso2": "SK",
                "name": "Northern Red Sea Region"
            },
            {
                "country_code": "ER",
                "country_id": 68,
                "id": 3425,
                "iso2": "AN",
                "name": "Anseba Region"
            },
            {
                "country_code": "ER",
                "country_id": 68,
                "id": 3426,
                "iso2": "MA",
                "name": "Maekel Region"
            },
            {
                "country_code": "ER",
                "country_id": 68,
                "id": 3427,
                "iso2": "DU",
                "name": "Debub Region"
            },
            {
                "country_code": "ER",
                "country_id": 68,
                "id": 3428,
                "iso2": "GB",
                "name": "Gash-Barka Region"
            },
            {
                "country_code": "ER",
                "country_id": 68,
                "id": 3429,
                "iso2": "DK",
                "name": "Southern Red Sea Region"
            }
        ]
    },
    {
        "id": 69,
        "name": "Estonia",
        "iso3": "EST",
        "iso2": "EE",
        "states": [
            {
                "country_code": "EE",
                "country_id": 69,
                "id": 3555,
                "iso2": "39",
                "name": "Hiiu County"
            },
            {
                "country_code": "EE",
                "country_id": 69,
                "id": 3556,
                "iso2": "84",
                "name": "Viljandi County"
            },
            {
                "country_code": "EE",
                "country_id": 69,
                "id": 3557,
                "iso2": "78",
                "name": "Tartu County"
            },
            {
                "country_code": "EE",
                "country_id": 69,
                "id": 3558,
                "iso2": "82",
                "name": "Valga County"
            },
            {
                "country_code": "EE",
                "country_id": 69,
                "id": 3559,
                "iso2": "70",
                "name": "Rapla County"
            },
            {
                "country_code": "EE",
                "country_id": 69,
                "id": 3560,
                "iso2": "86",
                "name": "Võru County"
            },
            {
                "country_code": "EE",
                "country_id": 69,
                "id": 3561,
                "iso2": "74",
                "name": "Saare County"
            },
            {
                "country_code": "EE",
                "country_id": 69,
                "id": 3562,
                "iso2": "67",
                "name": "Pärnu County"
            },
            {
                "country_code": "EE",
                "country_id": 69,
                "id": 3563,
                "iso2": "65",
                "name": "Põlva County"
            },
            {
                "country_code": "EE",
                "country_id": 69,
                "id": 3564,
                "iso2": "59",
                "name": "Lääne-Viru County"
            },
            {
                "country_code": "EE",
                "country_id": 69,
                "id": 3565,
                "iso2": "49",
                "name": "Jõgeva County"
            },
            {
                "country_code": "EE",
                "country_id": 69,
                "id": 3566,
                "iso2": "51",
                "name": "Järva County"
            },
            {
                "country_code": "EE",
                "country_id": 69,
                "id": 3567,
                "iso2": "37",
                "name": "Harju County"
            },
            {
                "country_code": "EE",
                "country_id": 69,
                "id": 3568,
                "iso2": "57",
                "name": "Lääne County"
            },
            {
                "country_code": "EE",
                "country_id": 69,
                "id": 3569,
                "iso2": "44",
                "name": "Ida-Viru County"
            }
        ]
    },
    {
        "id": 70,
        "name": "Ethiopia",
        "iso3": "ETH",
        "iso2": "ET",
        "states": [
            {
                "country_code": "ET",
                "country_id": 70,
                "id": 1,
                "iso2": "SN",
                "name": "Southern Nations, Nationalities, and Peoples' Region"
            },
            {
                "country_code": "ET",
                "country_id": 70,
                "id": 2,
                "iso2": "SO",
                "name": "Somali Region"
            },
            {
                "country_code": "ET",
                "country_id": 70,
                "id": 3,
                "iso2": "AM",
                "name": "Amhara Region"
            },
            {
                "country_code": "ET",
                "country_id": 70,
                "id": 4,
                "iso2": "TI",
                "name": "Tigray Region"
            },
            {
                "country_code": "ET",
                "country_id": 70,
                "id": 5,
                "iso2": "OR",
                "name": "Oromia Region"
            },
            {
                "country_code": "ET",
                "country_id": 70,
                "id": 6,
                "iso2": "AF",
                "name": "Afar Region"
            },
            {
                "country_code": "ET",
                "country_id": 70,
                "id": 7,
                "iso2": "HA",
                "name": "Harari Region"
            },
            {
                "country_code": "ET",
                "country_id": 70,
                "id": 8,
                "iso2": "DD",
                "name": "Dire Dawa"
            },
            {
                "country_code": "ET",
                "country_id": 70,
                "id": 9,
                "iso2": "BE",
                "name": "Benishangul-Gumuz Region"
            },
            {
                "country_code": "ET",
                "country_id": 70,
                "id": 10,
                "iso2": "GA",
                "name": "Gambela Region"
            },
            {
                "country_code": "ET",
                "country_id": 70,
                "id": 11,
                "iso2": "AA",
                "name": "Addis Ababa"
            }
        ]
    },
    {
        "id": 71,
        "name": "Falkland Islands",
        "iso3": "FLK",
        "iso2": "FK",
        "states": []
    },
    {
        "id": 72,
        "name": "Faroe Islands",
        "iso3": "FRO",
        "iso2": "FO",
        "states": []
    },
    {
        "id": 73,
        "name": "Fiji Islands",
        "iso3": "FJI",
        "iso2": "FJ",
        "states": [
            {
                "country_code": "FJ",
                "country_id": 73,
                "id": 1916,
                "iso2": "6",
                "name": "Lomaiviti"
            },
            {
                "country_code": "FJ",
                "country_id": 73,
                "id": 1917,
                "iso2": "1",
                "name": "Ba"
            },
            {
                "country_code": "FJ",
                "country_id": 73,
                "id": 1918,
                "iso2": "14",
                "name": "Tailevu"
            },
            {
                "country_code": "FJ",
                "country_id": 73,
                "id": 1919,
                "iso2": "8",
                "name": "Nadroga-Navosa"
            },
            {
                "country_code": "FJ",
                "country_id": 73,
                "id": 1920,
                "iso2": "12",
                "name": "Rewa"
            },
            {
                "country_code": "FJ",
                "country_id": 73,
                "id": 1921,
                "iso2": "N",
                "name": "Northern Division"
            },
            {
                "country_code": "FJ",
                "country_id": 73,
                "id": 1922,
                "iso2": "7",
                "name": "Macuata"
            },
            {
                "country_code": "FJ",
                "country_id": 73,
                "id": 1923,
                "iso2": "W",
                "name": "Western Division"
            },
            {
                "country_code": "FJ",
                "country_id": 73,
                "id": 1924,
                "iso2": "3",
                "name": "Cakaudrove"
            },
            {
                "country_code": "FJ",
                "country_id": 73,
                "id": 1925,
                "iso2": "13",
                "name": "Serua"
            },
            {
                "country_code": "FJ",
                "country_id": 73,
                "id": 1926,
                "iso2": "11",
                "name": "Ra"
            },
            {
                "country_code": "FJ",
                "country_id": 73,
                "id": 1927,
                "iso2": "9",
                "name": "Naitasiri"
            },
            {
                "country_code": "FJ",
                "country_id": 73,
                "id": 1928,
                "iso2": "10",
                "name": "Namosi"
            },
            {
                "country_code": "FJ",
                "country_id": 73,
                "id": 1929,
                "iso2": "C",
                "name": "Central Division"
            },
            {
                "country_code": "FJ",
                "country_id": 73,
                "id": 1930,
                "iso2": "2",
                "name": "Bua"
            },
            {
                "country_code": "FJ",
                "country_id": 73,
                "id": 1931,
                "iso2": "R",
                "name": "Rotuma"
            },
            {
                "country_code": "FJ",
                "country_id": 73,
                "id": 1932,
                "iso2": "E",
                "name": "Eastern Division"
            },
            {
                "country_code": "FJ",
                "country_id": 73,
                "id": 1933,
                "iso2": "5",
                "name": "Lau"
            },
            {
                "country_code": "FJ",
                "country_id": 73,
                "id": 1934,
                "iso2": "4",
                "name": "Kadavu"
            }
        ]
    },
    {
        "id": 74,
        "name": "Finland",
        "iso3": "FIN",
        "iso2": "FI",
        "states": [
            {
                "country_code": "FI",
                "country_id": 74,
                "id": 1492,
                "iso2": "IS",
                "name": "Eastern Finland Province"
            },
            {
                "country_code": "FI",
                "country_id": 74,
                "id": 1493,
                "iso2": "6",
                "name": "Tavastia Proper"
            },
            {
                "country_code": "FI",
                "country_id": 74,
                "id": 1494,
                "iso2": "7",
                "name": "Central Ostrobothnia"
            },
            {
                "country_code": "FI",
                "country_id": 74,
                "id": 1495,
                "iso2": "4",
                "name": "Southern Savonia"
            },
            {
                "country_code": "FI",
                "country_id": 74,
                "id": 1496,
                "iso2": "5",
                "name": "Kainuu"
            },
            {
                "country_code": "FI",
                "country_id": 74,
                "id": 1497,
                "iso2": "2",
                "name": "South Karelia"
            },
            {
                "country_code": "FI",
                "country_id": 74,
                "id": 1498,
                "iso2": "3",
                "name": "Southern Ostrobothnia"
            },
            {
                "country_code": "FI",
                "country_id": 74,
                "id": 1499,
                "iso2": "OL",
                "name": "Oulu Province"
            },
            {
                "country_code": "FI",
                "country_id": 74,
                "id": 1500,
                "iso2": "LL",
                "name": "Lapland"
            },
            {
                "country_code": "FI",
                "country_id": 74,
                "id": 1501,
                "iso2": "17",
                "name": "Satakunta"
            },
            {
                "country_code": "FI",
                "country_id": 74,
                "id": 1502,
                "iso2": "16",
                "name": "Päijänne Tavastia"
            },
            {
                "country_code": "FI",
                "country_id": 74,
                "id": 1503,
                "iso2": "15",
                "name": "Northern Savonia"
            },
            {
                "country_code": "FI",
                "country_id": 74,
                "id": 1504,
                "iso2": "13",
                "name": "North Karelia"
            },
            {
                "country_code": "FI",
                "country_id": 74,
                "id": 1505,
                "iso2": "14",
                "name": "Northern Ostrobothnia"
            },
            {
                "country_code": "FI",
                "country_id": 74,
                "id": 1506,
                "iso2": "11",
                "name": "Pirkanmaa"
            },
            {
                "country_code": "FI",
                "country_id": 74,
                "id": 1507,
                "iso2": "19",
                "name": "Finland Proper"
            },
            {
                "country_code": "FI",
                "country_id": 74,
                "id": 1508,
                "iso2": "12",
                "name": "Ostrobothnia"
            },
            {
                "country_code": "FI",
                "country_id": 74,
                "id": 1509,
                "iso2": "1",
                "name": "Åland Islands"
            },
            {
                "country_code": "FI",
                "country_id": 74,
                "id": 1510,
                "iso2": "18",
                "name": "Uusimaa"
            },
            {
                "country_code": "FI",
                "country_id": 74,
                "id": 1511,
                "iso2": "8",
                "name": "Central Finland"
            },
            {
                "country_code": "FI",
                "country_id": 74,
                "id": 1512,
                "iso2": "9",
                "name": "Kymenlaakso"
            }
        ]
    },
    {
        "id": 75,
        "name": "France",
        "iso3": "FRA",
        "iso2": "FR",
        "states": [
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4788,
                "iso2": "D",
                "name": "Burgundy"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4789,
                "iso2": "C",
                "name": "Auvergne"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4790,
                "iso2": "S",
                "name": "Picardy"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4791,
                "iso2": "G",
                "name": "Champagne-Ardenne"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4792,
                "iso2": "L",
                "name": "Limousin"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4793,
                "iso2": "O",
                "name": "Nord-Pas-de-Calais"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4794,
                "iso2": "BL",
                "name": "Saint Barthélemy"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4795,
                "iso2": "NAQ",
                "name": "Nouvelle-Aquitaine"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4796,
                "iso2": "IDF",
                "name": "Île-de-France"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4797,
                "iso2": "YT",
                "name": "Mayotte"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4798,
                "iso2": "ARA",
                "name": "Auvergne-Rhône-Alpes"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4799,
                "iso2": "OCC",
                "name": "Occitania"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4800,
                "iso2": "WF-AL",
                "name": "Alo"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4801,
                "iso2": "M",
                "name": "Lorraine"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4802,
                "iso2": "PDL",
                "name": "Pays de la Loire"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4803,
                "iso2": "K",
                "name": "Languedoc-Roussillon"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4804,
                "iso2": "NOR",
                "name": "Normandy"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4805,
                "iso2": "I",
                "name": "Franche-Comté"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4806,
                "iso2": "COR",
                "name": "Corsica"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4807,
                "iso2": "BRE",
                "name": "Brittany"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4808,
                "iso2": "B",
                "name": "Aquitaine"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4809,
                "iso2": "MF",
                "name": "Saint Martin"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4810,
                "iso2": "WF",
                "name": "Wallis and Futuna"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4811,
                "iso2": "A",
                "name": "Alsace"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4812,
                "iso2": "PAC",
                "name": "Provence-Alpes-Côte d'Azur"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4813,
                "iso2": "V",
                "name": "Rhône-Alpes"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4814,
                "iso2": "P",
                "name": "Lower Normandy"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4815,
                "iso2": "T",
                "name": "Poitou-Charentes"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4816,
                "iso2": "75",
                "name": "Paris"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4817,
                "iso2": "WF-UV",
                "name": "Uvea"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4818,
                "iso2": "CVL",
                "name": "Centre-Val de Loire"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4819,
                "iso2": "WF-SG",
                "name": "Sigave"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4820,
                "iso2": "GES",
                "name": "Grand Est"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4821,
                "iso2": "PM",
                "name": "Saint Pierre and Miquelon"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4822,
                "iso2": "GF",
                "name": "French Guiana"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4823,
                "iso2": "RE",
                "name": "Réunion"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4824,
                "iso2": "PF",
                "name": "French Polynesia"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4825,
                "iso2": "BFC",
                "name": "Bourgogne-Franche-Comté"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4826,
                "iso2": "Q",
                "name": "Upper Normandy"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4827,
                "iso2": "MQ",
                "name": "Martinique"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4828,
                "iso2": "HDF",
                "name": "Hauts-de-France"
            },
            {
                "country_code": "FR",
                "country_id": 75,
                "id": 4829,
                "iso2": "GP",
                "name": "Guadeloupe"
            }
        ]
    },
    {
        "id": 76,
        "name": "French Guiana",
        "iso3": "GUF",
        "iso2": "GF",
        "states": []
    },
    {
        "id": 77,
        "name": "French Polynesia",
        "iso3": "PYF",
        "iso2": "PF",
        "states": []
    },
    {
        "id": 78,
        "name": "French Southern Territories",
        "iso3": "ATF",
        "iso2": "TF",
        "states": []
    },
    {
        "id": 79,
        "name": "Gabon",
        "iso3": "GAB",
        "iso2": "GA",
        "states": [
            {
                "country_code": "GA",
                "country_id": 79,
                "id": 2723,
                "iso2": "9",
                "name": "Woleu-Ntem Province"
            },
            {
                "country_code": "GA",
                "country_id": 79,
                "id": 2724,
                "iso2": "6",
                "name": "Ogooué-Ivindo Province"
            },
            {
                "country_code": "GA",
                "country_id": 79,
                "id": 2725,
                "iso2": "5",
                "name": "Nyanga Province"
            },
            {
                "country_code": "GA",
                "country_id": 79,
                "id": 2726,
                "iso2": "2",
                "name": "Haut-Ogooué Province"
            },
            {
                "country_code": "GA",
                "country_id": 79,
                "id": 2727,
                "iso2": "1",
                "name": "Estuaire Province"
            },
            {
                "country_code": "GA",
                "country_id": 79,
                "id": 2728,
                "iso2": "8",
                "name": "Ogooué-Maritime Province"
            },
            {
                "country_code": "GA",
                "country_id": 79,
                "id": 2729,
                "iso2": "7",
                "name": "Ogooué-Lolo Province"
            },
            {
                "country_code": "GA",
                "country_id": 79,
                "id": 2730,
                "iso2": "3",
                "name": "Moyen-Ogooué Province"
            },
            {
                "country_code": "GA",
                "country_id": 79,
                "id": 2731,
                "iso2": "4",
                "name": "Ngounié Province"
            }
        ]
    },
    {
        "id": 80,
        "name": "Gambia The",
        "iso3": "GMB",
        "iso2": "GM",
        "states": [
            {
                "country_code": "GM",
                "country_id": 80,
                "id": 2666,
                "iso2": "B",
                "name": "Banjul"
            },
            {
                "country_code": "GM",
                "country_id": 80,
                "id": 2667,
                "iso2": "W",
                "name": "West Coast Division"
            },
            {
                "country_code": "GM",
                "country_id": 80,
                "id": 2668,
                "iso2": "U",
                "name": "Upper River Division"
            },
            {
                "country_code": "GM",
                "country_id": 80,
                "id": 2669,
                "iso2": "M",
                "name": "Central River Division"
            },
            {
                "country_code": "GM",
                "country_id": 80,
                "id": 2670,
                "iso2": "L",
                "name": "Lower River Division"
            },
            {
                "country_code": "GM",
                "country_id": 80,
                "id": 2671,
                "iso2": "N",
                "name": "North Bank Division"
            }
        ]
    },
    {
        "id": 81,
        "name": "Georgia",
        "iso3": "GEO",
        "iso2": "GE",
        "states": [
            {
                "country_code": "GE",
                "country_id": 81,
                "id": 897,
                "iso2": "29",
                "name": "Khelvachauri Municipality"
            },
            {
                "country_code": "GE",
                "country_id": 81,
                "id": 898,
                "iso2": "50",
                "name": "Senaki Municipality"
            },
            {
                "country_code": "GE",
                "country_id": 81,
                "id": 899,
                "iso2": "TB",
                "name": "Tbilisi"
            },
            {
                "country_code": "GE",
                "country_id": 81,
                "id": 900,
                "iso2": "AJ",
                "name": "Adjara"
            },
            {
                "country_code": "GE",
                "country_id": 81,
                "id": 901,
                "iso2": "AB",
                "name": "Autonomous Republic of Abkhazia"
            },
            {
                "country_code": "GE",
                "country_id": 81,
                "id": 902,
                "iso2": "MM",
                "name": "Mtskheta-Mtianeti"
            },
            {
                "country_code": "GE",
                "country_id": 81,
                "id": 903,
                "iso2": "SK",
                "name": "Shida Kartli"
            },
            {
                "country_code": "GE",
                "country_id": 81,
                "id": 904,
                "iso2": "KK",
                "name": "Kvemo Kartli"
            },
            {
                "country_code": "GE",
                "country_id": 81,
                "id": 905,
                "iso2": "IM",
                "name": "Imereti"
            },
            {
                "country_code": "GE",
                "country_id": 81,
                "id": 906,
                "iso2": "SJ",
                "name": "Samtskhe-Javakheti"
            },
            {
                "country_code": "GE",
                "country_id": 81,
                "id": 907,
                "iso2": "GU",
                "name": "Guria"
            },
            {
                "country_code": "GE",
                "country_id": 81,
                "id": 908,
                "iso2": "SZ",
                "name": "Samegrelo-Zemo Svaneti"
            },
            {
                "country_code": "GE",
                "country_id": 81,
                "id": 909,
                "iso2": "RL",
                "name": "Racha-Lechkhumi and Kvemo Svaneti"
            },
            {
                "country_code": "GE",
                "country_id": 81,
                "id": 910,
                "iso2": "KA",
                "name": "Kakheti"
            }
        ]
    },
    {
        "id": 82,
        "name": "Germany",
        "iso3": "DEU",
        "iso2": "DE",
        "states": [
            {
                "country_code": "DE",
                "country_id": 82,
                "id": 3005,
                "iso2": "SH",
                "name": "Schleswig-Holstein"
            },
            {
                "country_code": "DE",
                "country_id": 82,
                "id": 3006,
                "iso2": "BW",
                "name": "Baden-Württemberg"
            },
            {
                "country_code": "DE",
                "country_id": 82,
                "id": 3007,
                "iso2": "MV",
                "name": "Mecklenburg-Vorpommern"
            },
            {
                "country_code": "DE",
                "country_id": 82,
                "id": 3008,
                "iso2": "NI",
                "name": "Lower Saxony"
            },
            {
                "country_code": "DE",
                "country_id": 82,
                "id": 3009,
                "iso2": "BY",
                "name": "Bavaria"
            },
            {
                "country_code": "DE",
                "country_id": 82,
                "id": 3010,
                "iso2": "BE",
                "name": "Berlin"
            },
            {
                "country_code": "DE",
                "country_id": 82,
                "id": 3011,
                "iso2": "ST",
                "name": "Saxony-Anhalt"
            },
            {
                "country_code": "DE",
                "country_id": 82,
                "id": 3013,
                "iso2": "BB",
                "name": "Brandenburg"
            },
            {
                "country_code": "DE",
                "country_id": 82,
                "id": 3014,
                "iso2": "HB",
                "name": "Bremen"
            },
            {
                "country_code": "DE",
                "country_id": 82,
                "id": 3015,
                "iso2": "TH",
                "name": "Thuringia"
            },
            {
                "country_code": "DE",
                "country_id": 82,
                "id": 3016,
                "iso2": "HH",
                "name": "Hamburg"
            },
            {
                "country_code": "DE",
                "country_id": 82,
                "id": 3017,
                "iso2": "NW",
                "name": "North Rhine-Westphalia"
            },
            {
                "country_code": "DE",
                "country_id": 82,
                "id": 3018,
                "iso2": "HE",
                "name": "Hesse"
            },
            {
                "country_code": "DE",
                "country_id": 82,
                "id": 3019,
                "iso2": "RP",
                "name": "Rhineland-Palatinate"
            },
            {
                "country_code": "DE",
                "country_id": 82,
                "id": 3020,
                "iso2": "SL",
                "name": "Saarland"
            },
            {
                "country_code": "DE",
                "country_id": 82,
                "id": 3021,
                "iso2": "SN",
                "name": "Saxony"
            }
        ]
    },
    {
        "id": 83,
        "name": "Ghana",
        "iso3": "GHA",
        "iso2": "GH",
        "states": [
            {
                "country_code": "GH",
                "country_id": 83,
                "id": 48,
                "iso2": "AH",
                "name": "Ashanti Region"
            },
            {
                "country_code": "GH",
                "country_id": 83,
                "id": 49,
                "iso2": "WP",
                "name": "Western Region"
            },
            {
                "country_code": "GH",
                "country_id": 83,
                "id": 50,
                "iso2": "EP",
                "name": "Eastern Region"
            },
            {
                "country_code": "GH",
                "country_id": 83,
                "id": 51,
                "iso2": "NP",
                "name": "Northern Region"
            },
            {
                "country_code": "GH",
                "country_id": 83,
                "id": 52,
                "iso2": "CP",
                "name": "Central Region"
            },
            {
                "country_code": "GH",
                "country_id": 83,
                "id": 53,
                "iso2": "BA",
                "name": "Brong-Ahafo Region"
            },
            {
                "country_code": "GH",
                "country_id": 83,
                "id": 54,
                "iso2": "AA",
                "name": "Greater Accra Region"
            },
            {
                "country_code": "GH",
                "country_id": 83,
                "id": 55,
                "iso2": "UE",
                "name": "Upper East Region"
            },
            {
                "country_code": "GH",
                "country_id": 83,
                "id": 56,
                "iso2": "TV",
                "name": "Volta Region"
            },
            {
                "country_code": "GH",
                "country_id": 83,
                "id": 57,
                "iso2": "UW",
                "name": "Upper West Region"
            }
        ]
    },
    {
        "id": 84,
        "name": "Gibraltar",
        "iso3": "GIB",
        "iso2": "GI",
        "states": []
    },
    {
        "id": 85,
        "name": "Greece",
        "iso3": "GRC",
        "iso2": "GR",
        "states": [
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2095,
                "iso2": "41",
                "name": "Karditsa Regional Unit"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2096,
                "iso2": "G",
                "name": "West Greece Region"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2097,
                "iso2": "54",
                "name": "Thessaloniki Regional Unit"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2098,
                "iso2": "12",
                "name": "Arcadia Prefecture"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2099,
                "iso2": "53",
                "name": "Imathia Regional Unit"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2100,
                "iso2": "56",
                "name": "Kastoria Regional Unit"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2101,
                "iso2": "4",
                "name": "Euboea"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2102,
                "iso2": "51",
                "name": "Grevena Prefecture"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2103,
                "iso2": "34",
                "name": "Preveza Prefecture"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2104,
                "iso2": "24",
                "name": "Lefkada Regional Unit"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2105,
                "iso2": "11",
                "name": "Argolis Regional Unit"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2106,
                "iso2": "16",
                "name": "Laconia"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2107,
                "iso2": "59",
                "name": "Pella Regional Unit"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2108,
                "iso2": "C",
                "name": "West Macedonia Region"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2109,
                "iso2": "M",
                "name": "Crete Region"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2110,
                "iso2": "D",
                "name": "Epirus Region"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2111,
                "iso2": "57",
                "name": "Kilkis Regional Unit"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2112,
                "iso2": "58",
                "name": "Kozani Prefecture"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2113,
                "iso2": "33",
                "name": "Ioannina Regional Unit"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2114,
                "iso2": "6",
                "name": "Phthiotis Prefecture"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2115,
                "iso2": "94",
                "name": "Chania Regional Unit"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2116,
                "iso2": "13",
                "name": "Achaea Regional Unit"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2117,
                "iso2": "A",
                "name": "East Macedonia and Thrace"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2118,
                "iso2": "L",
                "name": "South Aegean"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2119,
                "iso2": "J",
                "name": "Peloponnese Region"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2120,
                "iso2": "A2",
                "name": "East Attica Regional Unit"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2121,
                "iso2": "62",
                "name": "Serres Prefecture"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2122,
                "iso2": "I",
                "name": "Attica Region"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2123,
                "iso2": "1",
                "name": "Aetolia-Acarnania Regional Unit"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2124,
                "iso2": "22",
                "name": "Corfu Prefecture"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2125,
                "iso2": "B",
                "name": "Central Macedonia"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2126,
                "iso2": "3",
                "name": "Boeotia Regional Unit"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2127,
                "iso2": "23",
                "name": "Kefalonia Prefecture"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2128,
                "iso2": "H",
                "name": "Central Greece Region"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2129,
                "iso2": "15",
                "name": "Corinthia Regional Unit"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2130,
                "iso2": "52",
                "name": "Drama Regional Unit"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2131,
                "iso2": "F",
                "name": "Ionian Islands Region"
            },
            {
                "country_code": "GR",
                "country_id": 85,
                "id": 2132,
                "iso2": "42",
                "name": "Larissa Prefecture"
            }
        ]
    },
    {
        "id": 86,
        "name": "Greenland",
        "iso3": "GRL",
        "iso2": "GL",
        "states": []
    },
    {
        "id": 87,
        "name": "Grenada",
        "iso3": "GRD",
        "iso2": "GD",
        "states": [
            {
                "country_code": "GD",
                "country_id": 87,
                "id": 3863,
                "iso2": "6",
                "name": "Saint Patrick Parish"
            },
            {
                "country_code": "GD",
                "country_id": 87,
                "id": 3864,
                "iso2": "3",
                "name": "Saint George Parish"
            },
            {
                "country_code": "GD",
                "country_id": 87,
                "id": 3865,
                "iso2": "1",
                "name": "Saint Andrew Parish"
            },
            {
                "country_code": "GD",
                "country_id": 87,
                "id": 3866,
                "iso2": "5",
                "name": "Saint Mark Parish"
            },
            {
                "country_code": "GD",
                "country_id": 87,
                "id": 3867,
                "iso2": "10",
                "name": "Carriacou and Petite Martinique"
            },
            {
                "country_code": "GD",
                "country_id": 87,
                "id": 3868,
                "iso2": "4",
                "name": "Saint John Parish"
            },
            {
                "country_code": "GD",
                "country_id": 87,
                "id": 3869,
                "iso2": "2",
                "name": "Saint David Parish"
            }
        ]
    },
    {
        "id": 88,
        "name": "Guadeloupe",
        "iso3": "GLP",
        "iso2": "GP",
        "states": []
    },
    {
        "id": 89,
        "name": "Guam",
        "iso3": "GUM",
        "iso2": "GU",
        "states": []
    },
    {
        "id": 90,
        "name": "Guatemala",
        "iso3": "GTM",
        "iso2": "GT",
        "states": [
            {
                "country_code": "GT",
                "country_id": 90,
                "id": 3657,
                "iso2": "QC",
                "name": "Quiché Department"
            },
            {
                "country_code": "GT",
                "country_id": 90,
                "id": 3658,
                "iso2": "JA",
                "name": "Jalapa Department"
            },
            {
                "country_code": "GT",
                "country_id": 90,
                "id": 3659,
                "iso2": "IZ",
                "name": "Izabal Department"
            },
            {
                "country_code": "GT",
                "country_id": 90,
                "id": 3660,
                "iso2": "SU",
                "name": "Suchitepéquez Department"
            },
            {
                "country_code": "GT",
                "country_id": 90,
                "id": 3661,
                "iso2": "SO",
                "name": "Sololá Department"
            },
            {
                "country_code": "GT",
                "country_id": 90,
                "id": 3662,
                "iso2": "PR",
                "name": "El Progreso Department"
            },
            {
                "country_code": "GT",
                "country_id": 90,
                "id": 3663,
                "iso2": "TO",
                "name": "Totonicapán Department"
            },
            {
                "country_code": "GT",
                "country_id": 90,
                "id": 3664,
                "iso2": "RE",
                "name": "Retalhuleu Department"
            },
            {
                "country_code": "GT",
                "country_id": 90,
                "id": 3665,
                "iso2": "SR",
                "name": "Santa Rosa Department"
            },
            {
                "country_code": "GT",
                "country_id": 90,
                "id": 3666,
                "iso2": "CQ",
                "name": "Chiquimula Department"
            },
            {
                "country_code": "GT",
                "country_id": 90,
                "id": 3667,
                "iso2": "SM",
                "name": "San Marcos Department"
            },
            {
                "country_code": "GT",
                "country_id": 90,
                "id": 3668,
                "iso2": "QZ",
                "name": "Quetzaltenango Department"
            },
            {
                "country_code": "GT",
                "country_id": 90,
                "id": 3669,
                "iso2": "PE",
                "name": "Petén Department"
            },
            {
                "country_code": "GT",
                "country_id": 90,
                "id": 3670,
                "iso2": "HU",
                "name": "Huehuetenango Department"
            },
            {
                "country_code": "GT",
                "country_id": 90,
                "id": 3671,
                "iso2": "AV",
                "name": "Alta Verapaz Department"
            },
            {
                "country_code": "GT",
                "country_id": 90,
                "id": 3672,
                "iso2": "GU",
                "name": "Guatemala Department"
            },
            {
                "country_code": "GT",
                "country_id": 90,
                "id": 3673,
                "iso2": "JU",
                "name": "Jutiapa Department"
            },
            {
                "country_code": "GT",
                "country_id": 90,
                "id": 3674,
                "iso2": "BV",
                "name": "Baja Verapaz Department"
            },
            {
                "country_code": "GT",
                "country_id": 90,
                "id": 3675,
                "iso2": "CM",
                "name": "Chimaltenango Department"
            },
            {
                "country_code": "GT",
                "country_id": 90,
                "id": 3676,
                "iso2": "SA",
                "name": "Sacatepéquez Department"
            },
            {
                "country_code": "GT",
                "country_id": 90,
                "id": 3677,
                "iso2": "ES",
                "name": "Escuintla Department"
            }
        ]
    },
    {
        "id": 91,
        "name": "Guernsey and Alderney",
        "iso3": "GGY",
        "iso2": "GG",
        "states": []
    },
    {
        "id": 92,
        "name": "Guinea",
        "iso3": "GIN",
        "iso2": "GN",
        "states": [
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2672,
                "iso2": "BE",
                "name": "Beyla Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2673,
                "iso2": "MD",
                "name": "Mandiana Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2674,
                "iso2": "YO",
                "name": "Yomou Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2675,
                "iso2": "FR",
                "name": "Fria Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2676,
                "iso2": "B",
                "name": "Boké Region"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2677,
                "iso2": "L",
                "name": "Labé Region"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2678,
                "iso2": "NZ",
                "name": "Nzérékoré Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2679,
                "iso2": "DB",
                "name": "Dabola Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2680,
                "iso2": "LA",
                "name": "Labé Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2681,
                "iso2": "DU",
                "name": "Dubréka Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2682,
                "iso2": "FA",
                "name": "Faranah Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2683,
                "iso2": "FO",
                "name": "Forécariah Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2684,
                "iso2": "N",
                "name": "Nzérékoré Region"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2685,
                "iso2": "GA",
                "name": "Gaoual Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2686,
                "iso2": "C",
                "name": "Conakry"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2687,
                "iso2": "TE",
                "name": "Télimélé Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2688,
                "iso2": "DI",
                "name": "Dinguiraye Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2689,
                "iso2": "MM",
                "name": "Mamou Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2690,
                "iso2": "LE",
                "name": "Lélouma Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2691,
                "iso2": "KS",
                "name": "Kissidougou Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2692,
                "iso2": "KB",
                "name": "Koubia Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2693,
                "iso2": "KD",
                "name": "Kindia Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2694,
                "iso2": "PI",
                "name": "Pita Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2695,
                "iso2": "KO",
                "name": "Kouroussa Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2696,
                "iso2": "TO",
                "name": "Tougué Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2697,
                "iso2": "K",
                "name": "Kankan Region"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2698,
                "iso2": "M",
                "name": "Mamou Region"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2699,
                "iso2": "BF",
                "name": "Boffa Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2700,
                "iso2": "ML",
                "name": "Mali Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2701,
                "iso2": "D",
                "name": "Kindia Region"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2702,
                "iso2": "MC",
                "name": "Macenta Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2703,
                "iso2": "KN",
                "name": "Koundara Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2704,
                "iso2": "KA",
                "name": "Kankan Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2705,
                "iso2": "CO",
                "name": "Coyah Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2706,
                "iso2": "DL",
                "name": "Dalaba Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2707,
                "iso2": "SI",
                "name": "Siguiri Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2708,
                "iso2": "LO",
                "name": "Lola Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2709,
                "iso2": "BK",
                "name": "Boké Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2710,
                "iso2": "KE",
                "name": "Kérouané Prefecture"
            },
            {
                "country_code": "GN",
                "country_id": 92,
                "id": 2711,
                "iso2": "GU",
                "name": "Guéckédou Prefecture"
            }
        ]
    },
    {
        "id": 93,
        "name": "Guinea-Bissau",
        "iso3": "GNB",
        "iso2": "GW",
        "states": [
            {
                "country_code": "GW",
                "country_id": 93,
                "id": 2712,
                "iso2": "TO",
                "name": "Tombali Region"
            },
            {
                "country_code": "GW",
                "country_id": 93,
                "id": 2713,
                "iso2": "CA",
                "name": "Cacheu Region"
            },
            {
                "country_code": "GW",
                "country_id": 93,
                "id": 2714,
                "iso2": "BM",
                "name": "Biombo Region"
            },
            {
                "country_code": "GW",
                "country_id": 93,
                "id": 2715,
                "iso2": "QU",
                "name": "Quinara Region"
            },
            {
                "country_code": "GW",
                "country_id": 93,
                "id": 2716,
                "iso2": "S",
                "name": "Sul Province"
            },
            {
                "country_code": "GW",
                "country_id": 93,
                "id": 2717,
                "iso2": "N",
                "name": "Norte Province"
            },
            {
                "country_code": "GW",
                "country_id": 93,
                "id": 2718,
                "iso2": "OI",
                "name": "Oio Region"
            },
            {
                "country_code": "GW",
                "country_id": 93,
                "id": 2719,
                "iso2": "GA",
                "name": "Gabú Region"
            },
            {
                "country_code": "GW",
                "country_id": 93,
                "id": 2720,
                "iso2": "BA",
                "name": "Bafatá"
            },
            {
                "country_code": "GW",
                "country_id": 93,
                "id": 2721,
                "iso2": "L",
                "name": "Leste Province"
            },
            {
                "country_code": "GW",
                "country_id": 93,
                "id": 2722,
                "iso2": "BL",
                "name": "Bolama Region"
            }
        ]
    },
    {
        "id": 94,
        "name": "Guyana",
        "iso3": "GUY",
        "iso2": "GY",
        "states": [
            {
                "country_code": "GY",
                "country_id": 94,
                "id": 2760,
                "iso2": "CU",
                "name": "Cuyuni-Mazaruni"
            },
            {
                "country_code": "GY",
                "country_id": 94,
                "id": 2761,
                "iso2": "PT",
                "name": "Potaro-Siparuni"
            },
            {
                "country_code": "GY",
                "country_id": 94,
                "id": 2762,
                "iso2": "MA",
                "name": "Mahaica-Berbice"
            },
            {
                "country_code": "GY",
                "country_id": 94,
                "id": 2763,
                "iso2": "UD",
                "name": "Upper Demerara-Berbice"
            },
            {
                "country_code": "GY",
                "country_id": 94,
                "id": 2764,
                "iso2": "BA",
                "name": "Barima-Waini"
            },
            {
                "country_code": "GY",
                "country_id": 94,
                "id": 2765,
                "iso2": "PM",
                "name": "Pomeroon-Supenaam"
            },
            {
                "country_code": "GY",
                "country_id": 94,
                "id": 2766,
                "iso2": "EB",
                "name": "East Berbice-Corentyne"
            },
            {
                "country_code": "GY",
                "country_id": 94,
                "id": 2767,
                "iso2": "DE",
                "name": "Demerara-Mahaica"
            },
            {
                "country_code": "GY",
                "country_id": 94,
                "id": 2768,
                "iso2": "ES",
                "name": "Essequibo Islands-West Demerara"
            },
            {
                "country_code": "GY",
                "country_id": 94,
                "id": 2769,
                "iso2": "UT",
                "name": "Upper Takutu-Upper Essequibo"
            }
        ]
    },
    {
        "id": 95,
        "name": "Haiti",
        "iso3": "HTI",
        "iso2": "HT",
        "states": [
            {
                "country_code": "HT",
                "country_id": 95,
                "id": 4117,
                "iso2": "ND",
                "name": "Nord"
            },
            {
                "country_code": "HT",
                "country_id": 95,
                "id": 4118,
                "iso2": "NI",
                "name": "Nippes"
            },
            {
                "country_code": "HT",
                "country_id": 95,
                "id": 4119,
                "iso2": "GA",
                "name": "Grand'Anse"
            },
            {
                "country_code": "HT",
                "country_id": 95,
                "id": 4120,
                "iso2": "OU",
                "name": "Ouest"
            },
            {
                "country_code": "HT",
                "country_id": 95,
                "id": 4121,
                "iso2": "NE",
                "name": "Nord-Est"
            },
            {
                "country_code": "HT",
                "country_id": 95,
                "id": 4122,
                "iso2": "SD",
                "name": "Sud"
            },
            {
                "country_code": "HT",
                "country_id": 95,
                "id": 4123,
                "iso2": "AR",
                "name": "Artibonite"
            },
            {
                "country_code": "HT",
                "country_id": 95,
                "id": 4124,
                "iso2": "SE",
                "name": "Sud-Est"
            },
            {
                "country_code": "HT",
                "country_id": 95,
                "id": 4125,
                "iso2": "CE",
                "name": "Centre"
            },
            {
                "country_code": "HT",
                "country_id": 95,
                "id": 4126,
                "iso2": "NO",
                "name": "Nord-Ouest"
            }
        ]
    },
    {
        "id": 96,
        "name": "Heard Island and McDonald Islands",
        "iso3": "HMD",
        "iso2": "HM",
        "states": []
    },
    {
        "id": 97,
        "name": "Honduras",
        "iso3": "HND",
        "iso2": "HN",
        "states": [
            {
                "country_code": "HN",
                "country_id": 97,
                "id": 4041,
                "iso2": "CH",
                "name": "Choluteca Department"
            },
            {
                "country_code": "HN",
                "country_id": 97,
                "id": 4042,
                "iso2": "CM",
                "name": "Comayagua Department"
            },
            {
                "country_code": "HN",
                "country_id": 97,
                "id": 4043,
                "iso2": "EP",
                "name": "El Paraíso Department"
            },
            {
                "country_code": "HN",
                "country_id": 97,
                "id": 4044,
                "iso2": "IN",
                "name": "Intibucá Department"
            },
            {
                "country_code": "HN",
                "country_id": 97,
                "id": 4045,
                "iso2": "IB",
                "name": "Bay Islands Department"
            },
            {
                "country_code": "HN",
                "country_id": 97,
                "id": 4046,
                "iso2": "CR",
                "name": "Cortés Department"
            },
            {
                "country_code": "HN",
                "country_id": 97,
                "id": 4047,
                "iso2": "AT",
                "name": "Atlántida Department"
            },
            {
                "country_code": "HN",
                "country_id": 97,
                "id": 4048,
                "iso2": "GD",
                "name": "Gracias a Dios Department"
            },
            {
                "country_code": "HN",
                "country_id": 97,
                "id": 4049,
                "iso2": "CP",
                "name": "Copán Department"
            },
            {
                "country_code": "HN",
                "country_id": 97,
                "id": 4050,
                "iso2": "OL",
                "name": "Olancho Department"
            },
            {
                "country_code": "HN",
                "country_id": 97,
                "id": 4051,
                "iso2": "CL",
                "name": "Colón Department"
            },
            {
                "country_code": "HN",
                "country_id": 97,
                "id": 4052,
                "iso2": "FM",
                "name": "Francisco Morazán Department"
            },
            {
                "country_code": "HN",
                "country_id": 97,
                "id": 4053,
                "iso2": "SB",
                "name": "Santa Bárbara Department"
            },
            {
                "country_code": "HN",
                "country_id": 97,
                "id": 4054,
                "iso2": "LE",
                "name": "Lempira Department"
            },
            {
                "country_code": "HN",
                "country_id": 97,
                "id": 4055,
                "iso2": "VA",
                "name": "Valle Department"
            },
            {
                "country_code": "HN",
                "country_id": 97,
                "id": 4056,
                "iso2": "OC",
                "name": "Ocotepeque Department"
            },
            {
                "country_code": "HN",
                "country_id": 97,
                "id": 4057,
                "iso2": "YO",
                "name": "Yoro Department"
            },
            {
                "country_code": "HN",
                "country_id": 97,
                "id": 4058,
                "iso2": "LP",
                "name": "La Paz Department"
            }
        ]
    },
    {
        "id": 98,
        "name": "Hong Kong S.A.R.",
        "iso3": "HKG",
        "iso2": "HK",
        "states": [
            {
                "country_code": "HK",
                "country_id": 98,
                "id": 4883,
                "iso2": "NYL",
                "name": "Yuen Long District"
            },
            {
                "country_code": "HK",
                "country_id": 98,
                "id": 4884,
                "iso2": "NTW",
                "name": "Tsuen Wan District"
            },
            {
                "country_code": "KH",
                "country_id": 98,
                "id": 4885,
                "iso2": "NTP",
                "name": "Tai Po District"
            },
            {
                "country_code": "HK",
                "country_id": 98,
                "id": 4887,
                "iso2": "NSK",
                "name": "Sai Kung District"
            },
            {
                "country_code": "HK",
                "country_id": 98,
                "id": 4888,
                "iso2": "NIS",
                "name": "Islands District"
            },
            {
                "country_code": "HK",
                "country_id": 98,
                "id": 4889,
                "iso2": "HCW",
                "name": "Central and Western District"
            },
            {
                "country_code": "HK",
                "country_id": 98,
                "id": 4890,
                "iso2": "HWC",
                "name": "Wan Chai"
            },
            {
                "country_code": "HK",
                "country_id": 98,
                "id": 4891,
                "iso2": "HEA",
                "name": "Eastern"
            },
            {
                "country_code": "HK",
                "country_id": 98,
                "id": 4892,
                "iso2": "HSO",
                "name": "Southern"
            },
            {
                "country_code": "HK",
                "country_id": 98,
                "id": 4893,
                "iso2": "KYT",
                "name": "Yau Tsim Mong"
            },
            {
                "country_code": "HK",
                "country_id": 98,
                "id": 4894,
                "iso2": "KSS",
                "name": "Sham Shui Po"
            },
            {
                "country_code": "HK",
                "country_id": 98,
                "id": 4895,
                "iso2": "KKC",
                "name": "Kowloon City"
            },
            {
                "country_code": "HK",
                "country_id": 98,
                "id": 4896,
                "iso2": "KWT",
                "name": "Wong Tai Sin"
            },
            {
                "country_code": "HK",
                "country_id": 98,
                "id": 4897,
                "iso2": "KKT",
                "name": "Kwun Tong"
            },
            {
                "country_code": "HK",
                "country_id": 98,
                "id": 4898,
                "iso2": "NKT",
                "name": "Kwai Tsing"
            },
            {
                "country_code": "HK",
                "country_id": 98,
                "id": 4899,
                "iso2": "NTM",
                "name": "Tuen Mun"
            },
            {
                "country_code": "HK",
                "country_id": 98,
                "id": 4900,
                "iso2": "NNO",
                "name": "North"
            },
            {
                "country_code": "HK",
                "country_id": 98,
                "id": 4901,
                "iso2": "NST",
                "name": "Sha Tin"
            }
        ]
    },
    {
        "id": 99,
        "name": "Hungary",
        "iso3": "HUN",
        "iso2": "HU",
        "states": [
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1027,
                "iso2": "HV",
                "name": "Hódmezővásárhely"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1028,
                "iso2": "ER",
                "name": "Érd"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1029,
                "iso2": "SD",
                "name": "Szeged"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1030,
                "iso2": "NK",
                "name": "Nagykanizsa"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1031,
                "iso2": "CS",
                "name": "Csongrád County"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1032,
                "iso2": "DE",
                "name": "Debrecen"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1033,
                "iso2": "SF",
                "name": "Székesfehérvár"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1034,
                "iso2": "NY",
                "name": "Nyíregyháza"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1035,
                "iso2": "SO",
                "name": "Somogy County"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1036,
                "iso2": "BC",
                "name": "Békéscsaba"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1037,
                "iso2": "EG",
                "name": "Eger"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1038,
                "iso2": "TO",
                "name": "Tolna County"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1039,
                "iso2": "VA",
                "name": "Vas County"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1040,
                "iso2": "HE",
                "name": "Heves County"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1041,
                "iso2": "GY",
                "name": "Győr"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1042,
                "iso2": "GS",
                "name": "Győr-Moson-Sopron County"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1043,
                "iso2": "JN",
                "name": "Jász-Nagykun-Szolnok County"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1044,
                "iso2": "FE",
                "name": "Fejér County"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1045,
                "iso2": "SZ",
                "name": "Szabolcs-Szatmár-Bereg County"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1046,
                "iso2": "ZA",
                "name": "Zala County"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1047,
                "iso2": "SK",
                "name": "Szolnok"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1048,
                "iso2": "BK",
                "name": "Bács-Kiskun County"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1049,
                "iso2": "DU",
                "name": "Dunaújváros"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1050,
                "iso2": "ZE",
                "name": "Zalaegerszeg"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1051,
                "iso2": "NO",
                "name": "Nógrád County"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1052,
                "iso2": "SH",
                "name": "Szombathely"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1053,
                "iso2": "PS",
                "name": "Pécs"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1054,
                "iso2": "VE",
                "name": "Veszprém County"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1055,
                "iso2": "BA",
                "name": "Baranya County"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1056,
                "iso2": "KM",
                "name": "Kecskemét"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1057,
                "iso2": "SN",
                "name": "Sopron"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1058,
                "iso2": "BZ",
                "name": "Borsod-Abaúj-Zemplén County"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1059,
                "iso2": "PE",
                "name": "Pest County"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1060,
                "iso2": "BE",
                "name": "Békés County"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1061,
                "iso2": "SS",
                "name": "Szekszárd"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1062,
                "iso2": "VM",
                "name": "Veszprém"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1063,
                "iso2": "HB",
                "name": "Hajdú-Bihar County"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1064,
                "iso2": "BU",
                "name": "Budapest"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1065,
                "iso2": "MI",
                "name": "Miskolc"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1066,
                "iso2": "TB",
                "name": "Tatabánya"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1067,
                "iso2": "KV",
                "name": "Kaposvár"
            },
            {
                "country_code": "HU",
                "country_id": 99,
                "id": 1068,
                "iso2": "ST",
                "name": "Salgótarján"
            }
        ]
    },
    {
        "id": 100,
        "name": "Iceland",
        "iso3": "ISL",
        "iso2": "IS",
        "states": [
            {
                "country_code": "IS",
                "country_id": 100,
                "id": 3430,
                "iso2": "2",
                "name": "Southern Peninsula Region"
            },
            {
                "country_code": "IS",
                "country_id": 100,
                "id": 3431,
                "iso2": "1",
                "name": "Capital Region"
            },
            {
                "country_code": "IS",
                "country_id": 100,
                "id": 3432,
                "iso2": "4",
                "name": "Westfjords"
            },
            {
                "country_code": "IS",
                "country_id": 100,
                "id": 3433,
                "iso2": "7",
                "name": "Eastern Region"
            },
            {
                "country_code": "IS",
                "country_id": 100,
                "id": 3434,
                "iso2": "8",
                "name": "Southern Region"
            },
            {
                "country_code": "IS",
                "country_id": 100,
                "id": 3435,
                "iso2": "5",
                "name": "Northwestern Region"
            },
            {
                "country_code": "IS",
                "country_id": 100,
                "id": 3436,
                "iso2": "3",
                "name": "Western Region"
            },
            {
                "country_code": "IS",
                "country_id": 100,
                "id": 3437,
                "iso2": "6",
                "name": "Northeastern Region"
            }
        ]
    },
    {
        "id": 101,
        "name": "India",
        "iso3": "IND",
        "iso2": "IN",
        "states": [
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4006,
                "iso2": "ML",
                "name": "Meghalaya"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4007,
                "iso2": "HR",
                "name": "Haryana"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4008,
                "iso2": "MH",
                "name": "Maharashtra"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4009,
                "iso2": "GA",
                "name": "Goa"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4010,
                "iso2": "MN",
                "name": "Manipur"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4011,
                "iso2": "PY",
                "name": "Puducherry"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4012,
                "iso2": "TG",
                "name": "Telangana"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4013,
                "iso2": "OR",
                "name": "Odisha"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4014,
                "iso2": "RJ",
                "name": "Rajasthan"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4015,
                "iso2": "PB",
                "name": "Punjab"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4016,
                "iso2": "UT",
                "name": "Uttarakhand"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4017,
                "iso2": "AP",
                "name": "Andhra Pradesh"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4018,
                "iso2": "NL",
                "name": "Nagaland"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4019,
                "iso2": "LD",
                "name": "Lakshadweep"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4020,
                "iso2": "HP",
                "name": "Himachal Pradesh"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4021,
                "iso2": "DL",
                "name": "Delhi"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4022,
                "iso2": "UP",
                "name": "Uttar Pradesh"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4023,
                "iso2": "AN",
                "name": "Andaman and Nicobar Islands"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4024,
                "iso2": "AR",
                "name": "Arunachal Pradesh"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4025,
                "iso2": "JH",
                "name": "Jharkhand"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4026,
                "iso2": "KA",
                "name": "Karnataka"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4027,
                "iso2": "AS",
                "name": "Assam"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4028,
                "iso2": "KL",
                "name": "Kerala"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4029,
                "iso2": "JK",
                "name": "Jammu and Kashmir"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4030,
                "iso2": "GJ",
                "name": "Gujarat"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4031,
                "iso2": "CH",
                "name": "Chandigarh"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4032,
                "iso2": "DN",
                "name": "Dadra and Nagar Haveli"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4033,
                "iso2": "DD",
                "name": "Daman and Diu"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4034,
                "iso2": "SK",
                "name": "Sikkim"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4035,
                "iso2": "TN",
                "name": "Tamil Nadu"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4036,
                "iso2": "MZ",
                "name": "Mizoram"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4037,
                "iso2": "BR",
                "name": "Bihar"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4038,
                "iso2": "TR",
                "name": "Tripura"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4039,
                "iso2": "MP",
                "name": "Madhya Pradesh"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4040,
                "iso2": "CT",
                "name": "Chhattisgarh"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4852,
                "iso2": "LA",
                "name": "Ladakh"
            },
            {
                "country_code": "IN",
                "country_id": 101,
                "id": 4853,
                "iso2": "WB",
                "name": "West Bengal"
            }
        ]
    },
    {
        "id": 102,
        "name": "Indonesia",
        "iso3": "IDN",
        "iso2": "ID",
        "states": [
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1792,
                "iso2": "SU",
                "name": "North Sumatra"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1793,
                "iso2": "BE",
                "name": "Bengkulu"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1794,
                "iso2": "KT",
                "name": "Central Kalimantan"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1795,
                "iso2": "SN",
                "name": "South Sulawesi"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1796,
                "iso2": "SG",
                "name": "Southeast Sulawesi"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1797,
                "iso2": "SM",
                "name": "Sumatra"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1798,
                "iso2": "PA",
                "name": "Papua"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1799,
                "iso2": "PB",
                "name": "West Papua"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1800,
                "iso2": "MA",
                "name": "Maluku"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1801,
                "iso2": "MU",
                "name": "North Maluku"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1802,
                "iso2": "JT",
                "name": "Central Java"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1803,
                "iso2": "SL",
                "name": "Sulawesi"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1804,
                "iso2": "KI",
                "name": "East Kalimantan"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1805,
                "iso2": "JK",
                "name": "Jakarta"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1806,
                "iso2": "KA",
                "name": "Kalimantan"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1807,
                "iso2": "KR",
                "name": "Riau Islands"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1808,
                "iso2": "SA",
                "name": "North Sulawesi"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1809,
                "iso2": "RI",
                "name": "Riau"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1810,
                "iso2": "BT",
                "name": "Banten"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1811,
                "iso2": "LA",
                "name": "Lampung"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1812,
                "iso2": "GO",
                "name": "Gorontalo"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1813,
                "iso2": "ST",
                "name": "Central Sulawesi"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1814,
                "iso2": "NB",
                "name": "West Nusa Tenggara"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1815,
                "iso2": "JA",
                "name": "Jambi"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1816,
                "iso2": "SS",
                "name": "South Sumatra"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1817,
                "iso2": "SR",
                "name": "West Sulawesi"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1818,
                "iso2": "NT",
                "name": "East Nusa Tenggara"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1819,
                "iso2": "KS",
                "name": "South Kalimantan"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1820,
                "iso2": "BB",
                "name": "Bangka Belitung Islands"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1821,
                "iso2": "NU",
                "name": "Lesser Sunda Islands"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1822,
                "iso2": "AC",
                "name": "Aceh"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1823,
                "iso2": "ML",
                "name": "Maluku Islands"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1824,
                "iso2": "KU",
                "name": "North Kalimantan"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1825,
                "iso2": "JB",
                "name": "West Java"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1826,
                "iso2": "BA",
                "name": "Bali"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1827,
                "iso2": "JI",
                "name": "East Java"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1828,
                "iso2": "SB",
                "name": "West Sumatra"
            },
            {
                "country_code": "ID",
                "country_id": 102,
                "id": 1829,
                "iso2": "YO",
                "name": "Special Region of Yogyakarta"
            }
        ]
    },
    {
        "id": 103,
        "name": "Iran",
        "iso3": "IRN",
        "iso2": "IR",
        "states": [
            {
                "country_code": "IR",
                "country_id": 103,
                "id": 3916,
                "iso2": "22",
                "name": "Markazi Province"
            },
            {
                "country_code": "IR",
                "country_id": 103,
                "id": 3917,
                "iso2": "10",
                "name": "Khuzestan Province"
            },
            {
                "country_code": "IR",
                "country_id": 103,
                "id": 3918,
                "iso2": "5",
                "name": "Ilam Province"
            },
            {
                "country_code": "IR",
                "country_id": 103,
                "id": 3919,
                "iso2": "17",
                "name": "Kermanshah Province"
            },
            {
                "country_code": "IR",
                "country_id": 103,
                "id": 3920,
                "iso2": "19",
                "name": "Gilan Province"
            },
            {
                "country_code": "IR",
                "country_id": 103,
                "id": 3921,
                "iso2": "8",
                "name": "Chaharmahal and Bakhtiari Province"
            },
            {
                "country_code": "IR",
                "country_id": 103,
                "id": 3922,
                "iso2": "26",
                "name": "Qom Province"
            },
            {
                "country_code": "IR",
                "country_id": 103,
                "id": 3923,
                "iso2": "4",
                "name": "Isfahan Province"
            },
            {
                "country_code": "IR",
                "country_id": 103,
                "id": 3924,
                "iso2": "2",
                "name": "West Azarbaijan Province"
            },
            {
                "country_code": "IR",
                "country_id": 103,
                "id": 3925,
                "iso2": "11",
                "name": "Zanjan Province"
            },
            {
                "country_code": "IR",
                "country_id": 103,
                "id": 3926,
                "iso2": "18",
                "name": "Kohgiluyeh and Boyer-Ahmad Province"
            },
            {
                "country_code": "IR",
                "country_id": 103,
                "id": 3927,
                "iso2": "30",
                "name": "Razavi Khorasan Province"
            },
            {
                "country_code": "IR",
                "country_id": 103,
                "id": 3928,
                "iso2": "20",
                "name": "Lorestan Province"
            },
            {
                "country_code": "IR",
                "country_id": 103,
                "id": 3929,
                "iso2": "32",
                "name": "Alborz Province"
            },
            {
                "country_code": "IR",
                "country_id": 103,
                "id": 3930,
                "iso2": "29",
                "name": "South Khorasan Province"
            },
            {
                "country_code": "IR",
                "country_id": 103,
                "id": 3931,
                "iso2": "13",
                "name": "Sistan and Baluchestan"
            },
            {
                "country_code": "IR",
                "country_id": 103,
                "id": 3932,
                "iso2": "6",
                "name": "Bushehr Province"
            },
            {
                "country_code": "IR",
                "country_id": 103,
                "id": 3933,
                "iso2": "27",
                "name": "Golestan Province"
            },
            {
                "country_code": "IR",
                "country_id": 103,
                "id": 3934,
                "iso2": "3",
                "name": "Ardabil Province"
            },
            {
                "country_code": "IR",
                "country_id": 103,
                "id": 3935,
                "iso2": "16",
                "name": "Kurdistan Province"
            },
            {
                "country_code": "IR",
                "country_id": 103,
                "id": 3936,
                "iso2": "25",
                "name": "Yazd Province"
            },
            {
                "country_code": "IR",
                "country_id": 103,
                "id": 3937,
                "iso2": "23",
                "name": "Hormozgan Province"
            },
            {
                "country_code": "IR",
                "country_id": 103,
                "id": 3938,
                "iso2": "21",
                "name": "Mazandaran Province"
            },
            {
                "country_code": "IR",
                "country_id": 103,
                "id": 3939,
                "iso2": "14",
                "name": "Fars Province"
            },
            {
                "country_code": "IR",
                "country_id": 103,
                "id": 3940,
                "iso2": "12",
                "name": "Semnan Province"
            },
            {
                "country_code": "IR",
                "country_id": 103,
                "id": 3941,
                "iso2": "28",
                "name": "Qazvin Province"
            },
            {
                "country_code": "IR",
                "country_id": 103,
                "id": 3942,
                "iso2": "31",
                "name": "North Khorasan Province"
            },
            {
                "country_code": "IR",
                "country_id": 103,
                "id": 3943,
                "iso2": "15",
                "name": "Kerman Province"
            },
            {
                "country_code": "IR",
                "country_id": 103,
                "id": 3944,
                "iso2": "1",
                "name": "East Azerbaijan Province"
            },
            {
                "country_code": "IR",
                "country_id": 103,
                "id": 3945,
                "iso2": "7",
                "name": "Tehran Province"
            }
        ]
    },
    {
        "id": 104,
        "name": "Iraq",
        "iso3": "IRQ",
        "iso2": "IQ",
        "states": [
            {
                "country_code": "IQ",
                "country_id": 104,
                "id": 3954,
                "iso2": "DQ",
                "name": "Dhi Qar Governorate"
            },
            {
                "country_code": "IQ",
                "country_id": 104,
                "id": 3955,
                "iso2": "BB",
                "name": "Babylon Governorate"
            },
            {
                "country_code": "IQ",
                "country_id": 104,
                "id": 3956,
                "iso2": "QA",
                "name": "Al-Qādisiyyah Governorate"
            },
            {
                "country_code": "IQ",
                "country_id": 104,
                "id": 3957,
                "iso2": "KA",
                "name": "Karbala Governorate"
            },
            {
                "country_code": "IQ",
                "country_id": 104,
                "id": 3958,
                "iso2": "MU",
                "name": "Al Muthanna Governorate"
            },
            {
                "country_code": "IQ",
                "country_id": 104,
                "id": 3959,
                "iso2": "BG",
                "name": "Baghdad Governorate"
            },
            {
                "country_code": "IQ",
                "country_id": 104,
                "id": 3960,
                "iso2": "BA",
                "name": "Basra Governorate"
            },
            {
                "country_code": "IQ",
                "country_id": 104,
                "id": 3961,
                "iso2": "SD",
                "name": "Saladin Governorate"
            },
            {
                "country_code": "IQ",
                "country_id": 104,
                "id": 3962,
                "iso2": "NA",
                "name": "Najaf Governorate"
            },
            {
                "country_code": "IQ",
                "country_id": 104,
                "id": 3963,
                "iso2": "NI",
                "name": "Nineveh Governorate"
            },
            {
                "country_code": "IQ",
                "country_id": 104,
                "id": 3964,
                "iso2": "AN",
                "name": "Al Anbar Governorate"
            },
            {
                "country_code": "IQ",
                "country_id": 104,
                "id": 3965,
                "iso2": "DI",
                "name": "Diyala Governorate"
            },
            {
                "country_code": "IQ",
                "country_id": 104,
                "id": 3966,
                "iso2": "MA",
                "name": "Maysan Governorate"
            },
            {
                "country_code": "IQ",
                "country_id": 104,
                "id": 3967,
                "iso2": "DA",
                "name": "Dohuk Governorate"
            },
            {
                "country_code": "IQ",
                "country_id": 104,
                "id": 3968,
                "iso2": "AR",
                "name": "Erbil Governorate"
            },
            {
                "country_code": "IQ",
                "country_id": 104,
                "id": 3969,
                "iso2": "SU",
                "name": "Sulaymaniyah Governorate"
            },
            {
                "country_code": "IQ",
                "country_id": 104,
                "id": 3970,
                "iso2": "WA",
                "name": "Wasit Governorate"
            },
            {
                "country_code": "IQ",
                "country_id": 104,
                "id": 3971,
                "iso2": "KI",
                "name": "Kirkuk Governorate"
            }
        ]
    },
    {
        "id": 105,
        "name": "Ireland",
        "iso3": "IRL",
        "iso2": "IE",
        "states": [
            {
                "country_code": "IE",
                "country_id": 105,
                "id": 1069,
                "iso2": "TA",
                "name": "County Tipperary"
            },
            {
                "country_code": "IE",
                "country_id": 105,
                "id": 1070,
                "iso2": "SO",
                "name": "County Sligo"
            },
            {
                "country_code": "IE",
                "country_id": 105,
                "id": 1071,
                "iso2": "DL",
                "name": "County Donegal"
            },
            {
                "country_code": "IE",
                "country_id": 105,
                "id": 1072,
                "iso2": "D",
                "name": "County Dublin"
            },
            {
                "country_code": "IE",
                "country_id": 105,
                "id": 1073,
                "iso2": "L",
                "name": "Leinster"
            },
            {
                "country_code": "IE",
                "country_id": 105,
                "id": 1074,
                "iso2": "CO",
                "name": "County Cork"
            },
            {
                "country_code": "IE",
                "country_id": 105,
                "id": 1075,
                "iso2": "MN",
                "name": "County Monaghan"
            },
            {
                "country_code": "IE",
                "country_id": 105,
                "id": 1076,
                "iso2": "LD",
                "name": "County Longford"
            },
            {
                "country_code": "IE",
                "country_id": 105,
                "id": 1077,
                "iso2": "KY",
                "name": "County Kerry"
            },
            {
                "country_code": "IE",
                "country_id": 105,
                "id": 1078,
                "iso2": "OY",
                "name": "County Offaly"
            },
            {
                "country_code": "IE",
                "country_id": 105,
                "id": 1079,
                "iso2": "G",
                "name": "County Galway"
            },
            {
                "country_code": "IE",
                "country_id": 105,
                "id": 1080,
                "iso2": "M",
                "name": "Munster"
            },
            {
                "country_code": "IE",
                "country_id": 105,
                "id": 1081,
                "iso2": "RN",
                "name": "County Roscommon"
            },
            {
                "country_code": "IE",
                "country_id": 105,
                "id": 1082,
                "iso2": "KE",
                "name": "County Kildare"
            },
            {
                "country_code": "IE",
                "country_id": 105,
                "id": 1083,
                "iso2": "LH",
                "name": "County Louth"
            },
            {
                "country_code": "IE",
                "country_id": 105,
                "id": 1084,
                "iso2": "MO",
                "name": "County Mayo"
            },
            {
                "country_code": "IE",
                "country_id": 105,
                "id": 1085,
                "iso2": "WW",
                "name": "County Wicklow"
            },
            {
                "country_code": "IE",
                "country_id": 105,
                "id": 1086,
                "iso2": "U",
                "name": "Ulster"
            },
            {
                "country_code": "IE",
                "country_id": 105,
                "id": 1087,
                "iso2": "C",
                "name": "Connacht"
            },
            {
                "country_code": "IE",
                "country_id": 105,
                "id": 1088,
                "iso2": "CN",
                "name": "County Cavan"
            },
            {
                "country_code": "IE",
                "country_id": 105,
                "id": 1089,
                "iso2": "WD",
                "name": "County Waterford"
            },
            {
                "country_code": "IE",
                "country_id": 105,
                "id": 1090,
                "iso2": "KK",
                "name": "County Kilkenny"
            },
            {
                "country_code": "IE",
                "country_id": 105,
                "id": 1091,
                "iso2": "CE",
                "name": "County Clare"
            },
            {
                "country_code": "IE",
                "country_id": 105,
                "id": 1092,
                "iso2": "MH",
                "name": "County Meath"
            },
            {
                "country_code": "IE",
                "country_id": 105,
                "id": 1093,
                "iso2": "WX",
                "name": "County Wexford"
            },
            {
                "country_code": "IE",
                "country_id": 105,
                "id": 1094,
                "iso2": "LK",
                "name": "County Limerick"
            },
            {
                "country_code": "IE",
                "country_id": 105,
                "id": 1095,
                "iso2": "CW",
                "name": "County Carlow"
            },
            {
                "country_code": "IE",
                "country_id": 105,
                "id": 1096,
                "iso2": "LS",
                "name": "County Laois"
            },
            {
                "country_code": "IE",
                "country_id": 105,
                "id": 1097,
                "iso2": "WH",
                "name": "County Westmeath"
            }
        ]
    },
    {
        "id": 106,
        "name": "Israel",
        "iso3": "ISR",
        "iso2": "IL",
        "states": [
            {
                "country_code": "IL",
                "country_id": 106,
                "id": 1366,
                "iso2": "Z",
                "name": "Northern District"
            },
            {
                "country_code": "IL",
                "country_id": 106,
                "id": 1367,
                "iso2": "M",
                "name": "Central District"
            },
            {
                "country_code": "IL",
                "country_id": 106,
                "id": 1368,
                "iso2": "D",
                "name": "Southern District"
            },
            {
                "country_code": "IL",
                "country_id": 106,
                "id": 1369,
                "iso2": "HA",
                "name": "Haifa District"
            },
            {
                "country_code": "IL",
                "country_id": 106,
                "id": 1370,
                "iso2": "JM",
                "name": "Jerusalem District"
            },
            {
                "country_code": "IL",
                "country_id": 106,
                "id": 1371,
                "iso2": "TA",
                "name": "Tel Aviv District"
            }
        ]
    },
    {
        "id": 107,
        "name": "Italy",
        "iso3": "ITA",
        "iso2": "IT",
        "states": [
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1664,
                "iso2": "52",
                "name": "Tuscany"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1665,
                "iso2": "PD",
                "name": "Province of Padua"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1666,
                "iso2": "PR",
                "name": "Province of Parma"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1667,
                "iso2": "SR",
                "name": "Libero consorzio comunale di Siracusa"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1668,
                "iso2": "PA",
                "name": "Metropolitan City of Palermo"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1669,
                "iso2": "72",
                "name": "Campania"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1670,
                "iso2": "57",
                "name": "Marche"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1671,
                "iso2": "RC",
                "name": "Metropolitan City of Reggio Calabria"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1672,
                "iso2": "AN",
                "name": "Province of Ancona"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1673,
                "iso2": "VE",
                "name": "Metropolitan City of Venice"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1674,
                "iso2": "LT",
                "name": "Province of Latina"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1675,
                "iso2": "LE",
                "name": "Province of Lecce"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1676,
                "iso2": "PV",
                "name": "Province of Pavia"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1677,
                "iso2": "LC",
                "name": "Province of Lecco"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1678,
                "iso2": "62",
                "name": "Lazio"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1679,
                "iso2": "65",
                "name": "Abruzzo"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1680,
                "iso2": "FI",
                "name": "Metropolitan City of Florence"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1681,
                "iso2": "AP",
                "name": "Province of Ascoli Piceno"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1682,
                "iso2": "CA",
                "name": "Metropolitan City of Cagliari"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1683,
                "iso2": "55",
                "name": "Umbria"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1684,
                "iso2": "BO",
                "name": "Metropolitan City of Bologna"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1685,
                "iso2": "PI",
                "name": "Province of Pisa"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1686,
                "iso2": "BT",
                "name": "Province of Barletta-Andria-Trani"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1687,
                "iso2": "PT",
                "name": "Province of Pistoia"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1688,
                "iso2": "75",
                "name": "Apulia"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1689,
                "iso2": "BL",
                "name": "Province of Belluno"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1690,
                "iso2": "PN",
                "name": "Province of Pordenone"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1691,
                "iso2": "PG",
                "name": "Province of Perugia"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1692,
                "iso2": "AV",
                "name": "Province of Avellino"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1693,
                "iso2": "PU",
                "name": "Pesaro and Urbino Province"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1694,
                "iso2": "PE",
                "name": "Province of Pescara"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1695,
                "iso2": "67",
                "name": "Molise"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1696,
                "iso2": "PC",
                "name": "Province of Piacenza"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1697,
                "iso2": "PZ",
                "name": "Province of Potenza"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1698,
                "iso2": "MI",
                "name": "Metropolitan City of Milan"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1699,
                "iso2": "GE",
                "name": "Metropolitan City of Genoa"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1700,
                "iso2": "PO",
                "name": "Province of Prato"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1701,
                "iso2": "BN",
                "name": "Benevento Province"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1702,
                "iso2": "21",
                "name": "Piedmont"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1703,
                "iso2": "78",
                "name": "Calabria"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1704,
                "iso2": "BG",
                "name": "Province of Bergamo"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1705,
                "iso2": "25",
                "name": "Lombardy"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1706,
                "iso2": "77",
                "name": "Basilicata"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1707,
                "iso2": "RA",
                "name": "Province of Ravenna"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1708,
                "iso2": "RE",
                "name": "Province of Reggio Emilia"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1709,
                "iso2": "82",
                "name": "Sicily"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1710,
                "iso2": "TO",
                "name": "Metropolitan City of Turin"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1711,
                "iso2": "RM",
                "name": "Metropolitan City of Rome"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1712,
                "iso2": "RI",
                "name": "Province of Rieti"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1713,
                "iso2": "RN",
                "name": "Province of Rimini"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1714,
                "iso2": "BR",
                "name": "Province of Brindisi"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1715,
                "iso2": "88",
                "name": "Sardinia"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1716,
                "iso2": "23",
                "name": "Aosta Valley"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1717,
                "iso2": "BS",
                "name": "Province of Brescia"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1718,
                "iso2": "CL",
                "name": "Libero consorzio comunale di Caltanissetta"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1719,
                "iso2": "RO",
                "name": "Province of Rovigo"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1720,
                "iso2": "SA",
                "name": "Province of Salerno"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1721,
                "iso2": "CB",
                "name": "Province of Campobasso"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1722,
                "iso2": "SS",
                "name": "Province of Sassari"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1723,
                "iso2": "EN",
                "name": "Libero consorzio comunale di Enna"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1724,
                "iso2": "NA",
                "name": "Metropolitan City of Naples"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1725,
                "iso2": "32",
                "name": "Trentino-South Tyrol"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1726,
                "iso2": "VB",
                "name": "Province of Verbano-Cusio-Ossola"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1727,
                "iso2": "AG",
                "name": "Libero consorzio comunale di Agrigento"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1728,
                "iso2": "CZ",
                "name": "Province of Catanzaro"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1729,
                "iso2": "RG",
                "name": "Libero consorzio comunale di Ragusa"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1730,
                "iso2": "CI",
                "name": "Province of Carbonia-Iglesias"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1731,
                "iso2": "CE",
                "name": "Province of Caserta"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1732,
                "iso2": "SV",
                "name": "Province of Savona"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1733,
                "iso2": "TP",
                "name": "Libero consorzio comunale di Trapani"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1734,
                "iso2": "SI",
                "name": "Province of Siena"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1735,
                "iso2": "VT",
                "name": "Province of Viterbo"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1736,
                "iso2": "VR",
                "name": "Province of Verona"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1737,
                "iso2": "VV",
                "name": "Province of Vibo Valentia"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1738,
                "iso2": "VI",
                "name": "Province of Vicenza"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1739,
                "iso2": "CH",
                "name": "Province of Chieti"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1740,
                "iso2": "CO",
                "name": "Province of Como"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1741,
                "iso2": "SO",
                "name": "Province of Sondrio"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1742,
                "iso2": "CS",
                "name": "Province of Cosenza"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1743,
                "iso2": "TA",
                "name": "Province of Taranto"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1744,
                "iso2": "FM",
                "name": "Province of Fermo"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1745,
                "iso2": "LI",
                "name": "Province of Livorno"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1746,
                "iso2": "FE",
                "name": "Province of Ferrara"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1747,
                "iso2": "LO",
                "name": "Province of Lodi"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1748,
                "iso2": "TN",
                "name": "Trentino"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1749,
                "iso2": "LU",
                "name": "Province of Lucca"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1750,
                "iso2": "MC",
                "name": "Province of Macerata"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1751,
                "iso2": "CR",
                "name": "Province of Cremona"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1752,
                "iso2": "TE",
                "name": "Province of Teramo"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1753,
                "iso2": "34",
                "name": "Veneto"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1754,
                "iso2": "KR",
                "name": "Province of Crotone"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1755,
                "iso2": "TR",
                "name": "Province of Terni"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1756,
                "iso2": "36",
                "name": "Friuli–Venezia Giulia"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1757,
                "iso2": "MO",
                "name": "Province of Modena"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1758,
                "iso2": "MN",
                "name": "Province of Mantua"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1759,
                "iso2": "MS",
                "name": "Province of Massa and Carrara"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1760,
                "iso2": "MT",
                "name": "Province of Matera"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1761,
                "iso2": "VS",
                "name": "Province of Medio Campidano"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1762,
                "iso2": "TV",
                "name": "Province of Treviso"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1763,
                "iso2": "TS",
                "name": "Province of Trieste"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1764,
                "iso2": "UD",
                "name": "Province of Udine"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1765,
                "iso2": "VA",
                "name": "Province of Varese"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1766,
                "iso2": "CT",
                "name": "Metropolitan City of Catania"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1767,
                "iso2": "BZ",
                "name": "South Tyrol"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1768,
                "iso2": "42",
                "name": "Liguria"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1769,
                "iso2": "MB",
                "name": "Province of Monza and Brianza"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1770,
                "iso2": "ME",
                "name": "Metropolitan City of Messina"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1771,
                "iso2": "FG",
                "name": "Province of Foggia"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1772,
                "iso2": "BA",
                "name": "Metropolitan City of Bari"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1773,
                "iso2": "45",
                "name": "Emilia-Romagna"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1774,
                "iso2": "NO",
                "name": "Province of Novara"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1775,
                "iso2": "CN",
                "name": "Province of Cuneo"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1776,
                "iso2": "FR",
                "name": "Province of Frosinone"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1777,
                "iso2": "GO",
                "name": "Province of Gorizia"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1778,
                "iso2": "BI",
                "name": "Province of Biella"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1779,
                "iso2": "FC",
                "name": "Province of Forlì-Cesena"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1780,
                "iso2": "AT",
                "name": "Province of Asti"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1781,
                "iso2": "AQ",
                "name": "Province of L'Aquila"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1782,
                "iso2": "OG",
                "name": "Province of Ogliastra"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1783,
                "iso2": "AL",
                "name": "Province of Alessandria"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1784,
                "iso2": "OT",
                "name": "Province of Olbia-Tempio"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1785,
                "iso2": "VC",
                "name": "Province of Vercelli"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1786,
                "iso2": "OR",
                "name": "Province of Oristano"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1787,
                "iso2": "GR",
                "name": "Province of Grosseto"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1788,
                "iso2": "IM",
                "name": "Province of Imperia"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1789,
                "iso2": "IS",
                "name": "Province of Isernia"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1790,
                "iso2": "NU",
                "name": "Province of Nuoro"
            },
            {
                "country_code": "IT",
                "country_id": 107,
                "id": 1791,
                "iso2": "SP",
                "name": "Province of La Spezia"
            }
        ]
    },
    {
        "id": 108,
        "name": "Jamaica",
        "iso3": "JAM",
        "iso2": "JM",
        "states": [
            {
                "country_code": "JM",
                "country_id": 108,
                "id": 3742,
                "iso2": "10",
                "name": "Westmoreland Parish"
            },
            {
                "country_code": "JM",
                "country_id": 108,
                "id": 3743,
                "iso2": "11",
                "name": "Saint Elizabeth Parish"
            },
            {
                "country_code": "JM",
                "country_id": 108,
                "id": 3744,
                "iso2": "6",
                "name": "Saint Ann Parish"
            },
            {
                "country_code": "JM",
                "country_id": 108,
                "id": 3745,
                "iso2": "8",
                "name": "Saint James Parish"
            },
            {
                "country_code": "JM",
                "country_id": 108,
                "id": 3746,
                "iso2": "14",
                "name": "Saint Catherine Parish"
            },
            {
                "country_code": "JM",
                "country_id": 108,
                "id": 3747,
                "iso2": "5",
                "name": "Saint Mary Parish"
            },
            {
                "country_code": "JM",
                "country_id": 108,
                "id": 3748,
                "iso2": "1",
                "name": "Kingston Parish"
            },
            {
                "country_code": "JM",
                "country_id": 108,
                "id": 3749,
                "iso2": "9",
                "name": "Hanover Parish"
            },
            {
                "country_code": "JM",
                "country_id": 108,
                "id": 3750,
                "iso2": "3",
                "name": "Saint Thomas Parish"
            },
            {
                "country_code": "JM",
                "country_id": 108,
                "id": 3751,
                "iso2": "2",
                "name": "Saint Andrew"
            },
            {
                "country_code": "JM",
                "country_id": 108,
                "id": 3752,
                "iso2": "4",
                "name": "Portland Parish"
            },
            {
                "country_code": "JM",
                "country_id": 108,
                "id": 3753,
                "iso2": "13",
                "name": "Clarendon Parish"
            },
            {
                "country_code": "JM",
                "country_id": 108,
                "id": 3754,
                "iso2": "12",
                "name": "Manchester Parish"
            },
            {
                "country_code": "JM",
                "country_id": 108,
                "id": 3755,
                "iso2": "7",
                "name": "Trelawny Parish"
            }
        ]
    },
    {
        "id": 109,
        "name": "Japan",
        "iso3": "JPN",
        "iso2": "JP",
        "states": [
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 820,
                "iso2": "33",
                "name": "Okayama Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 821,
                "iso2": "12",
                "name": "Chiba Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 822,
                "iso2": "44",
                "name": "Ōita Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 823,
                "iso2": "13",
                "name": "Tokyo"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 824,
                "iso2": "29",
                "name": "Nara Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 825,
                "iso2": "22",
                "name": "Shizuoka Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 826,
                "iso2": "32",
                "name": "Shimane Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 827,
                "iso2": "23",
                "name": "Aichi Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 828,
                "iso2": "34",
                "name": "Hiroshima Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 829,
                "iso2": "5",
                "name": "Akita Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 830,
                "iso2": "17",
                "name": "Ishikawa Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 831,
                "iso2": "28",
                "name": "Hyōgo Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 832,
                "iso2": "1",
                "name": "Hokkaidō Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 833,
                "iso2": "24",
                "name": "Mie Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 834,
                "iso2": "26",
                "name": "Kyōto Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 835,
                "iso2": "35",
                "name": "Yamaguchi Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 836,
                "iso2": "36",
                "name": "Tokushima Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 837,
                "iso2": "6",
                "name": "Yamagata Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 838,
                "iso2": "16",
                "name": "Toyama Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 839,
                "iso2": "2",
                "name": "Aomori Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 840,
                "iso2": "46",
                "name": "Kagoshima Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 841,
                "iso2": "15",
                "name": "Niigata Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 842,
                "iso2": "14",
                "name": "Kanagawa Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 843,
                "iso2": "20",
                "name": "Nagano Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 844,
                "iso2": "30",
                "name": "Wakayama Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 845,
                "iso2": "25",
                "name": "Shiga Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 846,
                "iso2": "43",
                "name": "Kumamoto Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 847,
                "iso2": "7",
                "name": "Fukushima Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 848,
                "iso2": "18",
                "name": "Fukui Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 849,
                "iso2": "42",
                "name": "Nagasaki Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 850,
                "iso2": "31",
                "name": "Tottori Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 851,
                "iso2": "8",
                "name": "Ibaraki Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 852,
                "iso2": "19",
                "name": "Yamanashi Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 853,
                "iso2": "47",
                "name": "Okinawa Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 854,
                "iso2": "9",
                "name": "Tochigi Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 855,
                "iso2": "45",
                "name": "Miyazaki Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 856,
                "iso2": "3",
                "name": "Iwate Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 857,
                "iso2": "4",
                "name": "Miyagi Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 858,
                "iso2": "21",
                "name": "Gifu Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 859,
                "iso2": "27",
                "name": "Ōsaka Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 860,
                "iso2": "11",
                "name": "Saitama Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 861,
                "iso2": "40",
                "name": "Fukuoka Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 862,
                "iso2": "10",
                "name": "Gunma Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 863,
                "iso2": "41",
                "name": "Saga Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 864,
                "iso2": "37",
                "name": "Kagawa Prefecture"
            },
            {
                "country_code": "JP",
                "country_id": 109,
                "id": 865,
                "iso2": "38",
                "name": "Ehime Prefecture"
            }
        ]
    },
    {
        "id": 110,
        "name": "Jersey",
        "iso3": "JEY",
        "iso2": "JE",
        "states": []
    },
    {
        "id": 111,
        "name": "Jordan",
        "iso3": "JOR",
        "iso2": "JO",
        "states": [
            {
                "country_code": "JO",
                "country_id": 111,
                "id": 956,
                "iso2": "KA",
                "name": "Karak Governorate"
            },
            {
                "country_code": "JO",
                "country_id": 111,
                "id": 957,
                "iso2": "AT",
                "name": "Tafilah Governorate"
            },
            {
                "country_code": "JO",
                "country_id": 111,
                "id": 958,
                "iso2": "MD",
                "name": "Madaba Governorate"
            },
            {
                "country_code": "JO",
                "country_id": 111,
                "id": 959,
                "iso2": "AQ",
                "name": "Aqaba Governorate"
            },
            {
                "country_code": "JO",
                "country_id": 111,
                "id": 960,
                "iso2": "IR",
                "name": "Irbid Governorate"
            },
            {
                "country_code": "JO",
                "country_id": 111,
                "id": 961,
                "iso2": "BA",
                "name": "Balqa Governorate"
            },
            {
                "country_code": "JO",
                "country_id": 111,
                "id": 962,
                "iso2": "MA",
                "name": "Mafraq Governorate"
            },
            {
                "country_code": "JO",
                "country_id": 111,
                "id": 963,
                "iso2": "AJ",
                "name": "Ajloun Governorate"
            },
            {
                "country_code": "JO",
                "country_id": 111,
                "id": 964,
                "iso2": "MN",
                "name": "Ma'an Governorate"
            },
            {
                "country_code": "JO",
                "country_id": 111,
                "id": 965,
                "iso2": "AM",
                "name": "Amman Governorate"
            },
            {
                "country_code": "JO",
                "country_id": 111,
                "id": 966,
                "iso2": "JA",
                "name": "Jerash Governorate"
            },
            {
                "country_code": "JO",
                "country_id": 111,
                "id": 967,
                "iso2": "AZ",
                "name": "Zarqa Governorate"
            }
        ]
    },
    {
        "id": 112,
        "name": "Kazakhstan",
        "iso3": "KAZ",
        "iso2": "KZ",
        "states": [
            {
                "country_code": "KZ",
                "country_id": 112,
                "id": 141,
                "iso2": "MAN",
                "name": "Mangystau Region"
            },
            {
                "country_code": "KZ",
                "country_id": 112,
                "id": 142,
                "iso2": "KZY",
                "name": "Kyzylorda Region"
            },
            {
                "country_code": "KZ",
                "country_id": 112,
                "id": 143,
                "iso2": "ALM",
                "name": "Almaty Region"
            },
            {
                "country_code": "KZ",
                "country_id": 112,
                "id": 144,
                "iso2": "SEV",
                "name": "North Kazakhstan Region"
            },
            {
                "country_code": "KZ",
                "country_id": 112,
                "id": 145,
                "iso2": "AKM",
                "name": "Akmola Region"
            },
            {
                "country_code": "KZ",
                "country_id": 112,
                "id": 146,
                "iso2": "PAV",
                "name": "Pavlodar Region"
            },
            {
                "country_code": "KZ",
                "country_id": 112,
                "id": 147,
                "iso2": "ZHA",
                "name": "Jambyl Region"
            },
            {
                "country_code": "KZ",
                "country_id": 112,
                "id": 148,
                "iso2": "ZAP",
                "name": "West Kazakhstan Province"
            },
            {
                "country_code": "KZ",
                "country_id": 112,
                "id": 149,
                "iso2": "YUZ",
                "name": "Turkestan Region"
            },
            {
                "country_code": "KZ",
                "country_id": 112,
                "id": 150,
                "iso2": "KAR",
                "name": "Karaganda Region"
            },
            {
                "country_code": "KZ",
                "country_id": 112,
                "id": 151,
                "iso2": "AKT",
                "name": "Aktobe Region"
            },
            {
                "country_code": "KZ",
                "country_id": 112,
                "id": 152,
                "iso2": "ALA",
                "name": "Almaty"
            },
            {
                "country_code": "KZ",
                "country_id": 112,
                "id": 153,
                "iso2": "ATY",
                "name": "Atyrau Region"
            },
            {
                "country_code": "KZ",
                "country_id": 112,
                "id": 154,
                "iso2": "VOS",
                "name": "East Kazakhstan Region"
            },
            {
                "country_code": "KZ",
                "country_id": 112,
                "id": 155,
                "iso2": "BAY",
                "name": "Baikonur"
            },
            {
                "country_code": "KZ",
                "country_id": 112,
                "id": 156,
                "iso2": "AST",
                "name": "Nur-Sultan"
            },
            {
                "country_code": "KZ",
                "country_id": 112,
                "id": 157,
                "iso2": "KUS",
                "name": "Kostanay Region"
            }
        ]
    },
    {
        "id": 113,
        "name": "Kenya",
        "iso3": "KEN",
        "iso2": "KE",
        "states": [
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 158,
                "iso2": "11",
                "name": "Kakamega County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 159,
                "iso2": "16",
                "name": "Kisii County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 160,
                "iso2": "200",
                "name": "Central Province"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 161,
                "iso2": "4",
                "name": "Busia County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 162,
                "iso2": "500",
                "name": "North Eastern Province"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 163,
                "iso2": "6",
                "name": "Embu County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 164,
                "iso2": "20",
                "name": "Laikipia County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 165,
                "iso2": "32",
                "name": "Nandi District"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 166,
                "iso2": "21",
                "name": "Lamu County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 167,
                "iso2": "15",
                "name": "Kirinyaga County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 168,
                "iso2": "3",
                "name": "Bungoma County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 169,
                "iso2": "44",
                "name": "Uasin Gishu District"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 170,
                "iso2": "9",
                "name": "Isiolo County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 171,
                "iso2": "17",
                "name": "Kisumu County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 172,
                "iso2": "300",
                "name": "Coast Province"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 173,
                "iso2": "19",
                "name": "Kwale County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 174,
                "iso2": "14",
                "name": "Kilifi County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 175,
                "iso2": "33",
                "name": "Narok County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 176,
                "iso2": "39",
                "name": "Taita–Taveta County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 177,
                "iso2": "800",
                "name": "Western Province"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 178,
                "iso2": "29",
                "name": "Muranga County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 179,
                "iso2": "700",
                "name": "Rift Valley Province"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 180,
                "iso2": "36",
                "name": "Nyeri County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 181,
                "iso2": "1",
                "name": "Baringo County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 182,
                "iso2": "46",
                "name": "Wajir County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 183,
                "iso2": "42",
                "name": "Trans-Nzoia District"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 184,
                "iso2": "22",
                "name": "Machakos County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 185,
                "iso2": "41",
                "name": "Tharaka Nithi County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 186,
                "iso2": "38",
                "name": "Siaya County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 187,
                "iso2": "24",
                "name": "Mandera County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 188,
                "iso2": "23",
                "name": "Makueni County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 189,
                "iso2": "400",
                "name": "Eastern Province"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 190,
                "iso2": "27",
                "name": "Migori County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 191,
                "iso2": "110",
                "name": "Nairobi"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 192,
                "iso2": "35",
                "name": "Nyandarua County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 193,
                "iso2": "12",
                "name": "Kericho County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 194,
                "iso2": "25",
                "name": "Marsabit County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 195,
                "iso2": "8",
                "name": "Homa Bay County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 196,
                "iso2": "7",
                "name": "Garissa County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 197,
                "iso2": "10",
                "name": "Kajiado County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 198,
                "iso2": "26",
                "name": "Meru County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 199,
                "iso2": "13",
                "name": "Kiambu County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 200,
                "iso2": "28",
                "name": "Mombasa County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 201,
                "iso2": "5",
                "name": "Elgeyo-Marakwet County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 202,
                "iso2": "45",
                "name": "Vihiga District"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 203,
                "iso2": "31",
                "name": "Nakuru District"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 204,
                "iso2": "600",
                "name": "Nyanza Province"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 205,
                "iso2": "40",
                "name": "Tana River County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 206,
                "iso2": "43",
                "name": "Turkana County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 207,
                "iso2": "37",
                "name": "Samburu County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 208,
                "iso2": "47",
                "name": "West Pokot County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 209,
                "iso2": "34",
                "name": "Nyamira District"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 210,
                "iso2": "2",
                "name": "Bomet County"
            },
            {
                "country_code": "KE",
                "country_id": 113,
                "id": 211,
                "iso2": "18",
                "name": "Kitui County"
            }
        ]
    },
    {
        "id": 114,
        "name": "Kiribati",
        "iso3": "KIR",
        "iso2": "KI",
        "states": [
            {
                "country_code": "KI",
                "country_id": 114,
                "id": 1830,
                "iso2": "P",
                "name": "Phoenix Islands"
            },
            {
                "country_code": "KI",
                "country_id": 114,
                "id": 1831,
                "iso2": "G",
                "name": "Gilbert Islands"
            },
            {
                "country_code": "KI",
                "country_id": 114,
                "id": 1832,
                "iso2": "L",
                "name": "Line Islands"
            }
        ]
    },
    {
        "id": 115,
        "name": "Korea North",
        "iso3": "PRK",
        "iso2": "KP",
        "states": [
            {
                "country_code": "KP",
                "country_id": 115,
                "id": 3995,
                "iso2": "9",
                "name": "North Hamgyong Province"
            },
            {
                "country_code": "KP",
                "country_id": 115,
                "id": 3996,
                "iso2": "10",
                "name": "Ryanggang Province"
            },
            {
                "country_code": "KP",
                "country_id": 115,
                "id": 3997,
                "iso2": "2",
                "name": "South Pyongan Province"
            },
            {
                "country_code": "KP",
                "country_id": 115,
                "id": 3998,
                "iso2": "4",
                "name": "Chagang Province"
            },
            {
                "country_code": "KP",
                "country_id": 115,
                "id": 3999,
                "iso2": "7",
                "name": "Kangwon Province"
            },
            {
                "country_code": "KP",
                "country_id": 115,
                "id": 4000,
                "iso2": "8",
                "name": "South Hamgyong Province"
            },
            {
                "country_code": "KP",
                "country_id": 115,
                "id": 4001,
                "iso2": "13",
                "name": "Rason"
            },
            {
                "country_code": "KP",
                "country_id": 115,
                "id": 4002,
                "iso2": "3",
                "name": "North Pyongan Province"
            },
            {
                "country_code": "KP",
                "country_id": 115,
                "id": 4003,
                "iso2": "5",
                "name": "South Hwanghae Province"
            },
            {
                "country_code": "KP",
                "country_id": 115,
                "id": 4004,
                "iso2": "6",
                "name": "North Hwanghae Province"
            },
            {
                "country_code": "KP",
                "country_id": 115,
                "id": 4005,
                "iso2": "1",
                "name": "Pyongyang"
            }
        ]
    },
    {
        "id": 116,
        "name": "Korea South",
        "iso3": "KOR",
        "iso2": "KR",
        "states": [
            {
                "country_code": "KR",
                "country_id": 116,
                "id": 3846,
                "iso2": "27",
                "name": "Daegu"
            },
            {
                "country_code": "KR",
                "country_id": 116,
                "id": 3847,
                "iso2": "41",
                "name": "Gyeonggi Province"
            },
            {
                "country_code": "KR",
                "country_id": 116,
                "id": 3848,
                "iso2": "28",
                "name": "Incheon"
            },
            {
                "country_code": "KR",
                "country_id": 116,
                "id": 3849,
                "iso2": "11",
                "name": "Seoul"
            },
            {
                "country_code": "KR",
                "country_id": 116,
                "id": 3850,
                "iso2": "30",
                "name": "Daejeon"
            },
            {
                "country_code": "KR",
                "country_id": 116,
                "id": 3851,
                "iso2": "45",
                "name": "North Jeolla Province"
            },
            {
                "country_code": "KR",
                "country_id": 116,
                "id": 3852,
                "iso2": "31",
                "name": "Ulsan"
            },
            {
                "country_code": "KR",
                "country_id": 116,
                "id": 3853,
                "iso2": "49",
                "name": "Jeju"
            },
            {
                "country_code": "KR",
                "country_id": 116,
                "id": 3854,
                "iso2": "43",
                "name": "North Chungcheong Province"
            },
            {
                "country_code": "KR",
                "country_id": 116,
                "id": 3855,
                "iso2": "47",
                "name": "North Gyeongsang Province"
            },
            {
                "country_code": "KR",
                "country_id": 116,
                "id": 3856,
                "iso2": "46",
                "name": "South Jeolla Province"
            },
            {
                "country_code": "KR",
                "country_id": 116,
                "id": 3857,
                "iso2": "48",
                "name": "South Gyeongsang Province"
            },
            {
                "country_code": "KR",
                "country_id": 116,
                "id": 3858,
                "iso2": "29",
                "name": "Gwangju"
            },
            {
                "country_code": "KR",
                "country_id": 116,
                "id": 3859,
                "iso2": "44",
                "name": "South Chungcheong Province"
            },
            {
                "country_code": "KR",
                "country_id": 116,
                "id": 3860,
                "iso2": "26",
                "name": "Busan"
            },
            {
                "country_code": "KR",
                "country_id": 116,
                "id": 3861,
                "iso2": "50",
                "name": "Sejong City"
            },
            {
                "country_code": "KR",
                "country_id": 116,
                "id": 3862,
                "iso2": "42",
                "name": "Gangwon Province"
            }
        ]
    },
    {
        "id": 117,
        "name": "Kuwait",
        "iso3": "KWT",
        "iso2": "KW",
        "states": [
            {
                "country_code": "KW",
                "country_id": 117,
                "id": 972,
                "iso2": "JA",
                "name": "Al Jahra Governorate"
            },
            {
                "country_code": "KW",
                "country_id": 117,
                "id": 973,
                "iso2": "HA",
                "name": "Hawalli Governorate"
            },
            {
                "country_code": "KW",
                "country_id": 117,
                "id": 974,
                "iso2": "MU",
                "name": "Mubarak Al-Kabeer Governorate"
            },
            {
                "country_code": "KW",
                "country_id": 117,
                "id": 975,
                "iso2": "FA",
                "name": "Al Farwaniyah Governorate"
            },
            {
                "country_code": "KW",
                "country_id": 117,
                "id": 976,
                "iso2": "KU",
                "name": "Capital Governorate"
            },
            {
                "country_code": "KW",
                "country_id": 117,
                "id": 977,
                "iso2": "AH",
                "name": "Al Ahmadi Governorate"
            }
        ]
    },
    {
        "id": 118,
        "name": "Kyrgyzstan",
        "iso3": "KGZ",
        "iso2": "KG",
        "states": [
            {
                "country_code": "KG",
                "country_id": 118,
                "id": 997,
                "iso2": "T",
                "name": "Talas Region"
            },
            {
                "country_code": "KG",
                "country_id": 118,
                "id": 998,
                "iso2": "B",
                "name": "Batken Region"
            },
            {
                "country_code": "KG",
                "country_id": 118,
                "id": 999,
                "iso2": "N",
                "name": "Naryn Region"
            },
            {
                "country_code": "KG",
                "country_id": 118,
                "id": 1000,
                "iso2": "J",
                "name": "Jalal-Abad Region"
            },
            {
                "country_code": "KG",
                "country_id": 118,
                "id": 1001,
                "iso2": "GB",
                "name": "Bishkek"
            },
            {
                "country_code": "KG",
                "country_id": 118,
                "id": 1002,
                "iso2": "Y",
                "name": "Issyk-Kul Region"
            },
            {
                "country_code": "KG",
                "country_id": 118,
                "id": 1003,
                "iso2": "GO",
                "name": "Osh"
            },
            {
                "country_code": "KG",
                "country_id": 118,
                "id": 1004,
                "iso2": "C",
                "name": "Chuy Region"
            },
            {
                "country_code": "KG",
                "country_id": 118,
                "id": 1005,
                "iso2": "O",
                "name": "Osh Region"
            }
        ]
    },
    {
        "id": 119,
        "name": "Laos",
        "iso3": "LAO",
        "iso2": "LA",
        "states": [
            {
                "country_code": "LA",
                "country_id": 119,
                "id": 978,
                "iso2": "LP",
                "name": "Luang Prabang Province"
            },
            {
                "country_code": "LA",
                "country_id": 119,
                "id": 979,
                "iso2": "VT",
                "name": "Vientiane Prefecture"
            },
            {
                "country_code": "LA",
                "country_id": 119,
                "id": 980,
                "iso2": "VI",
                "name": "Vientiane Province"
            },
            {
                "country_code": "LA",
                "country_id": 119,
                "id": 981,
                "iso2": "SL",
                "name": "Salavan Province"
            },
            {
                "country_code": "LA",
                "country_id": 119,
                "id": 982,
                "iso2": "AT",
                "name": "Attapeu Province"
            },
            {
                "country_code": "LA",
                "country_id": 119,
                "id": 983,
                "iso2": "XS",
                "name": "Xaisomboun Province"
            },
            {
                "country_code": "LA",
                "country_id": 119,
                "id": 984,
                "iso2": "XE",
                "name": "Sekong Province"
            },
            {
                "country_code": "LA",
                "country_id": 119,
                "id": 985,
                "iso2": "BL",
                "name": "Bolikhamsai Province"
            },
            {
                "country_code": "LA",
                "country_id": 119,
                "id": 986,
                "iso2": "KH",
                "name": "Khammouane Province"
            },
            {
                "country_code": "LA",
                "country_id": 119,
                "id": 987,
                "iso2": "PH",
                "name": "Phongsaly Province"
            },
            {
                "country_code": "LA",
                "country_id": 119,
                "id": 988,
                "iso2": "OU",
                "name": "Oudomxay Province"
            },
            {
                "country_code": "LA",
                "country_id": 119,
                "id": 989,
                "iso2": "HO",
                "name": "Houaphanh Province"
            },
            {
                "country_code": "LA",
                "country_id": 119,
                "id": 990,
                "iso2": "SV",
                "name": "Savannakhet Province"
            },
            {
                "country_code": "LA",
                "country_id": 119,
                "id": 991,
                "iso2": "BK",
                "name": "Bokeo Province"
            },
            {
                "country_code": "LA",
                "country_id": 119,
                "id": 992,
                "iso2": "LM",
                "name": "Luang Namtha Province"
            },
            {
                "country_code": "LA",
                "country_id": 119,
                "id": 993,
                "iso2": "XA",
                "name": "Sainyabuli Province"
            },
            {
                "country_code": "LA",
                "country_id": 119,
                "id": 994,
                "iso2": "XN",
                "name": "Xaisomboun"
            },
            {
                "country_code": "LA",
                "country_id": 119,
                "id": 995,
                "iso2": "XI",
                "name": "Xiangkhouang Province"
            },
            {
                "country_code": "LA",
                "country_id": 119,
                "id": 996,
                "iso2": "CH",
                "name": "Champasak Province"
            }
        ]
    },
    {
        "id": 120,
        "name": "Latvia",
        "iso3": "LVA",
        "iso2": "LV",
        "states": [
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4396,
                "iso2": "86",
                "name": "Salacgrīva Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4397,
                "iso2": "105",
                "name": "Vecumnieki Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4398,
                "iso2": "64",
                "name": "Naukšēni Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4399,
                "iso2": "36",
                "name": "Ilūkste Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4400,
                "iso2": "33",
                "name": "Gulbene Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4401,
                "iso2": "56",
                "name": "Līvāni Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4402,
                "iso2": "87",
                "name": "Salaspils Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4403,
                "iso2": "106",
                "name": "Ventspils Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4404,
                "iso2": "83",
                "name": "Rundāle Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4405,
                "iso2": "72",
                "name": "Pļaviņas Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4406,
                "iso2": "103",
                "name": "Vārkava Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4407,
                "iso2": "39",
                "name": "Jaunpiebalga Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4408,
                "iso2": "90",
                "name": "Sēja Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4409,
                "iso2": "99",
                "name": "Tukums Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4410,
                "iso2": "23",
                "name": "Cibla Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4411,
                "iso2": "19",
                "name": "Burtnieki Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4412,
                "iso2": "51",
                "name": "Ķegums Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4413,
                "iso2": "49",
                "name": "Krustpils Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4414,
                "iso2": "21",
                "name": "Cesvaine Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4415,
                "iso2": "92",
                "name": "Skrīveri Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4416,
                "iso2": "67",
                "name": "Ogre Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4417,
                "iso2": "68",
                "name": "Olaine Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4418,
                "iso2": "54",
                "name": "Limbaži Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4419,
                "iso2": "57",
                "name": "Lubāna Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4420,
                "iso2": "43",
                "name": "Kandava Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4421,
                "iso2": "VEN",
                "name": "Ventspils"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4422,
                "iso2": "48",
                "name": "Krimulda Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4423,
                "iso2": "82",
                "name": "Rugāji Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4424,
                "iso2": "41",
                "name": "Jelgava Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4425,
                "iso2": "101",
                "name": "Valka Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4426,
                "iso2": "84",
                "name": "Rūjiena Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4427,
                "iso2": "12",
                "name": "Babīte Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4428,
                "iso2": "27",
                "name": "Dundaga Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4429,
                "iso2": "74",
                "name": "Priekule Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4430,
                "iso2": "110",
                "name": "Zilupe Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4431,
                "iso2": "102",
                "name": "Varakļāni Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4432,
                "iso2": "65",
                "name": "Nereta Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4433,
                "iso2": "59",
                "name": "Madona Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4434,
                "iso2": "85",
                "name": "Sala Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4435,
                "iso2": "52",
                "name": "Ķekava Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4436,
                "iso2": "66",
                "name": "Nīca Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4437,
                "iso2": "26",
                "name": "Dobele Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4438,
                "iso2": "42",
                "name": "Jēkabpils Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4439,
                "iso2": "88",
                "name": "Saldus Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4440,
                "iso2": "79",
                "name": "Roja Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4441,
                "iso2": "34",
                "name": "Iecava Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4442,
                "iso2": "69",
                "name": "Ozolnieki Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4443,
                "iso2": "89",
                "name": "Saulkrasti Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4444,
                "iso2": "30",
                "name": "Ērgļi Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4445,
                "iso2": "1",
                "name": "Aglona Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4446,
                "iso2": "JUR",
                "name": "Jūrmala"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4447,
                "iso2": "93",
                "name": "Skrunda Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4448,
                "iso2": "29",
                "name": "Engure Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4449,
                "iso2": "37",
                "name": "Inčukalns Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4450,
                "iso2": "62",
                "name": "Mārupe Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4451,
                "iso2": "63",
                "name": "Mērsrags Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4452,
                "iso2": "46",
                "name": "Koknese Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4453,
                "iso2": "44",
                "name": "Kārsava Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4454,
                "iso2": "20",
                "name": "Carnikava Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4455,
                "iso2": "77",
                "name": "Rēzekne Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4456,
                "iso2": "107",
                "name": "Viesīte Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4457,
                "iso2": "9",
                "name": "Ape Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4458,
                "iso2": "28",
                "name": "Durbe Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4459,
                "iso2": "97",
                "name": "Talsi Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4460,
                "iso2": "LPX",
                "name": "Liepāja"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4461,
                "iso2": "61",
                "name": "Mālpils Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4462,
                "iso2": "94",
                "name": "Smiltene Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4463,
                "iso2": "DGV",
                "name": "Daugavpils"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4464,
                "iso2": "JKB",
                "name": "Jēkabpils"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4465,
                "iso2": "16",
                "name": "Bauska Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4466,
                "iso2": "104",
                "name": "Vecpiebalga Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4467,
                "iso2": "71",
                "name": "Pāvilosta Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4468,
                "iso2": "18",
                "name": "Brocēni Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4469,
                "iso2": "22",
                "name": "Cēsis Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4470,
                "iso2": "32",
                "name": "Grobiņa Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4471,
                "iso2": "17",
                "name": "Beverīna Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4472,
                "iso2": "2",
                "name": "Aizkraukle Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4473,
                "iso2": "VMR",
                "name": "Valmiera"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4474,
                "iso2": "47",
                "name": "Krāslava Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4475,
                "iso2": "38",
                "name": "Jaunjelgava Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4476,
                "iso2": "91",
                "name": "Sigulda Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4477,
                "iso2": "108",
                "name": "Viļaka Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4478,
                "iso2": "95",
                "name": "Stopiņi Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4479,
                "iso2": "76",
                "name": "Rauna Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4480,
                "iso2": "98",
                "name": "Tērvete Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4481,
                "iso2": "10",
                "name": "Auce Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4482,
                "iso2": "13",
                "name": "Baldone Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4483,
                "iso2": "73",
                "name": "Preiļi Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4484,
                "iso2": "5",
                "name": "Aloja Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4485,
                "iso2": "6",
                "name": "Alsunga Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4486,
                "iso2": "109",
                "name": "Viļāni Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4487,
                "iso2": "7",
                "name": "Alūksne Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4488,
                "iso2": "55",
                "name": "Līgatne Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4489,
                "iso2": "40",
                "name": "Jaunpils Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4490,
                "iso2": "50",
                "name": "Kuldīga Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4491,
                "iso2": "RIX",
                "name": "Riga"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4492,
                "iso2": "25",
                "name": "Daugavpils Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4493,
                "iso2": "80",
                "name": "Ropaži Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4494,
                "iso2": "96",
                "name": "Strenči Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4495,
                "iso2": "45",
                "name": "Kocēni Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4496,
                "iso2": "3",
                "name": "Aizpute Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4497,
                "iso2": "8",
                "name": "Amata Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4498,
                "iso2": "14",
                "name": "Baltinava Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4499,
                "iso2": "4",
                "name": "Aknīste Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4500,
                "iso2": "JEL",
                "name": "Jelgava"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4501,
                "iso2": "58",
                "name": "Ludza Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4502,
                "iso2": "78",
                "name": "Riebiņi Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4503,
                "iso2": "81",
                "name": "Rucava Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4504,
                "iso2": "24",
                "name": "Dagda Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4505,
                "iso2": "15",
                "name": "Balvi Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4506,
                "iso2": "75",
                "name": "Priekuļi Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4507,
                "iso2": "70",
                "name": "Pārgauja Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4508,
                "iso2": "100",
                "name": "Vaiņode Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4509,
                "iso2": "REZ",
                "name": "Rēzekne"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4510,
                "iso2": "31",
                "name": "Garkalne Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4511,
                "iso2": "35",
                "name": "Ikšķile Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4512,
                "iso2": "53",
                "name": "Lielvārde Municipality"
            },
            {
                "country_code": "LV",
                "country_id": 120,
                "id": 4513,
                "iso2": "60",
                "name": "Mazsalaca Municipality"
            }
        ]
    },
    {
        "id": 121,
        "name": "Lebanon",
        "iso3": "LBN",
        "iso2": "LB",
        "states": [
            {
                "country_code": "LB",
                "country_id": 121,
                "id": 2281,
                "iso2": "JA",
                "name": "South Governorate"
            },
            {
                "country_code": "LB",
                "country_id": 121,
                "id": 2282,
                "iso2": "JL",
                "name": "Mount Lebanon Governorate"
            },
            {
                "country_code": "LB",
                "country_id": 121,
                "id": 2283,
                "iso2": "BH",
                "name": "Baalbek-Hermel Governorate"
            },
            {
                "country_code": "LB",
                "country_id": 121,
                "id": 2284,
                "iso2": "AS",
                "name": "North Governorate"
            },
            {
                "country_code": "LB",
                "country_id": 121,
                "id": 2285,
                "iso2": "AK",
                "name": "Akkar Governorate"
            },
            {
                "country_code": "LB",
                "country_id": 121,
                "id": 2286,
                "iso2": "BA",
                "name": "Beirut Governorate"
            },
            {
                "country_code": "LB",
                "country_id": 121,
                "id": 2287,
                "iso2": "BI",
                "name": "Beqaa Governorate"
            },
            {
                "country_code": "LB",
                "country_id": 121,
                "id": 2288,
                "iso2": "NA",
                "name": "Nabatieh Governorate"
            }
        ]
    },
    {
        "id": 122,
        "name": "Lesotho",
        "iso3": "LSO",
        "iso2": "LS",
        "states": [
            {
                "country_code": "LS",
                "country_id": 122,
                "id": 3022,
                "iso2": "E",
                "name": "Mafeteng District"
            },
            {
                "country_code": "LS",
                "country_id": 122,
                "id": 3023,
                "iso2": "F",
                "name": "Mohale's Hoek District"
            },
            {
                "country_code": "LS",
                "country_id": 122,
                "id": 3024,
                "iso2": "J",
                "name": "Mokhotlong District"
            },
            {
                "country_code": "LS",
                "country_id": 122,
                "id": 3025,
                "iso2": "H",
                "name": "Qacha's Nek District"
            },
            {
                "country_code": "LS",
                "country_id": 122,
                "id": 3026,
                "iso2": "C",
                "name": "Leribe District"
            },
            {
                "country_code": "LS",
                "country_id": 122,
                "id": 3027,
                "iso2": "G",
                "name": "Quthing District"
            },
            {
                "country_code": "LS",
                "country_id": 122,
                "id": 3028,
                "iso2": "A",
                "name": "Maseru District"
            },
            {
                "country_code": "LS",
                "country_id": 122,
                "id": 3029,
                "iso2": "B",
                "name": "Butha-Buthe District"
            },
            {
                "country_code": "LS",
                "country_id": 122,
                "id": 3030,
                "iso2": "D",
                "name": "Berea District"
            },
            {
                "country_code": "LS",
                "country_id": 122,
                "id": 3031,
                "iso2": "K",
                "name": "Thaba-Tseka District"
            }
        ]
    },
    {
        "id": 123,
        "name": "Liberia",
        "iso3": "LBR",
        "iso2": "LR",
        "states": [
            {
                "country_code": "LR",
                "country_id": 123,
                "id": 3032,
                "iso2": "MO",
                "name": "Montserrado County"
            },
            {
                "country_code": "LR",
                "country_id": 123,
                "id": 3033,
                "iso2": "RI",
                "name": "River Cess County"
            },
            {
                "country_code": "LR",
                "country_id": 123,
                "id": 3034,
                "iso2": "BG",
                "name": "Bong County"
            },
            {
                "country_code": "LR",
                "country_id": 123,
                "id": 3035,
                "iso2": "SI",
                "name": "Sinoe County"
            },
            {
                "country_code": "LR",
                "country_id": 123,
                "id": 3036,
                "iso2": "CM",
                "name": "Grand Cape Mount County"
            },
            {
                "country_code": "LR",
                "country_id": 123,
                "id": 3037,
                "iso2": "LO",
                "name": "Lofa County"
            },
            {
                "country_code": "LR",
                "country_id": 123,
                "id": 3038,
                "iso2": "RG",
                "name": "River Gee County"
            },
            {
                "country_code": "LR",
                "country_id": 123,
                "id": 3039,
                "iso2": "GG",
                "name": "Grand Gedeh County"
            },
            {
                "country_code": "LR",
                "country_id": 123,
                "id": 3040,
                "iso2": "GB",
                "name": "Grand Bassa County"
            },
            {
                "country_code": "LR",
                "country_id": 123,
                "id": 3041,
                "iso2": "BM",
                "name": "Bomi County"
            },
            {
                "country_code": "LR",
                "country_id": 123,
                "id": 3042,
                "iso2": "MY",
                "name": "Maryland County"
            },
            {
                "country_code": "LR",
                "country_id": 123,
                "id": 3043,
                "iso2": "MG",
                "name": "Margibi County"
            },
            {
                "country_code": "LR",
                "country_id": 123,
                "id": 3044,
                "iso2": "GP",
                "name": "Gbarpolu County"
            },
            {
                "country_code": "LR",
                "country_id": 123,
                "id": 3045,
                "iso2": "GK",
                "name": "Grand Kru County"
            },
            {
                "country_code": "LR",
                "country_id": 123,
                "id": 3046,
                "iso2": "NI",
                "name": "Nimba"
            }
        ]
    },
    {
        "id": 124,
        "name": "Libya",
        "iso3": "LBY",
        "iso2": "LY",
        "states": [
            {
                "country_code": "LY",
                "country_id": 124,
                "id": 2961,
                "iso2": "MB",
                "name": "Murqub"
            },
            {
                "country_code": "LY",
                "country_id": 124,
                "id": 2962,
                "iso2": "NQ",
                "name": "Nuqat al Khams"
            },
            {
                "country_code": "LY",
                "country_id": 124,
                "id": 2963,
                "iso2": "ZA",
                "name": "Zawiya District"
            },
            {
                "country_code": "LY",
                "country_id": 124,
                "id": 2964,
                "iso2": "WA",
                "name": "Al Wahat District"
            },
            {
                "country_code": "LY",
                "country_id": 124,
                "id": 2965,
                "iso2": "SB",
                "name": "Sabha District"
            },
            {
                "country_code": "LY",
                "country_id": 124,
                "id": 2966,
                "iso2": "DR",
                "name": "Derna District"
            },
            {
                "country_code": "LY",
                "country_id": 124,
                "id": 2967,
                "iso2": "MQ",
                "name": "Murzuq District"
            },
            {
                "country_code": "LY",
                "country_id": 124,
                "id": 2968,
                "iso2": "MJ",
                "name": "Marj District"
            },
            {
                "country_code": "LY",
                "country_id": 124,
                "id": 2969,
                "iso2": "GT",
                "name": "Ghat District"
            },
            {
                "country_code": "LY",
                "country_id": 124,
                "id": 2970,
                "iso2": "JU",
                "name": "Jufra"
            },
            {
                "country_code": "LY",
                "country_id": 124,
                "id": 2971,
                "iso2": "TB",
                "name": "Tripoli District"
            },
            {
                "country_code": "LY",
                "country_id": 124,
                "id": 2972,
                "iso2": "KF",
                "name": "Kufra District"
            },
            {
                "country_code": "LY",
                "country_id": 124,
                "id": 2973,
                "iso2": "WD",
                "name": "Wadi al Hayaa District"
            },
            {
                "country_code": "LY",
                "country_id": 124,
                "id": 2974,
                "iso2": "JG",
                "name": "Jabal al Gharbi District"
            },
            {
                "country_code": "LY",
                "country_id": 124,
                "id": 2975,
                "iso2": "WS",
                "name": "Wadi al Shatii District"
            },
            {
                "country_code": "LY",
                "country_id": 124,
                "id": 2976,
                "iso2": "NL",
                "name": "Nalut District"
            },
            {
                "country_code": "LY",
                "country_id": 124,
                "id": 2977,
                "iso2": "SR",
                "name": "Sirte District"
            },
            {
                "country_code": "LY",
                "country_id": 124,
                "id": 2978,
                "iso2": "MI",
                "name": "Misrata District"
            },
            {
                "country_code": "LY",
                "country_id": 124,
                "id": 2979,
                "iso2": "JI",
                "name": "Jafara"
            },
            {
                "country_code": "LY",
                "country_id": 124,
                "id": 2980,
                "iso2": "JA",
                "name": "Jabal al Akhdar"
            },
            {
                "country_code": "LY",
                "country_id": 124,
                "id": 2981,
                "iso2": "BA",
                "name": "Benghazi"
            }
        ]
    },
    {
        "id": 125,
        "name": "Liechtenstein",
        "iso3": "LIE",
        "iso2": "LI",
        "states": [
            {
                "country_code": "LI",
                "country_id": 125,
                "id": 449,
                "iso2": "8",
                "name": "Schellenberg"
            },
            {
                "country_code": "LI",
                "country_id": 125,
                "id": 450,
                "iso2": "7",
                "name": "Schaan"
            },
            {
                "country_code": "LI",
                "country_id": 125,
                "id": 451,
                "iso2": "2",
                "name": "Eschen"
            },
            {
                "country_code": "LI",
                "country_id": 125,
                "id": 452,
                "iso2": "11",
                "name": "Vaduz"
            },
            {
                "country_code": "LI",
                "country_id": 125,
                "id": 453,
                "iso2": "6",
                "name": "Ruggell"
            },
            {
                "country_code": "LI",
                "country_id": 125,
                "id": 454,
                "iso2": "5",
                "name": "Planken"
            },
            {
                "country_code": "LI",
                "country_id": 125,
                "id": 455,
                "iso2": "4",
                "name": "Mauren"
            },
            {
                "country_code": "LI",
                "country_id": 125,
                "id": 456,
                "iso2": "10",
                "name": "Triesenberg"
            },
            {
                "country_code": "LI",
                "country_id": 125,
                "id": 457,
                "iso2": "3",
                "name": "Gamprin"
            },
            {
                "country_code": "LI",
                "country_id": 125,
                "id": 458,
                "iso2": "1",
                "name": "Balzers"
            },
            {
                "country_code": "LI",
                "country_id": 125,
                "id": 459,
                "iso2": "9",
                "name": "Triesen"
            }
        ]
    },
    {
        "id": 126,
        "name": "Lithuania",
        "iso3": "LTU",
        "iso2": "LT",
        "states": [
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1553,
                "iso2": "35",
                "name": "Plungė District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1554,
                "iso2": "44",
                "name": "Šiauliai District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1555,
                "iso2": "12",
                "name": "Jurbarkas District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1556,
                "iso2": "KU",
                "name": "Kaunas County"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1557,
                "iso2": "26",
                "name": "Mažeikiai District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1558,
                "iso2": "PN",
                "name": "Panevėžys County"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1559,
                "iso2": "8",
                "name": "Elektrėnai municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1560,
                "iso2": "49",
                "name": "Švenčionys District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1561,
                "iso2": "1",
                "name": "Akmenė District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1562,
                "iso2": "9",
                "name": "Ignalina District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1563,
                "iso2": "28",
                "name": "Neringa Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1564,
                "iso2": "59",
                "name": "Visaginas Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1565,
                "iso2": "16",
                "name": "Kaunas District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1566,
                "iso2": "6",
                "name": "Biržai District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1567,
                "iso2": "10",
                "name": "Jonava District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1568,
                "iso2": "37",
                "name": "Radviliškis District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1569,
                "iso2": "TE",
                "name": "Telšiai County"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1570,
                "iso2": "MR",
                "name": "Marijampolė County"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1571,
                "iso2": "22",
                "name": "Kretinga District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1572,
                "iso2": "50",
                "name": "Tauragė District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1573,
                "iso2": "TA",
                "name": "Tauragė County"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1574,
                "iso2": "AL",
                "name": "Alytus County"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1575,
                "iso2": "17",
                "name": "Kazlų Rūda municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1576,
                "iso2": "41",
                "name": "Šakiai District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1577,
                "iso2": "42",
                "name": "Šalčininkai District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1578,
                "iso2": "36",
                "name": "Prienai District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1579,
                "iso2": "7",
                "name": "Druskininkai municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1580,
                "iso2": "15",
                "name": "Kaunas City Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1581,
                "iso2": "11",
                "name": "Joniškis District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1582,
                "iso2": "27",
                "name": "Molėtai District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1583,
                "iso2": "13",
                "name": "Kaišiadorys District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1584,
                "iso2": "18",
                "name": "Kėdainiai District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1585,
                "iso2": "23",
                "name": "Kupiškis District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1586,
                "iso2": "SA",
                "name": "Šiauliai County"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1587,
                "iso2": "38",
                "name": "Raseiniai District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1588,
                "iso2": "31",
                "name": "Palanga City Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1589,
                "iso2": "32",
                "name": "Panevėžys City Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1590,
                "iso2": "39",
                "name": "Rietavas municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1591,
                "iso2": "14",
                "name": "Kalvarija municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1592,
                "iso2": "58",
                "name": "Vilnius District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1593,
                "iso2": "52",
                "name": "Trakai District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1594,
                "iso2": "47",
                "name": "Širvintos District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1595,
                "iso2": "30",
                "name": "Pakruojis District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1596,
                "iso2": "53",
                "name": "Ukmergė District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1597,
                "iso2": "20",
                "name": "Klaipeda City Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1598,
                "iso2": "54",
                "name": "Utena District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1599,
                "iso2": "3",
                "name": "Alytus District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1600,
                "iso2": "KL",
                "name": "Klaipėda County"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1601,
                "iso2": "VL",
                "name": "Vilnius County"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1602,
                "iso2": "55",
                "name": "Varėna District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1603,
                "iso2": "5",
                "name": "Birštonas Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1604,
                "iso2": "21",
                "name": "Klaipėda District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1605,
                "iso2": "2",
                "name": "Alytus City Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1606,
                "iso2": "57",
                "name": "Vilnius City Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1607,
                "iso2": "46",
                "name": "Šilutė District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1608,
                "iso2": "51",
                "name": "Telšiai District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1609,
                "iso2": "43",
                "name": "Šiauliai City Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1610,
                "iso2": "25",
                "name": "Marijampolė Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1611,
                "iso2": "24",
                "name": "Lazdijai District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1612,
                "iso2": "29",
                "name": "Pagėgiai municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1613,
                "iso2": "45",
                "name": "Šilalė District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1614,
                "iso2": "33",
                "name": "Panevėžys District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1615,
                "iso2": "40",
                "name": "Rokiškis District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1616,
                "iso2": "34",
                "name": "Pasvalys District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1617,
                "iso2": "48",
                "name": "Skuodas District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1618,
                "iso2": "19",
                "name": "Kelmė District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1619,
                "iso2": "60",
                "name": "Zarasai District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1620,
                "iso2": "56",
                "name": "Vilkaviškis District Municipality"
            },
            {
                "country_code": "LT",
                "country_id": 126,
                "id": 1621,
                "iso2": "UT",
                "name": "Utena County"
            }
        ]
    },
    {
        "id": 127,
        "name": "Luxembourg",
        "iso3": "LUX",
        "iso2": "LU",
        "states": [
            {
                "country_code": "LU",
                "country_id": 127,
                "id": 1513,
                "iso2": "DI",
                "name": "Canton of Diekirch"
            },
            {
                "country_code": "LU",
                "country_id": 127,
                "id": 1514,
                "iso2": "L",
                "name": "Luxembourg District"
            },
            {
                "country_code": "LU",
                "country_id": 127,
                "id": 1515,
                "iso2": "EC",
                "name": "Canton of Echternach"
            },
            {
                "country_code": "LU",
                "country_id": 127,
                "id": 1516,
                "iso2": "RD",
                "name": "Canton of Redange"
            },
            {
                "country_code": "LU",
                "country_id": 127,
                "id": 1517,
                "iso2": "ES",
                "name": "Canton of Esch-sur-Alzette"
            },
            {
                "country_code": "LU",
                "country_id": 127,
                "id": 1518,
                "iso2": "CA",
                "name": "Canton of Capellen"
            },
            {
                "country_code": "LU",
                "country_id": 127,
                "id": 1519,
                "iso2": "RM",
                "name": "Canton of Remich"
            },
            {
                "country_code": "LU",
                "country_id": 127,
                "id": 1520,
                "iso2": "G",
                "name": "Grevenmacher District"
            },
            {
                "country_code": "LU",
                "country_id": 127,
                "id": 1521,
                "iso2": "CL",
                "name": "Canton of Clervaux"
            },
            {
                "country_code": "LU",
                "country_id": 127,
                "id": 1522,
                "iso2": "ME",
                "name": "Canton of Mersch"
            },
            {
                "country_code": "LU",
                "country_id": 127,
                "id": 1523,
                "iso2": "VD",
                "name": "Canton of Vianden"
            },
            {
                "country_code": "LU",
                "country_id": 127,
                "id": 1524,
                "iso2": "D",
                "name": "Diekirch District"
            },
            {
                "country_code": "LU",
                "country_id": 127,
                "id": 1525,
                "iso2": "GR",
                "name": "Canton of Grevenmacher"
            },
            {
                "country_code": "LU",
                "country_id": 127,
                "id": 1526,
                "iso2": "WI",
                "name": "Canton of Wiltz"
            },
            {
                "country_code": "LU",
                "country_id": 127,
                "id": 1527,
                "iso2": "LU",
                "name": "Canton of Luxembourg"
            }
        ]
    },
    {
        "id": 128,
        "name": "Macau S.A.R.",
        "iso3": "MAC",
        "iso2": "MO",
        "states": []
    },
    {
        "id": 129,
        "name": "Macedonia",
        "iso3": "MKD",
        "iso2": "MK",
        "states": [
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 640,
                "iso2": "69",
                "name": "Sveti Nikole Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 641,
                "iso2": "43",
                "name": "Kratovo Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 642,
                "iso2": "31",
                "name": "Zajas Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 643,
                "iso2": "71",
                "name": "Staro Nagoričane Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 644,
                "iso2": "81",
                "name": "Češinovo-Obleševo Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 645,
                "iso2": "22",
                "name": "Debarca Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 646,
                "iso2": "63",
                "name": "Probištip Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 647,
                "iso2": "45",
                "name": "Krivogaštani Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 648,
                "iso2": "18",
                "name": "Gevgelija Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 649,
                "iso2": "5",
                "name": "Bogdanci Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 650,
                "iso2": "15",
                "name": "Vraneštica Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 651,
                "iso2": "13",
                "name": "Veles Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 652,
                "iso2": "7",
                "name": "Bosilovo Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 653,
                "iso2": "53",
                "name": "Mogila Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 654,
                "iso2": "75",
                "name": "Tearce Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 655,
                "iso2": "24",
                "name": "Demir Kapija Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 656,
                "iso2": "2",
                "name": "Aračinovo Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 657,
                "iso2": "28",
                "name": "Drugovo Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 658,
                "iso2": "11",
                "name": "Vasilevo Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 659,
                "iso2": "48",
                "name": "Lipkovo Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 660,
                "iso2": "8",
                "name": "Brvenica Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 661,
                "iso2": "83",
                "name": "Štip Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 662,
                "iso2": "12",
                "name": "Vevčani Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 663,
                "iso2": "76",
                "name": "Tetovo Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 664,
                "iso2": "54",
                "name": "Negotino Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 665,
                "iso2": "41",
                "name": "Konče Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 666,
                "iso2": "62",
                "name": "Prilep Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 667,
                "iso2": "68",
                "name": "Saraj Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 668,
                "iso2": "30",
                "name": "Želino Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 669,
                "iso2": "50",
                "name": "Mavrovo and Rostuša Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 670,
                "iso2": "61",
                "name": "Plasnica Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 671,
                "iso2": "10",
                "name": "Valandovo Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 672,
                "iso2": "14",
                "name": "Vinica Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 673,
                "iso2": "33",
                "name": "Zrnovci Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 674,
                "iso2": "37",
                "name": "Karbinci"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 675,
                "iso2": "27",
                "name": "Dolneni Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 676,
                "iso2": "80",
                "name": "Čaška Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 677,
                "iso2": "44",
                "name": "Kriva Palanka Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 678,
                "iso2": "35",
                "name": "Jegunovce Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 679,
                "iso2": "4",
                "name": "Bitola Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 680,
                "iso2": "84",
                "name": "Šuto Orizari Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 681,
                "iso2": "38",
                "name": "Karpoš Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 682,
                "iso2": "57",
                "name": "Oslomej Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 683,
                "iso2": "47",
                "name": "Kumanovo Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 684,
                "iso2": "85",
                "name": "Greater Skopje"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 685,
                "iso2": "60",
                "name": "Pehčevo Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 686,
                "iso2": "39",
                "name": "Kisela Voda Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 687,
                "iso2": "25",
                "name": "Demir Hisar Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 688,
                "iso2": "40",
                "name": "Kičevo Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 689,
                "iso2": "16",
                "name": "Vrapčište Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 690,
                "iso2": "34",
                "name": "Ilinden Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 691,
                "iso2": "67",
                "name": "Rosoman Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 692,
                "iso2": "52",
                "name": "Makedonski Brod Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 693,
                "iso2": "19",
                "name": "Gostivar Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 694,
                "iso2": "9",
                "name": "Butel Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 695,
                "iso2": "23",
                "name": "Delčevo Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 696,
                "iso2": "55",
                "name": "Novaci Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 697,
                "iso2": "26",
                "name": "Dojran Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 698,
                "iso2": "59",
                "name": "Petrovec Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 699,
                "iso2": "58",
                "name": "Ohrid Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 700,
                "iso2": "72",
                "name": "Struga Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 701,
                "iso2": "51",
                "name": "Makedonska Kamenica Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 702,
                "iso2": "77",
                "name": "Centar Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 703,
                "iso2": "1",
                "name": "Aerodrom Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 704,
                "iso2": "79",
                "name": "Čair Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 705,
                "iso2": "49",
                "name": "Lozovo Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 706,
                "iso2": "32",
                "name": "Zelenikovo Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 707,
                "iso2": "17",
                "name": "Gazi Baba Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 708,
                "iso2": "20",
                "name": "Gradsko Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 709,
                "iso2": "64",
                "name": "Radoviš Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 710,
                "iso2": "73",
                "name": "Strumica Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 711,
                "iso2": "74",
                "name": "Studeničani Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 712,
                "iso2": "66",
                "name": "Resen Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 713,
                "iso2": "36",
                "name": "Kavadarci Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 714,
                "iso2": "46",
                "name": "Kruševo Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 715,
                "iso2": "82",
                "name": "Čučer-Sandevo Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 716,
                "iso2": "3",
                "name": "Berovo Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 717,
                "iso2": "65",
                "name": "Rankovce Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 718,
                "iso2": "56",
                "name": "Novo Selo Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 719,
                "iso2": "70",
                "name": "Sopište Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 720,
                "iso2": "78",
                "name": "Centar Župa Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 721,
                "iso2": "6",
                "name": "Bogovinje Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 722,
                "iso2": "29",
                "name": "Gjorče Petrov Municipality"
            },
            {
                "country_code": "MK",
                "country_id": 129,
                "id": 723,
                "iso2": "42",
                "name": "Kočani Municipality"
            }
        ]
    },
    {
        "id": 130,
        "name": "Madagascar",
        "iso3": "MDG",
        "iso2": "MG",
        "states": [
            {
                "country_code": "MG",
                "country_id": 130,
                "id": 2948,
                "iso2": "F",
                "name": "Fianarantsoa Province"
            },
            {
                "country_code": "MG",
                "country_id": 130,
                "id": 2949,
                "iso2": "U",
                "name": "Toliara Province"
            },
            {
                "country_code": "MG",
                "country_id": 130,
                "id": 2950,
                "iso2": "D",
                "name": "Antsiranana Province"
            },
            {
                "country_code": "MG",
                "country_id": 130,
                "id": 2951,
                "iso2": "T",
                "name": "Antananarivo Province"
            },
            {
                "country_code": "MG",
                "country_id": 130,
                "id": 2952,
                "iso2": "A",
                "name": "Toamasina Province"
            },
            {
                "country_code": "MG",
                "country_id": 130,
                "id": 2953,
                "iso2": "M",
                "name": "Mahajanga Province"
            }
        ]
    },
    {
        "id": 131,
        "name": "Malawi",
        "iso3": "MWI",
        "iso2": "MW",
        "states": [
            {
                "country_code": "MW",
                "country_id": 131,
                "id": 3082,
                "iso2": "MH",
                "name": "Machinga District"
            },
            {
                "country_code": "MW",
                "country_id": 131,
                "id": 3083,
                "iso2": "ZO",
                "name": "Zomba District"
            },
            {
                "country_code": "MW",
                "country_id": 131,
                "id": 3084,
                "iso2": "MW",
                "name": "Mwanza District"
            },
            {
                "country_code": "MW",
                "country_id": 131,
                "id": 3085,
                "iso2": "NS",
                "name": "Nsanje District"
            },
            {
                "country_code": "MW",
                "country_id": 131,
                "id": 3086,
                "iso2": "SA",
                "name": "Salima District"
            },
            {
                "country_code": "MW",
                "country_id": 131,
                "id": 3087,
                "iso2": "CT",
                "name": "Chitipa district"
            },
            {
                "country_code": "MW",
                "country_id": 131,
                "id": 3088,
                "iso2": "NU",
                "name": "Ntcheu District"
            },
            {
                "country_code": "MW",
                "country_id": 131,
                "id": 3089,
                "iso2": "RU",
                "name": "Rumphi District"
            },
            {
                "country_code": "MW",
                "country_id": 131,
                "id": 3090,
                "iso2": "DO",
                "name": "Dowa District"
            },
            {
                "country_code": "MW",
                "country_id": 131,
                "id": 3091,
                "iso2": "KR",
                "name": "Karonga District"
            },
            {
                "country_code": "MW",
                "country_id": 131,
                "id": 3092,
                "iso2": "C",
                "name": "Central Region"
            },
            {
                "country_code": "MW",
                "country_id": 131,
                "id": 3093,
                "iso2": "LK",
                "name": "Likoma District"
            },
            {
                "country_code": "MW",
                "country_id": 131,
                "id": 3094,
                "iso2": "KS",
                "name": "Kasungu District"
            },
            {
                "country_code": "MW",
                "country_id": 131,
                "id": 3095,
                "iso2": "NB",
                "name": "Nkhata Bay District"
            },
            {
                "country_code": "MW",
                "country_id": 131,
                "id": 3096,
                "iso2": "BA",
                "name": "Balaka District"
            },
            {
                "country_code": "MW",
                "country_id": 131,
                "id": 3097,
                "iso2": "DE",
                "name": "Dedza District"
            },
            {
                "country_code": "MW",
                "country_id": 131,
                "id": 3098,
                "iso2": "TH",
                "name": "Thyolo District"
            },
            {
                "country_code": "MW",
                "country_id": 131,
                "id": 3099,
                "iso2": "MC",
                "name": "Mchinji District"
            },
            {
                "country_code": "MW",
                "country_id": 131,
                "id": 3100,
                "iso2": "NK",
                "name": "Nkhotakota District"
            },
            {
                "country_code": "MW",
                "country_id": 131,
                "id": 3101,
                "iso2": "LI",
                "name": "Lilongwe District"
            },
            {
                "country_code": "MW",
                "country_id": 131,
                "id": 3102,
                "iso2": "BL",
                "name": "Blantyre District"
            },
            {
                "country_code": "MW",
                "country_id": 131,
                "id": 3103,
                "iso2": "MU",
                "name": "Mulanje District"
            },
            {
                "country_code": "MW",
                "country_id": 131,
                "id": 3104,
                "iso2": "MZ",
                "name": "Mzimba District"
            },
            {
                "country_code": "MW",
                "country_id": 131,
                "id": 3105,
                "iso2": "N",
                "name": "Northern Region"
            },
            {
                "country_code": "MW",
                "country_id": 131,
                "id": 3106,
                "iso2": "S",
                "name": "Southern Region"
            },
            {
                "country_code": "MW",
                "country_id": 131,
                "id": 3107,
                "iso2": "CK",
                "name": "Chikwawa District"
            },
            {
                "country_code": "MW",
                "country_id": 131,
                "id": 3108,
                "iso2": "PH",
                "name": "Phalombe District"
            },
            {
                "country_code": "MW",
                "country_id": 131,
                "id": 3109,
                "iso2": "CR",
                "name": "Chiradzulu District"
            },
            {
                "country_code": "MW",
                "country_id": 131,
                "id": 3110,
                "iso2": "MG",
                "name": "Mangochi District"
            },
            {
                "country_code": "MW",
                "country_id": 131,
                "id": 3111,
                "iso2": "NI",
                "name": "Ntchisi District"
            }
        ]
    },
    {
        "id": 132,
        "name": "Malaysia",
        "iso3": "MYS",
        "iso2": "MY",
        "states": [
            {
                "country_code": "MY",
                "country_id": 132,
                "id": 1935,
                "iso2": "15",
                "name": "Labuan"
            },
            {
                "country_code": "MY",
                "country_id": 132,
                "id": 1936,
                "iso2": "12",
                "name": "Sabah"
            },
            {
                "country_code": "MY",
                "country_id": 132,
                "id": 1937,
                "iso2": "13",
                "name": "Sarawak"
            },
            {
                "country_code": "MY",
                "country_id": 132,
                "id": 1938,
                "iso2": "9",
                "name": "Perlis"
            },
            {
                "country_code": "MY",
                "country_id": 132,
                "id": 1939,
                "iso2": "7",
                "name": "Penang"
            },
            {
                "country_code": "MY",
                "country_id": 132,
                "id": 1940,
                "iso2": "6",
                "name": "Pahang"
            },
            {
                "country_code": "MY",
                "country_id": 132,
                "id": 1941,
                "iso2": "4",
                "name": "Melaka"
            },
            {
                "country_code": "MY",
                "country_id": 132,
                "id": 1942,
                "iso2": "11",
                "name": "Terengganu"
            },
            {
                "country_code": "MY",
                "country_id": 132,
                "id": 1943,
                "iso2": "8",
                "name": "Perak"
            },
            {
                "country_code": "MY",
                "country_id": 132,
                "id": 1944,
                "iso2": "10",
                "name": "Selangor"
            },
            {
                "country_code": "MY",
                "country_id": 132,
                "id": 1945,
                "iso2": "16",
                "name": "Putrajaya"
            },
            {
                "country_code": "MY",
                "country_id": 132,
                "id": 1946,
                "iso2": "3",
                "name": "Kelantan"
            },
            {
                "country_code": "MY",
                "country_id": 132,
                "id": 1947,
                "iso2": "2",
                "name": "Kedah"
            },
            {
                "country_code": "MY",
                "country_id": 132,
                "id": 1948,
                "iso2": "5",
                "name": "Negeri Sembilan"
            },
            {
                "country_code": "MY",
                "country_id": 132,
                "id": 1949,
                "iso2": "14",
                "name": "Kuala Lumpur"
            },
            {
                "country_code": "MY",
                "country_id": 132,
                "id": 1950,
                "iso2": "1",
                "name": "Johor"
            }
        ]
    },
    {
        "id": 133,
        "name": "Maldives",
        "iso3": "MDV",
        "iso2": "MV",
        "states": [
            {
                "country_code": "MV",
                "country_id": 133,
                "id": 2584,
                "iso2": "4",
                "name": "Vaavu Atoll"
            },
            {
                "country_code": "MV",
                "country_id": 133,
                "id": 2585,
                "iso2": "24",
                "name": "Shaviyani Atoll"
            },
            {
                "country_code": "MV",
                "country_id": 133,
                "id": 2586,
                "iso2": "7",
                "name": "Haa Alif Atoll"
            },
            {
                "country_code": "MV",
                "country_id": 133,
                "id": 2587,
                "iso2": "2",
                "name": "Alif Alif Atoll"
            },
            {
                "country_code": "MV",
                "country_id": 133,
                "id": 2588,
                "iso2": "NO",
                "name": "North Province"
            },
            {
                "country_code": "MV",
                "country_id": 133,
                "id": 2589,
                "iso2": "NC",
                "name": "North Central Province"
            },
            {
                "country_code": "MV",
                "country_id": 133,
                "id": 2590,
                "iso2": "17",
                "name": "Dhaalu Atoll"
            },
            {
                "country_code": "MV",
                "country_id": 133,
                "id": 2591,
                "iso2": "8",
                "name": "Thaa Atoll"
            },
            {
                "country_code": "MV",
                "country_id": 133,
                "id": 2592,
                "iso2": "25",
                "name": "Noonu Atoll"
            },
            {
                "country_code": "MV",
                "country_id": 133,
                "id": 2593,
                "iso2": "US",
                "name": "Upper South Province"
            },
            {
                "country_code": "MV",
                "country_id": 133,
                "id": 2594,
                "iso2": "1",
                "name": "Addu Atoll"
            },
            {
                "country_code": "MV",
                "country_id": 133,
                "id": 2595,
                "iso2": "29",
                "name": "Gnaviyani Atoll"
            },
            {
                "country_code": "MV",
                "country_id": 133,
                "id": 2596,
                "iso2": "26",
                "name": "Kaafu Atoll"
            },
            {
                "country_code": "MV",
                "country_id": 133,
                "id": 2597,
                "iso2": "23",
                "name": "Haa Dhaalu Atoll"
            },
            {
                "country_code": "MV",
                "country_id": 133,
                "id": 2598,
                "iso2": "27",
                "name": "Gaafu Alif Atoll"
            },
            {
                "country_code": "MV",
                "country_id": 133,
                "id": 2599,
                "iso2": "14",
                "name": "Faafu Atoll"
            },
            {
                "country_code": "MV",
                "country_id": 133,
                "id": 2600,
                "iso2": "0",
                "name": "Alif Dhaal Atoll"
            },
            {
                "country_code": "MV",
                "country_id": 133,
                "id": 2601,
                "iso2": "5",
                "name": "Laamu Atoll"
            },
            {
                "country_code": "MV",
                "country_id": 133,
                "id": 2602,
                "iso2": "13",
                "name": "Raa Atoll"
            },
            {
                "country_code": "MV",
                "country_id": 133,
                "id": 2603,
                "iso2": "28",
                "name": "Gaafu Dhaalu Atoll"
            },
            {
                "country_code": "MV",
                "country_id": 133,
                "id": 2604,
                "iso2": "CE",
                "name": "Central Province"
            },
            {
                "country_code": "MV",
                "country_id": 133,
                "id": 2605,
                "iso2": "SU",
                "name": "South Province"
            },
            {
                "country_code": "MV",
                "country_id": 133,
                "id": 2606,
                "iso2": "SC",
                "name": "South Central Province"
            },
            {
                "country_code": "MV",
                "country_id": 133,
                "id": 2607,
                "iso2": "3",
                "name": "Lhaviyani Atoll"
            },
            {
                "country_code": "MV",
                "country_id": 133,
                "id": 2608,
                "iso2": "12",
                "name": "Meemu Atoll"
            },
            {
                "country_code": "MV",
                "country_id": 133,
                "id": 2609,
                "iso2": "MLE",
                "name": "Malé"
            }
        ]
    },
    {
        "id": 134,
        "name": "Mali",
        "iso3": "MLI",
        "iso2": "ML",
        "states": [
            {
                "country_code": "ML",
                "country_id": 134,
                "id": 248,
                "iso2": "6",
                "name": "Tombouctou Region"
            },
            {
                "country_code": "ML",
                "country_id": 134,
                "id": 249,
                "iso2": "4",
                "name": "Ségou Region"
            },
            {
                "country_code": "ML",
                "country_id": 134,
                "id": 250,
                "iso2": "2",
                "name": "Koulikoro Region"
            },
            {
                "country_code": "ML",
                "country_id": 134,
                "id": 251,
                "iso2": "9",
                "name": "Ménaka Region"
            },
            {
                "country_code": "ML",
                "country_id": 134,
                "id": 252,
                "iso2": "1",
                "name": "Kayes Region"
            },
            {
                "country_code": "ML",
                "country_id": 134,
                "id": 253,
                "iso2": "BKO",
                "name": "Bamako"
            },
            {
                "country_code": "ML",
                "country_id": 134,
                "id": 254,
                "iso2": "3",
                "name": "Sikasso Region"
            },
            {
                "country_code": "ML",
                "country_id": 134,
                "id": 255,
                "iso2": "5",
                "name": "Mopti Region"
            },
            {
                "country_code": "ML",
                "country_id": 134,
                "id": 256,
                "iso2": "10",
                "name": "Taoudénit Region"
            },
            {
                "country_code": "ML",
                "country_id": 134,
                "id": 257,
                "iso2": "8",
                "name": "Kidal Region"
            },
            {
                "country_code": "ML",
                "country_id": 134,
                "id": 258,
                "iso2": "7",
                "name": "Gao Region"
            }
        ]
    },
    {
        "id": 135,
        "name": "Malta",
        "iso3": "MLT",
        "iso2": "MT",
        "states": [
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 74,
                "iso2": "33",
                "name": "Mqabba"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 75,
                "iso2": "49",
                "name": "San Ġwann"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 76,
                "iso2": "68",
                "name": "Żurrieq"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 77,
                "iso2": "25",
                "name": "Luqa"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 78,
                "iso2": "28",
                "name": "Marsaxlokk"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 79,
                "iso2": "42",
                "name": "Qala"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 80,
                "iso2": "66",
                "name": "Żebbuġ Malta"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 81,
                "iso2": "63",
                "name": "Xgħajra"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 82,
                "iso2": "23",
                "name": "Kirkop"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 83,
                "iso2": "46",
                "name": "Rabat"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 84,
                "iso2": "9",
                "name": "Floriana"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 85,
                "iso2": "65",
                "name": "Żebbuġ Gozo"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 86,
                "iso2": "57",
                "name": "Swieqi"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 87,
                "iso2": "50",
                "name": "Saint Lawrence"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 88,
                "iso2": "5",
                "name": "Birżebbuġa"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 89,
                "iso2": "29",
                "name": "Mdina"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 90,
                "iso2": "54",
                "name": "Santa Venera"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 91,
                "iso2": "22",
                "name": "Kerċem"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 92,
                "iso2": "14",
                "name": "Għarb"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 93,
                "iso2": "19",
                "name": "Iklin"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 94,
                "iso2": "53",
                "name": "Santa Luċija"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 95,
                "iso2": "60",
                "name": "Valletta"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 96,
                "iso2": "34",
                "name": "Msida"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 97,
                "iso2": "4",
                "name": "Birkirkara"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 98,
                "iso2": "55",
                "name": "Siġġiewi"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 99,
                "iso2": "21",
                "name": "Kalkara"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 100,
                "iso2": "48",
                "name": "St. Julian's"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 101,
                "iso2": "45",
                "name": "Victoria"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 102,
                "iso2": "30",
                "name": "Mellieħa"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 103,
                "iso2": "59",
                "name": "Tarxien"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 104,
                "iso2": "56",
                "name": "Sliema"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 105,
                "iso2": "18",
                "name": "Ħamrun"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 106,
                "iso2": "16",
                "name": "Għasri"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 107,
                "iso2": "3",
                "name": "Birgu"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 108,
                "iso2": "2",
                "name": "Balzan"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 109,
                "iso2": "31",
                "name": "Mġarr"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 110,
                "iso2": "1",
                "name": "Attard"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 111,
                "iso2": "44",
                "name": "Qrendi"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 112,
                "iso2": "38",
                "name": "Naxxar"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 113,
                "iso2": "12",
                "name": "Gżira"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 114,
                "iso2": "61",
                "name": "Xagħra"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 115,
                "iso2": "39",
                "name": "Paola"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 116,
                "iso2": "52",
                "name": "Sannat"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 117,
                "iso2": "7",
                "name": "Dingli"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 118,
                "iso2": "11",
                "name": "Gudja"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 119,
                "iso2": "43",
                "name": "Qormi"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 120,
                "iso2": "15",
                "name": "Għargħur"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 121,
                "iso2": "62",
                "name": "Xewkija"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 122,
                "iso2": "58",
                "name": "Ta' Xbiex"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 123,
                "iso2": "64",
                "name": "Żabbar"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 124,
                "iso2": "17",
                "name": "Għaxaq"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 125,
                "iso2": "40",
                "name": "Pembroke"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 126,
                "iso2": "24",
                "name": "Lija"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 127,
                "iso2": "41",
                "name": "Pietà"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 128,
                "iso2": "26",
                "name": "Marsa"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 129,
                "iso2": "8",
                "name": "Fgura"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 130,
                "iso2": "13",
                "name": "Għajnsielem"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 131,
                "iso2": "35",
                "name": "Mtarfa"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 132,
                "iso2": "36",
                "name": "Munxar"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 133,
                "iso2": "37",
                "name": "Nadur"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 134,
                "iso2": "10",
                "name": "Fontana"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 135,
                "iso2": "67",
                "name": "Żejtun"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 136,
                "iso2": "20",
                "name": "Senglea"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 137,
                "iso2": "27",
                "name": "Marsaskala"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 138,
                "iso2": "6",
                "name": "Cospicua"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 139,
                "iso2": "51",
                "name": "St. Paul's Bay"
            },
            {
                "country_code": "MT",
                "country_id": 135,
                "id": 140,
                "iso2": "32",
                "name": "Mosta"
            }
        ]
    },
    {
        "id": 136,
        "name": "Man (Isle of)",
        "iso3": "IMN",
        "iso2": "IM",
        "states": []
    },
    {
        "id": 137,
        "name": "Marshall Islands",
        "iso3": "MHL",
        "iso2": "MH",
        "states": [
            {
                "country_code": "MH",
                "country_id": 137,
                "id": 2573,
                "iso2": "T",
                "name": "Ratak Chain"
            },
            {
                "country_code": "MH",
                "country_id": 137,
                "id": 2574,
                "iso2": "L",
                "name": "Ralik Chain"
            }
        ]
    },
    {
        "id": 138,
        "name": "Martinique",
        "iso3": "MTQ",
        "iso2": "MQ",
        "states": []
    },
    {
        "id": 139,
        "name": "Mauritania",
        "iso3": "MRT",
        "iso2": "MR",
        "states": [
            {
                "country_code": "MR",
                "country_id": 139,
                "id": 3338,
                "iso2": "1",
                "name": "Hodh Ech Chargui Region"
            },
            {
                "country_code": "MR",
                "country_id": 139,
                "id": 3339,
                "iso2": "5",
                "name": "Brakna Region"
            },
            {
                "country_code": "MR",
                "country_id": 139,
                "id": 3340,
                "iso2": "11",
                "name": "Tiris Zemmour Region"
            },
            {
                "country_code": "MR",
                "country_id": 139,
                "id": 3341,
                "iso2": "4",
                "name": "Gorgol Region"
            },
            {
                "country_code": "MR",
                "country_id": 139,
                "id": 3342,
                "iso2": "12",
                "name": "Inchiri Region"
            },
            {
                "country_code": "MR",
                "country_id": 139,
                "id": 3343,
                "iso2": "14",
                "name": "Nouakchott-Nord Region"
            },
            {
                "country_code": "MR",
                "country_id": 139,
                "id": 3344,
                "iso2": "7",
                "name": "Adrar Region"
            },
            {
                "country_code": "MR",
                "country_id": 139,
                "id": 3345,
                "iso2": "9",
                "name": "Tagant Region"
            },
            {
                "country_code": "MR",
                "country_id": 139,
                "id": 3346,
                "iso2": "8",
                "name": "Dakhlet Nouadhibou"
            },
            {
                "country_code": "MR",
                "country_id": 139,
                "id": 3347,
                "iso2": "15",
                "name": "Nouakchott-Sud Region"
            },
            {
                "country_code": "MR",
                "country_id": 139,
                "id": 3348,
                "iso2": "6",
                "name": "Trarza Region"
            },
            {
                "country_code": "MR",
                "country_id": 139,
                "id": 3349,
                "iso2": "3",
                "name": "Assaba Region"
            },
            {
                "country_code": "MR",
                "country_id": 139,
                "id": 3350,
                "iso2": "10",
                "name": "Guidimaka Region"
            },
            {
                "country_code": "MR",
                "country_id": 139,
                "id": 3351,
                "iso2": "2",
                "name": "Hodh El Gharbi Region"
            },
            {
                "country_code": "MR",
                "country_id": 139,
                "id": 3352,
                "iso2": "13",
                "name": "Nouakchott-Ouest Region"
            }
        ]
    },
    {
        "id": 140,
        "name": "Mauritius",
        "iso3": "MUS",
        "iso2": "MU",
        "states": [
            {
                "country_code": "MU",
                "country_id": 140,
                "id": 3248,
                "iso2": "AG",
                "name": "Agaléga"
            },
            {
                "country_code": "MU",
                "country_id": 140,
                "id": 3249,
                "iso2": "RO",
                "name": "Rodrigues"
            },
            {
                "country_code": "MU",
                "country_id": 140,
                "id": 3250,
                "iso2": "PA",
                "name": "Pamplemousses District"
            },
            {
                "country_code": "MU",
                "country_id": 140,
                "id": 3251,
                "iso2": "CC",
                "name": "Cargados Carajos"
            },
            {
                "country_code": "MU",
                "country_id": 140,
                "id": 3252,
                "iso2": "VP",
                "name": "Vacoas-Phoenix"
            },
            {
                "country_code": "MU",
                "country_id": 140,
                "id": 3253,
                "iso2": "MO",
                "name": "Moka District"
            },
            {
                "country_code": "MU",
                "country_id": 140,
                "id": 3254,
                "iso2": "FL",
                "name": "Flacq District"
            },
            {
                "country_code": "MU",
                "country_id": 140,
                "id": 3255,
                "iso2": "CU",
                "name": "Curepipe"
            },
            {
                "country_code": "MU",
                "country_id": 140,
                "id": 3256,
                "iso2": "PU",
                "name": "Port Louis"
            },
            {
                "country_code": "MU",
                "country_id": 140,
                "id": 3257,
                "iso2": "SA",
                "name": "Savanne District"
            },
            {
                "country_code": "MU",
                "country_id": 140,
                "id": 3258,
                "iso2": "QB",
                "name": "Quatre Bornes"
            },
            {
                "country_code": "MU",
                "country_id": 140,
                "id": 3259,
                "iso2": "BL",
                "name": "Rivière Noire District"
            },
            {
                "country_code": "MU",
                "country_id": 140,
                "id": 3260,
                "iso2": "PL",
                "name": "Port Louis District"
            },
            {
                "country_code": "MU",
                "country_id": 140,
                "id": 3261,
                "iso2": "RR",
                "name": "Rivière du Rempart District"
            },
            {
                "country_code": "MU",
                "country_id": 140,
                "id": 3262,
                "iso2": "BR",
                "name": "Beau Bassin-Rose Hill"
            },
            {
                "country_code": "MU",
                "country_id": 140,
                "id": 3263,
                "iso2": "PW",
                "name": "Plaines Wilhems District"
            },
            {
                "country_code": "MU",
                "country_id": 140,
                "id": 3264,
                "iso2": "GP",
                "name": "Grand Port District"
            }
        ]
    },
    {
        "id": 141,
        "name": "Mayotte",
        "iso3": "MYT",
        "iso2": "YT",
        "states": []
    },
    {
        "id": 142,
        "name": "Mexico",
        "iso3": "MEX",
        "iso2": "MX",
        "states": [
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3447,
                "iso2": "CHH",
                "name": "Chihuahua"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3448,
                "iso2": "OAX",
                "name": "Oaxaca"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3449,
                "iso2": "SIN",
                "name": "Sinaloa"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3450,
                "iso2": "MEX",
                "name": "México"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3451,
                "iso2": "CHP",
                "name": "Chiapas"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3452,
                "iso2": "NLE",
                "name": "Nuevo León"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3453,
                "iso2": "DUR",
                "name": "Durango"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3454,
                "iso2": "TAB",
                "name": "Tabasco"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3455,
                "iso2": "QUE",
                "name": "Querétaro"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3456,
                "iso2": "AGU",
                "name": "Aguascalientes"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3457,
                "iso2": "BCN",
                "name": "Baja California"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3458,
                "iso2": "TLA",
                "name": "Tlaxcala"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3459,
                "iso2": "GRO",
                "name": "Guerrero"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3460,
                "iso2": "BCS",
                "name": "Baja California Sur"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3461,
                "iso2": "SLP",
                "name": "San Luis Potosí"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3462,
                "iso2": "ZAC",
                "name": "Zacatecas"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3463,
                "iso2": "TAM",
                "name": "Tamaulipas"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3464,
                "iso2": "VER",
                "name": "Veracruz"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3465,
                "iso2": "MOR",
                "name": "Morelos"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3466,
                "iso2": "YUC",
                "name": "Yucatán"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3467,
                "iso2": "ROO",
                "name": "Quintana Roo"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3468,
                "iso2": "SON",
                "name": "Sonora"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3469,
                "iso2": "GUA",
                "name": "Guanajuato"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3470,
                "iso2": "HID",
                "name": "Hidalgo"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3471,
                "iso2": "COA",
                "name": "Coahuila"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3472,
                "iso2": "COL",
                "name": "Colima"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3473,
                "iso2": "CMX",
                "name": "Mexico City"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3474,
                "iso2": "MIC",
                "name": "Michoacán"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3475,
                "iso2": "CAM",
                "name": "Campeche"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3476,
                "iso2": "PUE",
                "name": "Puebla"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 3477,
                "iso2": "NAY",
                "name": "Nayarit"
            },
            {
                "country_code": "MX",
                "country_id": 142,
                "id": 4857,
                "iso2": "JAL",
                "name": "Jalisco"
            }
        ]
    },
    {
        "id": 143,
        "name": "Micronesia",
        "iso3": "FSM",
        "iso2": "FM",
        "states": [
            {
                "country_code": "FM",
                "country_id": 143,
                "id": 2580,
                "iso2": "TRK",
                "name": "Chuuk State"
            },
            {
                "country_code": "FM",
                "country_id": 143,
                "id": 2581,
                "iso2": "PNI",
                "name": "Pohnpei State"
            },
            {
                "country_code": "FM",
                "country_id": 143,
                "id": 2582,
                "iso2": "YAP",
                "name": "Yap State"
            },
            {
                "country_code": "FM",
                "country_id": 143,
                "id": 2583,
                "iso2": "KSA",
                "name": "Kosrae State"
            }
        ]
    },
    {
        "id": 144,
        "name": "Moldova",
        "iso3": "MDA",
        "iso2": "MD",
        "states": [
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4360,
                "iso2": "CM",
                "name": "Cimișlia District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4361,
                "iso2": "OR",
                "name": "Orhei District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4362,
                "iso2": "BD",
                "name": "Bender Municipality"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4363,
                "iso2": "NI",
                "name": "Nisporeni District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4364,
                "iso2": "SI",
                "name": "Sîngerei District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4365,
                "iso2": "CS",
                "name": "Căușeni District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4366,
                "iso2": "CL",
                "name": "Călărași District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4367,
                "iso2": "GL",
                "name": "Glodeni District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4368,
                "iso2": "AN",
                "name": "Anenii Noi District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4369,
                "iso2": "IA",
                "name": "Ialoveni District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4370,
                "iso2": "FL",
                "name": "Florești District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4371,
                "iso2": "TE",
                "name": "Telenești District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4372,
                "iso2": "TA",
                "name": "Taraclia District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4373,
                "iso2": "CU",
                "name": "Chișinău Municipality"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4374,
                "iso2": "SO",
                "name": "Soroca District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4375,
                "iso2": "BR",
                "name": "Briceni District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4376,
                "iso2": "RI",
                "name": "Rîșcani District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4377,
                "iso2": "ST",
                "name": "Strășeni District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4378,
                "iso2": "SV",
                "name": "Ștefan Vodă District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4379,
                "iso2": "BS",
                "name": "Basarabeasca District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4380,
                "iso2": "CT",
                "name": "Cantemir District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4381,
                "iso2": "FA",
                "name": "Fălești District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4382,
                "iso2": "HI",
                "name": "Hîncești District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4383,
                "iso2": "DU",
                "name": "Dubăsari District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4384,
                "iso2": "DO",
                "name": "Dondușeni District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4385,
                "iso2": "GA",
                "name": "Gagauzia"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4386,
                "iso2": "UN",
                "name": "Ungheni District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4387,
                "iso2": "ED",
                "name": "Edineț District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4388,
                "iso2": "SD",
                "name": "Șoldănești District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4389,
                "iso2": "OC",
                "name": "Ocnița District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4390,
                "iso2": "CR",
                "name": "Criuleni District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4391,
                "iso2": "CA",
                "name": "Cahul District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4392,
                "iso2": "DR",
                "name": "Drochia District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4393,
                "iso2": "BA",
                "name": "Bălți Municipality"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4394,
                "iso2": "RE",
                "name": "Rezina District"
            },
            {
                "country_code": "MD",
                "country_id": 144,
                "id": 4395,
                "iso2": "SN",
                "name": "Transnistria autonomous territorial unit"
            }
        ]
    },
    {
        "id": 145,
        "name": "Monaco",
        "iso3": "MCO",
        "iso2": "MC",
        "states": []
    },
    {
        "id": 146,
        "name": "Mongolia",
        "iso3": "MNG",
        "iso2": "MN",
        "states": [
            {
                "country_code": "MN",
                "country_id": 146,
                "id": 1961,
                "iso2": "67",
                "name": "Bulgan Province"
            },
            {
                "country_code": "MN",
                "country_id": 146,
                "id": 1962,
                "iso2": "37",
                "name": "Darkhan-Uul Province"
            },
            {
                "country_code": "MN",
                "country_id": 146,
                "id": 1963,
                "iso2": "61",
                "name": "Dornod Province"
            },
            {
                "country_code": "MN",
                "country_id": 146,
                "id": 1964,
                "iso2": "43",
                "name": "Khovd Province"
            },
            {
                "country_code": "MN",
                "country_id": 146,
                "id": 1965,
                "iso2": "55",
                "name": "Övörkhangai Province"
            },
            {
                "country_code": "MN",
                "country_id": 146,
                "id": 1966,
                "iso2": "35",
                "name": "Orkhon Province"
            },
            {
                "country_code": "MN",
                "country_id": 146,
                "id": 1967,
                "iso2": "53",
                "name": "Ömnögovi Province"
            },
            {
                "country_code": "MN",
                "country_id": 146,
                "id": 1968,
                "iso2": "47",
                "name": "Töv Province"
            },
            {
                "country_code": "MN",
                "country_id": 146,
                "id": 1969,
                "iso2": "71",
                "name": "Bayan-Ölgii Province"
            },
            {
                "country_code": "MN",
                "country_id": 146,
                "id": 1970,
                "iso2": "59",
                "name": "Dundgovi Province"
            },
            {
                "country_code": "MN",
                "country_id": 146,
                "id": 1971,
                "iso2": "46",
                "name": "Uvs Province"
            },
            {
                "country_code": "MN",
                "country_id": 146,
                "id": 1972,
                "iso2": "65",
                "name": "Govi-Altai Province"
            },
            {
                "country_code": "MN",
                "country_id": 146,
                "id": 1973,
                "iso2": "73",
                "name": "Arkhangai Province"
            },
            {
                "country_code": "MN",
                "country_id": 146,
                "id": 1974,
                "iso2": "39",
                "name": "Khentii Province"
            },
            {
                "country_code": "MN",
                "country_id": 146,
                "id": 1975,
                "iso2": "41",
                "name": "Khövsgöl Province"
            },
            {
                "country_code": "MN",
                "country_id": 146,
                "id": 1976,
                "iso2": "69",
                "name": "Bayankhongor Province"
            },
            {
                "country_code": "MN",
                "country_id": 146,
                "id": 1977,
                "iso2": "51",
                "name": "Sükhbaatar Province"
            },
            {
                "country_code": "MN",
                "country_id": 146,
                "id": 1978,
                "iso2": "64",
                "name": "Govisümber Province"
            },
            {
                "country_code": "MN",
                "country_id": 146,
                "id": 1979,
                "iso2": "57",
                "name": "Zavkhan Province"
            },
            {
                "country_code": "MN",
                "country_id": 146,
                "id": 1980,
                "iso2": "49",
                "name": "Selenge Province"
            },
            {
                "country_code": "MN",
                "country_id": 146,
                "id": 1981,
                "iso2": "63",
                "name": "Dornogovi Province"
            }
        ]
    },
    {
        "id": 147,
        "name": "Montenegro",
        "iso3": "MNE",
        "iso2": "ME",
        "states": [
            {
                "country_code": "ME",
                "country_id": 147,
                "id": 12,
                "iso2": "23",
                "name": "Petnjica Municipality"
            },
            {
                "country_code": "ME",
                "country_id": 147,
                "id": 13,
                "iso2": "2",
                "name": "Bar Municipality"
            },
            {
                "country_code": "ME",
                "country_id": 147,
                "id": 14,
                "iso2": "7",
                "name": "Danilovgrad Municipality"
            },
            {
                "country_code": "ME",
                "country_id": 147,
                "id": 15,
                "iso2": "17",
                "name": "Rožaje Municipality"
            },
            {
                "country_code": "ME",
                "country_id": 147,
                "id": 16,
                "iso2": "15",
                "name": "Plužine Municipality"
            },
            {
                "country_code": "ME",
                "country_id": 147,
                "id": 17,
                "iso2": "12",
                "name": "Nikšić Municipality"
            },
            {
                "country_code": "ME",
                "country_id": 147,
                "id": 18,
                "iso2": "18",
                "name": "Šavnik Municipality"
            },
            {
                "country_code": "ME",
                "country_id": 147,
                "id": 19,
                "iso2": "13",
                "name": "Plav Municipality"
            },
            {
                "country_code": "ME",
                "country_id": 147,
                "id": 20,
                "iso2": "14",
                "name": "Pljevlja Municipality"
            },
            {
                "country_code": "ME",
                "country_id": 147,
                "id": 21,
                "iso2": "3",
                "name": "Berane Municipality"
            },
            {
                "country_code": "ME",
                "country_id": 147,
                "id": 22,
                "iso2": "11",
                "name": "Mojkovac Municipality"
            },
            {
                "country_code": "ME",
                "country_id": 147,
                "id": 23,
                "iso2": "1",
                "name": "Andrijevica Municipality"
            },
            {
                "country_code": "ME",
                "country_id": 147,
                "id": 24,
                "iso2": "22",
                "name": "Gusinje Municipality"
            },
            {
                "country_code": "ME",
                "country_id": 147,
                "id": 25,
                "iso2": "4",
                "name": "Bijelo Polje Municipality"
            },
            {
                "country_code": "ME",
                "country_id": 147,
                "id": 26,
                "iso2": "10",
                "name": "Kotor Municipality"
            },
            {
                "country_code": "ME",
                "country_id": 147,
                "id": 27,
                "iso2": "16",
                "name": "Podgorica Municipality"
            },
            {
                "country_code": "ME",
                "country_id": 147,
                "id": 28,
                "iso2": "6",
                "name": "Old Royal Capital Cetinje"
            },
            {
                "country_code": "ME",
                "country_id": 147,
                "id": 29,
                "iso2": "19",
                "name": "Tivat Municipality"
            },
            {
                "country_code": "ME",
                "country_id": 147,
                "id": 30,
                "iso2": "5",
                "name": "Budva Municipality"
            },
            {
                "country_code": "ME",
                "country_id": 147,
                "id": 31,
                "iso2": "9",
                "name": "Kolašin Municipality"
            },
            {
                "country_code": "ME",
                "country_id": 147,
                "id": 32,
                "iso2": "21",
                "name": "Žabljak Municipality"
            },
            {
                "country_code": "ME",
                "country_id": 147,
                "id": 33,
                "iso2": "20",
                "name": "Ulcinj Municipality"
            }
        ]
    },
    {
        "id": 148,
        "name": "Montserrat",
        "iso3": "MSR",
        "iso2": "MS",
        "states": []
    },
    {
        "id": 149,
        "name": "Morocco",
        "iso3": "MAR",
        "iso2": "MA",
        "states": [
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3265,
                "iso2": "GUE",
                "name": "Guelmim Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3266,
                "iso2": "AOU",
                "name": "Aousserd Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3267,
                "iso2": "HOC",
                "name": "Al Hoceïma Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3268,
                "iso2": "LAR",
                "name": "Larache Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3269,
                "iso2": "OUA",
                "name": "Ouarzazate Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3270,
                "iso2": "BOM",
                "name": "Boulemane Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3271,
                "iso2": "2",
                "name": "Oriental"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3272,
                "iso2": "BEM",
                "name": "Béni-Mellal Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3273,
                "iso2": "SYB",
                "name": "Sidi Youssef Ben Ali"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3274,
                "iso2": "CHI",
                "name": "Chichaoua Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3275,
                "iso2": "BOD",
                "name": "Boujdour Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3276,
                "iso2": "KHE",
                "name": "Khémisset Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3277,
                "iso2": "TIZ",
                "name": "Tiznit Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3278,
                "iso2": "5",
                "name": "Béni Mellal-Khénifra"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3279,
                "iso2": "SIK",
                "name": "Sidi Kacem Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3280,
                "iso2": "JDI",
                "name": "El Jadida Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3281,
                "iso2": "NAD",
                "name": "Nador Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3282,
                "iso2": "SET",
                "name": "Settat Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3283,
                "iso2": "ZAG",
                "name": "Zagora Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3284,
                "iso2": "MED",
                "name": "Mediouna Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3285,
                "iso2": "BER",
                "name": "Berkane Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3286,
                "iso2": "TNT",
                "name": "Tan-Tan Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3287,
                "iso2": "NOU",
                "name": "Nouaceur Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3288,
                "iso2": "7",
                "name": "Marrakesh-Safi"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3289,
                "iso2": "SEF",
                "name": "Sefrou Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3290,
                "iso2": "8",
                "name": "Drâa-Tafilalet"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3291,
                "iso2": "HAJ",
                "name": "El Hajeb Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3292,
                "iso2": "ESM",
                "name": "Es Semara Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3293,
                "iso2": "LAA",
                "name": "Laâyoune Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3294,
                "iso2": "INE",
                "name": "Inezgane-Aït Melloul Prefecture"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3295,
                "iso2": "9",
                "name": "Souss-Massa"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3296,
                "iso2": "TAZ",
                "name": "Taza Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3297,
                "iso2": "ASZ",
                "name": "Assa-Zag Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3298,
                "iso2": "11",
                "name": "Laâyoune-Sakia El Hamra"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3299,
                "iso2": "ERR",
                "name": "Errachidia Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3300,
                "iso2": "FAH",
                "name": "Fahs Anjra Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3301,
                "iso2": "FIG",
                "name": "Figuig Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3302,
                "iso2": "CHT",
                "name": "Shtouka Ait Baha Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3303,
                "iso2": "6",
                "name": "Casablanca-Settat"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3304,
                "iso2": "BES",
                "name": "Ben Slimane Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3305,
                "iso2": "10",
                "name": "Guelmim-Oued Noun"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3306,
                "iso2": "12",
                "name": "Dakhla-Oued Ed-Dahab"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3307,
                "iso2": "JRA",
                "name": "Jerada Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3308,
                "iso2": "KEN",
                "name": "Kénitra Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3309,
                "iso2": "KES",
                "name": "Kelaat Sraghna Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3310,
                "iso2": "CHE",
                "name": "Chefchaouen Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3311,
                "iso2": "SAF",
                "name": "Safi Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3312,
                "iso2": "TAT",
                "name": "Tata Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3313,
                "iso2": "3",
                "name": "Fès-Meknès"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3314,
                "iso2": "TAR",
                "name": "Taroudant Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3315,
                "iso2": "MOU",
                "name": "Moulay Yacoub Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3316,
                "iso2": "ESI",
                "name": "Essaouira Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3317,
                "iso2": "KHN",
                "name": "Khénifra Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3318,
                "iso2": "TET",
                "name": "Tétouan Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3319,
                "iso2": "OUD",
                "name": "Oued Ed-Dahab Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3320,
                "iso2": "HAO",
                "name": "Al Haouz Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3321,
                "iso2": "AZI",
                "name": "Azilal Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3322,
                "iso2": "TAI",
                "name": "Taourirt Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3323,
                "iso2": "TAO",
                "name": "Taounate Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3324,
                "iso2": "1",
                "name": "Tanger-Tétouan-Al Hoceïma"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3325,
                "iso2": "IFR",
                "name": "Ifrane Province"
            },
            {
                "country_code": "MA",
                "country_id": 149,
                "id": 3326,
                "iso2": "KHO",
                "name": "Khouribga Province"
            }
        ]
    },
    {
        "id": 150,
        "name": "Mozambique",
        "iso3": "MOZ",
        "iso2": "MZ",
        "states": [
            {
                "country_code": "MZ",
                "country_id": 150,
                "id": 3327,
                "iso2": "P",
                "name": "Cabo Delgado Province"
            },
            {
                "country_code": "MZ",
                "country_id": 150,
                "id": 3328,
                "iso2": "Q",
                "name": "Zambezia Province"
            },
            {
                "country_code": "MZ",
                "country_id": 150,
                "id": 3329,
                "iso2": "G",
                "name": "Gaza Province"
            },
            {
                "country_code": "MZ",
                "country_id": 150,
                "id": 3330,
                "iso2": "I",
                "name": "Inhambane Province"
            },
            {
                "country_code": "MZ",
                "country_id": 150,
                "id": 3331,
                "iso2": "S",
                "name": "Sofala Province"
            },
            {
                "country_code": "MZ",
                "country_id": 150,
                "id": 3332,
                "iso2": "L",
                "name": "Maputo Province"
            },
            {
                "country_code": "MZ",
                "country_id": 150,
                "id": 3333,
                "iso2": "A",
                "name": "Niassa Province"
            },
            {
                "country_code": "MZ",
                "country_id": 150,
                "id": 3334,
                "iso2": "T",
                "name": "Tete Province"
            },
            {
                "country_code": "MZ",
                "country_id": 150,
                "id": 3335,
                "iso2": "MPM",
                "name": "Maputo"
            },
            {
                "country_code": "MZ",
                "country_id": 150,
                "id": 3336,
                "iso2": "N",
                "name": "Nampula Province"
            },
            {
                "country_code": "MZ",
                "country_id": 150,
                "id": 3337,
                "iso2": "B",
                "name": "Manica Province"
            }
        ]
    },
    {
        "id": 151,
        "name": "Myanmar",
        "iso3": "MMR",
        "iso2": "MM",
        "states": [
            {
                "country_code": "MM",
                "country_id": 151,
                "id": 2133,
                "iso2": "13",
                "name": "Kayin State"
            },
            {
                "country_code": "MM",
                "country_id": 151,
                "id": 2134,
                "iso2": "4",
                "name": "Mandalay Region"
            },
            {
                "country_code": "MM",
                "country_id": 151,
                "id": 2135,
                "iso2": "6",
                "name": "Yangon Region"
            },
            {
                "country_code": "MM",
                "country_id": 151,
                "id": 2136,
                "iso2": "3",
                "name": "Magway Region"
            },
            {
                "country_code": "MM",
                "country_id": 151,
                "id": 2137,
                "iso2": "14",
                "name": "Chin State"
            },
            {
                "country_code": "MM",
                "country_id": 151,
                "id": 2138,
                "iso2": "16",
                "name": "Rakhine State"
            },
            {
                "country_code": "MM",
                "country_id": 151,
                "id": 2139,
                "iso2": "17",
                "name": "Shan State"
            },
            {
                "country_code": "MM",
                "country_id": 151,
                "id": 2140,
                "iso2": "5",
                "name": "Tanintharyi Region"
            },
            {
                "country_code": "MM",
                "country_id": 151,
                "id": 2141,
                "iso2": "2",
                "name": "Bago"
            },
            {
                "country_code": "MM",
                "country_id": 151,
                "id": 2142,
                "iso2": "7",
                "name": "Ayeyarwady Region"
            },
            {
                "country_code": "MM",
                "country_id": 151,
                "id": 2143,
                "iso2": "11",
                "name": "Kachin State"
            },
            {
                "country_code": "MM",
                "country_id": 151,
                "id": 2144,
                "iso2": "12",
                "name": "Kayah State"
            },
            {
                "country_code": "MM",
                "country_id": 151,
                "id": 2145,
                "iso2": "1",
                "name": "Sagaing Region"
            },
            {
                "country_code": "MM",
                "country_id": 151,
                "id": 2146,
                "iso2": "18",
                "name": "Naypyidaw Union Territory"
            },
            {
                "country_code": "MM",
                "country_id": 151,
                "id": 2147,
                "iso2": "15",
                "name": "Mon State"
            }
        ]
    },
    {
        "id": 152,
        "name": "Namibia",
        "iso3": "NAM",
        "iso2": "NA",
        "states": [
            {
                "country_code": "NA",
                "country_id": 152,
                "id": 34,
                "iso2": "KU",
                "name": "Kunene Region"
            },
            {
                "country_code": "NA",
                "country_id": 152,
                "id": 35,
                "iso2": "KW",
                "name": "Kavango West Region"
            },
            {
                "country_code": "NA",
                "country_id": 152,
                "id": 36,
                "iso2": "KE",
                "name": "Kavango East Region"
            },
            {
                "country_code": "NA",
                "country_id": 152,
                "id": 37,
                "iso2": "ON",
                "name": "Oshana Region"
            },
            {
                "country_code": "NA",
                "country_id": 152,
                "id": 38,
                "iso2": "HA",
                "name": "Hardap Region"
            },
            {
                "country_code": "NA",
                "country_id": 152,
                "id": 39,
                "iso2": "OS",
                "name": "Omusati Region"
            },
            {
                "country_code": "NA",
                "country_id": 152,
                "id": 40,
                "iso2": "OW",
                "name": "Ohangwena Region"
            },
            {
                "country_code": "NA",
                "country_id": 152,
                "id": 41,
                "iso2": "OH",
                "name": "Omaheke Region"
            },
            {
                "country_code": "NA",
                "country_id": 152,
                "id": 42,
                "iso2": "OT",
                "name": "Oshikoto Region"
            },
            {
                "country_code": "NA",
                "country_id": 152,
                "id": 43,
                "iso2": "ER",
                "name": "Erongo Region"
            },
            {
                "country_code": "NA",
                "country_id": 152,
                "id": 44,
                "iso2": "KH",
                "name": "Khomas Region"
            },
            {
                "country_code": "NA",
                "country_id": 152,
                "id": 45,
                "iso2": "KA",
                "name": "Karas Region"
            },
            {
                "country_code": "NA",
                "country_id": 152,
                "id": 46,
                "iso2": "OD",
                "name": "Otjozondjupa Region"
            },
            {
                "country_code": "NA",
                "country_id": 152,
                "id": 47,
                "iso2": "CA",
                "name": "Zambezi Region"
            }
        ]
    },
    {
        "id": 153,
        "name": "Nauru",
        "iso3": "NRU",
        "iso2": "NR",
        "states": [
            {
                "country_code": "NR",
                "country_id": 153,
                "id": 4654,
                "iso2": "9",
                "name": "Ewa District"
            },
            {
                "country_code": "NR",
                "country_id": 153,
                "id": 4655,
                "iso2": "13",
                "name": "Uaboe District"
            },
            {
                "country_code": "NR",
                "country_id": 153,
                "id": 4656,
                "iso2": "1",
                "name": "Aiwo District"
            },
            {
                "country_code": "NR",
                "country_id": 153,
                "id": 4657,
                "iso2": "11",
                "name": "Meneng District"
            },
            {
                "country_code": "NR",
                "country_id": 153,
                "id": 4658,
                "iso2": "2",
                "name": "Anabar District"
            },
            {
                "country_code": "NR",
                "country_id": 153,
                "id": 4659,
                "iso2": "12",
                "name": "Nibok District"
            },
            {
                "country_code": "NR",
                "country_id": 153,
                "id": 4660,
                "iso2": "5",
                "name": "Baiti District"
            },
            {
                "country_code": "NR",
                "country_id": 153,
                "id": 4661,
                "iso2": "10",
                "name": "Ijuw District"
            },
            {
                "country_code": "NR",
                "country_id": 153,
                "id": 4662,
                "iso2": "7",
                "name": "Buada District"
            },
            {
                "country_code": "NR",
                "country_id": 153,
                "id": 4663,
                "iso2": "4",
                "name": "Anibare District"
            },
            {
                "country_code": "NR",
                "country_id": 153,
                "id": 4664,
                "iso2": "14",
                "name": "Yaren District"
            },
            {
                "country_code": "NR",
                "country_id": 153,
                "id": 4665,
                "iso2": "6",
                "name": "Boe District"
            },
            {
                "country_code": "NR",
                "country_id": 153,
                "id": 4666,
                "iso2": "8",
                "name": "Denigomodu District"
            },
            {
                "country_code": "NR",
                "country_id": 153,
                "id": 4667,
                "iso2": "3",
                "name": "Anetan District"
            }
        ]
    },
    {
        "id": 154,
        "name": "Nepal",
        "iso3": "NPL",
        "iso2": "NP",
        "states": [
            {
                "country_code": "NP",
                "country_id": 154,
                "id": 2066,
                "iso2": "2",
                "name": "Mid-Western Region"
            },
            {
                "country_code": "NP",
                "country_id": 154,
                "id": 2067,
                "iso2": "3",
                "name": "Western Region"
            },
            {
                "country_code": "NP",
                "country_id": 154,
                "id": 2068,
                "iso2": "5",
                "name": "Far-Western Development Region"
            },
            {
                "country_code": "NP",
                "country_id": 154,
                "id": 2069,
                "iso2": "4",
                "name": "Eastern Development Region"
            },
            {
                "country_code": "NP",
                "country_id": 154,
                "id": 2070,
                "iso2": "ME",
                "name": "Mechi Zone"
            },
            {
                "country_code": "NP",
                "country_id": 154,
                "id": 2071,
                "iso2": "BH",
                "name": "Bheri Zone"
            },
            {
                "country_code": "NP",
                "country_id": 154,
                "id": 2072,
                "iso2": "KO",
                "name": "Kosi Zone"
            },
            {
                "country_code": "NP",
                "country_id": 154,
                "id": 2073,
                "iso2": "1",
                "name": "Central Region"
            },
            {
                "country_code": "NP",
                "country_id": 154,
                "id": 2074,
                "iso2": "LU",
                "name": "Lumbini Zone"
            },
            {
                "country_code": "NP",
                "country_id": 154,
                "id": 2075,
                "iso2": "NA",
                "name": "Narayani Zone"
            },
            {
                "country_code": "NP",
                "country_id": 154,
                "id": 2076,
                "iso2": "JA",
                "name": "Janakpur Zone"
            },
            {
                "country_code": "NP",
                "country_id": 154,
                "id": 2077,
                "iso2": "RA",
                "name": "Rapti Zone"
            },
            {
                "country_code": "NP",
                "country_id": 154,
                "id": 2078,
                "iso2": "SE",
                "name": "Seti Zone"
            },
            {
                "country_code": "NP",
                "country_id": 154,
                "id": 2079,
                "iso2": "KA",
                "name": "Karnali Zone"
            },
            {
                "country_code": "NP",
                "country_id": 154,
                "id": 2080,
                "iso2": "DH",
                "name": "Dhaulagiri Zone"
            },
            {
                "country_code": "NP",
                "country_id": 154,
                "id": 2081,
                "iso2": "GA",
                "name": "Gandaki Zone"
            },
            {
                "country_code": "NP",
                "country_id": 154,
                "id": 2082,
                "iso2": "BA",
                "name": "Bagmati Zone"
            },
            {
                "country_code": "NP",
                "country_id": 154,
                "id": 2083,
                "iso2": "MA",
                "name": "Mahakali Zone"
            },
            {
                "country_code": "NP",
                "country_id": 154,
                "id": 2084,
                "iso2": "SA",
                "name": "Sagarmatha Zone"
            }
        ]
    },
    {
        "id": 155,
        "name": "Bonaire, Sint Eustatius and Saba",
        "iso3": "BES",
        "iso2": "BQ",
        "states": []
    },
    {
        "id": 156,
        "name": "Netherlands The",
        "iso3": "NLD",
        "iso2": "NL",
        "states": [
            {
                "country_code": "NL",
                "country_id": 156,
                "id": 2610,
                "iso2": "UT",
                "name": "Utrecht"
            },
            {
                "country_code": "NL",
                "country_id": 156,
                "id": 2611,
                "iso2": "GE",
                "name": "Gelderland"
            },
            {
                "country_code": "NL",
                "country_id": 156,
                "id": 2612,
                "iso2": "NH",
                "name": "North Holland"
            },
            {
                "country_code": "NL",
                "country_id": 156,
                "id": 2613,
                "iso2": "DR",
                "name": "Drenthe"
            },
            {
                "country_code": "NL",
                "country_id": 156,
                "id": 2614,
                "iso2": "ZH",
                "name": "South Holland"
            },
            {
                "country_code": "NL",
                "country_id": 156,
                "id": 2615,
                "iso2": "LI",
                "name": "Limburg"
            },
            {
                "country_code": "NL",
                "country_id": 156,
                "id": 2616,
                "iso2": "BQ3",
                "name": "Sint Eustatius"
            },
            {
                "country_code": "NL",
                "country_id": 156,
                "id": 2617,
                "iso2": "GR",
                "name": "Groningen"
            },
            {
                "country_code": "NL",
                "country_id": 156,
                "id": 2618,
                "iso2": "OV",
                "name": "Overijssel"
            },
            {
                "country_code": "NL",
                "country_id": 156,
                "id": 2619,
                "iso2": "FL",
                "name": "Flevoland"
            },
            {
                "country_code": "NL",
                "country_id": 156,
                "id": 2620,
                "iso2": "ZE",
                "name": "Zeeland"
            },
            {
                "country_code": "NL",
                "country_id": 156,
                "id": 2621,
                "iso2": "BQ2",
                "name": "Saba"
            },
            {
                "country_code": "NL",
                "country_id": 156,
                "id": 2622,
                "iso2": "FR",
                "name": "Friesland"
            },
            {
                "country_code": "NL",
                "country_id": 156,
                "id": 2623,
                "iso2": "NB",
                "name": "North Brabant"
            },
            {
                "country_code": "NL",
                "country_id": 156,
                "id": 2624,
                "iso2": "BQ1",
                "name": "Bonaire"
            }
        ]
    },
    {
        "id": 157,
        "name": "New Caledonia",
        "iso3": "NCL",
        "iso2": "NC",
        "states": []
    },
    {
        "id": 158,
        "name": "New Zealand",
        "iso3": "NZL",
        "iso2": "NZ",
        "states": [
            {
                "country_code": "NZ",
                "country_id": 158,
                "id": 4059,
                "iso2": "NTL",
                "name": "Northland Region"
            },
            {
                "country_code": "NZ",
                "country_id": 158,
                "id": 4060,
                "iso2": "MWT",
                "name": "Manawatu-Wanganui Region"
            },
            {
                "country_code": "NZ",
                "country_id": 158,
                "id": 4061,
                "iso2": "WKO",
                "name": "Waikato Region"
            },
            {
                "country_code": "NZ",
                "country_id": 158,
                "id": 4062,
                "iso2": "OTA",
                "name": "Otago Region"
            },
            {
                "country_code": "NZ",
                "country_id": 158,
                "id": 4063,
                "iso2": "MBH",
                "name": "Marlborough Region"
            },
            {
                "country_code": "NZ",
                "country_id": 158,
                "id": 4064,
                "iso2": "WTC",
                "name": "West Coast Region"
            },
            {
                "country_code": "NZ",
                "country_id": 158,
                "id": 4065,
                "iso2": "WGN",
                "name": "Wellington Region"
            },
            {
                "country_code": "NZ",
                "country_id": 158,
                "id": 4066,
                "iso2": "CAN",
                "name": "Canterbury Region"
            },
            {
                "country_code": "NZ",
                "country_id": 158,
                "id": 4067,
                "iso2": "CIT",
                "name": "Chatham Islands"
            },
            {
                "country_code": "NZ",
                "country_id": 158,
                "id": 4068,
                "iso2": "GIS",
                "name": "Gisborne District"
            },
            {
                "country_code": "NZ",
                "country_id": 158,
                "id": 4069,
                "iso2": "TKI",
                "name": "Taranaki Region"
            },
            {
                "country_code": "NZ",
                "country_id": 158,
                "id": 4070,
                "iso2": "NSN",
                "name": "Nelson Region"
            },
            {
                "country_code": "NZ",
                "country_id": 158,
                "id": 4071,
                "iso2": "STL",
                "name": "Southland Region"
            },
            {
                "country_code": "NZ",
                "country_id": 158,
                "id": 4072,
                "iso2": "AUK",
                "name": "Auckland Region"
            },
            {
                "country_code": "NZ",
                "country_id": 158,
                "id": 4073,
                "iso2": "TAS",
                "name": "Tasman District"
            },
            {
                "country_code": "NZ",
                "country_id": 158,
                "id": 4074,
                "iso2": "BOP",
                "name": "Bay of Plenty Region"
            },
            {
                "country_code": "NZ",
                "country_id": 158,
                "id": 4075,
                "iso2": "HKB",
                "name": "Hawke's Bay Region"
            }
        ]
    },
    {
        "id": 159,
        "name": "Nicaragua",
        "iso3": "NIC",
        "iso2": "NI",
        "states": [
            {
                "country_code": "NI",
                "country_id": 159,
                "id": 940,
                "iso2": "CO",
                "name": "Chontales Department"
            },
            {
                "country_code": "NI",
                "country_id": 159,
                "id": 941,
                "iso2": "MN",
                "name": "Managua Department"
            },
            {
                "country_code": "NI",
                "country_id": 159,
                "id": 942,
                "iso2": "RI",
                "name": "Rivas Department"
            },
            {
                "country_code": "NI",
                "country_id": 159,
                "id": 943,
                "iso2": "GR",
                "name": "Granada Department"
            },
            {
                "country_code": "NI",
                "country_id": 159,
                "id": 944,
                "iso2": "LE",
                "name": "León Department"
            },
            {
                "country_code": "NI",
                "country_id": 159,
                "id": 945,
                "iso2": "ES",
                "name": "Estelí Department"
            },
            {
                "country_code": "NI",
                "country_id": 159,
                "id": 946,
                "iso2": "BO",
                "name": "Boaco Department"
            },
            {
                "country_code": "NI",
                "country_id": 159,
                "id": 947,
                "iso2": "MT",
                "name": "Matagalpa Department"
            },
            {
                "country_code": "NI",
                "country_id": 159,
                "id": 948,
                "iso2": "MD",
                "name": "Madriz Department"
            },
            {
                "country_code": "NI",
                "country_id": 159,
                "id": 949,
                "iso2": "SJ",
                "name": "Río San Juan Department"
            },
            {
                "country_code": "NI",
                "country_id": 159,
                "id": 950,
                "iso2": "CA",
                "name": "Carazo Department"
            },
            {
                "country_code": "NI",
                "country_id": 159,
                "id": 951,
                "iso2": "AN",
                "name": "North Caribbean Coast Autonomous Region"
            },
            {
                "country_code": "NI",
                "country_id": 159,
                "id": 952,
                "iso2": "AS",
                "name": "South Caribbean Coast Autonomous Region"
            },
            {
                "country_code": "NI",
                "country_id": 159,
                "id": 953,
                "iso2": "MS",
                "name": "Masaya Department"
            },
            {
                "country_code": "NI",
                "country_id": 159,
                "id": 954,
                "iso2": "CI",
                "name": "Chinandega Department"
            },
            {
                "country_code": "NI",
                "country_id": 159,
                "id": 955,
                "iso2": "JI",
                "name": "Jinotega Department"
            }
        ]
    },
    {
        "id": 160,
        "name": "Niger",
        "iso3": "NER",
        "iso2": "NE",
        "states": [
            {
                "country_code": "NE",
                "country_id": 160,
                "id": 67,
                "iso2": "6",
                "name": "Tillabéri Region"
            },
            {
                "country_code": "NE",
                "country_id": 160,
                "id": 68,
                "iso2": "3",
                "name": "Dosso Region"
            },
            {
                "country_code": "NE",
                "country_id": 160,
                "id": 69,
                "iso2": "7",
                "name": "Zinder Region"
            },
            {
                "country_code": "NE",
                "country_id": 160,
                "id": 70,
                "iso2": "4",
                "name": "Maradi Region"
            },
            {
                "country_code": "NE",
                "country_id": 160,
                "id": 71,
                "iso2": "1",
                "name": "Agadez Region"
            },
            {
                "country_code": "NE",
                "country_id": 160,
                "id": 72,
                "iso2": "2",
                "name": "Diffa Region"
            },
            {
                "country_code": "NE",
                "country_id": 160,
                "id": 73,
                "iso2": "5",
                "name": "Tahoua Region"
            }
        ]
    },
    {
        "id": 161,
        "name": "Nigeria",
        "iso3": "NGA",
        "iso2": "NG",
        "states": [
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 288,
                "iso2": "JI",
                "name": "Jigawa State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 289,
                "iso2": "EN",
                "name": "Enugu State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 290,
                "iso2": "KE",
                "name": "Kebbi State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 291,
                "iso2": "BE",
                "name": "Benue State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 292,
                "iso2": "SO",
                "name": "Sokoto State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 293,
                "iso2": "FC",
                "name": "Federal Capital Territory"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 294,
                "iso2": "KD",
                "name": "Kaduna State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 295,
                "iso2": "KW",
                "name": "Kwara State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 296,
                "iso2": "OY",
                "name": "Oyo State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 297,
                "iso2": "YO",
                "name": "Yobe State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 298,
                "iso2": "KO",
                "name": "Kogi State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 299,
                "iso2": "ZA",
                "name": "Zamfara State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 300,
                "iso2": "KN",
                "name": "Kano State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 301,
                "iso2": "NA",
                "name": "Nasarawa State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 302,
                "iso2": "PL",
                "name": "Plateau State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 303,
                "iso2": "AB",
                "name": "Abia State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 304,
                "iso2": "AK",
                "name": "Akwa Ibom State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 305,
                "iso2": "BY",
                "name": "Bayelsa State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 306,
                "iso2": "LA",
                "name": "Lagos"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 307,
                "iso2": "BO",
                "name": "Borno State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 308,
                "iso2": "IM",
                "name": "Imo State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 309,
                "iso2": "EK",
                "name": "Ekiti State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 310,
                "iso2": "GO",
                "name": "Gombe State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 311,
                "iso2": "EB",
                "name": "Ebonyi State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 312,
                "iso2": "BA",
                "name": "Bauchi State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 313,
                "iso2": "KT",
                "name": "Katsina State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 314,
                "iso2": "CR",
                "name": "Cross River State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 315,
                "iso2": "AN",
                "name": "Anambra State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 316,
                "iso2": "DE",
                "name": "Delta State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 317,
                "iso2": "NI",
                "name": "Niger State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 318,
                "iso2": "ED",
                "name": "Edo State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 319,
                "iso2": "TA",
                "name": "Taraba State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 320,
                "iso2": "AD",
                "name": "Adamawa State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 321,
                "iso2": "ON",
                "name": "Ondo State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 322,
                "iso2": "OS",
                "name": "Osun State"
            },
            {
                "country_code": "NG",
                "country_id": 161,
                "id": 323,
                "iso2": "OG",
                "name": "Ogun State"
            }
        ]
    },
    {
        "id": 162,
        "name": "Niue",
        "iso3": "NIU",
        "iso2": "NU",
        "states": []
    },
    {
        "id": 163,
        "name": "Norfolk Island",
        "iso3": "NFK",
        "iso2": "NF",
        "states": []
    },
    {
        "id": 164,
        "name": "Northern Mariana Islands",
        "iso3": "MNP",
        "iso2": "MP",
        "states": []
    },
    {
        "id": 165,
        "name": "Norway",
        "iso3": "NOR",
        "iso2": "NO",
        "states": [
            {
                "country_code": "NO",
                "country_id": 165,
                "id": 1006,
                "iso2": "50",
                "name": "Trøndelag"
            },
            {
                "country_code": "NO",
                "country_id": 165,
                "id": 1007,
                "iso2": "3",
                "name": "Oslo"
            },
            {
                "country_code": "NO",
                "country_id": 165,
                "id": 1008,
                "iso2": "7",
                "name": "Vestfold"
            },
            {
                "country_code": "NO",
                "country_id": 165,
                "id": 1009,
                "iso2": "5",
                "name": "Oppland"
            },
            {
                "country_code": "NO",
                "country_id": 165,
                "id": 1010,
                "iso2": "16",
                "name": "Sør-Trøndelag"
            },
            {
                "country_code": "NO",
                "country_id": 165,
                "id": 1011,
                "iso2": "6",
                "name": "Buskerud"
            },
            {
                "country_code": "NO",
                "country_id": 165,
                "id": 1012,
                "iso2": "17",
                "name": "Nord-Trøndelag"
            },
            {
                "country_code": "NO",
                "country_id": 165,
                "id": 1013,
                "iso2": "21",
                "name": "Svalbard"
            },
            {
                "country_code": "NO",
                "country_id": 165,
                "id": 1014,
                "iso2": "10",
                "name": "Vest-Agder"
            },
            {
                "country_code": "NO",
                "country_id": 165,
                "id": 1015,
                "iso2": "19",
                "name": "Troms"
            },
            {
                "country_code": "NO",
                "country_id": 165,
                "id": 1016,
                "iso2": "20",
                "name": "Finnmark"
            },
            {
                "country_code": "NO",
                "country_id": 165,
                "id": 1017,
                "iso2": "2",
                "name": "Akershus"
            },
            {
                "country_code": "NO",
                "country_id": 165,
                "id": 1018,
                "iso2": "14",
                "name": "Sogn og Fjordane"
            },
            {
                "country_code": "NO",
                "country_id": 165,
                "id": 1019,
                "iso2": "4",
                "name": "Hedmark"
            },
            {
                "country_code": "NO",
                "country_id": 165,
                "id": 1020,
                "iso2": "15",
                "name": "Møre og Romsdal"
            },
            {
                "country_code": "NO",
                "country_id": 165,
                "id": 1021,
                "iso2": "11",
                "name": "Rogaland"
            },
            {
                "country_code": "NO",
                "country_id": 165,
                "id": 1022,
                "iso2": "1",
                "name": "Østfold"
            },
            {
                "country_code": "NO",
                "country_id": 165,
                "id": 1023,
                "iso2": "12",
                "name": "Hordaland"
            },
            {
                "country_code": "NO",
                "country_id": 165,
                "id": 1024,
                "iso2": "8",
                "name": "Telemark"
            },
            {
                "country_code": "NO",
                "country_id": 165,
                "id": 1025,
                "iso2": "18",
                "name": "Nordland"
            },
            {
                "country_code": "NO",
                "country_id": 165,
                "id": 1026,
                "iso2": "22",
                "name": "Jan Mayen"
            }
        ]
    },
    {
        "id": 166,
        "name": "Oman",
        "iso3": "OMN",
        "iso2": "OM",
        "states": [
            {
                "country_code": "OM",
                "country_id": 166,
                "id": 3047,
                "iso2": "ZA",
                "name": "Ad Dhahirah Governorate"
            },
            {
                "country_code": "OM",
                "country_id": 166,
                "id": 3048,
                "iso2": "BS",
                "name": "Al Batinah North Governorate"
            },
            {
                "country_code": "OM",
                "country_id": 166,
                "id": 3049,
                "iso2": "BJ",
                "name": "Al Batinah South Governorate"
            },
            {
                "country_code": "OM",
                "country_id": 166,
                "id": 3050,
                "iso2": "BA",
                "name": "Al Batinah Region"
            },
            {
                "country_code": "OM",
                "country_id": 166,
                "id": 3051,
                "iso2": "SH",
                "name": "Ash Sharqiyah Region"
            },
            {
                "country_code": "OM",
                "country_id": 166,
                "id": 3052,
                "iso2": "MU",
                "name": "Musandam Governorate"
            },
            {
                "country_code": "OM",
                "country_id": 166,
                "id": 3053,
                "iso2": "SS",
                "name": "Ash Sharqiyah North Governorate"
            },
            {
                "country_code": "OM",
                "country_id": 166,
                "id": 3054,
                "iso2": "SJ",
                "name": "Ash Sharqiyah South Governorate"
            },
            {
                "country_code": "OM",
                "country_id": 166,
                "id": 3055,
                "iso2": "MA",
                "name": "Muscat Governorate"
            },
            {
                "country_code": "OM",
                "country_id": 166,
                "id": 3056,
                "iso2": "WU",
                "name": "Al Wusta Governorate"
            },
            {
                "country_code": "OM",
                "country_id": 166,
                "id": 3057,
                "iso2": "ZU",
                "name": "Dhofar Governorate"
            },
            {
                "country_code": "OM",
                "country_id": 166,
                "id": 3058,
                "iso2": "DA",
                "name": "Ad Dakhiliyah Governorate"
            },
            {
                "country_code": "OM",
                "country_id": 166,
                "id": 3059,
                "iso2": "BU",
                "name": "Al Buraimi Governorate"
            }
        ]
    },
    {
        "id": 167,
        "name": "Pakistan",
        "iso3": "PAK",
        "iso2": "PK",
        "states": [
            {
                "country_code": "PK",
                "country_id": 167,
                "id": 3169,
                "iso2": "IS",
                "name": "Islamabad Capital Territory"
            },
            {
                "country_code": "PK",
                "country_id": 167,
                "id": 3170,
                "iso2": "GB",
                "name": "Gilgit-Baltistan"
            },
            {
                "country_code": "PK",
                "country_id": 167,
                "id": 3171,
                "iso2": "KP",
                "name": "Khyber Pakhtunkhwa"
            },
            {
                "country_code": "PK",
                "country_id": 167,
                "id": 3172,
                "iso2": "JK",
                "name": "Azad Kashmir"
            },
            {
                "country_code": "PK",
                "country_id": 167,
                "id": 3173,
                "iso2": "TA",
                "name": "Federally Administered Tribal Areas"
            },
            {
                "country_code": "PK",
                "country_id": 167,
                "id": 3174,
                "iso2": "BA",
                "name": "Balochistan"
            },
            {
                "country_code": "PK",
                "country_id": 167,
                "id": 3175,
                "iso2": "SD",
                "name": "Sindh"
            },
            {
                "country_code": "PK",
                "country_id": 167,
                "id": 3176,
                "iso2": "PB",
                "name": "Punjab"
            }
        ]
    },
    {
        "id": 168,
        "name": "Palau",
        "iso3": "PLW",
        "iso2": "PW",
        "states": [
            {
                "country_code": "PW",
                "country_id": 168,
                "id": 4526,
                "iso2": "350",
                "name": "Peleliu"
            },
            {
                "country_code": "PW",
                "country_id": 168,
                "id": 4527,
                "iso2": "222",
                "name": "Ngardmau"
            },
            {
                "country_code": "PW",
                "country_id": 168,
                "id": 4528,
                "iso2": "4",
                "name": "Airai"
            },
            {
                "country_code": "PW",
                "country_id": 168,
                "id": 4529,
                "iso2": "50",
                "name": "Hatohobei"
            },
            {
                "country_code": "PW",
                "country_id": 168,
                "id": 4530,
                "iso2": "212",
                "name": "Melekeok"
            },
            {
                "country_code": "PW",
                "country_id": 168,
                "id": 4531,
                "iso2": "224",
                "name": "Ngatpang"
            },
            {
                "country_code": "PW",
                "country_id": 168,
                "id": 4532,
                "iso2": "150",
                "name": "Koror"
            },
            {
                "country_code": "PW",
                "country_id": 168,
                "id": 4533,
                "iso2": "218",
                "name": "Ngarchelong"
            },
            {
                "country_code": "PW",
                "country_id": 168,
                "id": 4534,
                "iso2": "228",
                "name": "Ngiwal"
            },
            {
                "country_code": "PW",
                "country_id": 168,
                "id": 4535,
                "iso2": "370",
                "name": "Sonsorol"
            },
            {
                "country_code": "PW",
                "country_id": 168,
                "id": 4536,
                "iso2": "226",
                "name": "Ngchesar"
            },
            {
                "country_code": "PW",
                "country_id": 168,
                "id": 4537,
                "iso2": "214",
                "name": "Ngaraard"
            },
            {
                "country_code": "PW",
                "country_id": 168,
                "id": 4538,
                "iso2": "10",
                "name": "Angaur"
            },
            {
                "country_code": "PW",
                "country_id": 168,
                "id": 4539,
                "iso2": "100",
                "name": "Kayangel"
            },
            {
                "country_code": "PW",
                "country_id": 168,
                "id": 4540,
                "iso2": "2",
                "name": "Aimeliik"
            },
            {
                "country_code": "PW",
                "country_id": 168,
                "id": 4541,
                "iso2": "227",
                "name": "Ngeremlengui"
            }
        ]
    },
    {
        "id": 169,
        "name": "Palestinian Territory Occupied",
        "iso3": "PSE",
        "iso2": "PS",
        "states": []
    },
    {
        "id": 170,
        "name": "Panama",
        "iso3": "PAN",
        "iso2": "PA",
        "states": [
            {
                "country_code": "PA",
                "country_id": 170,
                "id": 1385,
                "iso2": "5",
                "name": "Darién Province"
            },
            {
                "country_code": "PA",
                "country_id": 170,
                "id": 1386,
                "iso2": "3",
                "name": "Colón Province"
            },
            {
                "country_code": "PA",
                "country_id": 170,
                "id": 1387,
                "iso2": "2",
                "name": "Coclé Province"
            },
            {
                "country_code": "PA",
                "country_id": 170,
                "id": 1388,
                "iso2": "KY",
                "name": "Guna Yala"
            },
            {
                "country_code": "PA",
                "country_id": 170,
                "id": 1389,
                "iso2": "6",
                "name": "Herrera Province"
            },
            {
                "country_code": "PA",
                "country_id": 170,
                "id": 1390,
                "iso2": "7",
                "name": "Los Santos Province"
            },
            {
                "country_code": "PA",
                "country_id": 170,
                "id": 1391,
                "iso2": "NB",
                "name": "Ngöbe-Buglé Comarca"
            },
            {
                "country_code": "PA",
                "country_id": 170,
                "id": 1392,
                "iso2": "9",
                "name": "Veraguas Province"
            },
            {
                "country_code": "PA",
                "country_id": 170,
                "id": 1393,
                "iso2": "1",
                "name": "Bocas del Toro Province"
            },
            {
                "country_code": "PA",
                "country_id": 170,
                "id": 1394,
                "iso2": "10",
                "name": "Panamá Oeste Province"
            },
            {
                "country_code": "PA",
                "country_id": 170,
                "id": 1395,
                "iso2": "8",
                "name": "Panamá Province"
            },
            {
                "country_code": "PA",
                "country_id": 170,
                "id": 1396,
                "iso2": "EM",
                "name": "Emberá-Wounaan Comarca"
            },
            {
                "country_code": "PA",
                "country_id": 170,
                "id": 1397,
                "iso2": "4",
                "name": "Chiriquí Province"
            }
        ]
    },
    {
        "id": 171,
        "name": "Papua new Guinea",
        "iso3": "PNG",
        "iso2": "PG",
        "states": [
            {
                "country_code": "PG",
                "country_id": 171,
                "id": 4830,
                "iso2": "WBK",
                "name": "West New Britain Province"
            },
            {
                "country_code": "PG",
                "country_id": 171,
                "id": 4831,
                "iso2": "NSB",
                "name": "Bougainville"
            },
            {
                "country_code": "PG",
                "country_id": 171,
                "id": 4832,
                "iso2": "JWK",
                "name": "Jiwaka Province"
            },
            {
                "country_code": "PG",
                "country_id": 171,
                "id": 4833,
                "iso2": "HLA",
                "name": "Hela"
            },
            {
                "country_code": "PG",
                "country_id": 171,
                "id": 4834,
                "iso2": "EBR",
                "name": "East New Britain"
            },
            {
                "country_code": "PG",
                "country_id": 171,
                "id": 4835,
                "iso2": "MPL",
                "name": "Morobe Province"
            },
            {
                "country_code": "PG",
                "country_id": 171,
                "id": 4836,
                "iso2": "SAN",
                "name": "Sandaun Province"
            },
            {
                "country_code": "PG",
                "country_id": 171,
                "id": 4837,
                "iso2": "NCD",
                "name": "Port Moresby"
            },
            {
                "country_code": "PG",
                "country_id": 171,
                "id": 4838,
                "iso2": "NPP",
                "name": "Oro Province"
            },
            {
                "country_code": "PG",
                "country_id": 171,
                "id": 4839,
                "iso2": "GPK",
                "name": "Gulf"
            },
            {
                "country_code": "PG",
                "country_id": 171,
                "id": 4840,
                "iso2": "WHM",
                "name": "Western Highlands Province"
            },
            {
                "country_code": "PG",
                "country_id": 171,
                "id": 4841,
                "iso2": "NIK",
                "name": "New Ireland Province"
            },
            {
                "country_code": "PG",
                "country_id": 171,
                "id": 4842,
                "iso2": "MRL",
                "name": "Manus Province"
            },
            {
                "country_code": "PG",
                "country_id": 171,
                "id": 4843,
                "iso2": "MPM",
                "name": "Madang Province"
            },
            {
                "country_code": "PG",
                "country_id": 171,
                "id": 4844,
                "iso2": "SHM",
                "name": "Southern Highlands Province"
            },
            {
                "country_code": "PG",
                "country_id": 171,
                "id": 4845,
                "iso2": "EHG",
                "name": "Eastern Highlands Province"
            },
            {
                "country_code": "PG",
                "country_id": 171,
                "id": 4846,
                "iso2": "CPK",
                "name": "Chimbu Province"
            },
            {
                "country_code": "PG",
                "country_id": 171,
                "id": 4847,
                "iso2": "CPM",
                "name": "Central Province"
            },
            {
                "country_code": "PG",
                "country_id": 171,
                "id": 4848,
                "iso2": "EPW",
                "name": "Enga Province"
            },
            {
                "country_code": "PG",
                "country_id": 171,
                "id": 4849,
                "iso2": "MBA",
                "name": "Milne Bay Province"
            },
            {
                "country_code": "PG",
                "country_id": 171,
                "id": 4850,
                "iso2": "WPD",
                "name": "Western Province"
            }
        ]
    },
    {
        "id": 172,
        "name": "Paraguay",
        "iso3": "PRY",
        "iso2": "PY",
        "states": [
            {
                "country_code": "PY",
                "country_id": 172,
                "id": 2770,
                "iso2": "15",
                "name": "Presidente Hayes Department"
            },
            {
                "country_code": "PY",
                "country_id": 172,
                "id": 2771,
                "iso2": "14",
                "name": "Canindeyú"
            },
            {
                "country_code": "PY",
                "country_id": 172,
                "id": 2772,
                "iso2": "4",
                "name": "Guairá Department"
            },
            {
                "country_code": "PY",
                "country_id": 172,
                "id": 2773,
                "iso2": "5",
                "name": "Caaguazú"
            },
            {
                "country_code": "PY",
                "country_id": 172,
                "id": 2774,
                "iso2": "9",
                "name": "Paraguarí Department"
            },
            {
                "country_code": "PY",
                "country_id": 172,
                "id": 2775,
                "iso2": "6",
                "name": "Caazapá"
            },
            {
                "country_code": "PY",
                "country_id": 172,
                "id": 2776,
                "iso2": "2",
                "name": "San Pedro Department"
            },
            {
                "country_code": "PY",
                "country_id": 172,
                "id": 2777,
                "iso2": "11",
                "name": "Central Department"
            },
            {
                "country_code": "PY",
                "country_id": 172,
                "id": 2778,
                "iso2": "7",
                "name": "Itapúa"
            },
            {
                "country_code": "PY",
                "country_id": 172,
                "id": 2779,
                "iso2": "1",
                "name": "Concepción Department"
            },
            {
                "country_code": "PY",
                "country_id": 172,
                "id": 2780,
                "iso2": "19",
                "name": "Boquerón Department"
            },
            {
                "country_code": "PY",
                "country_id": 172,
                "id": 2781,
                "iso2": "12",
                "name": "Ñeembucú Department"
            },
            {
                "country_code": "PY",
                "country_id": 172,
                "id": 2782,
                "iso2": "13",
                "name": "Amambay Department"
            },
            {
                "country_code": "PY",
                "country_id": 172,
                "id": 2783,
                "iso2": "3",
                "name": "Cordillera Department"
            },
            {
                "country_code": "PY",
                "country_id": 172,
                "id": 2784,
                "iso2": "10",
                "name": "Alto Paraná Department"
            },
            {
                "country_code": "PY",
                "country_id": 172,
                "id": 2785,
                "iso2": "16",
                "name": "Alto Paraguay Department"
            },
            {
                "country_code": "PY",
                "country_id": 172,
                "id": 2786,
                "iso2": "8",
                "name": "Misiones Department"
            }
        ]
    },
    {
        "id": 173,
        "name": "Peru",
        "iso3": "PER",
        "iso2": "PE",
        "states": [
            {
                "country_code": "PE",
                "country_id": 173,
                "id": 3678,
                "iso2": "MDD",
                "name": "Madre de Dios"
            },
            {
                "country_code": "PE",
                "country_id": 173,
                "id": 3679,
                "iso2": "HUV",
                "name": "Huancavelica"
            },
            {
                "country_code": "PE",
                "country_id": 173,
                "id": 3680,
                "iso2": "ANC",
                "name": "Áncash"
            },
            {
                "country_code": "PE",
                "country_id": 173,
                "id": 3681,
                "iso2": "ARE",
                "name": "Arequipa"
            },
            {
                "country_code": "PE",
                "country_id": 173,
                "id": 3682,
                "iso2": "PUN",
                "name": "Puno"
            },
            {
                "country_code": "PE",
                "country_id": 173,
                "id": 3683,
                "iso2": "LAL",
                "name": "La Libertad"
            },
            {
                "country_code": "PE",
                "country_id": 173,
                "id": 3684,
                "iso2": "UCA",
                "name": "Ucayali"
            },
            {
                "country_code": "PE",
                "country_id": 173,
                "id": 3685,
                "iso2": "AMA",
                "name": "Amazonas"
            },
            {
                "country_code": "PE",
                "country_id": 173,
                "id": 3686,
                "iso2": "PAS",
                "name": "Pasco"
            },
            {
                "country_code": "PE",
                "country_id": 173,
                "id": 3687,
                "iso2": "HUC",
                "name": "Huanuco"
            },
            {
                "country_code": "PE",
                "country_id": 173,
                "id": 3688,
                "iso2": "CAJ",
                "name": "Cajamarca"
            },
            {
                "country_code": "PE",
                "country_id": 173,
                "id": 3689,
                "iso2": "TUM",
                "name": "Tumbes"
            },
            {
                "country_code": "PE",
                "country_id": 173,
                "id": 3691,
                "iso2": "CUS",
                "name": "Cusco"
            },
            {
                "country_code": "PE",
                "country_id": 173,
                "id": 3692,
                "iso2": "AYA",
                "name": "Ayacucho"
            },
            {
                "country_code": "PE",
                "country_id": 173,
                "id": 3693,
                "iso2": "JUN",
                "name": "Junín"
            },
            {
                "country_code": "PE",
                "country_id": 173,
                "id": 3694,
                "iso2": "SAM",
                "name": "San Martín"
            },
            {
                "country_code": "PE",
                "country_id": 173,
                "id": 3695,
                "iso2": "LIM",
                "name": "Lima"
            },
            {
                "country_code": "PE",
                "country_id": 173,
                "id": 3696,
                "iso2": "TAC",
                "name": "Tacna"
            },
            {
                "country_code": "PE",
                "country_id": 173,
                "id": 3697,
                "iso2": "PIU",
                "name": "Piura"
            },
            {
                "country_code": "PE",
                "country_id": 173,
                "id": 3698,
                "iso2": "MOQ",
                "name": "Moquegua"
            },
            {
                "country_code": "PE",
                "country_id": 173,
                "id": 3699,
                "iso2": "APU",
                "name": "Apurímac"
            },
            {
                "country_code": "PE",
                "country_id": 173,
                "id": 3700,
                "iso2": "ICA",
                "name": "Ica"
            },
            {
                "country_code": "PE",
                "country_id": 173,
                "id": 3701,
                "iso2": "CAL",
                "name": "Callao"
            },
            {
                "country_code": "PE",
                "country_id": 173,
                "id": 3702,
                "iso2": "LAM",
                "name": "Lambayeque"
            }
        ]
    },
    {
        "id": 174,
        "name": "Philippines",
        "iso3": "PHL",
        "iso2": "PH",
        "states": [
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1269,
                "iso2": "ROM",
                "name": "Romblon"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1270,
                "iso2": "BUK",
                "name": "Bukidnon"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1271,
                "iso2": "RIZ",
                "name": "Rizal"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1272,
                "iso2": "BOH",
                "name": "Bohol"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1273,
                "iso2": "QUI",
                "name": "Quirino"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1274,
                "iso2": "BIL",
                "name": "Biliran"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1275,
                "iso2": "QUE",
                "name": "Quezon"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1276,
                "iso2": "SIG",
                "name": "Siquijor"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1277,
                "iso2": "SAR",
                "name": "Sarangani"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1278,
                "iso2": "BUL",
                "name": "Bulacan"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1279,
                "iso2": "CAG",
                "name": "Cagayan"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1280,
                "iso2": "SCO",
                "name": "South Cotabato"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1281,
                "iso2": "SOR",
                "name": "Sorsogon"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1282,
                "iso2": "SUK",
                "name": "Sultan Kudarat"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1283,
                "iso2": "CAN",
                "name": "Camarines Norte"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1284,
                "iso2": "SLE",
                "name": "Southern Leyte"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1285,
                "iso2": "CAM",
                "name": "Camiguin"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1286,
                "iso2": "SUN",
                "name": "Surigao del Norte"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1287,
                "iso2": "CAS",
                "name": "Camarines Sur"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1288,
                "iso2": "SLU",
                "name": "Sulu"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1289,
                "iso2": "DAO",
                "name": "Davao Oriental"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1290,
                "iso2": "EAS",
                "name": "Eastern Samar"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1291,
                "iso2": "DIN",
                "name": "Dinagat Islands"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1292,
                "iso2": "CAP",
                "name": "Capiz"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1293,
                "iso2": "TAW",
                "name": "Tawi-Tawi"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1294,
                "iso2": "40",
                "name": "Calabarzon"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1295,
                "iso2": "TAR",
                "name": "Tarlac"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1296,
                "iso2": "SUR",
                "name": "Surigao del Sur"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1297,
                "iso2": "ZMB",
                "name": "Zambales"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1298,
                "iso2": "ILN",
                "name": "Ilocos Norte"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1299,
                "iso2": "41",
                "name": "Mimaropa"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1300,
                "iso2": "IFU",
                "name": "Ifugao"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1301,
                "iso2": "CAT",
                "name": "Catanduanes"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1302,
                "iso2": "ZAN",
                "name": "Zamboanga del Norte"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1303,
                "iso2": "GUI",
                "name": "Guimaras"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1304,
                "iso2": "5",
                "name": "Bicol Region"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1305,
                "iso2": "6",
                "name": "Western Visayas"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1306,
                "iso2": "CEB",
                "name": "Cebu"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1307,
                "iso2": "CAV",
                "name": "Cavite"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1308,
                "iso2": "7",
                "name": "Central Visayas"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1309,
                "iso2": "DVO",
                "name": "Davao Occidental"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1310,
                "iso2": "12",
                "name": "Soccsksargen"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1311,
                "iso2": "COM",
                "name": "Compostela Valley"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1312,
                "iso2": "KAL",
                "name": "Kalinga"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1313,
                "iso2": "ISA",
                "name": "Isabela"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1314,
                "iso2": "13",
                "name": "Caraga"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1315,
                "iso2": "ILI",
                "name": "Iloilo"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1316,
                "iso2": "14",
                "name": "Autonomous Region in Muslim Mindanao"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1317,
                "iso2": "LUN",
                "name": "La Union"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1318,
                "iso2": "DAS",
                "name": "Davao del Sur"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1319,
                "iso2": "DAV",
                "name": "Davao del Norte"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1320,
                "iso2": "NCO",
                "name": "Cotabato"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1321,
                "iso2": "ILS",
                "name": "Ilocos Sur"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1322,
                "iso2": "8",
                "name": "Eastern Visayas"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1323,
                "iso2": "AGN",
                "name": "Agusan del Norte"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1324,
                "iso2": "ABR",
                "name": "Abra"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1325,
                "iso2": "9",
                "name": "Zamboanga Peninsula"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1326,
                "iso2": "AGS",
                "name": "Agusan del Sur"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1327,
                "iso2": "LAN",
                "name": "Lanao del Norte"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1328,
                "iso2": "LAG",
                "name": "Laguna"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1329,
                "iso2": "MAD",
                "name": "Marinduque"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1330,
                "iso2": "MAG",
                "name": "Maguindanao"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1331,
                "iso2": "AKL",
                "name": "Aklan"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1332,
                "iso2": "LEY",
                "name": "Leyte"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1333,
                "iso2": "LAS",
                "name": "Lanao del Sur"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1334,
                "iso2": "APA",
                "name": "Apayao"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1335,
                "iso2": "15",
                "name": "Cordillera Administrative Region"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1336,
                "iso2": "ANT",
                "name": "Antique"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1337,
                "iso2": "ALB",
                "name": "Albay"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1338,
                "iso2": "MAS",
                "name": "Masbate"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1339,
                "iso2": "10",
                "name": "Northern Mindanao"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1340,
                "iso2": "11",
                "name": "Davao Region"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1341,
                "iso2": "AUR",
                "name": "Aurora"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1342,
                "iso2": "2",
                "name": "Cagayan Valley"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1343,
                "iso2": "MSC",
                "name": "Misamis Occidental"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1344,
                "iso2": "BAN",
                "name": "Bataan"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1345,
                "iso2": "3",
                "name": "Central Luzon"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1346,
                "iso2": "BAS",
                "name": "Basilan"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1347,
                "iso2": "NCR",
                "name": "Metro Manila"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1348,
                "iso2": "MSR",
                "name": "Misamis Oriental"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1349,
                "iso2": "NSA",
                "name": "Northern Samar"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1350,
                "iso2": "NER",
                "name": "Negros Oriental"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1351,
                "iso2": "NEC",
                "name": "Negros Occidental"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1352,
                "iso2": "BTN",
                "name": "Batanes"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1353,
                "iso2": "MOU",
                "name": "Mountain Province"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1354,
                "iso2": "MDR",
                "name": "Oriental Mindoro"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1355,
                "iso2": "1",
                "name": "Ilocos Region"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1356,
                "iso2": "MDC",
                "name": "Occidental Mindoro"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1357,
                "iso2": "ZAS",
                "name": "Zamboanga del Sur"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1358,
                "iso2": "NUV",
                "name": "Nueva Vizcaya"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1359,
                "iso2": "BTG",
                "name": "Batangas"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1360,
                "iso2": "NUE",
                "name": "Nueva Ecija"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1361,
                "iso2": "PLW",
                "name": "Palawan"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1362,
                "iso2": "ZSI",
                "name": "Zamboanga Sibugay"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1363,
                "iso2": "BEN",
                "name": "Benguet"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1364,
                "iso2": "PAN",
                "name": "Pangasinan"
            },
            {
                "country_code": "PH",
                "country_id": 174,
                "id": 1365,
                "iso2": "PAM",
                "name": "Pampanga"
            }
        ]
    },
    {
        "id": 175,
        "name": "Pitcairn Island",
        "iso3": "PCN",
        "iso2": "PN",
        "states": []
    },
    {
        "id": 176,
        "name": "Poland",
        "iso3": "POL",
        "iso2": "PL",
        "states": [
            {
                "country_code": "PL",
                "country_id": 176,
                "id": 1622,
                "iso2": "OP",
                "name": "Opole Voivodeship"
            },
            {
                "country_code": "PL",
                "country_id": 176,
                "id": 1623,
                "iso2": "SL",
                "name": "Silesian Voivodeship"
            },
            {
                "country_code": "PL",
                "country_id": 176,
                "id": 1624,
                "iso2": "PM",
                "name": "Pomeranian Voivodeship"
            },
            {
                "country_code": "PL",
                "country_id": 176,
                "id": 1625,
                "iso2": "KP",
                "name": "Kuyavian-Pomeranian Voivodeship"
            },
            {
                "country_code": "PL",
                "country_id": 176,
                "id": 1626,
                "iso2": "PK",
                "name": "Podkarpackie Voivodeship"
            },
            {
                "country_code": "PL",
                "country_id": 176,
                "id": 1627,
                "iso2": "KI",
                "name": "Kielce"
            },
            {
                "country_code": "PL",
                "country_id": 176,
                "id": 1628,
                "iso2": "WN",
                "name": "Warmian-Masurian Voivodeship"
            },
            {
                "country_code": "PL",
                "country_id": 176,
                "id": 1629,
                "iso2": "DS",
                "name": "Lower Silesian Voivodeship"
            },
            {
                "country_code": "PL",
                "country_id": 176,
                "id": 1630,
                "iso2": "SK",
                "name": "Świętokrzyskie Voivodeship"
            },
            {
                "country_code": "PL",
                "country_id": 176,
                "id": 1631,
                "iso2": "LB",
                "name": "Lubusz Voivodeship"
            },
            {
                "country_code": "PL",
                "country_id": 176,
                "id": 1632,
                "iso2": "PD",
                "name": "Podlaskie Voivodeship"
            },
            {
                "country_code": "PL",
                "country_id": 176,
                "id": 1633,
                "iso2": "ZP",
                "name": "West Pomeranian Voivodeship"
            },
            {
                "country_code": "PL",
                "country_id": 176,
                "id": 1634,
                "iso2": "WP",
                "name": "Greater Poland Voivodeship"
            },
            {
                "country_code": "PL",
                "country_id": 176,
                "id": 1635,
                "iso2": "MA",
                "name": "Lesser Poland Voivodeship"
            },
            {
                "country_code": "PL",
                "country_id": 176,
                "id": 1636,
                "iso2": "LD",
                "name": "Łódź Voivodeship"
            },
            {
                "country_code": "PL",
                "country_id": 176,
                "id": 1637,
                "iso2": "MZ",
                "name": "Masovian Voivodeship"
            },
            {
                "country_code": "PL",
                "country_id": 176,
                "id": 1638,
                "iso2": "LU",
                "name": "Lublin Voivodeship"
            }
        ]
    },
    {
        "id": 177,
        "name": "Portugal",
        "iso3": "PRT",
        "iso2": "PT",
        "states": [
            {
                "country_code": "PT",
                "country_id": 177,
                "id": 2228,
                "iso2": "11",
                "name": "Lisbon District"
            },
            {
                "country_code": "PT",
                "country_id": 177,
                "id": 2229,
                "iso2": "4",
                "name": "Bragança District"
            },
            {
                "country_code": "PT",
                "country_id": 177,
                "id": 2230,
                "iso2": "2",
                "name": "Beja District"
            },
            {
                "country_code": "PT",
                "country_id": 177,
                "id": 2231,
                "iso2": "30",
                "name": "Madeira"
            },
            {
                "country_code": "PT",
                "country_id": 177,
                "id": 2232,
                "iso2": "12",
                "name": "Portalegre District"
            },
            {
                "country_code": "PT",
                "country_id": 177,
                "id": 2233,
                "iso2": "20",
                "name": "Azores"
            },
            {
                "country_code": "PT",
                "country_id": 177,
                "id": 2234,
                "iso2": "17",
                "name": "Vila Real District"
            },
            {
                "country_code": "PT",
                "country_id": 177,
                "id": 2235,
                "iso2": "1",
                "name": "Aveiro District"
            },
            {
                "country_code": "PT",
                "country_id": 177,
                "id": 2236,
                "iso2": "7",
                "name": "Évora District"
            },
            {
                "country_code": "PT",
                "country_id": 177,
                "id": 2237,
                "iso2": "18",
                "name": "Viseu District"
            },
            {
                "country_code": "PT",
                "country_id": 177,
                "id": 2238,
                "iso2": "14",
                "name": "Santarém District"
            },
            {
                "country_code": "PT",
                "country_id": 177,
                "id": 2239,
                "iso2": "8",
                "name": "Faro District"
            },
            {
                "country_code": "PT",
                "country_id": 177,
                "id": 2240,
                "iso2": "10",
                "name": "Leiria District"
            },
            {
                "country_code": "PT",
                "country_id": 177,
                "id": 2241,
                "iso2": "5",
                "name": "Castelo Branco District"
            },
            {
                "country_code": "PT",
                "country_id": 177,
                "id": 2242,
                "iso2": "15",
                "name": "Setúbal District"
            },
            {
                "country_code": "PT",
                "country_id": 177,
                "id": 2243,
                "iso2": "13",
                "name": "Porto District"
            },
            {
                "country_code": "PT",
                "country_id": 177,
                "id": 2244,
                "iso2": "3",
                "name": "Braga District"
            },
            {
                "country_code": "PT",
                "country_id": 177,
                "id": 2245,
                "iso2": "16",
                "name": "Viana do Castelo District"
            },
            {
                "country_code": "PT",
                "country_id": 177,
                "id": 2246,
                "iso2": "6",
                "name": "Coimbra District"
            },
            {
                "country_code": "PT",
                "country_id": 177,
                "id": 4859,
                "iso2": "9",
                "name": "Guarda District"
            }
        ]
    },
    {
        "id": 178,
        "name": "Puerto Rico",
        "iso3": "PRI",
        "iso2": "PR",
        "states": []
    },
    {
        "id": 179,
        "name": "Qatar",
        "iso3": "QAT",
        "iso2": "QA",
        "states": [
            {
                "country_code": "QA",
                "country_id": 179,
                "id": 3177,
                "iso2": "RA",
                "name": "Al Rayyan Municipality"
            },
            {
                "country_code": "QA",
                "country_id": 179,
                "id": 3178,
                "iso2": "SH",
                "name": "Al-Shahaniya"
            },
            {
                "country_code": "QA",
                "country_id": 179,
                "id": 3179,
                "iso2": "WA",
                "name": "Al Wakrah"
            },
            {
                "country_code": "QA",
                "country_id": 179,
                "id": 3180,
                "iso2": "MS",
                "name": "Madinat ash Shamal"
            },
            {
                "country_code": "QA",
                "country_id": 179,
                "id": 3181,
                "iso2": "DA",
                "name": "Doha"
            },
            {
                "country_code": "QA",
                "country_id": 179,
                "id": 3182,
                "iso2": "ZA",
                "name": "Al Daayen"
            },
            {
                "country_code": "QA",
                "country_id": 179,
                "id": 3183,
                "iso2": "KH",
                "name": "Al Khor"
            },
            {
                "country_code": "QA",
                "country_id": 179,
                "id": 3184,
                "iso2": "US",
                "name": "Umm Salal Municipality"
            }
        ]
    },
    {
        "id": 180,
        "name": "Reunion",
        "iso3": "REU",
        "iso2": "RE",
        "states": []
    },
    {
        "id": 181,
        "name": "Romania",
        "iso3": "ROU",
        "iso2": "RO",
        "states": [
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4720,
                "iso2": "SV",
                "name": "Suceava County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4721,
                "iso2": "HD",
                "name": "Hunedoara County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4722,
                "iso2": "AG",
                "name": "Arges"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4723,
                "iso2": "BH",
                "name": "Bihor County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4724,
                "iso2": "AB",
                "name": "Alba"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4725,
                "iso2": "IF",
                "name": "Ilfov County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4726,
                "iso2": "GR",
                "name": "Giurgiu County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4727,
                "iso2": "TL",
                "name": "Tulcea County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4728,
                "iso2": "TR",
                "name": "Teleorman County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4729,
                "iso2": "PH",
                "name": "Prahova County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4730,
                "iso2": "B",
                "name": "Bucharest"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4731,
                "iso2": "NT",
                "name": "Neamț County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4732,
                "iso2": "CL",
                "name": "Călărași County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4733,
                "iso2": "BN",
                "name": "Bistrița-Năsăud County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4734,
                "iso2": "CJ",
                "name": "Cluj County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4735,
                "iso2": "IS",
                "name": "Iași County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4736,
                "iso2": "BR",
                "name": "Braila"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4737,
                "iso2": "CT",
                "name": "Constanța County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4738,
                "iso2": "OT",
                "name": "Olt County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4739,
                "iso2": "AR",
                "name": "Arad County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4740,
                "iso2": "BT",
                "name": "Botoșani County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4741,
                "iso2": "SJ",
                "name": "Sălaj County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4742,
                "iso2": "DJ",
                "name": "Dolj County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4743,
                "iso2": "IL",
                "name": "Ialomița County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4744,
                "iso2": "BC",
                "name": "Bacău County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4745,
                "iso2": "DB",
                "name": "Dâmbovița County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4746,
                "iso2": "SM",
                "name": "Satu Mare County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4747,
                "iso2": "GL",
                "name": "Galați County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4748,
                "iso2": "TM",
                "name": "Timiș County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4749,
                "iso2": "HR",
                "name": "Harghita County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4750,
                "iso2": "GJ",
                "name": "Gorj County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4751,
                "iso2": "MH",
                "name": "Mehedinți County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4752,
                "iso2": "VS",
                "name": "Vaslui County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4753,
                "iso2": "CS",
                "name": "Caraș-Severin County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4754,
                "iso2": "CV",
                "name": "Covasna County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4755,
                "iso2": "SB",
                "name": "Sibiu County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4756,
                "iso2": "BZ",
                "name": "Buzău County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4757,
                "iso2": "VL",
                "name": "Vâlcea County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4758,
                "iso2": "VN",
                "name": "Vrancea County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4759,
                "iso2": "BV",
                "name": "Brașov County"
            },
            {
                "country_code": "RO",
                "country_id": 181,
                "id": 4760,
                "iso2": "MM",
                "name": "Mureș County"
            }
        ]
    },
    {
        "id": 182,
        "name": "Russia",
        "iso3": "RUS",
        "iso2": "RU",
        "states": [
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1833,
                "iso2": "PRI",
                "name": "Primorsky Krai"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1834,
                "iso2": "NGR",
                "name": "Novgorod Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1835,
                "iso2": "YEV",
                "name": "Jewish Autonomous Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1836,
                "iso2": "NEN",
                "name": "Nenets Autonomous Okrug"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1837,
                "iso2": "ROS",
                "name": "Rostov Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1838,
                "iso2": "KHM",
                "name": "Khanty-Mansi Autonomous Okrug"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1839,
                "iso2": "MAG",
                "name": "Magadan Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1840,
                "iso2": "KYA",
                "name": "Krasnoyarsk Krai"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1841,
                "iso2": "KR",
                "name": "Republic of Karelia"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1842,
                "iso2": "BU",
                "name": "Republic of Buryatia"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1843,
                "iso2": "MUR",
                "name": "Murmansk Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1844,
                "iso2": "KLU",
                "name": "Kaluga Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1845,
                "iso2": "CHE",
                "name": "Chelyabinsk Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1846,
                "iso2": "OMS",
                "name": "Omsk Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1847,
                "iso2": "YAN",
                "name": "Yamalo-Nenets Autonomous Okrug"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1848,
                "iso2": "SA",
                "name": "Sakha Republic"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1849,
                "iso2": "ARK",
                "name": "Arkhangelsk"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1850,
                "iso2": "DA",
                "name": "Republic of Dagestan"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1851,
                "iso2": "YAR",
                "name": "Yaroslavl Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1852,
                "iso2": "AD",
                "name": "Republic of Adygea"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1853,
                "iso2": "SE",
                "name": "Republic of North Ossetia-Alania"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1854,
                "iso2": "BA",
                "name": "Republic of Bashkortostan"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1855,
                "iso2": "KRS",
                "name": "Kursk Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1856,
                "iso2": "ULY",
                "name": "Ulyanovsk Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1857,
                "iso2": "NIZ",
                "name": "Nizhny Novgorod Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1858,
                "iso2": "AMU",
                "name": "Amur Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1859,
                "iso2": "CHU",
                "name": "Chukotka Autonomous Okrug"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1860,
                "iso2": "TVE",
                "name": "Tver Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1861,
                "iso2": "TA",
                "name": "Republic of Tatarstan"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1862,
                "iso2": "SAM",
                "name": "Samara Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1863,
                "iso2": "PSK",
                "name": "Pskov Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1864,
                "iso2": "IVA",
                "name": "Ivanovo Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1865,
                "iso2": "KAM",
                "name": "Kamchatka Krai"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1866,
                "iso2": "AST",
                "name": "Astrakhan Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1867,
                "iso2": "BRY",
                "name": "Bryansk Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1868,
                "iso2": "STA",
                "name": "Stavropol Krai"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1869,
                "iso2": "KC",
                "name": "Karachay-Cherkess Republic"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1870,
                "iso2": "ME",
                "name": "Mari El Republic"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1871,
                "iso2": "PER",
                "name": "Perm Krai"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1872,
                "iso2": "TOM",
                "name": "Tomsk Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1873,
                "iso2": "KHA",
                "name": "Khabarovsk Krai"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1874,
                "iso2": "VLG",
                "name": "Vologda Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1875,
                "iso2": "SAK",
                "name": "Sakhalin"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1876,
                "iso2": "AL",
                "name": "Altai Republic"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1877,
                "iso2": "KK",
                "name": "Republic of Khakassia"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1878,
                "iso2": "TAM",
                "name": "Tambov Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1879,
                "iso2": "SPE",
                "name": "Saint Petersburg"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1880,
                "iso2": "IRK",
                "name": "Irkutsk"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1881,
                "iso2": "VLA",
                "name": "Vladimir Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1882,
                "iso2": "MOS",
                "name": "Moscow Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1883,
                "iso2": "KL",
                "name": "Republic of Kalmykia"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1884,
                "iso2": "IN",
                "name": "Republic of Ingushetia"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1885,
                "iso2": "SMO",
                "name": "Smolensk Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1886,
                "iso2": "ORE",
                "name": "Orenburg Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1887,
                "iso2": "SAR",
                "name": "Saratov Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1888,
                "iso2": "NVS",
                "name": "Novosibirsk"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1889,
                "iso2": "LIP",
                "name": "Lipetsk Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1890,
                "iso2": "KIR",
                "name": "Kirov Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1891,
                "iso2": "KDA",
                "name": "Krasnodar Krai"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1892,
                "iso2": "KB",
                "name": "Kabardino-Balkar Republic"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1893,
                "iso2": "CE",
                "name": "Chechen Republic"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1894,
                "iso2": "SVE",
                "name": "Sverdlovsk"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1895,
                "iso2": "TUL",
                "name": "Tula Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1896,
                "iso2": "LEN",
                "name": "Leningrad Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1897,
                "iso2": "KEM",
                "name": "Kemerovo Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1898,
                "iso2": "MO",
                "name": "Republic of Mordovia"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1899,
                "iso2": "KO",
                "name": "Komi Republic"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1900,
                "iso2": "TY",
                "name": "Tuva Republic"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1901,
                "iso2": "MOW",
                "name": "Moscow"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1902,
                "iso2": "KGD",
                "name": "Kaliningrad"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1903,
                "iso2": "BEL",
                "name": "Belgorod Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1904,
                "iso2": "ZAB",
                "name": "Zabaykalsky Krai"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1905,
                "iso2": "RYA",
                "name": "Ryazan Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1906,
                "iso2": "VOR",
                "name": "Voronezh Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1907,
                "iso2": "TYU",
                "name": "Tyumen Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1908,
                "iso2": "ORL",
                "name": "Oryol Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1909,
                "iso2": "PNZ",
                "name": "Penza Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1910,
                "iso2": "KOS",
                "name": "Kostroma Oblast"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1911,
                "iso2": "ALT",
                "name": "Altai Krai"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1912,
                "iso2": "UA-40",
                "name": "Sevastopol"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1913,
                "iso2": "UD",
                "name": "Udmurt Republic"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1914,
                "iso2": "CU",
                "name": "Chuvash Republic"
            },
            {
                "country_code": "RU",
                "country_id": 182,
                "id": 1915,
                "iso2": "KGN",
                "name": "Kurgan Oblast"
            }
        ]
    },
    {
        "id": 183,
        "name": "Rwanda",
        "iso3": "RWA",
        "iso2": "RW",
        "states": [
            {
                "country_code": "RW",
                "country_id": 183,
                "id": 259,
                "iso2": "5",
                "name": "Southern Province"
            },
            {
                "country_code": "RW",
                "country_id": 183,
                "id": 260,
                "iso2": "4",
                "name": "Western Province"
            },
            {
                "country_code": "RW",
                "country_id": 183,
                "id": 261,
                "iso2": "2",
                "name": "Eastern Province"
            },
            {
                "country_code": "RW",
                "country_id": 183,
                "id": 262,
                "iso2": "1",
                "name": "Kigali district"
            },
            {
                "country_code": "RW",
                "country_id": 183,
                "id": 263,
                "iso2": "3",
                "name": "Northern Province"
            }
        ]
    },
    {
        "id": 184,
        "name": "Saint Helena",
        "iso3": "SHN",
        "iso2": "SH",
        "states": []
    },
    {
        "id": 185,
        "name": "Saint Kitts And Nevis",
        "iso3": "KNA",
        "iso2": "KN",
        "states": [
            {
                "country_code": "KN",
                "country_id": 185,
                "id": 3831,
                "iso2": "11",
                "name": "Saint Peter Basseterre Parish"
            },
            {
                "country_code": "KN",
                "country_id": 185,
                "id": 3832,
                "iso2": "N",
                "name": "Nevis"
            },
            {
                "country_code": "KN",
                "country_id": 185,
                "id": 3833,
                "iso2": "1",
                "name": "Christ Church Nichola Town Parish"
            },
            {
                "country_code": "KN",
                "country_id": 185,
                "id": 3834,
                "iso2": "9",
                "name": "Saint Paul Capisterre Parish"
            },
            {
                "country_code": "KN",
                "country_id": 185,
                "id": 3835,
                "iso2": "5",
                "name": "Saint James Windward Parish"
            },
            {
                "country_code": "KN",
                "country_id": 185,
                "id": 3836,
                "iso2": "2",
                "name": "Saint Anne Sandy Point Parish"
            },
            {
                "country_code": "KN",
                "country_id": 185,
                "id": 3837,
                "iso2": "4",
                "name": "Saint George Gingerland Parish"
            },
            {
                "country_code": "KN",
                "country_id": 185,
                "id": 3838,
                "iso2": "10",
                "name": "Saint Paul Charlestown Parish"
            },
            {
                "country_code": "KN",
                "country_id": 185,
                "id": 3839,
                "iso2": "12",
                "name": "Saint Thomas Lowland Parish"
            },
            {
                "country_code": "KN",
                "country_id": 185,
                "id": 3840,
                "iso2": "7",
                "name": "Saint John Figtree Parish"
            },
            {
                "country_code": "KN",
                "country_id": 185,
                "id": 3841,
                "iso2": "K",
                "name": "Saint Kitts"
            },
            {
                "country_code": "KN",
                "country_id": 185,
                "id": 3842,
                "iso2": "13",
                "name": "Saint Thomas Middle Island Parish"
            },
            {
                "country_code": "KN",
                "country_id": 185,
                "id": 3843,
                "iso2": "15",
                "name": "Trinity Palmetto Point Parish"
            },
            {
                "country_code": "KN",
                "country_id": 185,
                "id": 3844,
                "iso2": "8",
                "name": "Saint Mary Cayon Parish"
            },
            {
                "country_code": "KN",
                "country_id": 185,
                "id": 3845,
                "iso2": "6",
                "name": "Saint John Capisterre Parish"
            }
        ]
    },
    {
        "id": 186,
        "name": "Saint Lucia",
        "iso3": "LCA",
        "iso2": "LC",
        "states": [
            {
                "country_code": "LC",
                "country_id": 186,
                "id": 3756,
                "iso2": "5",
                "name": "Dennery Quarter"
            },
            {
                "country_code": "LC",
                "country_id": 186,
                "id": 3757,
                "iso2": "1",
                "name": "Anse la Raye Quarter"
            },
            {
                "country_code": "LC",
                "country_id": 186,
                "id": 3758,
                "iso2": "2",
                "name": "Castries Quarter"
            },
            {
                "country_code": "LC",
                "country_id": 186,
                "id": 3759,
                "iso2": "7",
                "name": "Laborie Quarter"
            },
            {
                "country_code": "LC",
                "country_id": 186,
                "id": 3760,
                "iso2": "3",
                "name": "Choiseul Quarter"
            },
            {
                "country_code": "LC",
                "country_id": 186,
                "id": 3761,
                "iso2": "12",
                "name": "Canaries"
            },
            {
                "country_code": "LC",
                "country_id": 186,
                "id": 3762,
                "iso2": "8",
                "name": "Micoud Quarter"
            },
            {
                "country_code": "LC",
                "country_id": 186,
                "id": 3763,
                "iso2": "11",
                "name": "Vieux Fort Quarter"
            },
            {
                "country_code": "LC",
                "country_id": 186,
                "id": 3764,
                "iso2": "10",
                "name": "Soufrière Quarter"
            },
            {
                "country_code": "LC",
                "country_id": 186,
                "id": 3765,
                "iso2": "9",
                "name": "Praslin Quarter"
            },
            {
                "country_code": "LC",
                "country_id": 186,
                "id": 3766,
                "iso2": "6",
                "name": "Gros Islet Quarter"
            },
            {
                "country_code": "LC",
                "country_id": 186,
                "id": 3767,
                "iso2": "4",
                "name": "Dauphin Quarter"
            }
        ]
    },
    {
        "id": 187,
        "name": "Saint Pierre and Miquelon",
        "iso3": "SPM",
        "iso2": "PM",
        "states": []
    },
    {
        "id": 188,
        "name": "Saint Vincent And The Grenadines",
        "iso3": "VCT",
        "iso2": "VC",
        "states": [
            {
                "country_code": "VC",
                "country_id": 188,
                "id": 3384,
                "iso2": "4",
                "name": "Saint George Parish"
            },
            {
                "country_code": "VC",
                "country_id": 188,
                "id": 3385,
                "iso2": "5",
                "name": "Saint Patrick Parish"
            },
            {
                "country_code": "VC",
                "country_id": 188,
                "id": 3386,
                "iso2": "2",
                "name": "Saint Andrew Parish"
            },
            {
                "country_code": "VC",
                "country_id": 188,
                "id": 3387,
                "iso2": "3",
                "name": "Saint David Parish"
            },
            {
                "country_code": "VC",
                "country_id": 188,
                "id": 3388,
                "iso2": "6",
                "name": "Grenadines Parish"
            },
            {
                "country_code": "VC",
                "country_id": 188,
                "id": 3389,
                "iso2": "1",
                "name": "Charlotte Parish"
            }
        ]
    },
    {
        "id": 189,
        "name": "Saint-Barthelemy",
        "iso3": "BLM",
        "iso2": "BL",
        "states": []
    },
    {
        "id": 190,
        "name": "Saint-Martin (French part)",
        "iso3": "MAF",
        "iso2": "MF",
        "states": []
    },
    {
        "id": 191,
        "name": "Samoa",
        "iso3": "WSM",
        "iso2": "WS",
        "states": [
            {
                "country_code": "WS",
                "country_id": 191,
                "id": 4761,
                "iso2": "AL",
                "name": "Aiga-i-le-Tai"
            },
            {
                "country_code": "WS",
                "country_id": 191,
                "id": 4762,
                "iso2": "SA",
                "name": "Satupa'itea"
            },
            {
                "country_code": "WS",
                "country_id": 191,
                "id": 4763,
                "iso2": "AA",
                "name": "A'ana"
            },
            {
                "country_code": "WS",
                "country_id": 191,
                "id": 4764,
                "iso2": "FA",
                "name": "Fa'asaleleaga"
            },
            {
                "country_code": "WS",
                "country_id": 191,
                "id": 4765,
                "iso2": "AT",
                "name": "Atua"
            },
            {
                "country_code": "WS",
                "country_id": 191,
                "id": 4766,
                "iso2": "VS",
                "name": "Vaisigano"
            },
            {
                "country_code": "WS",
                "country_id": 191,
                "id": 4767,
                "iso2": "PA",
                "name": "Palauli"
            },
            {
                "country_code": "WS",
                "country_id": 191,
                "id": 4768,
                "iso2": "VF",
                "name": "Va'a-o-Fonoti"
            },
            {
                "country_code": "WS",
                "country_id": 191,
                "id": 4769,
                "iso2": "GE",
                "name": "Gaga'emauga"
            },
            {
                "country_code": "WS",
                "country_id": 191,
                "id": 4770,
                "iso2": "TU",
                "name": "Tuamasaga"
            },
            {
                "country_code": "WS",
                "country_id": 191,
                "id": 4771,
                "iso2": "GI",
                "name": "Gaga'ifomauga"
            }
        ]
    },
    {
        "id": 192,
        "name": "San Marino",
        "iso3": "SMR",
        "iso2": "SM",
        "states": [
            {
                "country_code": "SM",
                "country_id": 192,
                "id": 58,
                "iso2": "7",
                "name": "San Marino"
            },
            {
                "country_code": "SM",
                "country_id": 192,
                "id": 59,
                "iso2": "1",
                "name": "Acquaviva"
            },
            {
                "country_code": "SM",
                "country_id": 192,
                "id": 60,
                "iso2": "2",
                "name": "Chiesanuova"
            },
            {
                "country_code": "SM",
                "country_id": 192,
                "id": 61,
                "iso2": "6",
                "name": "Borgo Maggiore"
            },
            {
                "country_code": "SM",
                "country_id": 192,
                "id": 62,
                "iso2": "4",
                "name": "Faetano"
            },
            {
                "country_code": "SM",
                "country_id": 192,
                "id": 63,
                "iso2": "8",
                "name": "Montegiardino"
            },
            {
                "country_code": "SM",
                "country_id": 192,
                "id": 64,
                "iso2": "3",
                "name": "Domagnano"
            },
            {
                "country_code": "SM",
                "country_id": 192,
                "id": 65,
                "iso2": "9",
                "name": "Serravalle"
            },
            {
                "country_code": "SM",
                "country_id": 192,
                "id": 66,
                "iso2": "5",
                "name": "Fiorentino"
            }
        ]
    },
    {
        "id": 193,
        "name": "Sao Tome and Principe",
        "iso3": "STP",
        "iso2": "ST",
        "states": [
            {
                "country_code": "ST",
                "country_id": 193,
                "id": 270,
                "iso2": "P",
                "name": "Príncipe Province"
            },
            {
                "country_code": "ST",
                "country_id": 193,
                "id": 271,
                "iso2": "S",
                "name": "São Tomé Province"
            }
        ]
    },
    {
        "id": 194,
        "name": "Saudi Arabia",
        "iso3": "SAU",
        "iso2": "SA",
        "states": [
            {
                "country_code": "SA",
                "country_id": 194,
                "id": 2849,
                "iso2": "1",
                "name": "Riyadh Region"
            },
            {
                "country_code": "SA",
                "country_id": 194,
                "id": 2850,
                "iso2": "2",
                "name": "Makkah Region"
            },
            {
                "country_code": "SA",
                "country_id": 194,
                "id": 2851,
                "iso2": "3",
                "name": "Al Madinah Region"
            },
            {
                "country_code": "SA",
                "country_id": 194,
                "id": 2852,
                "iso2": "7",
                "name": "Tabuk Region"
            },
            {
                "country_code": "SA",
                "country_id": 194,
                "id": 2853,
                "iso2": "14",
                "name": "'Asir Region"
            },
            {
                "country_code": "SA",
                "country_id": 194,
                "id": 2854,
                "iso2": "8",
                "name": "Northern Borders Region"
            },
            {
                "country_code": "SA",
                "country_id": 194,
                "id": 2855,
                "iso2": "6",
                "name": "Ha'il Region"
            },
            {
                "country_code": "SA",
                "country_id": 194,
                "id": 2856,
                "iso2": "4",
                "name": "Eastern Province"
            },
            {
                "country_code": "SA",
                "country_id": 194,
                "id": 2857,
                "iso2": "12",
                "name": "Al Jawf Region"
            },
            {
                "country_code": "SA",
                "country_id": 194,
                "id": 2858,
                "iso2": "9",
                "name": "Jizan Region"
            },
            {
                "country_code": "SA",
                "country_id": 194,
                "id": 2859,
                "iso2": "11",
                "name": "Al Bahah Region"
            },
            {
                "country_code": "SA",
                "country_id": 194,
                "id": 2860,
                "iso2": "10",
                "name": "Najran Region"
            },
            {
                "country_code": "SA",
                "country_id": 194,
                "id": 2861,
                "iso2": "5",
                "name": "Al-Qassim Region"
            }
        ]
    },
    {
        "id": 195,
        "name": "Senegal",
        "iso3": "SEN",
        "iso2": "SN",
        "states": [
            {
                "country_code": "SN",
                "country_id": 195,
                "id": 473,
                "iso2": "DK",
                "name": "Dakar"
            },
            {
                "country_code": "SN",
                "country_id": 195,
                "id": 474,
                "iso2": "KD",
                "name": "Kolda"
            },
            {
                "country_code": "SN",
                "country_id": 195,
                "id": 475,
                "iso2": "KA",
                "name": "Kaffrine"
            },
            {
                "country_code": "SN",
                "country_id": 195,
                "id": 476,
                "iso2": "MT",
                "name": "Matam"
            },
            {
                "country_code": "SN",
                "country_id": 195,
                "id": 477,
                "iso2": "SL",
                "name": "Saint-Louis"
            },
            {
                "country_code": "SN",
                "country_id": 195,
                "id": 478,
                "iso2": "ZG",
                "name": "Ziguinchor"
            },
            {
                "country_code": "SN",
                "country_id": 195,
                "id": 479,
                "iso2": "FK",
                "name": "Fatick"
            },
            {
                "country_code": "SN",
                "country_id": 195,
                "id": 480,
                "iso2": "DB",
                "name": "Diourbel Region"
            },
            {
                "country_code": "SN",
                "country_id": 195,
                "id": 481,
                "iso2": "KE",
                "name": "Kédougou"
            },
            {
                "country_code": "SN",
                "country_id": 195,
                "id": 482,
                "iso2": "SE",
                "name": "Sédhiou"
            },
            {
                "country_code": "SN",
                "country_id": 195,
                "id": 483,
                "iso2": "KL",
                "name": "Kaolack"
            },
            {
                "country_code": "SN",
                "country_id": 195,
                "id": 484,
                "iso2": "TH",
                "name": "Thiès Region"
            },
            {
                "country_code": "SN",
                "country_id": 195,
                "id": 485,
                "iso2": "LG",
                "name": "Louga"
            },
            {
                "country_code": "SN",
                "country_id": 195,
                "id": 486,
                "iso2": "TC",
                "name": "Tambacounda Region"
            }
        ]
    },
    {
        "id": 196,
        "name": "Serbia",
        "iso3": "SRB",
        "iso2": "RS",
        "states": [
            {
                "country_code": "RS",
                "country_id": 196,
                "id": 3711,
                "iso2": "6",
                "name": "South Bačka District"
            },
            {
                "country_code": "RS",
                "country_id": 196,
                "id": 3712,
                "iso2": "22",
                "name": "Pirot District"
            },
            {
                "country_code": "RS",
                "country_id": 196,
                "id": 3713,
                "iso2": "4",
                "name": "South Banat District"
            },
            {
                "country_code": "RS",
                "country_id": 196,
                "id": 3714,
                "iso2": "1",
                "name": "North Bačka District"
            },
            {
                "country_code": "RS",
                "country_id": 196,
                "id": 3715,
                "iso2": "23",
                "name": "Jablanica District"
            },
            {
                "country_code": "RS",
                "country_id": 196,
                "id": 3716,
                "iso2": "2",
                "name": "Central Banat District"
            },
            {
                "country_code": "RS",
                "country_id": 196,
                "id": 3717,
                "iso2": "14",
                "name": "Bor District"
            },
            {
                "country_code": "RS",
                "country_id": 196,
                "id": 3718,
                "iso2": "21",
                "name": "Toplica District"
            },
            {
                "country_code": "RS",
                "country_id": 196,
                "id": 3719,
                "iso2": "8",
                "name": "Mačva District"
            },
            {
                "country_code": "RS",
                "country_id": 196,
                "id": 3720,
                "iso2": "19",
                "name": "Rasina District"
            },
            {
                "country_code": "RS",
                "country_id": 196,
                "id": 3721,
                "iso2": "24",
                "name": "Pčinja District"
            },
            {
                "country_code": "RS",
                "country_id": 196,
                "id": 3722,
                "iso2": "20",
                "name": "Nišava District"
            },
            {
                "country_code": "RS",
                "country_id": 196,
                "id": 3724,
                "iso2": "9",
                "name": "Kolubara District"
            },
            {
                "country_code": "RS",
                "country_id": 196,
                "id": 3725,
                "iso2": "18",
                "name": "Raška District"
            },
            {
                "country_code": "RS",
                "country_id": 196,
                "id": 3726,
                "iso2": "5",
                "name": "West Bačka District"
            },
            {
                "country_code": "RS",
                "country_id": 196,
                "id": 3727,
                "iso2": "17",
                "name": "Moravica District"
            },
            {
                "country_code": "RS",
                "country_id": 196,
                "id": 3728,
                "iso2": "0",
                "name": "Belgrade"
            },
            {
                "country_code": "RS",
                "country_id": 196,
                "id": 3729,
                "iso2": "16",
                "name": "Zlatibor District"
            },
            {
                "country_code": "RS",
                "country_id": 196,
                "id": 3731,
                "iso2": "15",
                "name": "Zaječar District"
            },
            {
                "country_code": "RS",
                "country_id": 196,
                "id": 3732,
                "iso2": "11",
                "name": "Braničevo District"
            },
            {
                "country_code": "RS",
                "country_id": 196,
                "id": 3733,
                "iso2": "VO",
                "name": "Vojvodina"
            },
            {
                "country_code": "RS",
                "country_id": 196,
                "id": 3734,
                "iso2": "12",
                "name": "Šumadija District"
            },
            {
                "country_code": "RS",
                "country_id": 196,
                "id": 3736,
                "iso2": "3",
                "name": "North Banat District"
            },
            {
                "country_code": "RS",
                "country_id": 196,
                "id": 3737,
                "iso2": "13",
                "name": "Pomoravlje District"
            },
            {
                "country_code": "RS",
                "country_id": 196,
                "id": 3740,
                "iso2": "7",
                "name": "Srem District"
            },
            {
                "country_code": "RS",
                "country_id": 196,
                "id": 3741,
                "iso2": "10",
                "name": "Podunavlje District"
            }
        ]
    },
    {
        "id": 197,
        "name": "Seychelles",
        "iso3": "SYC",
        "iso2": "SC",
        "states": [
            {
                "country_code": "SC",
                "country_id": 197,
                "id": 494,
                "iso2": "17",
                "name": "Mont Buxton"
            },
            {
                "country_code": "SC",
                "country_id": 197,
                "id": 495,
                "iso2": "15",
                "name": "La Digue"
            },
            {
                "country_code": "SC",
                "country_id": 197,
                "id": 496,
                "iso2": "22",
                "name": "Saint Louis"
            },
            {
                "country_code": "SC",
                "country_id": 197,
                "id": 497,
                "iso2": "6",
                "name": "Baie Lazare"
            },
            {
                "country_code": "SC",
                "country_id": 197,
                "id": 498,
                "iso2": "18",
                "name": "Mont Fleuri"
            },
            {
                "country_code": "SC",
                "country_id": 197,
                "id": 499,
                "iso2": "24",
                "name": "Les Mamelles"
            },
            {
                "country_code": "SC",
                "country_id": 197,
                "id": 500,
                "iso2": "13",
                "name": "Grand'Anse Mahé"
            },
            {
                "country_code": "SC",
                "country_id": 197,
                "id": 501,
                "iso2": "25",
                "name": "Roche Caiman"
            },
            {
                "country_code": "SC",
                "country_id": 197,
                "id": 502,
                "iso2": "5",
                "name": "Anse Royale"
            },
            {
                "country_code": "SC",
                "country_id": 197,
                "id": 503,
                "iso2": "12",
                "name": "Glacis"
            },
            {
                "country_code": "SC",
                "country_id": 197,
                "id": 504,
                "iso2": "14",
                "name": "Grand'Anse Praslin"
            },
            {
                "country_code": "SC",
                "country_id": 197,
                "id": 505,
                "iso2": "10",
                "name": "Bel Ombre"
            },
            {
                "country_code": "SC",
                "country_id": 197,
                "id": 506,
                "iso2": "1",
                "name": "Anse-aux-Pins"
            },
            {
                "country_code": "SC",
                "country_id": 197,
                "id": 507,
                "iso2": "21",
                "name": "Port Glaud"
            },
            {
                "country_code": "SC",
                "country_id": 197,
                "id": 508,
                "iso2": "4",
                "name": "Au Cap"
            },
            {
                "country_code": "SC",
                "country_id": 197,
                "id": 509,
                "iso2": "23",
                "name": "Takamaka"
            },
            {
                "country_code": "SC",
                "country_id": 197,
                "id": 510,
                "iso2": "20",
                "name": "Pointe La Rue"
            },
            {
                "country_code": "SC",
                "country_id": 197,
                "id": 511,
                "iso2": "19",
                "name": "Plaisance"
            },
            {
                "country_code": "SC",
                "country_id": 197,
                "id": 512,
                "iso2": "8",
                "name": "Beau Vallon"
            },
            {
                "country_code": "SC",
                "country_id": 197,
                "id": 513,
                "iso2": "2",
                "name": "Anse Boileau"
            },
            {
                "country_code": "SC",
                "country_id": 197,
                "id": 514,
                "iso2": "7",
                "name": "Baie Sainte Anne"
            },
            {
                "country_code": "SC",
                "country_id": 197,
                "id": 515,
                "iso2": "9",
                "name": "Bel Air"
            },
            {
                "country_code": "SC",
                "country_id": 197,
                "id": 516,
                "iso2": "16",
                "name": "La Rivière Anglaise"
            },
            {
                "country_code": "SC",
                "country_id": 197,
                "id": 517,
                "iso2": "11",
                "name": "Cascade"
            }
        ]
    },
    {
        "id": 198,
        "name": "Sierra Leone",
        "iso3": "SLE",
        "iso2": "SL",
        "states": [
            {
                "country_code": "SL",
                "country_id": 198,
                "id": 911,
                "iso2": "N",
                "name": "Northern Province"
            },
            {
                "country_code": "SL",
                "country_id": 198,
                "id": 912,
                "iso2": "S",
                "name": "Southern Province"
            },
            {
                "country_code": "SL",
                "country_id": 198,
                "id": 913,
                "iso2": "W",
                "name": "Western Area"
            },
            {
                "country_code": "SL",
                "country_id": 198,
                "id": 914,
                "iso2": "E",
                "name": "Eastern Province"
            }
        ]
    },
    {
        "id": 199,
        "name": "Singapore",
        "iso3": "SGP",
        "iso2": "SG",
        "states": [
            {
                "country_code": "SG",
                "country_id": 199,
                "id": 4649,
                "iso2": "2",
                "name": "North East Community Development Council"
            },
            {
                "country_code": "SG",
                "country_id": 199,
                "id": 4650,
                "iso2": "4",
                "name": "South East Community Development Council"
            },
            {
                "country_code": "SG",
                "country_id": 199,
                "id": 4651,
                "iso2": "1",
                "name": "Central Singapore Community Development Council"
            },
            {
                "country_code": "SG",
                "country_id": 199,
                "id": 4652,
                "iso2": "5",
                "name": "South West Community Development Council"
            },
            {
                "country_code": "SG",
                "country_id": 199,
                "id": 4653,
                "iso2": "3",
                "name": "North West Community Development Council"
            }
        ]
    },
    {
        "id": 200,
        "name": "Slovakia",
        "iso3": "SVK",
        "iso2": "SK",
        "states": [
            {
                "country_code": "SK",
                "country_id": 200,
                "id": 4352,
                "iso2": "BC",
                "name": "Banská Bystrica Region"
            },
            {
                "country_code": "SK",
                "country_id": 200,
                "id": 4353,
                "iso2": "KI",
                "name": "Košice Region"
            },
            {
                "country_code": "SK",
                "country_id": 200,
                "id": 4354,
                "iso2": "PV",
                "name": "Prešov Region"
            },
            {
                "country_code": "SK",
                "country_id": 200,
                "id": 4355,
                "iso2": "TA",
                "name": "Trnava Region"
            },
            {
                "country_code": "SK",
                "country_id": 200,
                "id": 4356,
                "iso2": "BL",
                "name": "Bratislava Region"
            },
            {
                "country_code": "SK",
                "country_id": 200,
                "id": 4357,
                "iso2": "NI",
                "name": "Nitra Region"
            },
            {
                "country_code": "SK",
                "country_id": 200,
                "id": 4358,
                "iso2": "TC",
                "name": "Trenčín Region"
            },
            {
                "country_code": "SK",
                "country_id": 200,
                "id": 4359,
                "iso2": "ZI",
                "name": "Žilina Region"
            }
        ]
    },
    {
        "id": 201,
        "name": "Slovenia",
        "iso3": "SVN",
        "iso2": "SI",
        "states": [
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4141,
                "iso2": "151",
                "name": "Braslovče Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4142,
                "iso2": "58",
                "name": "Lenart Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4143,
                "iso2": "171",
                "name": "Oplotnica"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4144,
                "iso2": "134",
                "name": "Velike Lašče Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4145,
                "iso2": "159",
                "name": "Hajdina Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4146,
                "iso2": "92",
                "name": "Podčetrtek Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4147,
                "iso2": "152",
                "name": "Cankova Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4148,
                "iso2": "137",
                "name": "Vitanje Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4149,
                "iso2": "111",
                "name": "Sežana Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4150,
                "iso2": "45",
                "name": "Kidričevo Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4151,
                "iso2": "15",
                "name": "Črenšovci Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4152,
                "iso2": "36",
                "name": "Idrija Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4153,
                "iso2": "185",
                "name": "Trnovska Vas Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4154,
                "iso2": "138",
                "name": "Vodice Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4155,
                "iso2": "103",
                "name": "Ravne na Koroškem Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4156,
                "iso2": "167",
                "name": "Lovrenc na Pohorju Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4157,
                "iso2": "69",
                "name": "Majšperk Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4158,
                "iso2": "66",
                "name": "Loški Potok Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4159,
                "iso2": "23",
                "name": "Domžale Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4160,
                "iso2": "209",
                "name": "Rečica ob Savinji Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4161,
                "iso2": "172",
                "name": "Podlehnik Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4162,
                "iso2": "13",
                "name": "Cerknica Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4163,
                "iso2": "189",
                "name": "Vransko Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4164,
                "iso2": "181",
                "name": "Sveta Ana Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4165,
                "iso2": "8",
                "name": "Brezovica Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4166,
                "iso2": "148",
                "name": "Benedikt Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4167,
                "iso2": "19",
                "name": "Divača Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4168,
                "iso2": "77",
                "name": "Moravče Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4169,
                "iso2": "112",
                "name": "Slovenj Gradec City Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4170,
                "iso2": "121",
                "name": "Škocjan Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4171,
                "iso2": "120",
                "name": "Šentjur Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4172,
                "iso2": "89",
                "name": "Pesnica Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4173,
                "iso2": "22",
                "name": "Dol pri Ljubljani Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4174,
                "iso2": "65",
                "name": "Loška Dolina Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4175,
                "iso2": "160",
                "name": "Hoče–Slivnica Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4176,
                "iso2": "153",
                "name": "Cerkvenjak Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4177,
                "iso2": "82",
                "name": "Naklo Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4178,
                "iso2": "14",
                "name": "Cerkno Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4179,
                "iso2": "149",
                "name": "Bistrica ob Sotli Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4180,
                "iso2": "43",
                "name": "Kamnik Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4181,
                "iso2": "6",
                "name": "Bovec Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4182,
                "iso2": "143",
                "name": "Zavrč Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4183,
                "iso2": "1",
                "name": "Ajdovščina Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4184,
                "iso2": "91",
                "name": "Pivka Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4185,
                "iso2": "127",
                "name": "Štore Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4186,
                "iso2": "51",
                "name": "Kozje Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4187,
                "iso2": "123",
                "name": "Municipality of Škofljica"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4188,
                "iso2": "174",
                "name": "Prebold Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4189,
                "iso2": "156",
                "name": "Dobrovnik Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4190,
                "iso2": "79",
                "name": "Mozirje Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4191,
                "iso2": "11",
                "name": "City Municipality of Celje"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4192,
                "iso2": "147",
                "name": "Žiri Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4193,
                "iso2": "162",
                "name": "Horjul Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4194,
                "iso2": "184",
                "name": "Tabor Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4195,
                "iso2": "99",
                "name": "Radeče Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4196,
                "iso2": "136",
                "name": "Vipava Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4197,
                "iso2": "55",
                "name": "Kungota"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4198,
                "iso2": "114",
                "name": "Slovenske Konjice Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4199,
                "iso2": "88",
                "name": "Osilnica Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4200,
                "iso2": "5",
                "name": "Borovnica Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4201,
                "iso2": "90",
                "name": "Piran Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4202,
                "iso2": "3",
                "name": "Bled Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4203,
                "iso2": "163",
                "name": "Jezersko Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4204,
                "iso2": "98",
                "name": "Rače–Fram Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4205,
                "iso2": "84",
                "name": "Nova Gorica City Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4206,
                "iso2": "176",
                "name": "Razkrižje Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4207,
                "iso2": "177",
                "name": "Ribnica na Pohorju Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4208,
                "iso2": "81",
                "name": "Muta Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4209,
                "iso2": "107",
                "name": "Rogatec Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4210,
                "iso2": "28",
                "name": "Gorišnica Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4211,
                "iso2": "56",
                "name": "Kuzma Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4212,
                "iso2": "76",
                "name": "Mislinja Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4213,
                "iso2": "26",
                "name": "Duplek Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4214,
                "iso2": "130",
                "name": "Trebnje Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4215,
                "iso2": "9",
                "name": "Brežice Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4216,
                "iso2": "20",
                "name": "Dobrepolje Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4217,
                "iso2": "158",
                "name": "Grad Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4218,
                "iso2": "78",
                "name": "Moravske Toplice Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4219,
                "iso2": "67",
                "name": "Luče Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4220,
                "iso2": "75",
                "name": "Miren–Kostanjevica Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4221,
                "iso2": "87",
                "name": "Ormož Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4222,
                "iso2": "33",
                "name": "Šalovci Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4223,
                "iso2": "169",
                "name": "Miklavž na Dravskem Polju Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4224,
                "iso2": "198",
                "name": "Makole Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4225,
                "iso2": "59",
                "name": "Lendava Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4226,
                "iso2": "141",
                "name": "Vuzenica Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4227,
                "iso2": "44",
                "name": "Kanal ob Soči Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4228,
                "iso2": "96",
                "name": "Ptuj City Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4229,
                "iso2": "182",
                "name": "Sveti Andraž v Slovenskih Goricah Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4230,
                "iso2": "178",
                "name": "Selnica ob Dravi Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4231,
                "iso2": "102",
                "name": "Radovljica Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4232,
                "iso2": "16",
                "name": "Črna na Koroškem Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4233,
                "iso2": "106",
                "name": "Rogaška Slatina Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4234,
                "iso2": "93",
                "name": "Podvelka Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4235,
                "iso2": "104",
                "name": "Ribnica Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4236,
                "iso2": "85",
                "name": "City Municipality of Novo Mesto"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4237,
                "iso2": "170",
                "name": "Mirna Peč Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4238,
                "iso2": "166",
                "name": "Križevci Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4239,
                "iso2": "200",
                "name": "Poljčane Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4240,
                "iso2": "7",
                "name": "Brda Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4241,
                "iso2": "119",
                "name": "Šentjernej Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4242,
                "iso2": "70",
                "name": "Maribor City Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4243,
                "iso2": "46",
                "name": "Kobarid Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4244,
                "iso2": "168",
                "name": "Markovci Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4245,
                "iso2": "139",
                "name": "Vojnik Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4246,
                "iso2": "129",
                "name": "Trbovlje Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4247,
                "iso2": "128",
                "name": "Tolmin Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4248,
                "iso2": "126",
                "name": "Šoštanj Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4249,
                "iso2": "191",
                "name": "Žetale Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4250,
                "iso2": "131",
                "name": "Tržič Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4251,
                "iso2": "132",
                "name": "Turnišče Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4252,
                "iso2": "155",
                "name": "Dobrna Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4253,
                "iso2": "201",
                "name": "Renče–Vogrsko Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4254,
                "iso2": "197",
                "name": "Kostanjevica na Krki Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4255,
                "iso2": "116",
                "name": "Sveti Jurij ob Ščavnici Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4256,
                "iso2": "146",
                "name": "Železniki Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4257,
                "iso2": "188",
                "name": "Veržej Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4258,
                "iso2": "190",
                "name": "Žalec Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4259,
                "iso2": "115",
                "name": "Starše Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4260,
                "iso2": "204",
                "name": "Sveta Trojica v Slovenskih Goricah Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4261,
                "iso2": "180",
                "name": "Solčava Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4262,
                "iso2": "140",
                "name": "Vrhnika Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4263,
                "iso2": "202",
                "name": "Središče ob Dravi"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4264,
                "iso2": "105",
                "name": "Rogašovci Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4265,
                "iso2": "74",
                "name": "Mežica Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4266,
                "iso2": "42",
                "name": "Juršinci Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4267,
                "iso2": "187",
                "name": "Velika Polana Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4268,
                "iso2": "110",
                "name": "Sevnica Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4269,
                "iso2": "142",
                "name": "Zagorje ob Savi Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4270,
                "iso2": "61",
                "name": "Ljubljana City Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4271,
                "iso2": "31",
                "name": "Gornji Petrovci Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4272,
                "iso2": "173",
                "name": "Polzela Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4273,
                "iso2": "205",
                "name": "Sveti Tomaž Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4274,
                "iso2": "175",
                "name": "Prevalje Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4275,
                "iso2": "101",
                "name": "Radlje ob Dravi Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4276,
                "iso2": "192",
                "name": "Žirovnica Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4277,
                "iso2": "179",
                "name": "Sodražica Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4278,
                "iso2": "150",
                "name": "Bloke Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4279,
                "iso2": "194",
                "name": "Šmartno pri Litiji Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4280,
                "iso2": "108",
                "name": "Ruše Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4281,
                "iso2": "157",
                "name": "Dolenjske Toplice Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4282,
                "iso2": "4",
                "name": "Bohinj Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4283,
                "iso2": "164",
                "name": "Komenda Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4284,
                "iso2": "207",
                "name": "Gorje Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4285,
                "iso2": "124",
                "name": "Šmarje pri Jelšah Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4286,
                "iso2": "37",
                "name": "Ig Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4287,
                "iso2": "52",
                "name": "Kranj City Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4288,
                "iso2": "97",
                "name": "Puconci Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4289,
                "iso2": "206",
                "name": "Šmarješke Toplice Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4290,
                "iso2": "24",
                "name": "Dornava Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4291,
                "iso2": "17",
                "name": "Črnomelj Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4292,
                "iso2": "100",
                "name": "Radenci Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4293,
                "iso2": "27",
                "name": "Gorenja Vas–Poljane Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4294,
                "iso2": "62",
                "name": "Ljubno Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4295,
                "iso2": "154",
                "name": "Dobje Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4296,
                "iso2": "125",
                "name": "Šmartno ob Paki Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4297,
                "iso2": "199",
                "name": "Mokronog–Trebelno Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4298,
                "iso2": "212",
                "name": "Mirna Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4299,
                "iso2": "117",
                "name": "Šenčur Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4300,
                "iso2": "135",
                "name": "Videm Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4301,
                "iso2": "2",
                "name": "Beltinci Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4302,
                "iso2": "68",
                "name": "Lukovica Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4303,
                "iso2": "95",
                "name": "Preddvor Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4304,
                "iso2": "18",
                "name": "Destrnik Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4305,
                "iso2": "39",
                "name": "Ivančna Gorica Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4306,
                "iso2": "208",
                "name": "Log–Dragomer Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4307,
                "iso2": "193",
                "name": "Žužemberk Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4308,
                "iso2": "21",
                "name": "Dobrova–Polhov Gradec Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4309,
                "iso2": "196",
                "name": "Municipality of Cirkulane"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4310,
                "iso2": "12",
                "name": "Cerklje na Gorenjskem Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4311,
                "iso2": "211",
                "name": "Šentrupert Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4312,
                "iso2": "10",
                "name": "Tišina Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4313,
                "iso2": "80",
                "name": "Murska Sobota City Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4314,
                "iso2": "54",
                "name": "Municipality of Krško"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4315,
                "iso2": "49",
                "name": "Komen Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4316,
                "iso2": "122",
                "name": "Škofja Loka Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4317,
                "iso2": "183",
                "name": "Šempeter–Vrtojba Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4318,
                "iso2": "195",
                "name": "Municipality of Apače"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4319,
                "iso2": "50",
                "name": "Koper City Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4320,
                "iso2": "86",
                "name": "Odranci Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4321,
                "iso2": "35",
                "name": "Hrpelje–Kozina Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4322,
                "iso2": "40",
                "name": "Izola Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4323,
                "iso2": "73",
                "name": "Metlika Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4324,
                "iso2": "118",
                "name": "Šentilj Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4325,
                "iso2": "47",
                "name": "Kobilje Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4326,
                "iso2": "213",
                "name": "Ankaran Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4327,
                "iso2": "161",
                "name": "Hodoš Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4328,
                "iso2": "210",
                "name": "Sveti Jurij v Slovenskih Goricah Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4329,
                "iso2": "83",
                "name": "Nazarje Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4330,
                "iso2": "94",
                "name": "Postojna Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4331,
                "iso2": "165",
                "name": "Kostel Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4332,
                "iso2": "113",
                "name": "Slovenska Bistrica Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4333,
                "iso2": "203",
                "name": "Straža Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4334,
                "iso2": "186",
                "name": "Trzin Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4335,
                "iso2": "48",
                "name": "Kočevje Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4336,
                "iso2": "32",
                "name": "Grosuplje Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4337,
                "iso2": "41",
                "name": "Jesenice Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4338,
                "iso2": "57",
                "name": "Laško Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4339,
                "iso2": "30",
                "name": "Gornji Grad Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4340,
                "iso2": "53",
                "name": "Kranjska Gora Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4341,
                "iso2": "34",
                "name": "Hrastnik Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4342,
                "iso2": "144",
                "name": "Zreče Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4343,
                "iso2": "29",
                "name": "Gornja Radgona Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4344,
                "iso2": "38",
                "name": "Municipality of Ilirska Bistrica"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4345,
                "iso2": "25",
                "name": "Dravograd Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4346,
                "iso2": "109",
                "name": "Semič Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4347,
                "iso2": "60",
                "name": "Litija Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4348,
                "iso2": "72",
                "name": "Mengeš Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4349,
                "iso2": "71",
                "name": "Medvode Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4350,
                "iso2": "64",
                "name": "Logatec Municipality"
            },
            {
                "country_code": "SI",
                "country_id": 201,
                "id": 4351,
                "iso2": "63",
                "name": "Ljutomer Municipality"
            }
        ]
    },
    {
        "id": 202,
        "name": "Solomon Islands",
        "iso3": "SLB",
        "iso2": "SB",
        "states": [
            {
                "country_code": "SB",
                "country_id": 202,
                "id": 4778,
                "iso2": "CT",
                "name": "Honiara"
            },
            {
                "country_code": "SB",
                "country_id": 202,
                "id": 4779,
                "iso2": "TE",
                "name": "Temotu Province"
            },
            {
                "country_code": "SB",
                "country_id": 202,
                "id": 4780,
                "iso2": "IS",
                "name": "Isabel Province"
            },
            {
                "country_code": "SB",
                "country_id": 202,
                "id": 4781,
                "iso2": "CH",
                "name": "Choiseul Province"
            },
            {
                "country_code": "SB",
                "country_id": 202,
                "id": 4782,
                "iso2": "MK",
                "name": "Makira-Ulawa Province"
            },
            {
                "country_code": "SB",
                "country_id": 202,
                "id": 4783,
                "iso2": "ML",
                "name": "Malaita Province"
            },
            {
                "country_code": "SB",
                "country_id": 202,
                "id": 4784,
                "iso2": "CE",
                "name": "Central Province"
            },
            {
                "country_code": "SB",
                "country_id": 202,
                "id": 4785,
                "iso2": "GU",
                "name": "Guadalcanal Province"
            },
            {
                "country_code": "SB",
                "country_id": 202,
                "id": 4786,
                "iso2": "WE",
                "name": "Western Province"
            },
            {
                "country_code": "SB",
                "country_id": 202,
                "id": 4787,
                "iso2": "RB",
                "name": "Rennell and Bellona Province"
            }
        ]
    },
    {
        "id": 203,
        "name": "Somalia",
        "iso3": "SOM",
        "iso2": "SO",
        "states": [
            {
                "country_code": "SO",
                "country_id": 203,
                "id": 915,
                "iso2": "HI",
                "name": "Hiran"
            },
            {
                "country_code": "SO",
                "country_id": 203,
                "id": 916,
                "iso2": "MU",
                "name": "Mudug"
            },
            {
                "country_code": "SO",
                "country_id": 203,
                "id": 917,
                "iso2": "BK",
                "name": "Bakool"
            },
            {
                "country_code": "SO",
                "country_id": 203,
                "id": 918,
                "iso2": "GA",
                "name": "Galguduud"
            },
            {
                "country_code": "SO",
                "country_id": 203,
                "id": 919,
                "iso2": "SA",
                "name": "Sanaag Region"
            },
            {
                "country_code": "SO",
                "country_id": 203,
                "id": 920,
                "iso2": "NU",
                "name": "Nugal"
            },
            {
                "country_code": "SO",
                "country_id": 203,
                "id": 921,
                "iso2": "SH",
                "name": "Lower Shebelle"
            },
            {
                "country_code": "SO",
                "country_id": 203,
                "id": 922,
                "iso2": "JD",
                "name": "Middle Juba"
            },
            {
                "country_code": "SO",
                "country_id": 203,
                "id": 923,
                "iso2": "SD",
                "name": "Middle Shebelle"
            },
            {
                "country_code": "SO",
                "country_id": 203,
                "id": 924,
                "iso2": "JH",
                "name": "Lower Juba"
            },
            {
                "country_code": "SO",
                "country_id": 203,
                "id": 925,
                "iso2": "AW",
                "name": "Awdal Region"
            },
            {
                "country_code": "SO",
                "country_id": 203,
                "id": 926,
                "iso2": "BY",
                "name": "Bay"
            },
            {
                "country_code": "SO",
                "country_id": 203,
                "id": 927,
                "iso2": "BN",
                "name": "Banaadir"
            },
            {
                "country_code": "SO",
                "country_id": 203,
                "id": 928,
                "iso2": "GE",
                "name": "Gedo"
            },
            {
                "country_code": "SO",
                "country_id": 203,
                "id": 929,
                "iso2": "TO",
                "name": "Togdheer Region"
            },
            {
                "country_code": "SO",
                "country_id": 203,
                "id": 930,
                "iso2": "BR",
                "name": "Bari"
            }
        ]
    },
    {
        "id": 204,
        "name": "South Africa",
        "iso3": "ZAF",
        "iso2": "ZA",
        "states": [
            {
                "country_code": "ZA",
                "country_id": 204,
                "id": 931,
                "iso2": "NC",
                "name": "Northern Cape"
            },
            {
                "country_code": "ZA",
                "country_id": 204,
                "id": 932,
                "iso2": "FS",
                "name": "Free State"
            },
            {
                "country_code": "ZA",
                "country_id": 204,
                "id": 933,
                "iso2": "LP",
                "name": "Limpopo"
            },
            {
                "country_code": "ZA",
                "country_id": 204,
                "id": 934,
                "iso2": "NW",
                "name": "North West"
            },
            {
                "country_code": "ZA",
                "country_id": 204,
                "id": 935,
                "iso2": "KZN",
                "name": "KwaZulu-Natal"
            },
            {
                "country_code": "ZA",
                "country_id": 204,
                "id": 936,
                "iso2": "GP",
                "name": "Gauteng"
            },
            {
                "country_code": "ZA",
                "country_id": 204,
                "id": 937,
                "iso2": "MP",
                "name": "Mpumalanga"
            },
            {
                "country_code": "ZA",
                "country_id": 204,
                "id": 938,
                "iso2": "EC",
                "name": "Eastern Cape"
            },
            {
                "country_code": "ZA",
                "country_id": 204,
                "id": 939,
                "iso2": "WC",
                "name": "Western Cape"
            }
        ]
    },
    {
        "id": 205,
        "name": "South Georgia",
        "iso3": "SGS",
        "iso2": "GS",
        "states": []
    },
    {
        "id": 206,
        "name": "South Sudan",
        "iso3": "SSD",
        "iso2": "SS",
        "states": [
            {
                "country_code": "SS",
                "country_id": 206,
                "id": 2085,
                "iso2": "UY",
                "name": "Unity"
            },
            {
                "country_code": "SS",
                "country_id": 206,
                "id": 2086,
                "iso2": "NU",
                "name": "Upper Nile"
            },
            {
                "country_code": "SS",
                "country_id": 206,
                "id": 2087,
                "iso2": "WR",
                "name": "Warrap"
            },
            {
                "country_code": "SS",
                "country_id": 206,
                "id": 2088,
                "iso2": "BN",
                "name": "Northern Bahr el Ghazal"
            },
            {
                "country_code": "SS",
                "country_id": 206,
                "id": 2089,
                "iso2": "EW",
                "name": "Western Equatoria"
            },
            {
                "country_code": "SS",
                "country_id": 206,
                "id": 2090,
                "iso2": "LK",
                "name": "Lakes"
            },
            {
                "country_code": "SS",
                "country_id": 206,
                "id": 2091,
                "iso2": "BW",
                "name": "Western Bahr el Ghazal"
            },
            {
                "country_code": "SS",
                "country_id": 206,
                "id": 2092,
                "iso2": "EC",
                "name": "Central Equatoria"
            },
            {
                "country_code": "SS",
                "country_id": 206,
                "id": 2093,
                "iso2": "EE",
                "name": "Eastern Equatoria"
            },
            {
                "country_code": "SS",
                "country_id": 206,
                "id": 2094,
                "iso2": "JG",
                "name": "Jonglei State"
            }
        ]
    },
    {
        "id": 207,
        "name": "Spain",
        "iso3": "ESP",
        "iso2": "ES",
        "states": [
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1145,
                "iso2": "MU",
                "name": "Murcia Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1146,
                "iso2": "BU",
                "name": "Burgos Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1147,
                "iso2": "SA",
                "name": "Salamanca Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1148,
                "iso2": "VI",
                "name": "Araba / Álava"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1149,
                "iso2": "M",
                "name": "Madrid Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1150,
                "iso2": "CR",
                "name": "Ciudad Real Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1151,
                "iso2": "AL",
                "name": "Almería Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1152,
                "iso2": "V",
                "name": "Valencia Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1153,
                "iso2": "BA",
                "name": "Badajoz Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1154,
                "iso2": "PO",
                "name": "Pontevedra Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1155,
                "iso2": "SE",
                "name": "Seville Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1156,
                "iso2": "A",
                "name": "Alicante Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1157,
                "iso2": "P",
                "name": "Palencia Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1158,
                "iso2": "MD",
                "name": "Community of Madrid"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1159,
                "iso2": "ML",
                "name": "Melilla"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1160,
                "iso2": "O",
                "name": "Province of Asturias"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1161,
                "iso2": "ZA",
                "name": "Zamora Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1162,
                "iso2": "Z",
                "name": "Zaragoza Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1163,
                "iso2": "HU",
                "name": "Huesca Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1164,
                "iso2": "T",
                "name": "Tarragona Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1165,
                "iso2": "TO",
                "name": "Toledo Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1166,
                "iso2": "GC",
                "name": "Las Palmas Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1167,
                "iso2": "GA",
                "name": "Galicia"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1168,
                "iso2": "AB",
                "name": "Albacete Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1169,
                "iso2": "CU",
                "name": "Cuenca Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1170,
                "iso2": "S",
                "name": "Cantabria"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1171,
                "iso2": "RI",
                "name": "La Rioja"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1172,
                "iso2": "GU",
                "name": "Guadalajara Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1173,
                "iso2": "OR",
                "name": "Ourense Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1174,
                "iso2": "PM",
                "name": "Balearic Islands"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1175,
                "iso2": "VC",
                "name": "Valencian Community"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1176,
                "iso2": "MC",
                "name": "Region of Murcia"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1177,
                "iso2": "AR",
                "name": "Aragon"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1178,
                "iso2": "GI",
                "name": "Girona Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1179,
                "iso2": "C",
                "name": "A Coruña Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1180,
                "iso2": "B",
                "name": "Barcelona Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1181,
                "iso2": "J",
                "name": "Jaén Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1182,
                "iso2": "TE",
                "name": "Teruel Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1183,
                "iso2": "VA",
                "name": "Valladolid Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1184,
                "iso2": "CL",
                "name": "Castile and León"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1185,
                "iso2": "CN",
                "name": "Canary Islands"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1186,
                "iso2": "BI",
                "name": "Biscay"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1187,
                "iso2": "LU",
                "name": "Lugo Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1188,
                "iso2": "MA",
                "name": "Málaga Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1189,
                "iso2": "AV",
                "name": "Province of Ávila"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1190,
                "iso2": "EX",
                "name": "Extremadura"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1191,
                "iso2": "PV",
                "name": "Basque Country"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1192,
                "iso2": "SG",
                "name": "Segovia Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1193,
                "iso2": "AN",
                "name": "Andalusia"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1194,
                "iso2": "GR",
                "name": "Granada Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1195,
                "iso2": "L",
                "name": "Lleida Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1196,
                "iso2": "CC",
                "name": "Cáceres Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1197,
                "iso2": "CO",
                "name": "Córdoba Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1198,
                "iso2": "TF",
                "name": "Santa Cruz de Tenerife Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1199,
                "iso2": "H",
                "name": "Huelva Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1200,
                "iso2": "LE",
                "name": "León Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1201,
                "iso2": "CA",
                "name": "Cádiz Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1202,
                "iso2": "SS",
                "name": "Gipuzkoa"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1203,
                "iso2": "CT",
                "name": "Catalonia"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1204,
                "iso2": "NC",
                "name": "Navarre"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1205,
                "iso2": "CM",
                "name": "Castile-La Mancha"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1206,
                "iso2": "CE",
                "name": "Ceuta"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1207,
                "iso2": "CS",
                "name": "Castellón Province"
            },
            {
                "country_code": "ES",
                "country_id": 207,
                "id": 1208,
                "iso2": "SO",
                "name": "Soria Province"
            }
        ]
    },
    {
        "id": 208,
        "name": "Sri Lanka",
        "iso3": "LKA",
        "iso2": "LK",
        "states": [
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2787,
                "iso2": "41",
                "name": "Jaffna District"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2788,
                "iso2": "21",
                "name": "Kandy District"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2789,
                "iso2": "13",
                "name": "Kalutara District"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2790,
                "iso2": "81",
                "name": "Badulla District"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2791,
                "iso2": "33",
                "name": "Hambantota District"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2792,
                "iso2": "31",
                "name": "Galle District"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2793,
                "iso2": "42",
                "name": "Kilinochchi District"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2794,
                "iso2": "23",
                "name": "Nuwara Eliya District"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2795,
                "iso2": "53",
                "name": "Trincomalee District"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2796,
                "iso2": "62",
                "name": "Puttalam District"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2797,
                "iso2": "92",
                "name": "Kegalle District"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2798,
                "iso2": "2",
                "name": "Central Province"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2799,
                "iso2": "52",
                "name": "Ampara District"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2800,
                "iso2": "7",
                "name": "North Central Province"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2801,
                "iso2": "3",
                "name": "Southern Province"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2802,
                "iso2": "1",
                "name": "Western Province"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2803,
                "iso2": "9",
                "name": "Sabaragamuwa Province"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2804,
                "iso2": "12",
                "name": "Gampaha District"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2805,
                "iso2": "43",
                "name": "Mannar District"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2806,
                "iso2": "32",
                "name": "Matara District"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2807,
                "iso2": "91",
                "name": "Ratnapura district"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2808,
                "iso2": "5",
                "name": "Eastern Province"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2809,
                "iso2": "44",
                "name": "Vavuniya District"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2810,
                "iso2": "22",
                "name": "Matale District"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2811,
                "iso2": "8",
                "name": "Uva Province"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2812,
                "iso2": "72",
                "name": "Polonnaruwa District"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2813,
                "iso2": "4",
                "name": "Northern Province"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2814,
                "iso2": "45",
                "name": "Mullaitivu District"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2815,
                "iso2": "11",
                "name": "Colombo District"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2816,
                "iso2": "71",
                "name": "Anuradhapura District"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2817,
                "iso2": "6",
                "name": "North Western Province"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2818,
                "iso2": "51",
                "name": "Batticaloa District"
            },
            {
                "country_code": "LK",
                "country_id": 208,
                "id": 2819,
                "iso2": "82",
                "name": "Monaragala District"
            }
        ]
    },
    {
        "id": 209,
        "name": "Sudan",
        "iso3": "SDN",
        "iso2": "SD",
        "states": [
            {
                "country_code": "SD",
                "country_id": 209,
                "id": 879,
                "iso2": "NW",
                "name": "White Nile"
            },
            {
                "country_code": "SD",
                "country_id": 209,
                "id": 880,
                "iso2": "RS",
                "name": "Red Sea"
            },
            {
                "country_code": "SD",
                "country_id": 209,
                "id": 881,
                "iso2": "KH",
                "name": "Khartoum"
            },
            {
                "country_code": "SD",
                "country_id": 209,
                "id": 882,
                "iso2": "SI",
                "name": "Sennar"
            },
            {
                "country_code": "SD",
                "country_id": 209,
                "id": 883,
                "iso2": "KS",
                "name": "South Kordofan"
            },
            {
                "country_code": "SD",
                "country_id": 209,
                "id": 884,
                "iso2": "KA",
                "name": "Kassala"
            },
            {
                "country_code": "SD",
                "country_id": 209,
                "id": 885,
                "iso2": "GZ",
                "name": "Al Jazirah"
            },
            {
                "country_code": "SD",
                "country_id": 209,
                "id": 886,
                "iso2": "GD",
                "name": "Al Qadarif"
            },
            {
                "country_code": "SD",
                "country_id": 209,
                "id": 887,
                "iso2": "NB",
                "name": "Blue Nile"
            },
            {
                "country_code": "SD",
                "country_id": 209,
                "id": 888,
                "iso2": "DW",
                "name": "West Darfur"
            },
            {
                "country_code": "SD",
                "country_id": 209,
                "id": 889,
                "iso2": "GK",
                "name": "West Kordofan"
            },
            {
                "country_code": "SD",
                "country_id": 209,
                "id": 890,
                "iso2": "DN",
                "name": "North Darfur"
            },
            {
                "country_code": "SD",
                "country_id": 209,
                "id": 891,
                "iso2": "NR",
                "name": "River Nile"
            },
            {
                "country_code": "SD",
                "country_id": 209,
                "id": 892,
                "iso2": "DE",
                "name": "East Darfur"
            },
            {
                "country_code": "SD",
                "country_id": 209,
                "id": 893,
                "iso2": "KN",
                "name": "North Kordofan"
            },
            {
                "country_code": "SD",
                "country_id": 209,
                "id": 894,
                "iso2": "DS",
                "name": "South Darfur"
            },
            {
                "country_code": "SD",
                "country_id": 209,
                "id": 895,
                "iso2": "NO",
                "name": "Northern"
            },
            {
                "country_code": "SD",
                "country_id": 209,
                "id": 896,
                "iso2": "DC",
                "name": "Central Darfur"
            }
        ]
    },
    {
        "id": 210,
        "name": "Suriname",
        "iso3": "SUR",
        "iso2": "SR",
        "states": [
            {
                "country_code": "SR",
                "country_id": 210,
                "id": 2839,
                "iso2": "CM",
                "name": "Commewijne District"
            },
            {
                "country_code": "SR",
                "country_id": 210,
                "id": 2840,
                "iso2": "NI",
                "name": "Nickerie District"
            },
            {
                "country_code": "SR",
                "country_id": 210,
                "id": 2841,
                "iso2": "PR",
                "name": "Para District"
            },
            {
                "country_code": "SR",
                "country_id": 210,
                "id": 2842,
                "iso2": "CR",
                "name": "Coronie District"
            },
            {
                "country_code": "SR",
                "country_id": 210,
                "id": 2843,
                "iso2": "PM",
                "name": "Paramaribo District"
            },
            {
                "country_code": "SR",
                "country_id": 210,
                "id": 2844,
                "iso2": "WA",
                "name": "Wanica District"
            },
            {
                "country_code": "SR",
                "country_id": 210,
                "id": 2845,
                "iso2": "MA",
                "name": "Marowijne District"
            },
            {
                "country_code": "SR",
                "country_id": 210,
                "id": 2846,
                "iso2": "BR",
                "name": "Brokopondo District"
            },
            {
                "country_code": "SR",
                "country_id": 210,
                "id": 2847,
                "iso2": "SI",
                "name": "Sipaliwini District"
            },
            {
                "country_code": "SR",
                "country_id": 210,
                "id": 2848,
                "iso2": "SA",
                "name": "Saramacca District"
            }
        ]
    },
    {
        "id": 211,
        "name": "Svalbard And Jan Mayen Islands",
        "iso3": "SJM",
        "iso2": "SJ",
        "states": []
    },
    {
        "id": 212,
        "name": "Swaziland",
        "iso3": "SWZ",
        "iso2": "SZ",
        "states": [
            {
                "country_code": "SZ",
                "country_id": 212,
                "id": 968,
                "iso2": "MA",
                "name": "Manzini District"
            },
            {
                "country_code": "SZ",
                "country_id": 212,
                "id": 969,
                "iso2": "HH",
                "name": "Hhohho District"
            },
            {
                "country_code": "SZ",
                "country_id": 212,
                "id": 970,
                "iso2": "LU",
                "name": "Lubombo District"
            },
            {
                "country_code": "SZ",
                "country_id": 212,
                "id": 971,
                "iso2": "SH",
                "name": "Shiselweni District"
            }
        ]
    },
    {
        "id": 213,
        "name": "Sweden",
        "iso3": "SWE",
        "iso2": "SE",
        "states": [
            {
                "country_code": "SE",
                "country_id": 213,
                "id": 1533,
                "iso2": "X",
                "name": "Gävleborg County"
            },
            {
                "country_code": "SE",
                "country_id": 213,
                "id": 1534,
                "iso2": "W",
                "name": "Dalarna County"
            },
            {
                "country_code": "SE",
                "country_id": 213,
                "id": 1535,
                "iso2": "S",
                "name": "Värmland County"
            },
            {
                "country_code": "SE",
                "country_id": 213,
                "id": 1536,
                "iso2": "E",
                "name": "Östergötland County"
            },
            {
                "country_code": "SE",
                "country_id": 213,
                "id": 1537,
                "iso2": "K",
                "name": "Blekinge"
            },
            {
                "country_code": "SE",
                "country_id": 213,
                "id": 1538,
                "iso2": "BD",
                "name": "Norrbotten County"
            },
            {
                "country_code": "SE",
                "country_id": 213,
                "id": 1539,
                "iso2": "T",
                "name": "Örebro County"
            },
            {
                "country_code": "SE",
                "country_id": 213,
                "id": 1540,
                "iso2": "D",
                "name": "Södermanland County"
            },
            {
                "country_code": "SE",
                "country_id": 213,
                "id": 1541,
                "iso2": "M",
                "name": "Skåne County"
            },
            {
                "country_code": "SE",
                "country_id": 213,
                "id": 1542,
                "iso2": "G",
                "name": "Kronoberg County"
            },
            {
                "country_code": "SE",
                "country_id": 213,
                "id": 1543,
                "iso2": "AC",
                "name": "Västerbotten County"
            },
            {
                "country_code": "SE",
                "country_id": 213,
                "id": 1544,
                "iso2": "H",
                "name": "Kalmar County"
            },
            {
                "country_code": "SE",
                "country_id": 213,
                "id": 1545,
                "iso2": "C",
                "name": "Uppsala County"
            },
            {
                "country_code": "SE",
                "country_id": 213,
                "id": 1546,
                "iso2": "I",
                "name": "Gotland County"
            },
            {
                "country_code": "SE",
                "country_id": 213,
                "id": 1547,
                "iso2": "O",
                "name": "Västra Götaland County"
            },
            {
                "country_code": "SE",
                "country_id": 213,
                "id": 1548,
                "iso2": "N",
                "name": "Halland County"
            },
            {
                "country_code": "SE",
                "country_id": 213,
                "id": 1549,
                "iso2": "U",
                "name": "Västmanland County"
            },
            {
                "country_code": "SE",
                "country_id": 213,
                "id": 1550,
                "iso2": "F",
                "name": "Jönköping County"
            },
            {
                "country_code": "SE",
                "country_id": 213,
                "id": 1551,
                "iso2": "AB",
                "name": "Stockholm County"
            },
            {
                "country_code": "SE",
                "country_id": 213,
                "id": 1552,
                "iso2": "Y",
                "name": "Västernorrland County"
            }
        ]
    },
    {
        "id": 214,
        "name": "Switzerland",
        "iso3": "CHE",
        "iso2": "CH",
        "states": [
            {
                "country_code": "CH",
                "country_id": 214,
                "id": 1639,
                "iso2": "AG",
                "name": "Aargau"
            },
            {
                "country_code": "CH",
                "country_id": 214,
                "id": 1640,
                "iso2": "FR",
                "name": "Canton of Fribourg"
            },
            {
                "country_code": "CH",
                "country_id": 214,
                "id": 1641,
                "iso2": "BL",
                "name": "Basel-Landschaft"
            },
            {
                "country_code": "CH",
                "country_id": 214,
                "id": 1642,
                "iso2": "UR",
                "name": "Uri"
            },
            {
                "country_code": "CH",
                "country_id": 214,
                "id": 1643,
                "iso2": "TI",
                "name": "Ticino"
            },
            {
                "country_code": "CH",
                "country_id": 214,
                "id": 1644,
                "iso2": "SG",
                "name": "Canton of St. Gallen"
            },
            {
                "country_code": "CH",
                "country_id": 214,
                "id": 1645,
                "iso2": "BE",
                "name": "canton of Bern"
            },
            {
                "country_code": "CH",
                "country_id": 214,
                "id": 1646,
                "iso2": "ZG",
                "name": "Canton of Zug"
            },
            {
                "country_code": "CH",
                "country_id": 214,
                "id": 1647,
                "iso2": "GE",
                "name": "Canton of Geneva"
            },
            {
                "country_code": "CH",
                "country_id": 214,
                "id": 1648,
                "iso2": "VS",
                "name": "Canton of Valais"
            },
            {
                "country_code": "CH",
                "country_id": 214,
                "id": 1649,
                "iso2": "AI",
                "name": "Appenzell Innerrhoden"
            },
            {
                "country_code": "CH",
                "country_id": 214,
                "id": 1650,
                "iso2": "OW",
                "name": "Obwalden"
            },
            {
                "country_code": "CH",
                "country_id": 214,
                "id": 1651,
                "iso2": "VD",
                "name": "Canton of Vaud"
            },
            {
                "country_code": "CH",
                "country_id": 214,
                "id": 1652,
                "iso2": "NW",
                "name": "Nidwalden"
            },
            {
                "country_code": "CH",
                "country_id": 214,
                "id": 1653,
                "iso2": "SZ",
                "name": "Schwyz"
            },
            {
                "country_code": "CH",
                "country_id": 214,
                "id": 1654,
                "iso2": "SH",
                "name": "Canton of Schaffhausen"
            },
            {
                "country_code": "CH",
                "country_id": 214,
                "id": 1655,
                "iso2": "AR",
                "name": "Appenzell Ausserrhoden"
            },
            {
                "country_code": "CH",
                "country_id": 214,
                "id": 1656,
                "iso2": "ZH",
                "name": "canton of Zürich"
            },
            {
                "country_code": "CH",
                "country_id": 214,
                "id": 1657,
                "iso2": "TG",
                "name": "Thurgau"
            },
            {
                "country_code": "CH",
                "country_id": 214,
                "id": 1658,
                "iso2": "JU",
                "name": "Canton of Jura"
            },
            {
                "country_code": "CH",
                "country_id": 214,
                "id": 1659,
                "iso2": "NE",
                "name": "Canton of Neuchâtel"
            },
            {
                "country_code": "CH",
                "country_id": 214,
                "id": 1660,
                "iso2": "GR",
                "name": "Graubünden"
            },
            {
                "country_code": "CH",
                "country_id": 214,
                "id": 1661,
                "iso2": "GL",
                "name": "Glarus"
            },
            {
                "country_code": "CH",
                "country_id": 214,
                "id": 1662,
                "iso2": "SO",
                "name": "Canton of Solothurn"
            },
            {
                "country_code": "CH",
                "country_id": 214,
                "id": 1663,
                "iso2": "LU",
                "name": "Canton of Lucerne"
            }
        ]
    },
    {
        "id": 215,
        "name": "Syria",
        "iso3": "SYR",
        "iso2": "SY",
        "states": [
            {
                "country_code": "SY",
                "country_id": 215,
                "id": 2934,
                "iso2": "HM",
                "name": "Hama Governorate"
            },
            {
                "country_code": "SY",
                "country_id": 215,
                "id": 2935,
                "iso2": "RD",
                "name": "Rif Dimashq Governorate"
            },
            {
                "country_code": "SY",
                "country_id": 215,
                "id": 2936,
                "iso2": "SU",
                "name": "As-Suwayda Governorate"
            },
            {
                "country_code": "SY",
                "country_id": 215,
                "id": 2937,
                "iso2": "DY",
                "name": "Deir ez-Zor Governorate"
            },
            {
                "country_code": "SY",
                "country_id": 215,
                "id": 2938,
                "iso2": "LA",
                "name": "Latakia Governorate"
            },
            {
                "country_code": "SY",
                "country_id": 215,
                "id": 2939,
                "iso2": "DI",
                "name": "Damascus Governorate"
            },
            {
                "country_code": "SY",
                "country_id": 215,
                "id": 2940,
                "iso2": "ID",
                "name": "Idlib Governorate"
            },
            {
                "country_code": "SY",
                "country_id": 215,
                "id": 2941,
                "iso2": "HA",
                "name": "Al-Hasakah Governorate"
            },
            {
                "country_code": "SY",
                "country_id": 215,
                "id": 2942,
                "iso2": "HI",
                "name": "Homs Governorate"
            },
            {
                "country_code": "SY",
                "country_id": 215,
                "id": 2943,
                "iso2": "QU",
                "name": "Quneitra Governorate"
            },
            {
                "country_code": "SY",
                "country_id": 215,
                "id": 2944,
                "iso2": "RA",
                "name": "Al-Raqqah Governorate"
            },
            {
                "country_code": "SY",
                "country_id": 215,
                "id": 2945,
                "iso2": "DR",
                "name": "Daraa Governorate"
            },
            {
                "country_code": "SY",
                "country_id": 215,
                "id": 2946,
                "iso2": "HL",
                "name": "Aleppo Governorate"
            },
            {
                "country_code": "SY",
                "country_id": 215,
                "id": 2947,
                "iso2": "TA",
                "name": "Tartus Governorate"
            }
        ]
    },
    {
        "id": 216,
        "name": "Taiwan",
        "iso3": "TWN",
        "iso2": "TW",
        "states": [
            {
                "country_code": "TW",
                "country_id": 216,
                "id": 3401,
                "iso2": "TNQ",
                "name": "Tainan County"
            },
            {
                "country_code": "TW",
                "country_id": 216,
                "id": 3402,
                "iso2": "ILA",
                "name": "Yilan County"
            },
            {
                "country_code": "TW",
                "country_id": 216,
                "id": 3403,
                "iso2": "PEN",
                "name": "Penghu County"
            },
            {
                "country_code": "TW",
                "country_id": 216,
                "id": 3404,
                "iso2": "CHA",
                "name": "Changhua County"
            },
            {
                "country_code": "TW",
                "country_id": 216,
                "id": 3405,
                "iso2": "PIF",
                "name": "Pingtung County"
            },
            {
                "country_code": "TW",
                "country_id": 216,
                "id": 3406,
                "iso2": "TXG",
                "name": "Taichung"
            },
            {
                "country_code": "TW",
                "country_id": 216,
                "id": 3407,
                "iso2": "NAN",
                "name": "Nantou County"
            },
            {
                "country_code": "TW",
                "country_id": 216,
                "id": 3408,
                "iso2": "CYI",
                "name": "Chiayi County"
            },
            {
                "country_code": "TW",
                "country_id": 216,
                "id": 3409,
                "iso2": "KHQ",
                "name": "Kaohsiung County"
            },
            {
                "country_code": "TW",
                "country_id": 216,
                "id": 3410,
                "iso2": "TTT",
                "name": "Taitung County"
            },
            {
                "country_code": "TW",
                "country_id": 216,
                "id": 3411,
                "iso2": "HUA",
                "name": "Hualien County"
            },
            {
                "country_code": "TW",
                "country_id": 216,
                "id": 3412,
                "iso2": "KHH",
                "name": "Kaohsiung"
            },
            {
                "country_code": "TW",
                "country_id": 216,
                "id": 3413,
                "iso2": "MIA",
                "name": "Miaoli County"
            },
            {
                "country_code": "TW",
                "country_id": 216,
                "id": 3414,
                "iso2": "TXQ",
                "name": "Taichung County"
            },
            {
                "country_code": "TW",
                "country_id": 216,
                "id": 3415,
                "iso2": "KIN",
                "name": "Kinmen"
            },
            {
                "country_code": "TW",
                "country_id": 216,
                "id": 3416,
                "iso2": "YUN",
                "name": "Yunlin County"
            },
            {
                "country_code": "TW",
                "country_id": 216,
                "id": 3417,
                "iso2": "HSZ",
                "name": "Hsinchu"
            },
            {
                "country_code": "TW",
                "country_id": 216,
                "id": 3418,
                "iso2": "CYQ",
                "name": "Chiayi City"
            },
            {
                "country_code": "TW",
                "country_id": 216,
                "id": 3419,
                "iso2": "TAO",
                "name": "Taoyuan City"
            },
            {
                "country_code": "TW",
                "country_id": 216,
                "id": 3420,
                "iso2": "LIE",
                "name": "Lienchiang County"
            },
            {
                "country_code": "TW",
                "country_id": 216,
                "id": 3421,
                "iso2": "TNN",
                "name": "Tainan"
            },
            {
                "country_code": "TW",
                "country_id": 216,
                "id": 3422,
                "iso2": "TPE",
                "name": "Taipei"
            },
            {
                "country_code": "TW",
                "country_id": 216,
                "id": 3423,
                "iso2": "HSQ",
                "name": "Hsinchu County"
            }
        ]
    },
    {
        "id": 217,
        "name": "Tajikistan",
        "iso3": "TJK",
        "iso2": "TJ",
        "states": [
            {
                "country_code": "TJ",
                "country_id": 217,
                "id": 3397,
                "iso2": "RA",
                "name": "districts of Republican Subordination"
            },
            {
                "country_code": "TJ",
                "country_id": 217,
                "id": 3398,
                "iso2": "KT",
                "name": "Khatlon Province"
            },
            {
                "country_code": "TJ",
                "country_id": 217,
                "id": 3399,
                "iso2": "GB",
                "name": "Gorno-Badakhshan Autonomous Province"
            },
            {
                "country_code": "TJ",
                "country_id": 217,
                "id": 3400,
                "iso2": "SU",
                "name": "Sughd Province"
            }
        ]
    },
    {
        "id": 218,
        "name": "Tanzania",
        "iso3": "TZA",
        "iso2": "TZ",
        "states": [
            {
                "country_code": "TZ",
                "country_id": 218,
                "id": 1463,
                "iso2": "22",
                "name": "Shinyanga Region"
            },
            {
                "country_code": "TZ",
                "country_id": 218,
                "id": 1464,
                "iso2": "30",
                "name": "Simiyu Region"
            },
            {
                "country_code": "TZ",
                "country_id": 218,
                "id": 1465,
                "iso2": "5",
                "name": "Kagera Region"
            },
            {
                "country_code": "TZ",
                "country_id": 218,
                "id": 1466,
                "iso2": "3",
                "name": "Dodoma Region"
            },
            {
                "country_code": "TZ",
                "country_id": 218,
                "id": 1467,
                "iso2": "9",
                "name": "Kilimanjaro Region"
            },
            {
                "country_code": "TZ",
                "country_id": 218,
                "id": 1468,
                "iso2": "13",
                "name": "Mara Region"
            },
            {
                "country_code": "TZ",
                "country_id": 218,
                "id": 1469,
                "iso2": "24",
                "name": "Tabora Region"
            },
            {
                "country_code": "TZ",
                "country_id": 218,
                "id": 1470,
                "iso2": "16",
                "name": "Morogoro Region"
            },
            {
                "country_code": "TZ",
                "country_id": 218,
                "id": 1471,
                "iso2": "11",
                "name": "Zanzibar Central/South Region"
            },
            {
                "country_code": "TZ",
                "country_id": 218,
                "id": 1472,
                "iso2": "10",
                "name": "South Pemba Region"
            },
            {
                "country_code": "TZ",
                "country_id": 218,
                "id": 1473,
                "iso2": "7",
                "name": "Zanzibar North Region"
            },
            {
                "country_code": "TZ",
                "country_id": 218,
                "id": 1474,
                "iso2": "23",
                "name": "Singida Region"
            },
            {
                "country_code": "TZ",
                "country_id": 218,
                "id": 1475,
                "iso2": "15",
                "name": "Zanzibar Urban/West Region"
            },
            {
                "country_code": "TZ",
                "country_id": 218,
                "id": 1476,
                "iso2": "17",
                "name": "Mtwara Region"
            },
            {
                "country_code": "TZ",
                "country_id": 218,
                "id": 1477,
                "iso2": "20",
                "name": "Rukwa Region"
            },
            {
                "country_code": "TZ",
                "country_id": 218,
                "id": 1478,
                "iso2": "8",
                "name": "Kigoma Region"
            },
            {
                "country_code": "TZ",
                "country_id": 218,
                "id": 1479,
                "iso2": "18",
                "name": "Mwanza Region"
            },
            {
                "country_code": "TZ",
                "country_id": 218,
                "id": 1480,
                "iso2": "29",
                "name": "Njombe Region"
            },
            {
                "country_code": "TZ",
                "country_id": 218,
                "id": 1481,
                "iso2": "27",
                "name": "Geita Region"
            },
            {
                "country_code": "TZ",
                "country_id": 218,
                "id": 1482,
                "iso2": "28",
                "name": "Katavi Region"
            },
            {
                "country_code": "TZ",
                "country_id": 218,
                "id": 1483,
                "iso2": "12",
                "name": "Lindi Region"
            },
            {
                "country_code": "TZ",
                "country_id": 218,
                "id": 1484,
                "iso2": "26",
                "name": "Manyara Region"
            },
            {
                "country_code": "TZ",
                "country_id": 218,
                "id": 1485,
                "iso2": "19",
                "name": "Pwani Region"
            },
            {
                "country_code": "TZ",
                "country_id": 218,
                "id": 1486,
                "iso2": "21",
                "name": "Ruvuma Region"
            },
            {
                "country_code": "TZ",
                "country_id": 218,
                "id": 1487,
                "iso2": "25",
                "name": "Tanga Region"
            },
            {
                "country_code": "TZ",
                "country_id": 218,
                "id": 1488,
                "iso2": "6",
                "name": "North Pemba Region"
            },
            {
                "country_code": "TZ",
                "country_id": 218,
                "id": 1489,
                "iso2": "4",
                "name": "Iringa Region"
            },
            {
                "country_code": "TZ",
                "country_id": 218,
                "id": 1490,
                "iso2": "2",
                "name": "Dar es Salaam Region"
            },
            {
                "country_code": "TZ",
                "country_id": 218,
                "id": 1491,
                "iso2": "1",
                "name": "Arusha Region"
            }
        ]
    },
    {
        "id": 219,
        "name": "Thailand",
        "iso3": "THA",
        "iso2": "TH",
        "states": [
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3478,
                "iso2": "81",
                "name": "Krabi"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3479,
                "iso2": "85",
                "name": "Ranong"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3480,
                "iso2": "39",
                "name": "Nong Bua Lam Phu"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3481,
                "iso2": "11",
                "name": "Samut Prakan"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3482,
                "iso2": "84",
                "name": "Surat Thani"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3483,
                "iso2": "51",
                "name": "Lamphun"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3484,
                "iso2": "43",
                "name": "Nong Khai"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3485,
                "iso2": "40",
                "name": "Khon Kaen"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3486,
                "iso2": "22",
                "name": "Chanthaburi"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3487,
                "iso2": "19",
                "name": "Saraburi"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3488,
                "iso2": "93",
                "name": "Phatthalung"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3489,
                "iso2": "53",
                "name": "Uttaradit"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3490,
                "iso2": "17",
                "name": "Sing Buri"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3491,
                "iso2": "50",
                "name": "Chiang Mai"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3492,
                "iso2": "60",
                "name": "Nakhon Sawan"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3493,
                "iso2": "95",
                "name": "Yala"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3494,
                "iso2": "14",
                "name": "Phra Nakhon Si Ayutthaya"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3495,
                "iso2": "12",
                "name": "Nonthaburi"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3496,
                "iso2": "23",
                "name": "Trat"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3497,
                "iso2": "30",
                "name": "Nakhon Ratchasima"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3498,
                "iso2": "57",
                "name": "Chiang Rai"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3499,
                "iso2": "70",
                "name": "Ratchaburi"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3500,
                "iso2": "13",
                "name": "Pathum Thani"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3501,
                "iso2": "47",
                "name": "Sakon Nakhon"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3502,
                "iso2": "75",
                "name": "Samut Songkhram"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3503,
                "iso2": "73",
                "name": "Nakhon Pathom"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3504,
                "iso2": "74",
                "name": "Samut Sakhon"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3505,
                "iso2": "58",
                "name": "Mae Hong Son"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3506,
                "iso2": "65",
                "name": "Phitsanulok"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3507,
                "iso2": "S",
                "name": "Pattaya"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3508,
                "iso2": "77",
                "name": "Prachuap Khiri Khan"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3509,
                "iso2": "42",
                "name": "Loei"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3510,
                "iso2": "45",
                "name": "Roi Et"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3511,
                "iso2": "71",
                "name": "Kanchanaburi"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3512,
                "iso2": "34",
                "name": "Ubon Ratchathani"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3513,
                "iso2": "20",
                "name": "Chon Buri"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3514,
                "iso2": "66",
                "name": "Phichit"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3515,
                "iso2": "67",
                "name": "Phetchabun"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3516,
                "iso2": "62",
                "name": "Kamphaeng Phet"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3517,
                "iso2": "44",
                "name": "Maha Sarakham"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3518,
                "iso2": "21",
                "name": "Rayong"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3519,
                "iso2": "15",
                "name": "Ang Thong"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3520,
                "iso2": "80",
                "name": "Nakhon Si Thammarat"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3521,
                "iso2": "35",
                "name": "Yasothon"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3522,
                "iso2": "18",
                "name": "Chai Nat"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3523,
                "iso2": "37",
                "name": "Amnat Charoen"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3524,
                "iso2": "72",
                "name": "Suphanburi"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3525,
                "iso2": "63",
                "name": "Tak"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3526,
                "iso2": "86",
                "name": "Chumphon"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3527,
                "iso2": "41",
                "name": "Udon Thani"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3528,
                "iso2": "54",
                "name": "Phrae"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3529,
                "iso2": "27",
                "name": "Sa Kaeo"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3530,
                "iso2": "55",
                "name": "Nan"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3531,
                "iso2": "32",
                "name": "Surin"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3532,
                "iso2": "76",
                "name": "Phetchaburi"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3533,
                "iso2": "38",
                "name": "Bueng Kan"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3534,
                "iso2": "31",
                "name": "Buri Ram"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3535,
                "iso2": "26",
                "name": "Nakhon Nayok"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3536,
                "iso2": "83",
                "name": "Phuket"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3537,
                "iso2": "91",
                "name": "Satun"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3538,
                "iso2": "56",
                "name": "Phayao"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3539,
                "iso2": "90",
                "name": "Songkhla"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3540,
                "iso2": "94",
                "name": "Pattani"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3541,
                "iso2": "92",
                "name": "Trang"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3542,
                "iso2": "25",
                "name": "Prachin Buri"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3543,
                "iso2": "16",
                "name": "Lopburi"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3544,
                "iso2": "52",
                "name": "Lampang"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3545,
                "iso2": "64",
                "name": "Sukhothai"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3546,
                "iso2": "49",
                "name": "Mukdahan"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3547,
                "iso2": "33",
                "name": "Si Sa Ket"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3548,
                "iso2": "48",
                "name": "Nakhon Phanom"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3549,
                "iso2": "82",
                "name": "Phang Nga"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3550,
                "iso2": "46",
                "name": "Kalasin"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3551,
                "iso2": "61",
                "name": "Uthai Thani"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3552,
                "iso2": "24",
                "name": "Chachoengsao"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3553,
                "iso2": "96",
                "name": "Narathiwat"
            },
            {
                "country_code": "TH",
                "country_id": 219,
                "id": 3554,
                "iso2": "10",
                "name": "Bangkok"
            }
        ]
    },
    {
        "id": 220,
        "name": "Togo",
        "iso3": "TGO",
        "iso2": "TG",
        "states": [
            {
                "country_code": "TG",
                "country_id": 220,
                "id": 2575,
                "iso2": "C",
                "name": "Centrale Region"
            },
            {
                "country_code": "TG",
                "country_id": 220,
                "id": 2576,
                "iso2": "M",
                "name": "Maritime"
            },
            {
                "country_code": "TG",
                "country_id": 220,
                "id": 2577,
                "iso2": "P",
                "name": "Plateaux Region"
            },
            {
                "country_code": "TG",
                "country_id": 220,
                "id": 2578,
                "iso2": "S",
                "name": "Savanes Region"
            },
            {
                "country_code": "TG",
                "country_id": 220,
                "id": 2579,
                "iso2": "K",
                "name": "Kara Region"
            }
        ]
    },
    {
        "id": 221,
        "name": "Tokelau",
        "iso3": "TKL",
        "iso2": "TK",
        "states": []
    },
    {
        "id": 222,
        "name": "Tonga",
        "iso3": "TON",
        "iso2": "TO",
        "states": [
            {
                "country_code": "TO",
                "country_id": 222,
                "id": 3911,
                "iso2": "5",
                "name": "Vavaʻu"
            },
            {
                "country_code": "TO",
                "country_id": 222,
                "id": 3912,
                "iso2": "4",
                "name": "Tongatapu"
            },
            {
                "country_code": "TO",
                "country_id": 222,
                "id": 3913,
                "iso2": "2",
                "name": "Haʻapai"
            },
            {
                "country_code": "TO",
                "country_id": 222,
                "id": 3914,
                "iso2": "3",
                "name": "Niuas"
            },
            {
                "country_code": "TO",
                "country_id": 222,
                "id": 3915,
                "iso2": "1",
                "name": "ʻEua"
            }
        ]
    },
    {
        "id": 223,
        "name": "Trinidad And Tobago",
        "iso3": "TTO",
        "iso2": "TT",
        "states": [
            {
                "country_code": "TT",
                "country_id": 223,
                "id": 3353,
                "iso2": "WTO",
                "name": "Western Tobago"
            },
            {
                "country_code": "TT",
                "country_id": 223,
                "id": 3354,
                "iso2": "CTT",
                "name": "Couva-Tabaquite-Talparo Regional Corporation"
            },
            {
                "country_code": "TT",
                "country_id": 223,
                "id": 3355,
                "iso2": "ETO",
                "name": "Eastern Tobago"
            },
            {
                "country_code": "TT",
                "country_id": 223,
                "id": 3356,
                "iso2": "MRC",
                "name": "Rio Claro-Mayaro Regional Corporation"
            },
            {
                "country_code": "TT",
                "country_id": 223,
                "id": 3357,
                "iso2": "SJL",
                "name": "San Juan-Laventille Regional Corporation"
            },
            {
                "country_code": "TT",
                "country_id": 223,
                "id": 3358,
                "iso2": "TUP",
                "name": "Tunapuna-Piarco Regional Corporation"
            },
            {
                "country_code": "TT",
                "country_id": 223,
                "id": 3359,
                "iso2": "SFO",
                "name": "San Fernando"
            },
            {
                "country_code": "TT",
                "country_id": 223,
                "id": 3360,
                "iso2": "PTF",
                "name": "Point Fortin"
            },
            {
                "country_code": "TT",
                "country_id": 223,
                "id": 3361,
                "iso2": "SGE",
                "name": "Sangre Grande Regional Corporation"
            },
            {
                "country_code": "TT",
                "country_id": 223,
                "id": 3362,
                "iso2": "ARI",
                "name": "Arima"
            },
            {
                "country_code": "TT",
                "country_id": 223,
                "id": 3363,
                "iso2": "POS",
                "name": "Port of Spain"
            },
            {
                "country_code": "TT",
                "country_id": 223,
                "id": 3364,
                "iso2": "SIP",
                "name": "Siparia Regional Corporation"
            },
            {
                "country_code": "TT",
                "country_id": 223,
                "id": 3365,
                "iso2": "PED",
                "name": "Penal-Debe Regional Corporation"
            },
            {
                "country_code": "TT",
                "country_id": 223,
                "id": 3366,
                "iso2": "CHA",
                "name": "Chaguanas"
            },
            {
                "country_code": "TT",
                "country_id": 223,
                "id": 3367,
                "iso2": "DMN",
                "name": "Diego Martin Regional Corporation"
            },
            {
                "country_code": "TT",
                "country_id": 223,
                "id": 3368,
                "iso2": "PRT",
                "name": "Princes Town Regional Corporation"
            }
        ]
    },
    {
        "id": 224,
        "name": "Tunisia",
        "iso3": "TUN",
        "iso2": "TN",
        "states": [
            {
                "country_code": "TN",
                "country_id": 224,
                "id": 2550,
                "iso2": "12",
                "name": "Ariana Governorate"
            },
            {
                "country_code": "TN",
                "country_id": 224,
                "id": 2551,
                "iso2": "23",
                "name": "Bizerte Governorate"
            },
            {
                "country_code": "TN",
                "country_id": 224,
                "id": 2552,
                "iso2": "32",
                "name": "Jendouba Governorate"
            },
            {
                "country_code": "TN",
                "country_id": 224,
                "id": 2553,
                "iso2": "52",
                "name": "Monastir Governorate"
            },
            {
                "country_code": "TN",
                "country_id": 224,
                "id": 2554,
                "iso2": "11",
                "name": "Tunis Governorate"
            },
            {
                "country_code": "TN",
                "country_id": 224,
                "id": 2555,
                "iso2": "14",
                "name": "Manouba Governorate"
            },
            {
                "country_code": "TN",
                "country_id": 224,
                "id": 2556,
                "iso2": "71",
                "name": "Gafsa Governorate"
            },
            {
                "country_code": "TN",
                "country_id": 224,
                "id": 2557,
                "iso2": "61",
                "name": "Sfax Governorate"
            },
            {
                "country_code": "TN",
                "country_id": 224,
                "id": 2558,
                "iso2": "81",
                "name": "Gabès Governorate"
            },
            {
                "country_code": "TN",
                "country_id": 224,
                "id": 2559,
                "iso2": "83",
                "name": "Tataouine Governorate"
            },
            {
                "country_code": "TN",
                "country_id": 224,
                "id": 2560,
                "iso2": "82",
                "name": "Medenine Governorate"
            },
            {
                "country_code": "TN",
                "country_id": 224,
                "id": 2561,
                "iso2": "33",
                "name": "Kef Governorate"
            },
            {
                "country_code": "TN",
                "country_id": 224,
                "id": 2562,
                "iso2": "73",
                "name": "Kebili Governorate"
            },
            {
                "country_code": "TN",
                "country_id": 224,
                "id": 2563,
                "iso2": "34",
                "name": "Siliana Governorate"
            },
            {
                "country_code": "TN",
                "country_id": 224,
                "id": 2564,
                "iso2": "41",
                "name": "Kairouan Governorate"
            },
            {
                "country_code": "TN",
                "country_id": 224,
                "id": 2565,
                "iso2": "22",
                "name": "Zaghouan Governorate"
            },
            {
                "country_code": "TN",
                "country_id": 224,
                "id": 2566,
                "iso2": "13",
                "name": "Ben Arous Governorate"
            },
            {
                "country_code": "TN",
                "country_id": 224,
                "id": 2567,
                "iso2": "43",
                "name": "Sidi Bouzid Governorate"
            },
            {
                "country_code": "TN",
                "country_id": 224,
                "id": 2568,
                "iso2": "53",
                "name": "Mahdia Governorate"
            },
            {
                "country_code": "TN",
                "country_id": 224,
                "id": 2569,
                "iso2": "72",
                "name": "Tozeur Governorate"
            },
            {
                "country_code": "TN",
                "country_id": 224,
                "id": 2570,
                "iso2": "42",
                "name": "Kasserine Governorate"
            },
            {
                "country_code": "TN",
                "country_id": 224,
                "id": 2571,
                "iso2": "51",
                "name": "Sousse Governorate"
            },
            {
                "country_code": "TN",
                "country_id": 224,
                "id": 2572,
                "iso2": "31",
                "name": "Kassrine"
            }
        ]
    },
    {
        "id": 225,
        "name": "Turkey",
        "iso3": "TUR",
        "iso2": "TR",
        "states": [
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2148,
                "iso2": "74",
                "name": "Bartın Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2149,
                "iso2": "43",
                "name": "Kütahya Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2150,
                "iso2": "54",
                "name": "Sakarya Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2151,
                "iso2": "22",
                "name": "Edirne Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2152,
                "iso2": "65",
                "name": "Van Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2153,
                "iso2": "12",
                "name": "Bingöl Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2154,
                "iso2": "79",
                "name": "Kilis Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2155,
                "iso2": "2",
                "name": "Adıyaman Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2156,
                "iso2": "33",
                "name": "Mersin Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2157,
                "iso2": "20",
                "name": "Denizli Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2158,
                "iso2": "44",
                "name": "Malatya Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2159,
                "iso2": "23",
                "name": "Elazığ Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2160,
                "iso2": "24",
                "name": "Erzincan Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2161,
                "iso2": "5",
                "name": "Amasya Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2162,
                "iso2": "49",
                "name": "Muş Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2163,
                "iso2": "16",
                "name": "Bursa Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2164,
                "iso2": "26",
                "name": "Eskişehir Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2165,
                "iso2": "25",
                "name": "Erzurum Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2166,
                "iso2": "76",
                "name": "Iğdır Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2167,
                "iso2": "59",
                "name": "Tekirdağ Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2168,
                "iso2": "18",
                "name": "Çankırı Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2169,
                "iso2": "7",
                "name": "Antalya Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2170,
                "iso2": "34",
                "name": "Istanbul Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2171,
                "iso2": "42",
                "name": "Konya Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2172,
                "iso2": "14",
                "name": "Bolu Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2173,
                "iso2": "19",
                "name": "Çorum Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2174,
                "iso2": "52",
                "name": "Ordu Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2175,
                "iso2": "10",
                "name": "Balıkesir Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2176,
                "iso2": "39",
                "name": "Kırklareli Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2177,
                "iso2": "69",
                "name": "Bayburt Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2178,
                "iso2": "71",
                "name": "Kırıkkale Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2179,
                "iso2": "3",
                "name": "Afyonkarahisar Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2180,
                "iso2": "40",
                "name": "Kırşehir Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2181,
                "iso2": "58",
                "name": "Sivas Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2182,
                "iso2": "48",
                "name": "Muğla Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2183,
                "iso2": "63",
                "name": "Şanlıurfa Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2184,
                "iso2": "70",
                "name": "Karaman Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2185,
                "iso2": "75",
                "name": "Ardahan Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2186,
                "iso2": "28",
                "name": "Giresun Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2187,
                "iso2": "9",
                "name": "Aydın Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2188,
                "iso2": "66",
                "name": "Yozgat Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2189,
                "iso2": "51",
                "name": "Niğde Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2190,
                "iso2": "30",
                "name": "Hakkâri Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2191,
                "iso2": "8",
                "name": "Artvin Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2192,
                "iso2": "62",
                "name": "Tunceli Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2193,
                "iso2": "4",
                "name": "Ağrı Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2194,
                "iso2": "72",
                "name": "Batman Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2195,
                "iso2": "41",
                "name": "Kocaeli Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2196,
                "iso2": "50",
                "name": "Nevşehir Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2197,
                "iso2": "37",
                "name": "Kastamonu Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2198,
                "iso2": "45",
                "name": "Manisa Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2199,
                "iso2": "60",
                "name": "Tokat Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2200,
                "iso2": "38",
                "name": "Kayseri Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2201,
                "iso2": "64",
                "name": "Uşak Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2202,
                "iso2": "81",
                "name": "Düzce Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2203,
                "iso2": "27",
                "name": "Gaziantep Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2204,
                "iso2": "29",
                "name": "Gümüşhane Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2205,
                "iso2": "35",
                "name": "İzmir Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2206,
                "iso2": "61",
                "name": "Trabzon Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2207,
                "iso2": "56",
                "name": "Siirt Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2208,
                "iso2": "36",
                "name": "Kars Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2209,
                "iso2": "15",
                "name": "Burdur Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2210,
                "iso2": "68",
                "name": "Aksaray Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2211,
                "iso2": "31",
                "name": "Hatay Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2212,
                "iso2": "1",
                "name": "Adana Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2213,
                "iso2": "67",
                "name": "Zonguldak Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2214,
                "iso2": "80",
                "name": "Osmaniye Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2215,
                "iso2": "13",
                "name": "Bitlis Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2216,
                "iso2": "17",
                "name": "Çanakkale Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2217,
                "iso2": "6",
                "name": "Ankara Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2218,
                "iso2": "77",
                "name": "Yalova Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2219,
                "iso2": "53",
                "name": "Rize Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2220,
                "iso2": "55",
                "name": "Samsun Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2221,
                "iso2": "11",
                "name": "Bilecik Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2222,
                "iso2": "32",
                "name": "Isparta Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2223,
                "iso2": "78",
                "name": "Karabük Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2224,
                "iso2": "47",
                "name": "Mardin Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2225,
                "iso2": "73",
                "name": "Şırnak Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2226,
                "iso2": "21",
                "name": "Diyarbakır Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 2227,
                "iso2": "46",
                "name": "Kahramanmaraş Province"
            },
            {
                "country_code": "TR",
                "country_id": 225,
                "id": 4854,
                "iso2": "57",
                "name": "Sinop Province"
            }
        ]
    },
    {
        "id": 226,
        "name": "Turkmenistan",
        "iso3": "TKM",
        "iso2": "TM",
        "states": [
            {
                "country_code": "TM",
                "country_id": 226,
                "id": 3369,
                "iso2": "M",
                "name": "Mary Region"
            },
            {
                "country_code": "TM",
                "country_id": 226,
                "id": 3370,
                "iso2": "L",
                "name": "Lebap Region"
            },
            {
                "country_code": "TM",
                "country_id": 226,
                "id": 3371,
                "iso2": "S",
                "name": "Ashgabat"
            },
            {
                "country_code": "TM",
                "country_id": 226,
                "id": 3372,
                "iso2": "B",
                "name": "Balkan Region"
            },
            {
                "country_code": "TM",
                "country_id": 226,
                "id": 3373,
                "iso2": "D",
                "name": "Daşoguz Region"
            },
            {
                "country_code": "TM",
                "country_id": 226,
                "id": 3374,
                "iso2": "A",
                "name": "Ahal Region"
            }
        ]
    },
    {
        "id": 227,
        "name": "Turks And Caicos Islands",
        "iso3": "TCA",
        "iso2": "TC",
        "states": []
    },
    {
        "id": 228,
        "name": "Tuvalu",
        "iso3": "TUV",
        "iso2": "TV",
        "states": [
            {
                "country_code": "TV",
                "country_id": 228,
                "id": 3946,
                "iso2": "NIT",
                "name": "Niutao Island Council"
            },
            {
                "country_code": "TV",
                "country_id": 228,
                "id": 3947,
                "iso2": "NMG",
                "name": "Nanumanga"
            },
            {
                "country_code": "TV",
                "country_id": 228,
                "id": 3948,
                "iso2": "NUI",
                "name": "Nui"
            },
            {
                "country_code": "TV",
                "country_id": 228,
                "id": 3949,
                "iso2": "NMA",
                "name": "Nanumea"
            },
            {
                "country_code": "TV",
                "country_id": 228,
                "id": 3950,
                "iso2": "VAI",
                "name": "Vaitupu"
            },
            {
                "country_code": "TV",
                "country_id": 228,
                "id": 3951,
                "iso2": "FUN",
                "name": "Funafuti"
            },
            {
                "country_code": "TV",
                "country_id": 228,
                "id": 3952,
                "iso2": "NKF",
                "name": "Nukufetau"
            },
            {
                "country_code": "TV",
                "country_id": 228,
                "id": 3953,
                "iso2": "NKL",
                "name": "Nukulaelae"
            }
        ]
    },
    {
        "id": 229,
        "name": "Uganda",
        "iso3": "UGA",
        "iso2": "UG",
        "states": [
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 324,
                "iso2": "412",
                "name": "Rukungiri District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 325,
                "iso2": "123",
                "name": "Kyankwanzi District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 326,
                "iso2": "405",
                "name": "Kabarole District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 327,
                "iso2": "106",
                "name": "Mpigi District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 328,
                "iso2": "302",
                "name": "Apac District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 329,
                "iso2": "314",
                "name": "Abim District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 330,
                "iso2": "313",
                "name": "Yumbe District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 331,
                "iso2": "431",
                "name": "Rukiga District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 332,
                "iso2": "N",
                "name": "Northern Region"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 333,
                "iso2": "232",
                "name": "Serere District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 334,
                "iso2": "205",
                "name": "Kamuli District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 335,
                "iso2": "316",
                "name": "Amuru District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 336,
                "iso2": "213",
                "name": "Kaberamaido District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 337,
                "iso2": "224",
                "name": "Namutumba District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 338,
                "iso2": "227",
                "name": "Kibuku District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 339,
                "iso2": "417",
                "name": "Ibanda District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 340,
                "iso2": "203",
                "name": "Iganga District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 341,
                "iso2": "317",
                "name": "Dokolo District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 342,
                "iso2": "307",
                "name": "Lira District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 343,
                "iso2": "219",
                "name": "Bukedea District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 344,
                "iso2": "323",
                "name": "Alebtong District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 345,
                "iso2": "319",
                "name": "Koboko District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 346,
                "iso2": "421",
                "name": "Kiryandongo District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 347,
                "iso2": "103",
                "name": "Kiboga District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 348,
                "iso2": "305",
                "name": "Kitgum District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 349,
                "iso2": "218",
                "name": "Bududa District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 350,
                "iso2": "209",
                "name": "Mbale District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 351,
                "iso2": "230",
                "name": "Namayingo District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 352,
                "iso2": "216",
                "name": "Amuria District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 353,
                "iso2": "324",
                "name": "Amudat District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 354,
                "iso2": "409",
                "name": "Masindi District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 355,
                "iso2": "419",
                "name": "Kiruhura District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 356,
                "iso2": "105",
                "name": "Masaka District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 357,
                "iso2": "332",
                "name": "Pakwach District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 358,
                "iso2": "429",
                "name": "Rubanda District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 359,
                "iso2": "212",
                "name": "Tororo District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 360,
                "iso2": "413",
                "name": "Kamwenge District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 361,
                "iso2": "301",
                "name": "Adjumani District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 362,
                "iso2": "113",
                "name": "Wakiso District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 363,
                "iso2": "309",
                "name": "Moyo District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 364,
                "iso2": "115",
                "name": "Mityana District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 365,
                "iso2": "221",
                "name": "Butaleja District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 366,
                "iso2": "121",
                "name": "Gomba District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 367,
                "iso2": "204",
                "name": "Jinja District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 368,
                "iso2": "112",
                "name": "Kayunga District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 369,
                "iso2": "228",
                "name": "Kween District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 370,
                "iso2": "W",
                "name": "Western Region"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 371,
                "iso2": "107",
                "name": "Mubende District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 372,
                "iso2": "E",
                "name": "Eastern Region"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 373,
                "iso2": "414",
                "name": "Kanungu District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 374,
                "iso2": "331",
                "name": "Omoro District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 375,
                "iso2": "118",
                "name": "Bukomansimbi District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 376,
                "iso2": "114",
                "name": "Lyantonde District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 377,
                "iso2": "117",
                "name": "Buikwe District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 378,
                "iso2": "328",
                "name": "Nwoya District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 379,
                "iso2": "330",
                "name": "Zombo District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 380,
                "iso2": "226",
                "name": "Buyende District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 381,
                "iso2": "430",
                "name": "Bunyangabu District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 382,
                "iso2": "102",
                "name": "Kampala District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 383,
                "iso2": "418",
                "name": "Isingiro District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 384,
                "iso2": "119",
                "name": "Butambala District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 385,
                "iso2": "220",
                "name": "Bukwo District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 386,
                "iso2": "402",
                "name": "Bushenyi District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 387,
                "iso2": "201",
                "name": "Bugiri District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 388,
                "iso2": "233",
                "name": "Butebo District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 389,
                "iso2": "416",
                "name": "Buliisa District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 390,
                "iso2": "329",
                "name": "Otuke District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 391,
                "iso2": "420",
                "name": "Buhweju District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 392,
                "iso2": "322",
                "name": "Agago District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 393,
                "iso2": "311",
                "name": "Nakapiripirit District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 394,
                "iso2": "122",
                "name": "Kalungu District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 395,
                "iso2": "308",
                "name": "Moroto District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 396,
                "iso2": "C",
                "name": "Central Region"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 397,
                "iso2": "321",
                "name": "Oyam District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 398,
                "iso2": "222",
                "name": "Kaliro District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 399,
                "iso2": "428",
                "name": "Kakumiro District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 400,
                "iso2": "234",
                "name": "Namisindwa District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 401,
                "iso2": "325",
                "name": "Kole District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 402,
                "iso2": "415",
                "name": "Kyenjojo District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 403,
                "iso2": "427",
                "name": "Kagadi District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 404,
                "iso2": "411",
                "name": "Ntungamo District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 405,
                "iso2": "101",
                "name": "Kalangala District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 406,
                "iso2": "109",
                "name": "Nakasongola District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 407,
                "iso2": "426",
                "name": "Sheema District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 408,
                "iso2": "312",
                "name": "Pader District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 409,
                "iso2": "408",
                "name": "Kisoro District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 410,
                "iso2": "108",
                "name": "Mukono District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 411,
                "iso2": "326",
                "name": "Lamwo District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 412,
                "iso2": "210",
                "name": "Pallisa District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 413,
                "iso2": "304",
                "name": "Gulu District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 414,
                "iso2": "120",
                "name": "Buvuma District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 415,
                "iso2": "410",
                "name": "Mbarara District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 416,
                "iso2": "315",
                "name": "Amolatar District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 417,
                "iso2": "124",
                "name": "Lwengo District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 418,
                "iso2": "214",
                "name": "Mayuge District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 419,
                "iso2": "401",
                "name": "Bundibugyo District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 420,
                "iso2": "207",
                "name": "Katakwi District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 421,
                "iso2": "320",
                "name": "Maracha District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 422,
                "iso2": "424",
                "name": "Ntoroko District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 423,
                "iso2": "116",
                "name": "Nakaseke District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 424,
                "iso2": "231",
                "name": "Ngora District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 425,
                "iso2": "208",
                "name": "Kumi District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 426,
                "iso2": "404",
                "name": "Kabale District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 427,
                "iso2": "111",
                "name": "Sembabule District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 428,
                "iso2": "225",
                "name": "Bulambuli District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 429,
                "iso2": "215",
                "name": "Sironko District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 430,
                "iso2": "327",
                "name": "Napak District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 431,
                "iso2": "202",
                "name": "Busia District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 432,
                "iso2": "206",
                "name": "Kapchorwa District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 433,
                "iso2": "104",
                "name": "Luwero District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 434,
                "iso2": "318",
                "name": "Kaabong District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 435,
                "iso2": "423",
                "name": "Mitooma District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 436,
                "iso2": "407",
                "name": "Kibaale District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 437,
                "iso2": "422",
                "name": "Kyegegwa District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 438,
                "iso2": "223",
                "name": "Manafwa District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 439,
                "iso2": "110",
                "name": "Rakai District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 440,
                "iso2": "406",
                "name": "Kasese District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 441,
                "iso2": "217",
                "name": "Budaka District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 442,
                "iso2": "425",
                "name": "Rubirizi District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 443,
                "iso2": "306",
                "name": "Kotido District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 444,
                "iso2": "211",
                "name": "Soroti District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 445,
                "iso2": "229",
                "name": "Luuka District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 446,
                "iso2": "310",
                "name": "Nebbi District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 447,
                "iso2": "303",
                "name": "Arua District"
            },
            {
                "country_code": "UG",
                "country_id": 229,
                "id": 448,
                "iso2": "125",
                "name": "Kyotera District"
            }
        ]
    },
    {
        "id": 230,
        "name": "Ukraine",
        "iso3": "UKR",
        "iso2": "UA",
        "states": [
            {
                "country_code": "UA",
                "country_id": 230,
                "id": 4668,
                "iso2": "18",
                "name": "Zhytomyr Oblast"
            },
            {
                "country_code": "UA",
                "country_id": 230,
                "id": 4669,
                "iso2": "5",
                "name": "Vinnytsia Oblast"
            },
            {
                "country_code": "UA",
                "country_id": 230,
                "id": 4670,
                "iso2": "21",
                "name": "Zakarpattia Oblast"
            },
            {
                "country_code": "UA",
                "country_id": 230,
                "id": 4671,
                "iso2": "32",
                "name": "Kyiv Oblast"
            },
            {
                "country_code": "UA",
                "country_id": 230,
                "id": 4672,
                "iso2": "46",
                "name": "Lviv Oblast"
            },
            {
                "country_code": "UA",
                "country_id": 230,
                "id": 4673,
                "iso2": "9",
                "name": "Luhansk Oblast"
            },
            {
                "country_code": "UA",
                "country_id": 230,
                "id": 4674,
                "iso2": "61",
                "name": "Ternopil Oblast"
            },
            {
                "country_code": "UA",
                "country_id": 230,
                "id": 4675,
                "iso2": "12",
                "name": "Dnipropetrovsk Oblast"
            },
            {
                "country_code": "UA",
                "country_id": 230,
                "id": 4676,
                "iso2": "30",
                "name": "Kiev"
            },
            {
                "country_code": "UA",
                "country_id": 230,
                "id": 4677,
                "iso2": "35",
                "name": "Kirovohrad Oblast"
            },
            {
                "country_code": "UA",
                "country_id": 230,
                "id": 4678,
                "iso2": "77",
                "name": "Chernivtsi Oblast"
            },
            {
                "country_code": "UA",
                "country_id": 230,
                "id": 4679,
                "iso2": "48",
                "name": "Mykolaiv Oblast"
            },
            {
                "country_code": "UA",
                "country_id": 230,
                "id": 4680,
                "iso2": "71",
                "name": "Cherkasy Oblast"
            },
            {
                "country_code": "UA",
                "country_id": 230,
                "id": 4681,
                "iso2": "68",
                "name": "Khmelnytsky Oblast"
            },
            {
                "country_code": "UA",
                "country_id": 230,
                "id": 4682,
                "iso2": "26",
                "name": "Ivano-Frankivsk Oblast"
            },
            {
                "country_code": "UA",
                "country_id": 230,
                "id": 4683,
                "iso2": "56",
                "name": "Rivne Oblast"
            },
            {
                "country_code": "UA",
                "country_id": 230,
                "id": 4684,
                "iso2": "65",
                "name": "Kherson Oblast"
            },
            {
                "country_code": "UA",
                "country_id": 230,
                "id": 4685,
                "iso2": "59",
                "name": "Sumy Oblast"
            },
            {
                "country_code": "UA",
                "country_id": 230,
                "id": 4686,
                "iso2": "63",
                "name": "Kharkiv Oblast"
            },
            {
                "country_code": "UA",
                "country_id": 230,
                "id": 4687,
                "iso2": "23",
                "name": "Zaporizhzhya Oblast"
            },
            {
                "country_code": "UA",
                "country_id": 230,
                "id": 4688,
                "iso2": "51",
                "name": "Odessa Oblast"
            },
            {
                "country_code": "UA",
                "country_id": 230,
                "id": 4689,
                "iso2": "43",
                "name": "Autonomous Republic of Crimea"
            },
            {
                "country_code": "UA",
                "country_id": 230,
                "id": 4690,
                "iso2": "7",
                "name": "Volyn Oblast"
            },
            {
                "country_code": "UA",
                "country_id": 230,
                "id": 4691,
                "iso2": "14",
                "name": "Donetsk Oblast"
            },
            {
                "country_code": "UA",
                "country_id": 230,
                "id": 4692,
                "iso2": "74",
                "name": "Chernihiv Oblast"
            }
        ]
    },
    {
        "id": 231,
        "name": "United Arab Emirates",
        "iso3": "ARE",
        "iso2": "AE",
        "states": [
            {
                "country_code": "AE",
                "country_id": 231,
                "id": 3390,
                "iso2": "SH",
                "name": "Sharjah Emirate"
            },
            {
                "country_code": "AE",
                "country_id": 231,
                "id": 3391,
                "iso2": "DU",
                "name": "Dubai"
            },
            {
                "country_code": "AE",
                "country_id": 231,
                "id": 3392,
                "iso2": "UQ",
                "name": "Umm al-Quwain"
            },
            {
                "country_code": "AE",
                "country_id": 231,
                "id": 3393,
                "iso2": "FU",
                "name": "Fujairah"
            },
            {
                "country_code": "AE",
                "country_id": 231,
                "id": 3394,
                "iso2": "RK",
                "name": "Ras al-Khaimah"
            },
            {
                "country_code": "AE",
                "country_id": 231,
                "id": 3395,
                "iso2": "AJ",
                "name": "Ajman Emirate"
            },
            {
                "country_code": "AE",
                "country_id": 231,
                "id": 3396,
                "iso2": "AZ",
                "name": "Abu Dhabi Emirate"
            }
        ]
    },


    {
        "id": 234,
        "name": "United States Minor Outlying Islands",
        "iso3": "UMI",
        "iso2": "UM",
        "states": [
            {
                "country_code": "UM",
                "country_id": 234,
                "id": 1398,
                "iso2": "UM-84",
                "name": "Howland Island"
            },
            {
                "country_code": "UM",
                "country_id": 234,
                "id": 1402,
                "iso2": "UM-81",
                "name": "Baker Island"
            },
            {
                "country_code": "UM",
                "country_id": 234,
                "id": 1403,
                "iso2": "UM-89",
                "name": "Kingman Reef"
            },
            {
                "country_code": "UM",
                "country_id": 234,
                "id": 1405,
                "iso2": "UM-79",
                "name": "Wake Island"
            },
            {
                "country_code": "UM",
                "country_id": 234,
                "id": 1428,
                "iso2": "UM-67",
                "name": "Johnston Atoll"
            },
            {
                "country_code": "UM",
                "country_id": 234,
                "id": 1438,
                "iso2": "UM-71",
                "name": "Midway Atoll"
            },
            {
                "country_code": "UM",
                "country_id": 234,
                "id": 1439,
                "iso2": "UM-76",
                "name": "Navassa Island"
            },
            {
                "country_code": "UM",
                "country_id": 234,
                "id": 1448,
                "iso2": "UM-95",
                "name": "Palmyra Atoll"
            }
        ]
    },
    {
        "id": 235,
        "name": "Uruguay",
        "iso3": "URY",
        "iso2": "UY",
        "states": [
            {
                "country_code": "UY",
                "country_id": 235,
                "id": 3203,
                "iso2": "FS",
                "name": "Flores Department"
            },
            {
                "country_code": "UY",
                "country_id": 235,
                "id": 3204,
                "iso2": "SJ",
                "name": "San José Department"
            },
            {
                "country_code": "UY",
                "country_id": 235,
                "id": 3205,
                "iso2": "AR",
                "name": "Artigas Department"
            },
            {
                "country_code": "UY",
                "country_id": 235,
                "id": 3206,
                "iso2": "MA",
                "name": "Maldonado Department"
            },
            {
                "country_code": "UY",
                "country_id": 235,
                "id": 3207,
                "iso2": "RV",
                "name": "Rivera Department"
            },
            {
                "country_code": "UY",
                "country_id": 235,
                "id": 3208,
                "iso2": "CO",
                "name": "Colonia Department"
            },
            {
                "country_code": "UY",
                "country_id": 235,
                "id": 3209,
                "iso2": "DU",
                "name": "Durazno Department"
            },
            {
                "country_code": "UY",
                "country_id": 235,
                "id": 3210,
                "iso2": "RN",
                "name": "Río Negro Department"
            },
            {
                "country_code": "UY",
                "country_id": 235,
                "id": 3211,
                "iso2": "CL",
                "name": "Cerro Largo Department"
            },
            {
                "country_code": "UY",
                "country_id": 235,
                "id": 3212,
                "iso2": "PA",
                "name": "Paysandú Department"
            },
            {
                "country_code": "UY",
                "country_id": 235,
                "id": 3213,
                "iso2": "CA",
                "name": "Canelones Department"
            },
            {
                "country_code": "UY",
                "country_id": 235,
                "id": 3214,
                "iso2": "TT",
                "name": "Treinta y Tres Department"
            },
            {
                "country_code": "UY",
                "country_id": 235,
                "id": 3215,
                "iso2": "LA",
                "name": "Lavalleja Department"
            },
            {
                "country_code": "UY",
                "country_id": 235,
                "id": 3216,
                "iso2": "RO",
                "name": "Rocha Department"
            },
            {
                "country_code": "UY",
                "country_id": 235,
                "id": 3217,
                "iso2": "FD",
                "name": "Florida Department"
            },
            {
                "country_code": "UY",
                "country_id": 235,
                "id": 3218,
                "iso2": "MO",
                "name": "Montevideo Department"
            },
            {
                "country_code": "UY",
                "country_id": 235,
                "id": 3219,
                "iso2": "SO",
                "name": "Soriano Department"
            },
            {
                "country_code": "UY",
                "country_id": 235,
                "id": 3220,
                "iso2": "SA",
                "name": "Salto Department"
            },
            {
                "country_code": "UY",
                "country_id": 235,
                "id": 3221,
                "iso2": "TA",
                "name": "Tacuarembó Department"
            }
        ]
    },
    {
        "id": 236,
        "name": "Uzbekistan",
        "iso3": "UZB",
        "iso2": "UZ",
        "states": [
            {
                "country_code": "UZ",
                "country_id": 236,
                "id": 2536,
                "iso2": "TK",
                "name": "Tashkent"
            },
            {
                "country_code": "UZ",
                "country_id": 236,
                "id": 2537,
                "iso2": "NG",
                "name": "Namangan Region"
            },
            {
                "country_code": "UZ",
                "country_id": 236,
                "id": 2538,
                "iso2": "FA",
                "name": "Fergana Region"
            },
            {
                "country_code": "UZ",
                "country_id": 236,
                "id": 2539,
                "iso2": "XO",
                "name": "Xorazm Region"
            },
            {
                "country_code": "UZ",
                "country_id": 236,
                "id": 2540,
                "iso2": "AN",
                "name": "Andijan Region"
            },
            {
                "country_code": "UZ",
                "country_id": 236,
                "id": 2541,
                "iso2": "BU",
                "name": "Bukhara Region"
            },
            {
                "country_code": "UZ",
                "country_id": 236,
                "id": 2542,
                "iso2": "NW",
                "name": "Navoiy Region"
            },
            {
                "country_code": "UZ",
                "country_id": 236,
                "id": 2543,
                "iso2": "QA",
                "name": "Qashqadaryo Region"
            },
            {
                "country_code": "UZ",
                "country_id": 236,
                "id": 2544,
                "iso2": "SA",
                "name": "Samarqand Region"
            },
            {
                "country_code": "UZ",
                "country_id": 236,
                "id": 2545,
                "iso2": "JI",
                "name": "Jizzakh Region"
            },
            {
                "country_code": "UZ",
                "country_id": 236,
                "id": 2546,
                "iso2": "SU",
                "name": "Surxondaryo Region"
            },
            {
                "country_code": "UZ",
                "country_id": 236,
                "id": 2547,
                "iso2": "SI",
                "name": "Sirdaryo Region"
            },
            {
                "country_code": "UZ",
                "country_id": 236,
                "id": 2548,
                "iso2": "QR",
                "name": "Karakalpakstan"
            },
            {
                "country_code": "UZ",
                "country_id": 236,
                "id": 2549,
                "iso2": "TO",
                "name": "Tashkent Region"
            }
        ]
    },
    {
        "id": 237,
        "name": "Vanuatu",
        "iso3": "VUT",
        "iso2": "VU",
        "states": [
            {
                "country_code": "VU",
                "country_id": 237,
                "id": 4772,
                "iso2": "TOB",
                "name": "Torba"
            },
            {
                "country_code": "VU",
                "country_id": 237,
                "id": 4773,
                "iso2": "PAM",
                "name": "Penama"
            },
            {
                "country_code": "VU",
                "country_id": 237,
                "id": 4774,
                "iso2": "SEE",
                "name": "Shefa"
            },
            {
                "country_code": "VU",
                "country_id": 237,
                "id": 4775,
                "iso2": "MAP",
                "name": "Malampa"
            },
            {
                "country_code": "VU",
                "country_id": 237,
                "id": 4776,
                "iso2": "SAM",
                "name": "Sanma"
            },
            {
                "country_code": "VU",
                "country_id": 237,
                "id": 4777,
                "iso2": "TAE",
                "name": "Tafea"
            }
        ]
    },
    {
        "id": 238,
        "name": "Vatican City State (Holy See)",
        "iso3": "VAT",
        "iso2": "VA",
        "states": []
    },
    {
        "id": 239,
        "name": "Venezuela",
        "iso3": "VEN",
        "iso2": "VE",
        "states": [
            {
                "country_code": "VE",
                "country_id": 239,
                "id": 2034,
                "iso2": "H",
                "name": "Cojedes"
            },
            {
                "country_code": "VE",
                "country_id": 239,
                "id": 2035,
                "iso2": "I",
                "name": "Falcón"
            },
            {
                "country_code": "VE",
                "country_id": 239,
                "id": 2036,
                "iso2": "P",
                "name": "Portuguesa"
            },
            {
                "country_code": "VE",
                "country_id": 239,
                "id": 2037,
                "iso2": "M",
                "name": "Miranda"
            },
            {
                "country_code": "VE",
                "country_id": 239,
                "id": 2038,
                "iso2": "K",
                "name": "Lara"
            },
            {
                "country_code": "VE",
                "country_id": 239,
                "id": 2039,
                "iso2": "F",
                "name": "Bolívar"
            },
            {
                "country_code": "VE",
                "country_id": 239,
                "id": 2040,
                "iso2": "G",
                "name": "Carabobo"
            },
            {
                "country_code": "VE",
                "country_id": 239,
                "id": 2041,
                "iso2": "U",
                "name": "Yaracuy"
            },
            {
                "country_code": "VE",
                "country_id": 239,
                "id": 2042,
                "iso2": "V",
                "name": "Zulia"
            },
            {
                "country_code": "VE",
                "country_id": 239,
                "id": 2043,
                "iso2": "T",
                "name": "Trujillo"
            },
            {
                "country_code": "VE",
                "country_id": 239,
                "id": 2044,
                "iso2": "Z",
                "name": "Amazonas"
            },
            {
                "country_code": "VE",
                "country_id": 239,
                "id": 2045,
                "iso2": "J",
                "name": "Guárico"
            },
            {
                "country_code": "VE",
                "country_id": 239,
                "id": 2046,
                "iso2": "W",
                "name": "Federal Dependencies of Venezuela"
            },
            {
                "country_code": "VE",
                "country_id": 239,
                "id": 2047,
                "iso2": "D",
                "name": "Aragua"
            },
            {
                "country_code": "VE",
                "country_id": 239,
                "id": 2048,
                "iso2": "S",
                "name": "Táchira"
            },
            {
                "country_code": "VE",
                "country_id": 239,
                "id": 2049,
                "iso2": "E",
                "name": "Barinas"
            },
            {
                "country_code": "VE",
                "country_id": 239,
                "id": 2050,
                "iso2": "B",
                "name": "Anzoátegui"
            },
            {
                "country_code": "VE",
                "country_id": 239,
                "id": 2051,
                "iso2": "Y",
                "name": "Delta Amacuro"
            },
            {
                "country_code": "VE",
                "country_id": 239,
                "id": 2052,
                "iso2": "O",
                "name": "Nueva Esparta"
            },
            {
                "country_code": "VE",
                "country_id": 239,
                "id": 2053,
                "iso2": "L",
                "name": "Mérida"
            },
            {
                "country_code": "VE",
                "country_id": 239,
                "id": 2054,
                "iso2": "N",
                "name": "Monagas"
            },
            {
                "country_code": "VE",
                "country_id": 239,
                "id": 2055,
                "iso2": "X",
                "name": "Vargas"
            },
            {
                "country_code": "VE",
                "country_id": 239,
                "id": 2056,
                "iso2": "R",
                "name": "Sucre"
            },
            {
                "country_code": "VE",
                "country_id": 239,
                "id": 4855,
                "iso2": "A",
                "name": "Capital District"
            },
            {
                "country_code": "VE",
                "country_id": 239,
                "id": 4856,
                "iso2": "C",
                "name": "Apure"
            }
        ]
    },
    {
        "id": 240,
        "name": "Vietnam",
        "iso3": "VNM",
        "iso2": "VN",
        "states": [
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3768,
                "iso2": "66",
                "name": "Hưng Yên"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3769,
                "iso2": "45",
                "name": "Đồng Tháp"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3770,
                "iso2": "43",
                "name": "Bà Rịa-Vũng Tàu"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3771,
                "iso2": "21",
                "name": "Thanh Hóa"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3772,
                "iso2": "28",
                "name": "Kon Tum"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3773,
                "iso2": "71",
                "name": "Điện Biên"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3774,
                "iso2": "70",
                "name": "Vĩnh Phúc"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3775,
                "iso2": "20",
                "name": "Thái Bình"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3776,
                "iso2": "27",
                "name": "Quảng Nam"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3777,
                "iso2": "73",
                "name": "Hậu Giang"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3778,
                "iso2": "59",
                "name": "Cà Mau"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3779,
                "iso2": "3",
                "name": "Hà Giang"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3780,
                "iso2": "22",
                "name": "Nghệ An"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3781,
                "iso2": "46",
                "name": "Tiền Giang"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3782,
                "iso2": "4",
                "name": "Cao Bằng"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3783,
                "iso2": "HP",
                "name": "Haiphong"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3784,
                "iso2": "6",
                "name": "Yên Bái"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3785,
                "iso2": "57",
                "name": "Bình Dương"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3786,
                "iso2": "18",
                "name": "Ninh Bình"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3787,
                "iso2": "40",
                "name": "Bình Thuận"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3788,
                "iso2": "36",
                "name": "Ninh Thuận"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3789,
                "iso2": "67",
                "name": "Nam Định"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3790,
                "iso2": "49",
                "name": "Vĩnh Long"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3791,
                "iso2": "56",
                "name": "Bắc Ninh"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3792,
                "iso2": "9",
                "name": "Lạng Sơn"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3793,
                "iso2": "34",
                "name": "Khánh Hòa"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3794,
                "iso2": "44",
                "name": "An Giang"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3795,
                "iso2": "7",
                "name": "Tuyên Quang"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3796,
                "iso2": "50",
                "name": "Bến Tre"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3797,
                "iso2": "58",
                "name": "Bình Phước"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3798,
                "iso2": "26",
                "name": "Thừa Thiên-Huế"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3799,
                "iso2": "14",
                "name": "Hòa Bình"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3800,
                "iso2": "47",
                "name": "Kiên Giang"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3801,
                "iso2": "68",
                "name": "Phú Thọ"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3802,
                "iso2": "63",
                "name": "Hà Nam"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3803,
                "iso2": "25",
                "name": "Quảng Trị"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3804,
                "iso2": "55",
                "name": "Bạc Liêu"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3805,
                "iso2": "51",
                "name": "Trà Vinh"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3806,
                "iso2": "DN",
                "name": "Da Nang"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3807,
                "iso2": "69",
                "name": "Thái Nguyên"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3808,
                "iso2": "41",
                "name": "Long An"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3809,
                "iso2": "24",
                "name": "Quảng Bình"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3810,
                "iso2": "HN",
                "name": "Hanoi"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3811,
                "iso2": "SG",
                "name": "Ho Chi Minh City"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3812,
                "iso2": "5",
                "name": "Sơn La"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3813,
                "iso2": "30",
                "name": "Gia Lai"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3814,
                "iso2": "13",
                "name": "Quảng Ninh"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3815,
                "iso2": "54",
                "name": "Bắc Giang"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3816,
                "iso2": "23",
                "name": "Hà Tĩnh"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3817,
                "iso2": "2",
                "name": "Lào Cai"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3818,
                "iso2": "35",
                "name": "Lâm Đồng"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3819,
                "iso2": "52",
                "name": "Sóc Trăng"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3820,
                "iso2": "15",
                "name": "Hà Tây"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3821,
                "iso2": "39",
                "name": "Đồng Nai"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3822,
                "iso2": "53",
                "name": "Bắc Kạn"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3823,
                "iso2": "72",
                "name": "Đắk Nông"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3824,
                "iso2": "32",
                "name": "Phú Yên"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3825,
                "iso2": "1",
                "name": "Lai Châu"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3826,
                "iso2": "37",
                "name": "Tây Ninh"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3827,
                "iso2": "61",
                "name": "Hải Dương"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3828,
                "iso2": "29",
                "name": "Quảng Ngãi"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3829,
                "iso2": "33",
                "name": "Đắk Lắk"
            },
            {
                "country_code": "VN",
                "country_id": 240,
                "id": 3830,
                "iso2": "31",
                "name": "Bình Định"
            }
        ]
    },
    {
        "id": 241,
        "name": "Virgin Islands (British)",
        "iso3": "VGB",
        "iso2": "VG",
        "states": []
    },
    {
        "id": 242,
        "name": "Virgin Islands (US)",
        "iso3": "VIR",
        "iso2": "VI",
        "states": []
    },
    {
        "id": 243,
        "name": "Wallis And Futuna Islands",
        "iso3": "WLF",
        "iso2": "WF",
        "states": []
    },
    {
        "id": 244,
        "name": "Western Sahara",
        "iso3": "ESH",
        "iso2": "EH",
        "states": []
    },
    {
        "id": 245,
        "name": "Yemen",
        "iso3": "YEM",
        "iso2": "YE",
        "states": [
            {
                "country_code": "YE",
                "country_id": 245,
                "id": 1231,
                "iso2": "TA",
                "name": "Ta'izz Governorate"
            },
            {
                "country_code": "YE",
                "country_id": 245,
                "id": 1232,
                "iso2": "SA",
                "name": "Sana'a"
            },
            {
                "country_code": "YE",
                "country_id": 245,
                "id": 1233,
                "iso2": "IB",
                "name": "Ibb Governorate"
            },
            {
                "country_code": "YE",
                "country_id": 245,
                "id": 1234,
                "iso2": "MA",
                "name": "Ma'rib Governorate"
            },
            {
                "country_code": "YE",
                "country_id": 245,
                "id": 1235,
                "iso2": "MW",
                "name": "Al Mahwit Governorate"
            },
            {
                "country_code": "YE",
                "country_id": 245,
                "id": 1236,
                "iso2": "SN",
                "name": "Sana'a Governorate"
            },
            {
                "country_code": "YE",
                "country_id": 245,
                "id": 1237,
                "iso2": "AB",
                "name": "Abyan Governorate"
            },
            {
                "country_code": "YE",
                "country_id": 245,
                "id": 1238,
                "iso2": "HD",
                "name": "Hadhramaut Governorate"
            },
            {
                "country_code": "YE",
                "country_id": 245,
                "id": 1239,
                "iso2": "SU",
                "name": "Socotra Governorate"
            },
            {
                "country_code": "YE",
                "country_id": 245,
                "id": 1240,
                "iso2": "BA",
                "name": "Al Bayda' Governorate"
            },
            {
                "country_code": "YE",
                "country_id": 245,
                "id": 1241,
                "iso2": "HU",
                "name": "Al Hudaydah Governorate"
            },
            {
                "country_code": "YE",
                "country_id": 245,
                "id": 1242,
                "iso2": "AD",
                "name": "'Adan Governorate"
            },
            {
                "country_code": "YE",
                "country_id": 245,
                "id": 1243,
                "iso2": "JA",
                "name": "Al Jawf Governorate"
            },
            {
                "country_code": "YE",
                "country_id": 245,
                "id": 1244,
                "iso2": "HJ",
                "name": "Hajjah Governorate"
            },
            {
                "country_code": "YE",
                "country_id": 245,
                "id": 1245,
                "iso2": "LA",
                "name": "Lahij Governorate"
            },
            {
                "country_code": "YE",
                "country_id": 245,
                "id": 1246,
                "iso2": "DH",
                "name": "Dhamar Governorate"
            },
            {
                "country_code": "YE",
                "country_id": 245,
                "id": 1247,
                "iso2": "SH",
                "name": "Shabwah Governorate"
            },
            {
                "country_code": "YE",
                "country_id": 245,
                "id": 1248,
                "iso2": "RA",
                "name": "Raymah Governorate"
            },
            {
                "country_code": "YE",
                "country_id": 245,
                "id": 1249,
                "iso2": "SD",
                "name": "Saada Governorate"
            },
            {
                "country_code": "YE",
                "country_id": 245,
                "id": 1250,
                "iso2": "AM",
                "name": "'Amran Governorate"
            },
            {
                "country_code": "YE",
                "country_id": 245,
                "id": 1251,
                "iso2": "MR",
                "name": "Al Mahrah Governorate"
            }
        ]
    },
    {
        "id": 246,
        "name": "Zambia",
        "iso3": "ZMB",
        "iso2": "ZM",
        "states": [
            {
                "country_code": "ZM",
                "country_id": 246,
                "id": 1982,
                "iso2": "5",
                "name": "Northern Province"
            },
            {
                "country_code": "ZM",
                "country_id": 246,
                "id": 1983,
                "iso2": "1",
                "name": "Western Province"
            },
            {
                "country_code": "ZM",
                "country_id": 246,
                "id": 1984,
                "iso2": "8",
                "name": "Copperbelt Province"
            },
            {
                "country_code": "ZM",
                "country_id": 246,
                "id": 1985,
                "iso2": "6",
                "name": "Northwestern Province"
            },
            {
                "country_code": "ZM",
                "country_id": 246,
                "id": 1986,
                "iso2": "2",
                "name": "Central Province"
            },
            {
                "country_code": "ZM",
                "country_id": 246,
                "id": 1987,
                "iso2": "4",
                "name": "Luapula Province"
            },
            {
                "country_code": "ZM",
                "country_id": 246,
                "id": 1988,
                "iso2": "9",
                "name": "Lusaka Province"
            },
            {
                "country_code": "ZM",
                "country_id": 246,
                "id": 1989,
                "iso2": "10",
                "name": "Muchinga Province"
            },
            {
                "country_code": "ZM",
                "country_id": 246,
                "id": 1990,
                "iso2": "7",
                "name": "Southern Province"
            },
            {
                "country_code": "ZM",
                "country_id": 246,
                "id": 1991,
                "iso2": "3",
                "name": "Eastern Province"
            }
        ]
    },
    {
        "id": 247,
        "name": "Zimbabwe",
        "iso3": "ZWE",
        "iso2": "ZW",
        "states": [
            {
                "country_code": "ZW",
                "country_id": 247,
                "id": 1951,
                "iso2": "ME",
                "name": "Mashonaland East Province"
            },
            {
                "country_code": "ZW",
                "country_id": 247,
                "id": 1952,
                "iso2": "MS",
                "name": "Matabeleland South Province"
            },
            {
                "country_code": "ZW",
                "country_id": 247,
                "id": 1953,
                "iso2": "MW",
                "name": "Mashonaland West Province"
            },
            {
                "country_code": "ZW",
                "country_id": 247,
                "id": 1954,
                "iso2": "MN",
                "name": "Matabeleland North Province"
            },
            {
                "country_code": "ZW",
                "country_id": 247,
                "id": 1955,
                "iso2": "MC",
                "name": "Mashonaland Central Province"
            },
            {
                "country_code": "ZW",
                "country_id": 247,
                "id": 1956,
                "iso2": "BU",
                "name": "Bulawayo Province"
            },
            {
                "country_code": "ZW",
                "country_id": 247,
                "id": 1957,
                "iso2": "MI",
                "name": "Midlands Province"
            },
            {
                "country_code": "ZW",
                "country_id": 247,
                "id": 1958,
                "iso2": "HA",
                "name": "Harare Province"
            },
            {
                "country_code": "ZW",
                "country_id": 247,
                "id": 1959,
                "iso2": "MA",
                "name": "Manicaland"
            },
            {
                "country_code": "ZW",
                "country_id": 247,
                "id": 1960,
                "iso2": "MV",
                "name": "Masvingo Province"
            }
        ]
    },
    {
        "id": 248,
        "name": "Kosovo",
        "iso3": "XKX",
        "iso2": "XK",
        "states": [
            {
                "country_code": "XK",
                "country_id": 248,
                "id": 3723,
                "iso2": "XPR",
                "name": "Prizren District"
            },
            {
                "country_code": "XK",
                "country_id": 248,
                "id": 3738,
                "iso2": "XPE",
                "name": "Peć District"
            },
            {
                "country_code": "XK",
                "country_id": 248,
                "id": 4874,
                "iso2": "XUF",
                "name": "Uroševac District (Ferizaj)"
            },
            {
                "country_code": "XK",
                "country_id": 248,
                "id": 4876,
                "iso2": "XDG",
                "name": "Đakovica District (Gjakove)"
            },
            {
                "country_code": "XK",
                "country_id": 248,
                "id": 4877,
                "iso2": "XGJ",
                "name": "Gjilan District"
            },
            {
                "country_code": "XK",
                "country_id": 248,
                "id": 4878,
                "iso2": "XKM",
                "name": "Kosovska Mitrovica District"
            },
            {
                "country_code": "XK",
                "country_id": 248,
                "id": 4879,
                "iso2": "XPI",
                "name": "Pristina (Priştine)"
            }
        ]
    },
    {
        "id": 249,
        "name": "Curaçao",
        "iso3": "CUW",
        "iso2": "CW",
        "states": []
    },
    {
        "id": 250,
        "name": "Sint Maarten (Dutch part)",
        "iso3": "SXM",
        "iso2": "SX",
        "states": []
    }
]
